import { Props } from '@/models/icon';

import { ResponseLength } from '../models';

type ToneIconProps = {
  type: ResponseLength;
} & Props;

export const ResponseLengthIcon = ({
  size = 24,
  color = 'var(--color-foreground)',
  type,
  ...rest
}: ToneIconProps) => {
  switch (type) {
    case 'short':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="none"
          viewBox="0 0 24 24"
          {...rest}
        >
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 10h18M3 14h8"
          />
        </svg>
      );
    case 'medium':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          fill="none"
          viewBox="0 0 24 24"
          {...rest}
        >
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3 8h18M3 12h18M3 16h8"
          />
        </svg>
      );
    case 'long':
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...rest}
        >
          <path
            d="M3 10H21M3 14H21M3 18H11M3 6H21"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};
