import { useMemo } from 'react';

import { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { CustomMenu } from '@/components/atoms/Menu/Menu';
import useOperatingSystem from '@/hooks/useOperatingSystem';
import { CopiedObject } from '@/util/CopyPasteController';

type Props = {
  options?: {
    label: string;
    shortcut: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleDelete: () => void;
  open: boolean;
  handleAddComment?: () => void;
  handleCopy?: (obj: CopiedObject) => void;
  handlePaste?: () => void;
  objectToCopy?: CopiedObject;
  disableCopy?: boolean;
};

const CUSTOM_STYLES = {
  '& .MuiMenuItem-root': {
    justifyContent: 'space-between',
    gap: 'var(--space-16)',
  },
};

export function ActionMenu({
  options = [],
  anchorEl,
  handleClose,
  open,
  handleDelete,
  handleCopy,
  handlePaste,
  objectToCopy,
  disableCopy = false,
}: MenuProps & Props) {
  const { metaKey, deleteShortcut } = useOperatingSystem();
  const { t } = useTranslation();

  const defaultOptions = useMemo(
    () => [
      {
        label: t('dialog.actions.menu.copy'),
        shortcut: `${metaKey} + C`,
        onClick: () => handleCopy(objectToCopy),
        disabled: disableCopy,
      },
      {
        label: t('dialog.actions.menu.paste'),
        shortcut: `${metaKey} + V`,
        onClick: handlePaste,
      },

      {
        label: t('dialog.actions.menu.delete'),
        shortcut: deleteShortcut,
        onClick: handleDelete,
      },
    ],
    [
      t,
      metaKey,
      disableCopy,
      handlePaste,
      deleteShortcut,
      handleDelete,
      handleCopy,
      objectToCopy,
    ]
  );

  const renderOptions = options.length ? options : defaultOptions;

  return (
    <CustomMenu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      sx={CUSTOM_STYLES}
    >
      {renderOptions.map((option) => (
        <MenuItem
          onClick={() => {
            // Close the menu after clicking on an option
            handleClose();
            option.onClick();
          }}
          disabled={option.disabled}
          key={option.label}
        >
          <Typography
            variant="body-regular"
            color="var(--color-foreground-muted)"
          >
            {option.label}
          </Typography>

          <Typography variant="body-regular" color="var(--color-bg-muted)">
            {option.shortcut}
          </Typography>
        </MenuItem>
      ))}
    </CustomMenu>
  );
}
