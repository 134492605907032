import { useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import retry from 'async-await-retry';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  versionsEndpoints as endpoints,
  brainsEndpoints,
  dialogsEndpoints,
  intentsEndpoints,
  webhooksEndpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import { Brain } from '@/models/brain';
import { Diff, Version, Versions } from '@/models/version';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { exportBrain as downloadBrain } from '@/util/file-manager';
import { resolveBrainsPath } from '@/util/util';

import useFeatureFlag from './useFeatureFlag';
import useHomeCheckList, { AccountUserPrefsEnum } from './useHomeCheckList';

export const API = Object.freeze({
  listVersions: async (brainId: string): Promise<Versions> =>
    callGet(endpoints.versions(brainId)),

  getVersion: async (brainId: string, v: number): Promise<Version> =>
    callGet(endpoints.version(brainId, v)),

  exportVersion: async (brainId: string, v: number): Promise<Version> =>
    callGet(`${endpoints.version(brainId, v)}?export=true`),

  createVersion: async (
    brainId: string,
    description: string
  ): Promise<Version> => callPost(endpoints.versions(brainId), { description }),

  deleteVersion: async (brainId: string, v: number): Promise<Version> =>
    callDelete(endpoints.version(brainId, v)),

  revertVersion: async (brainId: string, version: number): Promise<Brain> =>
    callPut(brainsEndpoints.brain(brainId), {
      version,
    }),

  getDiff: async (
    brainId: string,
    fromVersion: number,
    toVersion: number
  ): Promise<Diff> =>
    callGet(
      `${endpoints.diff(brainId)}?from_version=${fromVersion}&to_version=${toVersion}`
    ),
});

export const onVersionCreated = (
  queryClient: QueryClient,
  brain_id: string,
  version: Version
) => {
  queryClient.setQueryData<Versions>(
    [endpoints.versions(brain_id)],
    (prev) => ({
      versions: [version, ...(prev?.versions || [])],
    })
  );
};

export const onVersionRemoved = (
  queryClient: QueryClient,
  brain_id: string,
  version: number
) => {
  queryClient.setQueryData<Versions>(
    [endpoints.versions(brain_id)],
    (prev: Versions) => ({
      versions: (prev?.versions || []).filter((acc) => acc.version !== version),
    })
  );
  queryClient.invalidateQueries({
    queryKey: [endpoints.version(brain_id, version)],
  });
};

const useVersions = (brainId: string, versionNumber?: number) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { markAsComplete } = useHomeCheckList();

  const { slug, account } = useAccount();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { brain } = useBrains(brainId);
  const { ai_agents } = useFeatureFlag();

  const { data: versions, status: getStatus } = useQuery<Versions, Error>({
    queryKey: [endpoints.versions(brainId)],
    queryFn: () => API.listVersions(brainId),
    enabled: !!brainId,
  });

  const { data: version } = useQuery<Version, Error>({
    queryKey: [endpoints.version(brainId, versionNumber)],
    queryFn: () => API.getVersion(brainId, versionNumber),
    enabled: !!brainId && versionNumber > 0,
  });

  const {
    mutate: createVersion,
    status: createStatus,
    isPending: isCreatePending,
  } = useMutation<Version, Error, string>({
    mutationFn: (description) => API.createVersion(brainId, description),
    onSuccess: async (resp) => {
      // Refetch the version until the training is completed

      const isVersionTrained = async (v: Version) => {
        const result = await API.getVersion(brainId, v.version);
        if (result.status === 'available') {
          return Promise.resolve(result);
        }
        return Promise.reject(result);
      };

      const trainedVersion = await retry(isVersionTrained, [resp], {
        interval: 1500,
        exponential: true,
        factor: 2,
        retriesMax: 5,
      });

      onVersionCreated(queryClient, brainId, trainedVersion);
      markAsComplete(AccountUserPrefsEnum.CREATE_VERSION);

      queryClient.setQueryData<Version>(
        [
          endpoints.version(
            trainedVersion.brain_parent_id,
            trainedVersion.version
          ),
        ],
        () => trainedVersion
      );
      dispatch(addTemporalToast('success', t('version.version_created')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteVersion, status: deleteStatus } = useMutation<
    Version,
    Error,
    number
  >({
    mutationFn: (v) => API.deleteVersion(brainId, v),
    onSuccess: (resp) => {
      onVersionRemoved(queryClient, brainId, resp.version);
      dispatch(addTemporalToast('success', t('version.version_deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: restoreVersion, status: restoreStatus } = useMutation<
    Brain,
    Error,
    number
  >({
    mutationFn: (v) => API.revertVersion(brainId, v),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [brainsEndpoints.brain(brainId)],
        }),
        queryClient.invalidateQueries({ queryKey: [brainsEndpoints.brains] }),
        queryClient.invalidateQueries({
          queryKey: [webhooksEndpoints.webhooks(brainId)],
        }),
        queryClient.invalidateQueries({
          queryKey: [intentsEndpoints.intents(brainId)],
        }),
        queryClient.invalidateQueries({
          queryKey: [dialogsEndpoints.dialogs(brainId)],
        }),
      ]);
      dispatch(addTemporalToast('success', `Brain  reverted`));
      navigate(resolveBrainsPath(`/${slug}/brains/${brainId}`, ai_agents));
    },
    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const exportVersion = async (version: number) => {
    const eBrain = await callGet(
      `/www/api/v1/brains/${brainId}/versions/${version}?export=true`
    );
    downloadBrain({
      ...eBrain,
      name: `${brain?.name} - ${
        version == 0 ? t('common.draft') : `v${version}`
      }`,
    });
    dispatch(
      addTemporalToast('success', t('version.exported', { 0: version }))
    );
  };

  const versionsWithoutDraft = useMemo(
    () =>
      versions?.versions
        .filter((x) => x.version !== 0)
        .sort((a, b) => b.version - a.version),
    [versions]
  );

  return {
    versions: versions?.versions,
    maxVersions: account?.max_versions,
    maxVersionsReached: versions?.versions?.length - 1 >= account?.max_versions,
    willReachVersionsLimit:
      versions?.versions?.length === account?.max_versions,
    getStatus,
    version,
    createStatus,
    deleteStatus,
    restoreStatus,
    createVersion,
    deleteVersion,
    restoreVersion,
    exportVersion,
    versionsWithoutDraft,
    isCreatePending,
  };
};

export default useVersions;
