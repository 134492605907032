import { useMemo, memo, useState, useRef, useEffect, useCallback } from 'react';

import cn from 'classnames';
import {
  CopyIcon,
  MessageCircleIcon,
  StarIcon,
  Trash2Icon,
  UploadIcon,
  ZapIcon,
} from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import AvatarGroups from '@/components/atoms/Avatar/AvatarGroup/AvatarGroup';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { StarFilledIcon } from '@/components/atoms/Icons/StarFilledIcon';
import InlineStatus from '@/components/atoms/InlineStatus/InlineStatus';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useUser from '@/hooks/useUser';
import { Brain } from '@/models/brain';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { toggleTryIt } from '@/modules/TryIt/redux/actions';
import { pushModal } from '@/redux/modals/actions';
import { selectOnlineViewersByBrainId } from '@/redux/presence/selectors';
import { trackEvent } from '@/segment/segment';
import { resolveBrainsPath } from '@/util/util';

import Tile from './Tile';
import TileMenu from './TileMenu';
import { BRAIN_DELETE, MODAL_TRYIT } from '../Modals/ModalConductor';

import styles from './TileBrain.module.scss';

interface Props {
  brain: Brain;
  slug: string;
  showMenu?: boolean;
  menuItems?: {
    name: string;
    type?: string;
    icon: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onClick: () => void;
    keepOpen?: boolean;
    tooltipMessage?: string;
  }[];
  className?: string;
  isFavorite?: boolean;
  handleFavorite?: (event: React.FormEvent, brainId: Brain['brain_id']) => void;
  hasRecommendations?: boolean;
  hasActions?: boolean;
}

function TileBrain({
  brain,
  menuItems,
  slug,
  isFavorite = false,
  handleFavorite,
  hasRecommendations = false,
  hasActions = false,
}: Props) {
  const { t } = useTranslation();
  const { exportBrain } = useBrains();
  const { user } = useUser();
  const { ai_agents } = useFeatureFlag();

  const { viewers } = useSelector((state: RootState) =>
    selectOnlineViewersByBrainId(state, brain?.brain_id)
  );

  const viewersWithoutMe = useMemo(
    () => viewers.filter(({ user_id }) => user_id !== user?.user_id),
    [user?.user_id, viewers]
  );

  const [tooltipMessage, setTooltipMessage] = useState('');
  const intervalId = useRef(null);

  const { brain_id, name } = brain ?? {};
  const {
    dialog_count,
    version_count,
    entity_count,
    intent_count,
    language,
    last_trained,
    status,
    metadata,
  } = brain;

  const isOnboarding = metadata?.onboarding_status === 'unavailable';
  const onboardingType = metadata?.onboarding_type as undefined | string;
  const isTeamOnboardingAccount = slug === 'team-onboarding';

  const hasMetadata = Object.keys(metadata || {}).length > 0;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tileMenuItems = useMemo(
    () =>
      menuItems || [
        {
          name: t('common.test'),
          icon: (
            <MessageCircleIcon
              color="var(--color-foreground-muted)"
              size={16}
            />
          ),
          onClick: () => {
            trackEvent(EventName.ClickTryIt, { brain_id });
            dispatch(toggleTryIt());
            dispatch(pushModal(MODAL_TRYIT, { brainId: brain_id }));
            navigate(
              resolveBrainsPath(`/${slug}/brains/${brain_id}`, ai_agents)
            );
          },
        },
        {
          name: t('common.export'),
          icon: <UploadIcon color="var(--color-foreground-muted)" size={16} />,
          onClick: () => {
            trackEvent(EventName.ClickExportBrain, { brain_id });
            exportBrain(brain_id);
          },
        },
        {
          name: t('brains.copy_id'),
          icon: <CopyIcon size={16} color="var(--color-foreground-muted)" />,
          keepOpen: true,
          tooltipMessage,
          onClick: () => {
            trackEvent(EventName.ClickCopyBrainId, { brain_id });
            const text = brain.brain_id;
            navigator.clipboard.writeText(text);
            setTooltipMessage(t('common.copied'));
            intervalId.current = setTimeout(() => {
              setTooltipMessage('');
            }, 1200);
          },
        },
        {
          name: t('common.delete'),
          type: 'delete',
          icon: <Trash2Icon size={16} />,
          onClick: () => {
            const warnProps = {
              brainName: name,
              brainId: brain_id,
            };
            dispatch(pushModal(BRAIN_DELETE, warnProps));
          },
        },
      ],
    [
      ai_agents,
      brain.brain_id,
      brain_id,
      dispatch,
      exportBrain,
      menuItems,
      name,
      navigate,
      slug,
      t,
      tooltipMessage,
    ]
  );

  const getStatusBadgeVariant = useCallback(
    (status: string, isUnavailable: boolean, hasMetadata: boolean) => {
      switch (status) {
        case 'available':
          return 'success';
        case 'training':
          return 'info';
        case 'unavailable':
          return isUnavailable && hasMetadata ? 'info' : 'warning';
        default:
          return 'warning';
      }
    },
    []
  );

  useEffect(() => {
    return () => clearInterval(intervalId.current);
  }, []);

  const getDescription = useCallback(() => {
    if (isOnboarding) {
      return t('brains.onboarding_training', {
        0: t(`channels.${onboardingType}`),
      });
    }

    return t('brains.assets', {
      0: dialog_count,
      1: intent_count,
      2: entity_count,
      3: version_count,
    });
  }, [
    t,
    isOnboarding,
    dialog_count,
    intent_count,
    entity_count,
    version_count,
    onboardingType,
  ]);

  const renderTimestamp = useCallback(() => {
    const timeStamp = last_trained ? moment(last_trained).fromNow() : '-';

    return (
      <span className={styles.id}>{`${t(
        'brains.last_trained'
      )}: ${timeStamp}`}</span>
    );
  }, [t, last_trained]);

  const renderBrainsViewing = useCallback(() => {
    return (
      <div className={styles.brainsViewing}>
        <AvatarGroups>
          {viewersWithoutMe.map((x) => (
            <Avatar
              key={x.user_id}
              userId={x.user_id}
              size="small"
              hideStatus
            />
          ))}
        </AvatarGroups>
      </div>
    );
  }, [viewersWithoutMe]);

  return (
    <Link
      aria-label={name}
      className={cn(styles.link, styles.tile, 'tile', {
        [styles.disabled]: isOnboarding && !isTeamOnboardingAccount,
      })}
      to={resolveBrainsPath(`/${slug}/brains/${brain_id}`, ai_agents)}
      data-testid="brain-tile"
    >
      <Tile className={styles.wrapper}>
        <div className={styles.icon}>
          <ZapIcon color="var(--color-foreground-muted)" />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <span className={styles.name}>{name}</span>
            <span className={styles.country}>{`(${language})`}</span>
            {renderBrainsViewing()}
          </div>
          <span className={styles.desc}>{getDescription()}</span>
          <span className={styles.footer}>
            {renderTimestamp()}
            <span className={styles.details}>
              {hasRecommendations && (
                <InlineStatus
                  color="var(--color-foreground-muted)"
                  value="1"
                  text={t('brains.recommendations')}
                />
              )}
              {hasActions && (
                <InlineStatus
                  color="var(--color-warning)"
                  value="2"
                  text={t('brains.actions_required')}
                />
              )}

              {!last_trained && (
                <StatusBadge
                  label={isOnboarding ? t('status.training') : status}
                  withIcon
                  variant={getStatusBadgeVariant(
                    status,
                    isOnboarding,
                    hasMetadata
                  )}
                />
              )}
            </span>
          </span>
        </div>
        <div className={styles.menu}>
          {handleFavorite && (
            <IconButton
              ariaLabel="favorite"
              onClick={(e) => handleFavorite(e, brain.brain_id)}
            >
              {isFavorite ? (
                <StarFilledIcon
                  size={20}
                  color="var(--color-foreground-muted)"
                />
              ) : (
                <StarIcon size={20} color="var(--color-foreground-muted)" />
              )}
            </IconButton>
          )}
          <TileMenu menuItems={tileMenuItems} />
        </div>
      </Tile>
    </Link>
  );
}

export default memo(TileBrain);
