import { useCallback } from 'react';

import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import {
  CircleHelpIcon,
  CirclePlusIcon,
  Clock3Icon,
  FlagIcon,
  LocateFixedIcon,
} from 'lucide-react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IconizedDialog from '@/components/atoms/IconizedDialog/IconizedDialog';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { actions } from '@/models/permissions';
import { Presence } from '@/models/presence';
import { RootState } from '@/models/state';
import { useDialogReminder } from '@/modules/aiAgents/hooks/useDialogReminder';
import { getPermissions } from '@/redux/permissions/selectors';

import { SubnavType } from '../SubNav';

import styles from './TreeView.module.scss';

export interface ItemProps {
  id: string;
  link: string;
  name: string;
  collection: string;
  hasSuggestions?: boolean;
  isOver?: boolean;
  isOrphan?: boolean;
  needsAction?: boolean;
  whoIsViewing?: Presence[];
  selected: boolean;
  type?: 'intent' | 'event' | 'unknown';
  subnavType?: SubnavType;
}
export const renderDialogIcon = (type: string) => {
  if (type === 'intent') {
    return <LocateFixedIcon size={16} />;
  }
  if (type === 'event') {
    return <FlagIcon size={16} />;
  }
  if (type === 'unknown') {
    return <CircleHelpIcon size={16} />;
  }
  // This is a workaround to display the correct icon for the create sample dialog
  if (type === 'create_sample_dialog') {
    return <CirclePlusIcon size={16} color="currentColor" />;
  }
  return null;
};

function Item({
  name,
  id,
  link,
  collection,
  hasSuggestions = false,
  needsAction,
  isOrphan,
  whoIsViewing = [],
  selected,
  type,
  subnavType,
}: ItemProps) {
  const { t } = useTranslation();
  const { hasReminders } = useDialogReminder(id);
  const { ai_agents, ai_agents_reminder } = useFeatureFlag();
  const [{ isDragging }, dragRef] = useDrag({
    item: { id, name, collection },
    type: 'treeItem',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const isOffTopics = id === 'offtopics';
  const isRecommendation =
    collection === t('intent.recommendations.collection');

  const canWrite = useSelector(
    (state: RootState) => getPermissions(state, 'brains', actions.WRITE),
    shallowEqual
  );

  const showDot = !isRecommendation && (hasSuggestions || needsAction);

  const dotColor = useCallback(() => {
    if (hasSuggestions) {
      return 'var(--color-foreground-muted)';
    }
    if (needsAction && subnavType === 'intents') {
      return 'var(--color-warning)';
    }
    return null;
  }, [hasSuggestions, subnavType, needsAction]);

  return (
    <div
      ref={canWrite ? dragRef : null}
      className={cn(styles.treeItem, {
        [styles.isDragging]: isDragging,
      })}
    >
      <NavLink
        className={({ isActive }) =>
          cn(styles.link, {
            [styles.active]: isActive,
            [styles.isOrphan]: isOrphan,
            [styles.selected]: selected,
            [styles.hasIcon]: subnavType === 'dialogs',
          })
        }
        end
        to={link}
        title={name}
      >
        <IconizedDialog
          dialogName={name}
          type={type}
          showDot={showDot}
          isOffTopics={isOffTopics}
          whoIsViewing={whoIsViewing}
          dotColor={dotColor}
        />

        {ai_agents && ai_agents_reminder && hasReminders && (
          <Tooltip title={t('ai_agents.reminder.assigned_to_reminder')} arrow>
            <Clock3Icon size="16" />
          </Tooltip>
        )}
      </NavLink>
    </div>
  );
}
export default Item;
