import 'keen-slider/keen-slider.min.css';

import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { popModal } from '@/redux/modals/actions';

import styles from './ModalCarousel.module.scss';

type Props = {
  cards: {
    title: string;
    body: React.ReactNode | string;
    img: string;
  }[];
  onSubmit: () => void;
  onBlur: () => void;
};

const Carousel = ({ cards, onSubmit, onBlur }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slideChanged(slider) {
      setCurrentSlide(slider?.track?.details?.rel);
    },
    initial: 0,
  });

  const onModalClose = () => {
    onBlur();
    dispatch(popModal());
  };
  const handeNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentSlide !== cards.length - 1) {
      event.stopPropagation();
      instanceRef.current?.next();
    } else {
      onSubmit();
    }
  };

  return (
    <div className={cn(styles.container)}>
      <div className={styles.sliderContainer}>
        <div ref={sliderRef} className={cn('keen-slider', styles.slider)}>
          {cards.map(({ img, title, body }) => (
            <div
              key={`${title}}`}
              className={cn('keen-slider__slide', styles.card)}
            >
              <div className={styles.imageContainer}>
                <img src={img} alt={title} referrerPolicy="no-referrer" />
              </div>
              <div className={styles.content}>
                <Typography
                  component="p"
                  variant="subheading-bold"
                  className={styles.title}
                >
                  {title}
                </Typography>
                <Typography
                  color="var(--color-foreground-muted)"
                  className={styles.subtitle}
                >
                  {body}
                </Typography>
              </div>
            </div>
          ))}
          <div className={styles.pagination}>
            {cards?.map((card, idx) => {
              return (
                <button
                  data-testid="carousel-dot"
                  key={card?.title}
                  onClick={() => instanceRef.current?.moveToIdx(idx)}
                  className={cn(styles.dot, {
                    [styles.active]: currentSlide === idx,
                  })}
                />
              );
            })}
          </div>
        </div>

        <div className={styles.buttonsContainer}>
          {currentSlide === cards.length - 1 && (
            <Button
              variant="tertiary"
              disabled={currentSlide === 0}
              onClick={onModalClose}
            >
              {t('dialog.carousel.not_now')}
            </Button>
          )}

          <Button
            isFullWidth={currentSlide !== cards.length - 1}
            variant="primary"
            onClick={handeNextButtonClick}
          >
            {currentSlide === cards.length - 1
              ? t('common.update')
              : t('dialog.carousel.next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
