import { useCallback, useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { BackgroundWrapper } from '@/components/atoms/BackgroundWrapper/BackgroundWrapper';
import Button from '@/components/atoms/Button/Button/Button';
import Dropdown from '@/components/atoms/DropdownList/PlaceholderDropdown';
import Input from '@/components/atoms/Input/Input';
import Link from '@/components/atoms/Link/Link';
import { usePreferences } from '@/hooks/usePreferences';
import useUser from '@/hooks/useUser';
import { Preferences } from '@/models/user';
import { loadUser } from '@/redux/user/actions';
import {
  selectIsAuthenticated,
  selectIsTempUser,
  selectPendingInvitation,
} from '@/redux/user/selectors';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '@/util/constants';
import { removeEmptyValues } from '@/util/util';
import { LENGTH_S, createInputSchema, isRequired } from '@/util/validator';

import { MemberBadge } from '../../components/MemberBadge/MemberBadge';

import styles from './PersonalDetailsPage.module.scss';

type Form = {
  name: string;
  job: string;
  accept_terms: boolean;
  marketing_emails: boolean;
};

const JOB_OPTIONS = [
  'business_analyst',
  'customer_support',
  'designer',
  'developer',
  'executive',
  'hr_manager',
  'marketing',
  'product_manager',
  'sales',
  'other',
].map((j) => ({ value: j, label: j }));

export const PersonalDetailsPage = () => {
  // Custom hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateUserPreferences } = usePreferences();
  // Redux selectors
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isTempUser = useSelector(selectIsTempUser);
  const invitedAccount = useSelector(selectPendingInvitation);

  // RHF
  const { signUp, signUpStatus } = useUser();
  const {
    control,
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<Form>({
    defaultValues: {
      name: '',
      job: '',
      accept_terms: false,
      marketing_emails: false,
    },
    mode: 'onChange',
  });

  const handleCreateAccount = useCallback(
    ({ name }) => {
      signUp(
        { name, invitation_code: invitedAccount?.invitation_code },
        {
          onSuccess: () => {
            navigate('/invitation/welcome', { replace: true });
            updateUserPreferences(
              removeEmptyValues({
                job: getValues('job'),
                marketing_emails: getValues('marketing_emails'),
              }) as Preferences
            );
          },
        }
      );
    },
    [
      getValues,
      invitedAccount?.invitation_code,
      navigate,
      signUp,
      updateUserPreferences,
    ]
  );

  useEffect(() => {
    if (!isAuthenticated && !isTempUser) {
      dispatch(loadUser());
    }
  }, [dispatch, isAuthenticated, isTempUser]);

  // If user is already logged in, redirect to welcome page
  if (isAuthenticated) {
    return <Navigate to={'/profile/invitations'} />;
  }

  return (
    <BackgroundWrapper
      title={t('member_invitation.notification_invitation', {
        0: invitedAccount?.account_name,
      })}
      subtitle={t('member_invitation.add_details')}
    >
      <div className={styles.badge}>
        <MemberBadge
          accountAvatarUrl={invitedAccount?.account_avatar}
          roles={invitedAccount?.role_ids}
          accountName={invitedAccount?.account_name}
          name={watch('name')}
        />
      </div>

      <form
        className={styles.form}
        onSubmit={handleSubmit(handleCreateAccount)}
      >
        <fieldset>
          <Input
            size="medium"
            name="name"
            id="name"
            label={t('signup.name').toUpperCase()}
            placeholder={t('signup.name_placeholder')}
            lowercaseLabel
            register={register('name', createInputSchema(LENGTH_S))}
            errorMessage={errors.name?.message}
          />
          <Controller
            render={({ field: { value, onChange } }) => {
              return (
                <Dropdown
                  option={value}
                  setOption={onChange}
                  options={JOB_OPTIONS}
                  translationPrefix="signup.jobs."
                  label="job_function"
                  placeholder="job_function_placeholder"
                  uppercaseLabel
                />
              );
            }}
            name="job"
            control={control}
          />
        </fieldset>

        <fieldset>
          <FormControlLabel
            classes={{ label: styles.checkboxLabel }}
            control={
              <Controller
                name="marketing_emails"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    color="primary"
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={t('signup.agree_marketing')}
          />

          <FormControlLabel
            classes={{ label: styles.checkboxLabel }}
            control={
              <Controller
                name="accept_terms"
                control={control}
                rules={isRequired}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    color="primary"
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={
              <>
                <Trans i18nKey="signup.agree_terms">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={PRIVACY_POLICY_URL}
                  >
                    {t('signup.privacy_policy_link')}
                  </Link>
                  <Link
                    href={TERMS_OF_USE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('signup.terms_of_service_link')}
                  </Link>
                </Trans>
                *
              </>
            }
          />
        </fieldset>

        <Button
          type="submit"
          size="xlarge"
          isFullWidth
          className={styles.button}
          isLoading={signUpStatus === 'pending'}
          disabled={!isValid}
        >
          {t('signup.sign_up')}
        </Button>

        <Typography
          color="var(--color-foreground-muted)"
          variant="label-regular"
          className={styles.or}
        >
          {t('rules.or')}
        </Typography>
        <RouterLink to="/signup/welcome" className={styles.skip_button}>
          <Typography color="var(--color-foreground-muted)">
            {t('member_invitation.skip_invitation')}
          </Typography>
        </RouterLink>

        <Typography
          className={styles.responsiveAccountName}
          variant="body-regular"
          color="var(--color-foreground-muted)"
        >
          {invitedAccount?.account_name} {t('common.account')}
        </Typography>
      </form>
    </BackgroundWrapper>
  );
};
