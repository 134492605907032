import { PropsWithChildren } from 'react';

import { LockIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { usePermissions } from '@/hooks/usePermissions';
import { actions, Module } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import Loading from '../atoms/Loading/Loading';

const PermissionsWrapper = ({
  module,
  children,
  resource_id,
}: PropsWithChildren<{ module: Module; resource_id?: string }>) => {
  const { isLoading, listStatus } = usePermissions();
  const { t } = useTranslation();
  const canRead = useSelector((state: RootState) =>
    getPermissions(state, module, actions.READ, resource_id)
  );

  if (isLoading) {
    return <Loading />;
  }
  if (!canRead && listStatus === 'success') {
    return (
      <>
        <PageContentWrapper fullHeight>
          <TileEmptyPage
            title={t('permissions.no_access')}
            notClickable
            isFullHeight
            body={
              <>
                {t('permissions.contact_admin')} <br />{' '}
              </>
            }
            icon={<LockIcon size={100} color="var(--color-foreground-muted)" />}
          />
        </PageContentWrapper>
      </>
    );
  }

  return <>{children}</>;
};

export default PermissionsWrapper;
