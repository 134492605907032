import { useMemo } from 'react';

import cn from 'classnames';
import {
  ChevronDownIcon,
  CircleUserRoundIcon,
  PhoneIcon,
  ZapIcon,
} from 'lucide-react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import Whatsapp from '@/components/atoms/Icons/Whatsapp';
import LinkConnection from '@/components/atoms/Icons/WhatsAppTesting/LinkConnection';
import Link from '@/components/atoms/Link/Link';
import QrCodeGenerator from '@/components/atoms/QrCodeGenerator/QrCodeGenerator';
import { useAccount } from '@/hooks/useAccount';
import { TestNumberWithDeskId } from '@/hooks/useWhatsappTesting';
import { AccordionType } from '@/models/integration';
import { popModal } from '@/redux/modals/actions';
import { WHATSAPP_BRAIN_TEST_URL } from '@/util/constants';

import Modal from '../../Modal';

import styles from './ScanAndChatSubModal.module.scss';

type ScanAndChatSubModalProps = {
  onBackClick: () => void;
  selectedNumber: TestNumberWithDeskId;
  brainName: string;
  hasPermission: boolean;
};

const ScanAndChatSubModal = ({
  selectedNumber,
  onBackClick,
  brainName,
  hasPermission,
}: ScanAndChatSubModalProps) => {
  const navigate = useNavigate();
  const { slug } = useAccount();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const svgString = useMemo(
    () => encodeURIComponent(renderToStaticMarkup(<Whatsapp />)),
    []
  );
  const image = `data:image/svg+xml,${svgString}`;

  return (
    <Modal
      size="medium"
      title={t('whatsapp_test.scan.title')}
      autoFocus
      hideSecondaryButton
      primaryButtonText={t('common.done')}
      onPrimarySubmit={() => {
        dispatch(popModal());
      }}
    >
      <Button unstyled className={styles.back} onClick={onBackClick}>
        <ChevronDownIcon size={16} color={'var(--color-foreground-muted)'} />{' '}
        {t('common.back')}
      </Button>
      {!hasPermission && (
        <div className={styles.notice}>
          <Banner relativePosition variant="warning">
            {t('whatsapp_test.scan.notice')}
          </Banner>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.plane_left}>
          <p>{t('whatsapp_test.scan.text1')}</p>
          <div className={cn(styles.box, styles.qr)}>
            <QrCodeGenerator
              overlayType="link"
              url={WHATSAPP_BRAIN_TEST_URL}
              image={image}
              height={160}
              width={160}
              margin={0}
            />
          </div>
          <p>{t('whatsapp_test.scan.text2')}</p>
        </div>
        <div className={styles.plane_right}>
          <div className={styles.box}>
            <div className={styles.info}>
              <CircleUserRoundIcon
                color="var(--color-foreground-muted)"
                size={16}
              />{' '}
              <p>{selectedNumber.label}</p>
            </div>
            <div className={styles.info}>
              <PhoneIcon color="var(--color-foreground-muted)" size={16} />{' '}
              <p>+{selectedNumber.phone_number}</p>
            </div>
            <Link
              className={styles.edit}
              onClick={() => {
                navigate(
                  `/${slug}/environments/${selectedNumber.desk_id}/integrations/${selectedNumber.integration_id}#${AccordionType.WHATSAPP_TESTING}`
                );
                dispatch(popModal());
              }}
            >
              {t('common.edit')}
            </Link>
          </div>
          <div className={styles.chain}>
            <LinkConnection />
          </div>
          <div className={styles.box}>
            <div className={cn(styles.info, styles.brain)}>
              <ZapIcon size={16} color="var(--color-foreground-muted)" />{' '}
              <p>{brainName}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ScanAndChatSubModal;
