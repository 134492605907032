import { useCallback, useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { viewsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import {
  MODAL_DELETE,
  MODAL_VIEWS,
} from '@/components/organisms/Modals/ModalConductor';
import { EventName } from '@/models/segment';
import { View, ViewType } from '@/models/views';
import { MenuItem } from '@/modules/humanChat/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectUserId } from '@/redux/user/selectors';

import { useAccount } from './useAccount';
import { EmojiURL } from '../components/atoms/EmojiURL/EmojiURL';
import { conversationKeys } from '../modules/humanChat/hooks/useConversations';
import { selectDeskId } from '../redux/session/selectors';
import { trackEvent } from '../segment/segment';

type UpdateProps = {
  view_id: string;
  view: Partial<View>;
};

export type Views = {
  views: View[];
};

export type MenuItemsType = Array<MenuItem & { type: ViewType }>;

export const API = Object.freeze({
  listViews: async (deskId: string): Promise<Views> =>
    callGet(endpoints.views(deskId)),
  getView: async (deskId: string, viewId: string) =>
    callGet(endpoints.view(deskId, viewId)),
  updateView: async (
    desk_id: string,
    view_id: string,
    view: Partial<View>
  ): Promise<View> => callPut(endpoints.view(desk_id, view_id), view),
  createView: async (desk_id: string, view: Partial<View>): Promise<View> =>
    callPost(endpoints.views(desk_id), view),
  deleteView: async (desk_id: string, view_id: string): Promise<View> =>
    callDelete(endpoints.view(desk_id, view_id)),
  checkName: async (desk_id: string, name: string) =>
    callGet(endpoints.checkName(desk_id, name)),
});

export const onViewCreated = (queryClient: QueryClient, resp: View) =>
  queryClient.setQueryData([endpoints.views(resp.desk_id)], (prev: Views) => {
    return { ...prev, views: [...prev.views, resp] } as Views;
  });

export const onViewUpdated = (queryClient: QueryClient, resp: View) => {
  const { desk_id, view_id } = resp;

  queryClient.invalidateQueries({ queryKey: [endpoints.views(desk_id)] });

  // Invalidate queries in case of a change in the conditions
  queryClient.invalidateQueries({
    queryKey: conversationKeys.byView(desk_id, 'newest', view_id),
  });
  queryClient.invalidateQueries({
    queryKey: conversationKeys.byView(desk_id, 'oldest', view_id),
  });
};

export const onViewRemoved = (
  queryClient: QueryClient,
  deskId: string,
  view_id: string
) =>
  queryClient.setQueryData<Views>([endpoints.views(deskId)], (prev) => {
    return {
      ...prev,
      views: prev?.views?.filter((item) => item.view_id !== view_id),
    };
  });

export const useViews = (viewId?: string) => {
  const deskId = useSelector(selectDeskId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { slug } = useAccount();
  const user_id = useSelector(selectUserId);

  const { data: views, isLoading: isViewsLoading } = useQuery({
    queryKey: [endpoints.views(deskId)],
    queryFn: () => API.listViews(deskId),
    enabled: Boolean(deskId),
  });

  const dropdownOpen = views?.views.length > 0;

  const getView = useCallback(
    (view_id = viewId) =>
      views?.views?.find((view) => view.view_id === view_id),
    [views, viewId]
  );
  const isViewOwner = useCallback(
    (view_id = viewId) => getView(view_id)?.created_by === user_id,
    [getView, user_id, viewId]
  );

  const { mutate: createView, isPending: createLoading } = useMutation<
    View,
    Error,
    Partial<View>
  >({
    mutationFn: (view: View) => API.createView(deskId, view),
    onSuccess: (resp) => {
      onViewCreated(queryClient, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('conversation.views.view_created', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: updateView,
    isPending: updateLoading,
    status: _updateStatus,
  } = useMutation<View, Error, UpdateProps>({
    mutationFn: ({ view_id, view }) => API.updateView(deskId, view_id, view),
    onSuccess: (resp) => {
      onViewUpdated(queryClient, resp);
      dispatch(
        addTemporalToast(
          'success',
          t('conversation.views.view_updated', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteView } = useMutation<View, Error, string>({
    mutationFn: (view_id) => API.deleteView(deskId, view_id),
    onSuccess: (resp) => {
      onViewRemoved(queryClient, deskId, resp.view_id);
      dispatch(
        addTemporalToast(
          'success',
          t('conversation.views.view_deleted', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const menuItems = useMemo<MenuItemsType>(
    () =>
      views?.views?.map((view) => {
        return {
          type: view.type,
          icon: EmojiURL({ url: view.icon, alt: view.name }),
          text: view.name,
          onClick: () => {
            trackEvent(EventName.FilterByView);
            navigate(
              `/${slug}/chats/${deskId}/conversations?view_id=${view.view_id}`
            );
          },
          id: view.view_id,
          options: [
            {
              label: t('common.edit'),
              fn: () => {
                dispatch(pushModal(MODAL_VIEWS, { viewId: view.view_id }));
              },
            },
            {
              label: t('common.delete'),
              fn: () => {
                dispatch(
                  pushModal(MODAL_DELETE, {
                    onDelete: () => {
                      trackEvent(EventName.DeleteView);
                      deleteView(view.view_id);
                      dispatch(popModal());
                    },
                  })
                );
              },
            },
          ],
        };
      }) ?? [],
    [views?.views, navigate, slug, deskId, dispatch, t, deleteView]
  );

  const isValidName = async (name: string) => {
    try {
      if (name === getView()?.name) {
        return true;
      }
      await API.checkName(deskId, name);
      return true;
    } catch (error) {
      return false;
    }
  };

  return {
    views: menuItems,
    isViewsLoading,
    updateView,
    deleteView,
    createView,
    view: getView(),
    isLoading: updateLoading || createLoading,
    isValidName,
    isViewOwner: isViewOwner() || !viewId, // If there is not viewId means that the modal is being created, then it's the owner
    dropdownOpen,
  };
};
