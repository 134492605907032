import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { useSelector } from 'react-redux';

import { selectAccountId } from '@/redux/session/selectors';
import { X_MOVEO_ACCOUNT_ID } from '@/util/constants';
import { isDevOrLocal } from '@/util/util';

const ApolloProviderWithAccountId = ({ children }) => {
  const accountId = useSelector(selectAccountId);

  if (isDevOrLocal()) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

  /**
   * Initializes an Apollo Client instance for managing GraphQL operations.
   *
   * - Configures the client with a GraphQL endpoint URI (`/www/api/v1/graphql`).
   * - Sets up an in-memory cache with type policies for the `Query` type,
   *   including a custom key argument for the `get_session_content` field.
   *
   * @constant
   */
  const client = new ApolloClient({
    uri: '/www/api/v1/graphql',
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            get_session_content: {
              keyArgs: ['args', ['session_id']],
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
      },
    },
    headers: {
      [X_MOVEO_ACCOUNT_ID]: accountId,
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWithAccountId;
