import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Option } from '@/components/atoms/AutoComplete/AutoComplete';
import {
  getIntegrationByType,
  integrationByType,
} from '@/components/organisms/Integrations';
import useDesks from '@/hooks/useDesks';
import useEntities from '@/hooks/useEntities';
import { OptionBase } from '@/models/common';
import { EXAMPLE_VARIABLES_VALUE } from '@/redux/dialogs/helper';
import { selectAllTags } from '@/redux/dialogs/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { requireValueInRule } from '@/util/validator';

import useDialogs from './useDialogs';
interface Props {
  name: string;
  operator: string;
  value?: string;
}

export const operatorOptions = [
  { value: 'equal', label_key: 'dialog.condition.operator.is_equal_to' },
  {
    value: 'not_equal',
    label_key: 'dialog.condition.operator.is_not_equal_to',
  },
  { value: 'greater', label_key: 'dialog.condition.operator.is_greater_than' },
  { value: 'less', label_key: 'dialog.condition.operator.is_less_than' },
  { value: 'contain', label_key: 'dialog.condition.operator.contains' },
  {
    value: 'not_contain',
    label_key: 'dialog.condition.operator.does_not_contain',
  },
  { value: 'exist', label_key: 'dialog.condition.operator.exists' },
  { value: 'not_exist', label_key: 'dialog.condition.operator.does_not_exist' },
];

export const SYS_BUSINESS_OPTIONS_UNTRANSLATED = [
  {
    label: 'open',
    value: 'open',
    type: 'auto_complete.variable_labels.$sys-business',
  },
  {
    label: 'closed',
    value: 'closed',
    type: 'auto_complete.variable_labels.$sys-business',
  },
];

export const DEFAULT_EXAMPLE_VALUE_UNTRANSLATED = {
  label: 'dialog.pre-fill.value',
  value: 'dialog.pre-fill.value',
  type: 'dialog.condition.rule.values',
};

const useToolkitRule = (rule: Props) => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { entities } = useEntities(brainId);
  const { desks } = useDesks();
  const { dialogs } = useDialogs(brainId);
  const allTags = selectAllTags(dialogs);

  const SYS_BUSINESS_OPTIONS = useMemo(
    () =>
      SYS_BUSINESS_OPTIONS_UNTRANSLATED.map((x) => ({ ...x, type: t(x.type) })),
    [t]
  );

  const defaultExampleValue = useMemo(
    () => ({
      label: t(DEFAULT_EXAMPLE_VALUE_UNTRANSLATED.label),
      value: t(DEFAULT_EXAMPLE_VALUE_UNTRANSLATED.value),
      type: t(DEFAULT_EXAMPLE_VALUE_UNTRANSLATED.type),
    }),
    [t]
  );

  // Autosuggestion:
  // 1. @entity -> useEntities
  // 2. $sys-channel -> 'availableChannels'
  // 3. $sys-desk -> useDesks
  // 4. $sys-business -> 'open', 'closed' (sysBusinessOptions)
  // 5. $tags -> selectAllTags

  const availableChannels = useMemo(() => {
    const testChannel = 'test';
    const allChannels = Object.keys(integrationByType);

    return allChannels.reduce(
      (acc, val) => {
        // return only available channels
        if (
          Object.prototype.hasOwnProperty.call(
            getIntegrationByType(val),
            'ConfigurationComponent'
          )
        ) {
          acc.push({
            label: val,
            value: val,
          });
        }
        return acc;
      },
      // for test sessions
      [{ label: testChannel, value: testChannel }] as OptionBase[]
    );
  }, []);

  const availableDesks = useMemo(() => {
    return desks?.reduce((acc, desk) => {
      acc.push({
        label: desk.name,
        value: desk.desk_id,
      });
      return acc;
    }, [] as OptionBase[]);
  }, [desks]);

  // This is returned to the component
  const availableValues = useCallback(
    (ruleName: string): Option[] => {
      if (ruleName === '' || !ruleName) {
        return [];
      }

      const isEntity = ruleName.startsWith('@');
      if (isEntity) {
        const entity = entities?.find((x) => x.entity === ruleName.slice(1));
        return (
          entity?.values.map((entity) => ({
            type: t('dialog.condition.rule.values'),
            label: entity.value,
            value: entity.value,
          })) || []
        );
      }

      if (ruleName === '$tags') {
        return (
          allTags?.map((tab) => ({
            type: t('dialog.tags.auto_complete_group_by'),
            value: tab,
            label: tab,
          })) || []
        );
      }

      if (ruleName === '$sys-channel') {
        return (
          availableChannels?.map(({ label, value }) => ({
            type: t('common.channels'),
            value,
            label,
          })) || []
        );
      }

      if (ruleName === '$sys-desk') {
        return (
          availableDesks?.map(({ label, value }) => ({
            type: t('common.desks'),
            value,
            label,
          })) || []
        );
      }

      if (ruleName === '$sys-business') {
        return [...SYS_BUSINESS_OPTIONS];
      }

      return [];
    },
    [
      SYS_BUSINESS_OPTIONS,
      allTags,
      availableChannels,
      availableDesks,
      entities,
      t,
    ]
  );

  const getDefaultRuleValue = useCallback(
    (ruleName: string): Option => {
      const availableVals = availableValues(ruleName);

      if (availableVals?.length > 0) {
        return availableVals[0];
      }

      if (EXAMPLE_VARIABLES_VALUE[ruleName]) {
        return {
          label: EXAMPLE_VARIABLES_VALUE[ruleName],
          value: EXAMPLE_VARIABLES_VALUE[ruleName],
          type: t('dialog.condition.rule.values'),
        };
      }

      return defaultExampleValue;
    },
    [availableValues, defaultExampleValue, t]
  );

  const operators = useCallback(
    (name = rule.name) => {
      if (name === '$sys-channel' || name === '$sys-business') {
        const sysOptions = operatorOptions.filter(
          (x) => x.value === 'equal' || x.value === 'not_equal'
        );
        return sysOptions;
      }

      if (name === '$sys-desks') {
        const sysOptions = operatorOptions.filter(
          (x) =>
            x.value === 'equal' ||
            x.value === 'not_equal' ||
            x.value === 'exist' ||
            x.value === 'not_exist'
        );
        return sysOptions;
      }

      if (name === '$tags') {
        const sysOptions = operatorOptions.filter(
          (x) => x.value === 'contain' || x.value === 'not_contain'
        );
        return sysOptions;
      }

      return operatorOptions;
    },
    [rule.name]
  );

  const enableNewEntry = useMemo(() => {
    // Currently, only tags can add new entry
    if (rule.name === '$tags') {
      return true;
    }
    return false;
  }, [rule.name]);

  const hasValue = useCallback((operator) => {
    return requireValueInRule.includes(operator);
  }, []);

  return {
    availableDesks,
    availableValues: availableValues(rule.name),
    hasValue,
    getDefaultRuleValue,
    operators,
    enableNewEntry,
  };
};

export default useToolkitRule;
