import { useEffect, useLayoutEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useParams } from 'react-router';
import { useTitle } from 'react-use';

import Loading from '@/components/atoms/Loading/Loading';
import AccountSettingsRouter from '@/components/pages/AccountSettings/AccountSettingsRouter';
import BrainsRouter from '@/components/pages/Brains/BrainsRouter';
import ChatsRouter from '@/components/pages/Chats/ChatsRouter';
import CollectionsRouter from '@/components/pages/Collections/CollectionsRouter';
import DesksRouter from '@/components/pages/Desks/DeskRouter';
import Home from '@/components/pages/Home/Home';
import IntegrationsEmpty from '@/components/pages/Integrations/IntegrationsEmpty';
import ManageRouter from '@/components/pages/ManageAccess/ManageRouter';
import NotFound from '@/components/pages/NotFound/NotFound';
import { useRtmDesk } from '@/hooks/rtm/useRtmDesk';
import { useAccount } from '@/hooks/useAccount';
import useDesks from '@/hooks/useDesks';
import { useFeatureUpgrade } from '@/hooks/useFeatureUpgrade';
import { usePermissions } from '@/hooks/usePermissions';
import useUser from '@/hooks/useUser';
import AIAgentsRouter from '@/modules/aiAgents/AIAgentsRouter';
import AnalyticsRouter from '@/modules/analytics/AnalyticsRouter';
import { BroadcastsRouter } from '@/modules/broadcast/components/BroadcastsRouter';
import { DeveloperToolsRouter } from '@/modules/developerTools/DeveloperToolsRouter';
import RulesEmpty from '@/modules/rules/pages/Rules/RulesEmpty';
import { setAccountRole, setPermissions } from '@/redux/permissions/actions';
import {
  setAccountId,
  setAccountSlug,
  setDeskId,
} from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';
import { trackAccount } from '@/segment/segment';

const Account: React.FC = () => {
  const { slug } = useParams();
  const { permissions } = usePermissions();
  const dispatch = useDispatch();
  const { getUserStatus } = useUser();

  useFeatureUpgrade();
  const {
    account,
    accountRole,
    accountStatus,
    setLastUsedAccountSlug,
    isValidSlug,
  } = useAccount();
  const { desks } = useDesks();
  const { t } = useTranslation();

  const deskId = useSelector(selectDeskId);
  useRtmDesk();

  useTitle(slug);

  useLayoutEffect(() => {
    dispatch(setAccountSlug(slug));
  }, [dispatch, slug]);

  useEffect(() => {
    if (!deskId && !isEmpty(desks)) {
      dispatch(setDeskId(desks[0]?.desk_id));
    }
  }, [deskId, desks, dispatch]);

  useEffect(() => {
    if (account?.slug) {
      setLastUsedAccountSlug(account.slug);
    }
  }, [account?.slug, dispatch, setLastUsedAccountSlug]);

  useEffect(() => {
    if (account?.account_id) {
      dispatch(setAccountId(account?.account_id));
      dispatch(setAccountRole(accountRole));
      if (permissions) {
        dispatch(setPermissions(permissions));
      }
    }
  }, [dispatch, account?.account_id, accountRole, permissions]);

  useEffect(() => {
    if (account) {
      trackAccount(account);
    }
  }, [account]);

  // Show error page if account slug is not valid
  if (getUserStatus === 'error' && !isValidSlug()) {
    return <NotFound entity={t('common.account')} value={slug} />;
  }

  if (accountStatus === 'pending') {
    return <Loading />;
  }

  if (accountStatus === 'success' && !account) {
    return <NotFound />;
  }

  if (accountStatus === 'error') {
    return <NotFound entity={t('common.account')} value={slug} />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/environments/*" element={<DesksRouter />} />
        <Route path="/devtools/*" element={<DeveloperToolsRouter />} />
        <Route path="/collections/*" element={<CollectionsRouter />} />

        <Route path="/brains/*" element={<BrainsRouter />} />
        <Route path="/ai-agents/*" element={<AIAgentsRouter />} />
        <Route path="/broadcasts/*" element={<BroadcastsRouter />} />
        <Route path="/chats/*" element={<ChatsRouter />} />
        <Route path="/integrations" element={<IntegrationsEmpty />} />
        <Route path="/rules" element={<RulesEmpty />} />
        <Route path="/account/manage/*" element={<ManageRouter />} />
        <Route path="/account/*" element={<AccountSettingsRouter />} />
        <Route path="/analytics/*" element={<AnalyticsRouter />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Account;
