import { CalendarIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';

const HolidaysEmpty = ({ onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <>
      <ContextualHelp
        title={t('business_hours.holidays.title')}
        name="holidays-help"
        size="full"
      >
        {t('business_hours.holidays.help')}
      </ContextualHelp>
      <TileEmptyPage
        title={t('business_hours.holidays.empty_title')}
        body={
          <>
            {t('business_hours.holidays.body1')} <br />{' '}
            <Button
              onClick={onClick}
              variant="tertiary"
              underline
              disabled={disabled}
            >
              {t('business_hours.holidays.add_holidays')}
            </Button>
          </>
        }
        icon={<CalendarIcon color="var(--color-foreground-muted)" size={100} />}
      />
    </>
  );
};

export default HolidaysEmpty;
