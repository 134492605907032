import { SyntheticEvent } from 'react';

import cn from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { CarouselCard } from '@/models/action';

import Card from './Card';

import styles from './CarouselSlider.module.scss';

type CarouselSliderProps = {
  setActiveCard?: (index: number) => void;
  cards: CarouselCard[];
  activeCard: number;
  navigateSlider: (e: SyntheticEvent, direction: 'previous' | 'next') => void;
  isDragging?: boolean;
  handlePaginationClick?: (e: SyntheticEvent, index: number) => void;
};

const CarouselSlider = ({
  activeCard,
  cards,
  navigateSlider,
  isDragging = false,
  handlePaginationClick,
}: CarouselSliderProps) => {
  const { t } = useTranslation();
  // When you have deleted all cards
  if (cards.length === 0) {
    return null;
  }

  return (
    <div
      className={cn({
        [styles.wrapper]: true,
        [styles.isDragging]: isDragging,
      })}
    >
      <Card media={cards[activeCard]?.media} />

      {/* Left arrow */}
      <button
        className={cn(styles.arrow, styles['arrow--left'])}
        type="button"
        aria-label={t('dialog.carousel.previous_slide')}
        onClick={(e) => {
          navigateSlider(e, 'previous');
        }}
        disabled={activeCard === 0}
      >
        <ChevronLeftIcon color="var(--color-foreground-muted)" size={18} />
      </button>

      {/* Right arrow */}
      <button
        className={cn(styles.arrow, styles['arrow--right'])}
        type="button"
        aria-label={t('dialog.carousel.next_slide')}
        onClick={(e) => {
          navigateSlider(e, 'next');
        }}
        disabled={activeCard === cards.length - 1}
        data-testid="carousel-navigate-right"
      >
        <ChevronRightIcon color="var(--color-foreground-muted)" size={18} />
      </button>

      {/* Pagination buttons */}
      <div className={styles.pagination}>
        {cards?.map((card, idx) => {
          return (
            <button
              data-testid="carousel-dot"
              key={`${card?.media?.url}-${card?.title}` || idx}
              onClick={(e) => handlePaginationClick(e, idx)}
              className={cn(styles.dot, {
                [styles.active]: activeCard === idx,
              })}
            ></button>
          );
        })}
      </div>
    </div>
  );
};

export default CarouselSlider;
