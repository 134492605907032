import { useWhatsapp } from '@/hooks/useWhatsapp';

import Settings from './Settings';
import Connect from '../Facebook/Connect';
import IntegrationSkeleton from '../IntegrationSkeleton';

const Whatsapp = () => {
  const {
    integration,
    connectionStatus,
    isManual,
    onOAuthClick,
    isConnecting,
    isLoading,
  } = useWhatsapp();

  if (!integration) {
    return null;
  }

  if (isLoading) {
    return <IntegrationSkeleton />;
  }

  // If the integration is connected, or the user is managing it manually,
  // show the settings page
  if (connectionStatus === 'connected' || isManual) {
    return <Settings isManual={isManual} />;
  }

  return <Connect onOAuthClick={onOAuthClick} isConnecting={isConnecting} />;
};

export default Whatsapp;
