import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

export interface EventOption {
  id: string;
  label: string;
  description: string;
}

export const useEventOptions = (): EventOption[] => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        id: 'dialog:expired',
        label: t('plugins.dialog_expired.label'),
        description: t('plugins.dialog_expired.description'),
      },
    ],
    [t]
  );
};
