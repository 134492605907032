import { useCallback, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import { RangeApi } from '@udecode/plate';
import cn from 'classnames';
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';
import { useClickAway } from 'react-use';

import PlateEditor from '@/components/ui/editor/plate-editor';

import HelpTooltip from '../HelpTooltip/HelpTooltip';

import styles from './MarkdownEditor.module.scss';
import inputStyles from '../Input/Input.module.scss';

interface Props<T extends FieldValues> {
  defaultValue?: string;
  setValue?: UseFormSetValue<T>;
  name: string;
  autoFocus?: boolean;
  maxCharacters?: number;
  value?: string;
  error?: boolean;
  errorMessage?: React.ReactNode | string;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
  placeholder?: string;
  reset?: boolean;
  onClick?: () => void;
  shouldExpand?: boolean;
  size?: 'default' | 'small';
}
export default function MarkdownEditor<FieldValues>({
  defaultValue,
  setValue,
  name,
  autoFocus = false,
  maxCharacters,
  error,
  errorMessage,
  value,
  label,
  disabled,
  tooltip,
  placeholder,
  reset,
  onClick,
  shouldExpand,
  size = 'default',
}: Props<FieldValues>) {
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleChange = useCallback(
    (value: string) => {
      setValue(
        name as Path<FieldValues>,
        value as unknown as PathValue<FieldValues, Path<FieldValues>>,
        {
          shouldDirty: true,
          shouldValidate: !!error,
        }
      );
    },
    [error, name, setValue]
  );
  const handleContainerClick = useCallback(() => {
    if (disabled) {
      return;
    }
    setFocused(true);
    if (shouldExpand) {
      setExpanded(true);
      setTimeout(() => {
        if (!expanded && onClick) {
          onClick();
        }
      }, 0);
    }
  }, [disabled, expanded, onClick, shouldExpand]);

  useClickAway(containerRef, () => {
    if (RangeApi.isExpanded && shouldExpand) {
      // Adding a delay to allow any pending click events to propagate before collapsing the expanded state.
      setTimeout(() => {
        setExpanded(false);
      }, 500);
    }
    setFocused(false);
  });

  return (
    <>
      {label && (
        <label
          className={cn({
            [inputStyles.input__label]: true,
            [inputStyles.input__label__disabled]: disabled,
          })}
        >
          {label}
          <HelpTooltip tooltip={tooltip} />
        </label>
      )}
      <div
        ref={containerRef}
        className={cn(styles.container, inputStyles.input, {
          [inputStyles.input__input__error]: error,
          [styles.expanded]: expanded,
          [styles.focused]: focused,
        })}
        onClick={handleContainerClick}
        onKeyDown={() => {}}
        role="textbox"
        tabIndex={0}
      >
        <PlateEditor
          defaultValue={defaultValue}
          onChange={handleChange}
          autoFocus={autoFocus}
          placeholder={placeholder}
          value={value}
          reset={reset}
          expanded={expanded}
          disabled={disabled}
          size={size}
        />
      </div>
      {errorMessage && (
        <p className={inputStyles.input__input__error__message}>
          {errorMessage}
        </p>
      )}
      {maxCharacters && (
        <Typography
          variant="label-regular"
          color="var(--color-foreground-muted)"
          className={inputStyles.counter}
        >
          {value.length}/{maxCharacters}
        </Typography>
      )}
    </>
  );
}
