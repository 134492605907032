import { SmartphoneIcon } from 'lucide-react';

import { AndroidIcon } from '../AndroidIcon';
import MacOs from '../MacOs';
import MicrosoftWindow from '../MicrosoftWindow';

// Custom icons

export const PlatformIcon = ({ platform }: { platform: string }) => {
  const lowerCasePlatform = platform?.toLowerCase();

  if (
    lowerCasePlatform?.includes('mac') ||
    lowerCasePlatform?.includes('ios')
  ) {
    return <MacOs />;
  }
  if (
    lowerCasePlatform?.includes('microsoft') ||
    lowerCasePlatform?.includes('windows')
  ) {
    return <MicrosoftWindow />;
  }
  if (lowerCasePlatform?.includes('phone')) {
    return <SmartphoneIcon size={16} />;
  }
  if (lowerCasePlatform?.includes('android')) {
    return <AndroidIcon />;
  }

  return null;
};
