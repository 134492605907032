import { forwardRef, memo } from 'react';

import MuiAvatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useMembers from '@/hooks/useMembers';
import { RootState } from '@/models/state';
import { StyledBadge } from '@/modules/humanChat/components/LeftSidebar/Drawer/DrawerItem/components';
import { selectAgentById } from '@/redux/presence/selectors';

type Props = {
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
  active?: boolean;
  children?: React.ReactNode;
  userId?: string;
  altText?: string;
  src?: string;
  hideStatus?: boolean;
  status?: 'online' | 'offline' | 'away';
  hideTooltip?: boolean;
};

const sizeMap = {
  small: 16,
  medium: 24,
  large: 32,
};

const AvatarComponent = (
  {
    size = 'medium',
    loading = false,
    active = false,
    children,
    userId,
    altText,
    src,
    hideStatus = false,
    status,
    hideTooltip = false,
    ...props
  }: Props,
  ref
) => {
  const { t } = useTranslation();

  const { getMember } = useMembers();
  const user = getMember(userId);
  const agent = useSelector((state: RootState) =>
    selectAgentById(state, user?.user_id)
  );

  const lastActivityDate = moment(agent?.last_activity_at);
  const lastActivity = lastActivityDate?.fromNow();

  const text = t('conversation.last_visitors_activity', {
    0: lastActivity,
  });

  const statusState = status || agent?.status;

  if (loading) {
    const width = sizeMap[size];
    return <Skeleton height={width} width={width} variant="circular" />;
  }

  const sx = {
    width: sizeMap[size],
    height: sizeMap[size],
    ...(active
      ? {
          color: 'var(--color-text-primary-foreground)',
          bgcolor: 'var(--color-informational)',
        }
      : {}),
    '.MuiAvatar-fallback': {
      color: 'var(--color-white)',
    },
    backgroundColor: 'var(--color-foreground-muted)',
  };

  const badgedAvatar = (
    <StyledBadge
      {...props}
      ref={ref}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      status={statusState}
    >
      <MuiAvatar
        sx={sx}
        alt={user?.name}
        src={user?.avatar}
        slotProps={{
          img: {
            loading: 'lazy',
          },
        }}
      />
    </StyledBadge>
  );

  const avatarContent = () => {
    if (children) {
      return (
        <MuiAvatar
          {...props}
          ref={ref}
          sx={sx}
          alt={altText}
          slotProps={{
            img: {
              loading: 'lazy',
            },
          }}
        >
          {children}
        </MuiAvatar>
      );
    }

    if (src) {
      return (
        <MuiAvatar
          {...props}
          ref={ref}
          sx={sx}
          alt={altText}
          src={src}
          slotProps={{
            img: {
              loading: 'lazy',
            },
          }}
        />
      );
    }

    // avatar with badge
    if (statusState && !hideStatus && !hideTooltip) {
      if (lastActivityDate.isValid()) {
        return (
          <Tooltip title={text} arrow>
            {badgedAvatar}
          </Tooltip>
        );
      } else {
        return badgedAvatar;
      }
    }

    //avatar with badge without tooltip
    if (statusState && hideTooltip && !hideStatus) {
      return badgedAvatar;
    }

    return (
      <MuiAvatar
        {...props}
        ref={ref}
        sx={sx}
        alt={user?.name}
        src={user?.avatar}
        slotProps={{
          img: {
            loading: 'lazy',
          },
        }}
      />
    );
  };

  return avatarContent();
};

export const Avatar = memo(forwardRef(AvatarComponent));

Avatar.displayName = 'Avatar';
