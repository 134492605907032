import { useCallback, useEffect, useRef } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ChevronDownIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EmptyResults from '@/components/atoms/EmptyResults/EmptyResults';
import TileGroupedActivityLog from '@/components/organisms/Tile/TileActivityLog/TileGroupedActivityLog/TileGroupedActivityLog';
import { useActivityLogs } from '@/hooks/useActivityLogs';
import { AuditLog } from '@/models/auditLogs';
import Filters from '@/modules/analytics/components/filters/AccountFilters/AccountFilters';

import styles from './ActivityLogs.module.scss';

export const getFieldOptions = (ai_agents: boolean) => {
  return [
    { value: 'default', label_key: 'activityLogs.filters.default' },
    { value: 'desk', label_key: 'activityLogs.filters.desk' },
    {
      value: 'brain',
      label_key: `activityLogs.filters.${ai_agents ? 'ai_agent' : 'brain'}`,
    },
  ];
};

interface Props {
  hideFilters?: boolean;
  deskId?: string | null;
  brain_ids?: string[];
  brainId?: string;
}

const HOME_LIMIT = 5;
export const ActivityLogs = ({
  hideFilters,
  deskId,
  brain_ids,
  brainId,
}: Props) => {
  const { t } = useTranslation();
  const { slug: account_slug } = useParams();
  const limit = hideFilters ? HOME_LIMIT : undefined;
  const { data, fetchNextPage, hasNextPage, isLoading } = useActivityLogs(
    account_slug,
    undefined,
    deskId,
    brain_ids,
    brainId
  );
  const oldScrollRef = useRef<number>(null);
  const firstPageLength = data?.pages[0].audit_logs.length;
  const bodyRef = useRef<HTMLDivElement>(null);
  const logPages = [...(data?.pages || [])];

  useEffect(() => {
    if (bodyRef.current && !isLoading) {
      bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
    }
  }, [isLoading, firstPageLength]);

  useEffect(() => {
    if (data?.pages.length > 1) {
      bodyRef.current.scrollTop =
        bodyRef.current.scrollHeight - oldScrollRef.current;
    }
  }, [data?.pages.length]);

  const handleNextPageFetch = useCallback(() => {
    oldScrollRef.current = bodyRef.current.scrollHeight;
    return fetchNextPage();
  }, [fetchNextPage]);

  const renderSkeletons = (n = 3, ref = null) => {
    return Array(n)
      .fill(0)
      .map((_, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.skeleton} key={i} ref={ref}>
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton height={40} width={210} />
          </div>
        );
      });
  };

  // Group audit logs by target_id and brain_id/desk_id
  const groupActions = (actions: AuditLog[]) => {
    return actions.reduce((acc, action) => {
      if (!acc.length) {
        return [{ ...action, nested: [] }];
      }
      const lastGroup = acc[acc.length - 1];
      if (
        lastGroup.target_id === action.target_id &&
        ((lastGroup.brain_id && lastGroup.brain_id === action.brain_id) ||
          (lastGroup.desk_id && lastGroup.desk_id === action.desk_id))
      ) {
        lastGroup.nested.push(action);
      } else {
        acc.push({ ...action, nested: [] });
      }
      return acc;
    }, []);
  };

  return (
    <>
      {!hideFilters && <Filters auditLogsMode />}

      <div ref={bodyRef}>
        {!data && renderSkeletons()}
        {logPages.map((page) => {
          const nestedLogs = [...(page.audit_logs || [])];

          const grouped = groupActions(nestedLogs.slice(0, limit));

          return grouped.length ? (
            grouped
              .slice(0, limit)
              .map((groupedLog: AuditLog & { nested: [] }) => {
                return (
                  <TileGroupedActivityLog
                    key={groupedLog.log_id}
                    groupedLog={groupedLog}
                  />
                );
              })
          ) : (
            <EmptyResults
              key="empty_result"
              message={t('activityLogs.noLogs')}
              isAuditLogs
            />
          );
        })}
        {hasNextPage !== false && !hideFilters && (
          <div className={styles.viewMore}>
            <Typography variant="body-semi-bold" onClick={handleNextPageFetch}>
              {t('activityLogs.show_more')}
              <ChevronDownIcon size={16} color="var(--color-foreground)" />
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};
