import { FlagIcon, LocateFixedIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { TooltipButton } from './TooltipButton';

export const ConvertButton = ({ type, onTriggerConvert }) => {
  const { t } = useTranslation();
  const inner =
    type === 'event' ? (
      <LocateFixedIcon size={16} color="var(--color-background)" />
    ) : (
      <FlagIcon size={16} color="var(--color-background)" />
    );
  const title =
    type === 'event' ? t('dialog.convert_event') : t('dialog.convert_text');

  return (
    <TooltipButton title={title} onClick={onTriggerConvert}>
      {inner}
    </TooltipButton>
  );
};
