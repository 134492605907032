import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Context, ConversationSource } from '@/models/chat';
import {
  DebugStep,
  NodeType,
  TryIt as State,
  TryItCollection,
} from '@/models/tryIt';
import { LogSessionContentRow } from '@/modules/analytics/models';

import { calculateSessionNodes } from '../utils/helper';

export const defaultState: State = {
  isOpen: false,
  selectedNodeId: null,
  selectedActionId: null,
  selectedRequisiteIndex: null,
  selectedIfNoRequisite: null,
  selectedMessage: {
    context: {},
    debugLogs: [],
    index: null,
  },
  sessionContent: null,
  context: [{}],
  isReplay: false,
  sessionId: null,
  sessionNodes: [] as NodeType[] | Partial<NodeType>[],
  isLoadingResponse: false,
  source: null,
  isAmended: false,
};

const slice = createSlice({
  name: 'tryIt',
  initialState: defaultState,
  reducers: {
    setSessionContent: (state, action: PayloadAction<LogSessionContentRow>) => {
      state.sessionContent = action.payload;
      state.sessionNodes = calculateSessionNodes(action.payload.messages);
    },
    setSessionNodes(
      state,
      action: PayloadAction<NodeType[] | Partial<NodeType>[]>
    ) {
      state.sessionNodes = action.payload;
    },
    setAddTryItMessage: (state, action: PayloadAction<Partial<NodeType>>) => {
      state.sessionNodes = [...state.sessionNodes, action.payload];
    },
    setNodeAmend: (state, action) => {
      const amendPayload = action.payload;
      state.sessionNodes = state.sessionNodes.map((node) => {
        if (node.request_id === amendPayload.request_id) {
          return {
            ...node,
            corrections: [
              ...(node?.corrections ?? []),
              {
                task: amendPayload.task,
                language: amendPayload.language,
                correction: amendPayload.correction,
              },
            ],
          };
        }
        return node;
      });
      state.isAmended = true;
    },
    setIsLoadingResponse: (state, action: PayloadAction<boolean>) => {
      state.isLoadingResponse = action.payload;
    },
    setSessionSource: (state, action: PayloadAction<ConversationSource>) => {
      state.source = action.payload;
    },
    toggleTryIt: (state) => ({ ...state, isOpen: !state.isOpen }),
    openTryIt: (state) => ({ ...state, isOpen: true }),
    closeTryIt: (state) => ({ ...state, isOpen: false }),
    updateSelectedMessage: (
      state,
      action: PayloadAction<{
        selectedMessage: {
          context: Context;
          debugLogs: string[];
          index: number | null;
          collection?: TryItCollection;
          steps?: DebugStep[];
        };
        isReplay?: boolean;
      }>
    ) => {
      state.selectedMessage = {
        ...state.selectedMessage,
        ...action.payload.selectedMessage,
      };
    },
    selectTryItNode: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedNodeId: action.payload,
      selectedActionId: null,
      selectedRequisiteIndex: null,
      selectedIfNoRequisite: null,
    }),
    selectTryItAction: (
      state,
      action: PayloadAction<{ node_id: string; action_id: string }>
    ) => ({
      ...state,
      selectedActionId: action.payload.action_id,
      selectedNodeId: action.payload.node_id,
      selectedRequisiteIndex: null,
      selectedIfNoRequisite: null,
    }),
    selectTryItRequisite: (
      state,
      action: PayloadAction<{
        node_id: string;
        requisite_idx: number;
        if_no_requisite?: true;
      }>
    ) => ({
      ...state,
      selectedNodeId: action.payload.node_id,
      selectedActionId: null,
      selectedIfNoRequisite: action.payload.if_no_requisite ?? null,
      selectedRequisiteIndex: action.payload.requisite_idx ?? null,
    }),
    resetSelections: (state) => ({
      ...state,
      selectedNodeId: null,
      selectedActionId: null,
      selectedRequisiteIndex: null,
      selectedIfNoRequisite: null,
    }),
    updateContext: (state, action: PayloadAction<Context[]>) => ({
      ...state,
      context: action.payload,
    }),
    updateIsReplay: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isReplay: action.payload,
    }),
    resetSelectedMessage: (state) => ({
      ...state,
      sessionContent: null,
      sessionNodes: [],
      isAmended: false,
      selectedMessage: {
        context: {},
        debugLogs: [],
        steps: [],
        index: null,
        collection: {},
      },
    }),
    updateSessionId: (state, action: PayloadAction<string>) => ({
      ...state,
      sessionId: action.payload,
      context: [{}],
    }),
    resetTryIt: () => defaultState,
  },
});

// Extract and export each action creator by name
export const {
  setSessionContent,
  setSessionNodes,

  toggleTryIt,
  openTryIt,
  closeTryIt,
  updateIsReplay,
  updateSelectedMessage,
  selectTryItNode,
  selectTryItAction,
  selectTryItRequisite,
  updateContext,
  resetSelections,
  resetSelectedMessage,
  updateSessionId,
  resetTryIt,
  setAddTryItMessage,
  setNodeAmend,
  setIsLoadingResponse,
  setSessionSource,
} = slice.actions;

// Export the reducer, either as a default or named export
export default slice.reducer;
