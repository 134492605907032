import { useCallback, useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import { BotIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { Presence } from '@/models/presence';
import { RootState } from '@/models/state';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAllAgents } from '@/redux/presence/selectors';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';

import SelectUser, { AssignedBrain } from '../../SelectUser/SelectUser';
import { ContextTagsSection } from '../Sections/ContextTagsSection';
import { InformationSection } from '../Sections/InformationSection';
import { RelatedConversationsSection } from '../Sections/RelatedConversationsSection';

import styles from '../RightSidebar.module.scss';

export const OverviewPanel = () => {
  // Local state
  const [activeMemberIndex, setActiveMemberIndex] = useState(null);
  const [representatives, setRepresentatives] = useState<
    (Presence | AssignedBrain)[] | []
  >();

  // Redux selectors
  const agents = useSelector(selectAllAgents);
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );

  // Custom hooks
  const { t } = useTranslation();
  const { conversation, updateConversation } = useConversations({
    deskId,
    sessionId,
  });
  const { departments } = useDepartments(deskId);
  const { brain } = useBrains(conversation?.assignee_brain_id);

  // Memoized variables
  const defaultBrainName = useMemo(() => t('common.virtual_assistant'), [t]);
  const activeDepartmentIndex = useMemo(
    () =>
      departments?.findIndex(
        (d) => d.department_id === conversation?.department_id
      ),
    [conversation?.department_id, departments]
  );

  const handleSelectMember = useCallback(
    (i) => {
      const agent = representatives[i];
      if (agent && 'agent_id' in agent) {
        updateConversation({
          assignee_agent_id: agent?.agent_id,
          assignee_brain_id: null,
        });
      } else {
        updateConversation({
          assignee_agent_id: null,
          assignee_brain_id: null,
        });
      }
    },
    [representatives, updateConversation]
  );

  const handleSelectDepartment = useCallback(
    (i) => {
      updateConversation({
        department_id: i !== null ? departments[i].department_id : null,
      });
    },
    [departments, updateConversation]
  );

  useEffect(() => {
    if (agents) {
      const statusPriority = { online: 1, away: 2, offline: 3 };
      const sortedAgents = [...agents].sort((a, b) => {
        return statusPriority?.[a?.status] - statusPriority?.[b?.status];
      });
      if (brain) {
        const representatives = [
          ...sortedAgents,
          {
            ...brain,
            name: brain?.name || defaultBrainName,
            brain_id: brain?.brain_id,
            children: (
              <BotIcon size={16} color="var(--color-foreground-muted)" />
            ),
          },
        ];
        setRepresentatives(representatives);
      } else {
        setRepresentatives(sortedAgents);
      }
    }
  }, [agents, brain, defaultBrainName]);

  useEffect(() => {
    if (representatives && conversation) {
      const active = representatives?.findIndex(
        (x) =>
          x['agent_id'] === conversation?.assignee_agent_id ||
          x['brain_id'] === conversation?.assignee_brain_id ||
          x['name'] === defaultBrainName
      );
      setActiveMemberIndex(active !== -1 ? active : null);
    }
  }, [
    conversation,
    conversation?.assignee_agent_id,
    conversation?.assignee_brain_id,
    defaultBrainName,
    representatives,
    t,
  ]);

  return (
    <>
      <header className={styles.header}>
        <Typography
          color="var(--color-foreground-muted)"
          variant="body-semi-bold"
        >
          {t('analytics.overview')}
        </Typography>
      </header>
      <section>
        <div className={styles.row}>
          <Typography
            variant="label-regular"
            color="var(--color-foreground-muted)"
          >
            {t('departments.assign_to')}:
          </Typography>

          {representatives && (
            <SelectUser
              activeIndex={activeMemberIndex}
              options={representatives}
              onSelect={(i) => handleSelectMember(i)}
              disabled={!canWrite}
              type="members"
            />
          )}
        </div>

        {departments?.length > 0 && (
          <div className={styles.row}>
            <Typography
              variant="label-regular"
              color="var(--color-foreground-muted)"
            >
              {t('departments.department')}:
            </Typography>
            <SelectUser
              options={departments}
              onSelect={(i) => handleSelectDepartment(i)}
              disabled={!canWrite}
              type="departments"
              activeIndex={
                activeDepartmentIndex >= 0 ? activeDepartmentIndex : null
              }
            />
          </div>
        )}
      </section>

      <InformationSection />

      <ContextTagsSection />

      <RelatedConversationsSection />
    </>
  );
};
