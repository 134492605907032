import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { BookIcon, CirclePlayIcon, ImageIcon, MapPinIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import TextareaAutosize from '@/components/atoms/TextareaAutosize/TextareaAutosize';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';

import { VariablesSection } from './VariablesSection/VariablesSection';
import { WhatsappTemplate } from '../../models';
import {
  getComponentsFromTemplate,
  getVariablesLength,
  isMediaHeader,
} from '../../utils';
import { HeaderMediaSection } from './VariablesSection/HeaderMediaSection';

import styles from './TemplateBody.module.scss';

interface Props {
  template: WhatsappTemplate;
  isReadOnly: boolean;
}
const getHeader = (header, t: TFunction) => {
  if (!header) {
    return null;
  }
  if (header?.text) {
    return <Input readOnly label="Header" value={header?.text} />;
  }
  let icon = null;
  let label = '';
  switch (header?.format?.toLowerCase()) {
    case 'location':
      icon = <MapPinIcon size={16} color="var(--color-foreground-muted)" />;
      label = 'Location';
      break;
    case 'document':
      icon = <BookIcon size={16} color="var(--color-foreground-muted)" />;
      label = 'document.pdf';
      break;
    case 'image':
      icon = <ImageIcon size={16} color="var(--color-foreground-muted)" />;
      label = 'image.jpg';
      break;
    case 'video':
      icon = <CirclePlayIcon size={16} color="var(--color-foreground-muted)" />;
      label = 'video.mp4';
      break;
  }
  return (
    <Box my={1} mb={2}>
      <Typography
        variant="label-caps-large"
        component="label"
        className={styles.label}
      >
        {t('broadcasts.template_header')}
      </Typography>
      <Box display="flex" alignItems="center" gap={1} mt={1}>
        {icon}
        <Typography color="var(--color-bg-muted)" variant="body1">
          {label}
        </Typography>
      </Box>
    </Box>
  );
};
export const TemplateBody = ({ template, isReadOnly }: Props) => {
  const { t } = useTranslation();
  const { header, body, footer, buttons } = getComponentsFromTemplate(template);
  const headerVariablesCount = getVariablesLength(header);
  const bodyVariablesCount = getVariablesLength(body);

  return (
    <>
      <Box mt={2}>
        <TitleSubtitle
          title={t('webhook.test.preview')}
          subtitle={t('broadcasts.template_preview')}
          size="large"
        />
      </Box>
      {getHeader(header, t)}

      {headerVariablesCount > 0 && !isMediaHeader(header) && (
        <VariablesSection
          name="header"
          isReadOnly={isReadOnly}
          template={template}
        />
      )}
      {isMediaHeader(header) && (
        <HeaderMediaSection isReadOnly={isReadOnly} templateHeader={header} />
      )}
      {body?.text && (
        <div className={styles.textarea}>
          <Typography
            variant="label-caps-large"
            component="label"
            className={styles.label}
          >
            {t('dialog.email.body')}
          </Typography>

          <TextareaAutosize
            key={body.text}
            readOnly
            defaultValue={body.text}
            onBlur={() => {}}
            onKeyUp={() => {}}
            onKeyDown={() => {}}
          />
        </div>
      )}
      {bodyVariablesCount > 0 && (
        <VariablesSection
          name="body"
          isReadOnly={isReadOnly}
          template={template}
        />
      )}

      {footer?.text && (
        <Box mt={2}>
          <Input readOnly label={t('broadcasts.footer')} value={footer?.text} />
        </Box>
      )}
      {buttons && (
        <div>
          <Typography
            variant="label-caps-large"
            component="label"
            className={styles.label}
          >
            {t('dialog.carousel.buttons')}
          </Typography>
          <div className={styles.buttonsContainer}>
            {buttons.map((button) => (
              <div key={button.text} className={styles.buttonContainer}>
                <Input readOnly key={button.text} value={button.text} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
