// Custom icon

export const CircleUserRoundDotted = ({
  size = 16,
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.33 6.465a10.052 10.052 0 0 0-2.812-2.806M10 2.2a10.046 10.046 0 0 1 4 0M21.8 14a10.044 10.044 0 0 0 0-4m-1.47 7.535a10.051 10.051 0 0 1-2.812 2.806M10 21.8a10.052 10.052 0 0 0 4 0M3.67 17.535a10.048 10.048 0 0 0 2.812 2.806M2.2 10a10.046 10.046 0 0 0 0 4m1.47-7.535a10.048 10.048 0 0 1 2.812-2.806M18 20a6 6 0 0 0-6-6m0 0a6 6 0 0 0-6 6m6-6a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
    </svg>
  );
};
