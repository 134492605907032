import { useCallback, useEffect } from 'react';

import { WebhookIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import {
  TileBrainSkeleton,
  TileCreation,
  TileWebhook,
} from '@/components/organisms/Tile';
import TileList from '@/components/organisms/Tile/TileList';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useWebhooks from '@/hooks/useWebhooks';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';
import { resolveBrainsPath } from '@/util/util';

export default function Webhooks() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { slug } = useAccount();

  const { webhooks, createStatus } = useWebhooks(brainId);
  useTitle(t('pages.webhooks', { 0: brain?.name }));
  const { ai_agents } = useFeatureFlag();

  useEffect(() => {
    pageView(PageName.WEBHOOKS);
  }, []);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const showCreateWebhookModal = useCallback(() => {
    navigate(
      resolveBrainsPath(`/${slug}/brains/${brainId}/webhooks/draft`, ai_agents)
    );
  }, [brainId, navigate, slug, ai_agents]);

  const renderSkeletons = useCallback((n = 3) => {
    return (
      Array(n)
        .fill(0)
        // eslint-disable-next-line react/no-array-index-key
        .map((_, i) => <TileBrainSkeleton isBrain={false} key={i} />)
    );
  }, []);

  const renderTiles = useCallback(() => {
    return webhooks.map((webhook) => (
      <TileWebhook
        brainId={brainId}
        webhook={webhook}
        key={webhook.webhook_id}
      />
    ));
  }, [brainId, webhooks]);

  return (
    <PageContentWrapper newPlain2 readOnly={!canWrite}>
      <TileList>
        {canWrite && (
          <TileCreation
            title={t('webhook.header.create')}
            onCreateClick={showCreateWebhookModal}
            icon={
              <WebhookIcon color="var(--color-foreground-muted)" size={16} />
            }
            subtitle={t('webhook.create_new.subtitle')}
          />
        )}
        {!webhooks && renderSkeletons(3)}
        {webhooks && renderTiles()}
        {createStatus === 'pending' && renderSkeletons(1)}
      </TileList>
    </PageContentWrapper>
  );
}
