import cn from 'classnames';
import { Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';

import styles from './ModalTemplateMessage.module.scss';

interface VariableInputProps<FormType> {
  variableDisplay: string;
  fieldPath: Path<FormType>;
  register: UseFormRegister<FormType>;
  error: boolean;
  setInputRef?: (element: HTMLInputElement | null) => void;
  required?: boolean;
  validationMessage?: string;
}

export const VariableInput = <FormType,>({
  variableDisplay,
  fieldPath,
  register,
  error,
  setInputRef,
  required = true,
  validationMessage,
}: VariableInputProps<FormType>) => {
  const { t } = useTranslation();

  return (
    <div className={cn(styles.buttonContainer, styles.withPrefix)}>
      <div className="p-2 w-fit h-10 flex items-center justify-center rounded-l rounded-tr-none rounded-br-none bg-neutral-50 text-ellipsis text-gray-700 text-sm border border-gray-300 -mr-0.5">
        {variableDisplay}
      </div>
      <Input
        error={error}
        id={fieldPath as string}
        {...register(fieldPath, {
          required: required
            ? validationMessage || t('validation.required')
            : false,
        })}
        ref={(e) => {
          register(fieldPath).ref(e);
          if (setInputRef) {
            setInputRef(e);
          }
        }}
      />
    </div>
  );
};
