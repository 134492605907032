import { useCallback, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import cloneDeep from 'lodash/cloneDeep';
import { CirclePlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import { Action, CarouselCard } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { generateDefaultCarouselCard, randId } from '@/redux/dialogs/helper';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import {
  addCarouselCard,
  updateCardButtons,
  updateCarouselCards,
} from '@/redux/nodes/actions';
import { selectSelectedCarouselIndex } from '@/redux/nodes/selectors';
import { capitalizeFirstLetter, noop } from '@/util/util';

import CarouselAccordion from './CarouselAccordion';
import { MAX_CAROUSEL_CARDS } from './constants';
import { move } from '../../helper';
import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionCarousel.module.scss';
export type CarouselFormType = {
  carousel: CarouselCard[];
};

const ToolkitActionCarousel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedCarouselIndex = useSelector(selectSelectedCarouselIndex);
  const { actionId, cards } = useSelector((state: RootState) => {
    const selectedAction = selectSelectedAction(state) as Extract<
      Action,
      { type: 'carousel' }
    >;

    return {
      actionId: selectedAction?.action_id ?? '',
      cards: selectedAction?.cards ?? [],
    };
  }, shallowEqual);
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  // Add a unique id to every button in the card
  const cardsWithButtonId = useMemo(
    () =>
      cards.map((card) => ({
        ...card,
        buttons: card?.buttons?.map((button) => ({
          ...button,
          id: button.id || randId(6),
        })),
      })),
    [cards]
  );

  const showErrorBanner = cards.length === 0;
  const hasReachedMaxCards = cards.length === MAX_CAROUSEL_CARDS;

  const createNewCard = useCallback(() => {
    const newCard = generateDefaultCarouselCard(cards.length + 1);

    // Update redux
    dispatch(
      addCarouselCard({
        direction: 'end',
        currentIndex: selectedCarouselIndex,
        actionId,
        newCard,
      })
    );
  }, [actionId, cards.length, dispatch, selectedCarouselIndex]);

  const addCarouselButton = useCallback(
    (index: number) => {
      const randomId = randId(6);
      const buttons = [
        ...cardsWithButtonId[index].buttons,
        {
          type: 'postback',
          value: t('dialog.carousel.rand_text', { 0: randomId }),
          label: `${t('dialog.carousel.button')} ${cardsWithButtonId[index].buttons.length + 1}`,
          id: randomId,
        },
      ];

      dispatch(
        updateCardButtons({
          actionId,
          cardIndex: index,
          buttons,
        })
      );
    },
    [actionId, cardsWithButtonId, dispatch, t]
  );

  const updateErrors = useCallback(
    (key: string, value: string, index?: number) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.carousel'),
            body: capitalizeFirstLetter(value),
            type: 'carousel',
            alertField: key,
            index,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t]
  );

  const handleDragCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const reOrderedCards = move(
        cloneDeep(cards as CarouselCard[]),
        dragIndex,
        hoverIndex
      );

      dispatch(
        updateCarouselCards({
          actionId,
          cards: cloneDeep(reOrderedCards),
        })
      );
    },
    [actionId, cards, dispatch]
  );

  // Update dialog errors
  useEffect(() => {
    const body = showErrorBanner
      ? t('dialog.errors.at_least_one', {
          0: t('actions.types.card').toLowerCase(),
        })
      : undefined;

    updateErrors('carousel', body);
  }, [showErrorBanner, t, updateErrors]);

  return (
    <ToolkitWrapper type="carousel">
      <Box pb="var(--space-8)" textAlign="center">
        <Button
          size="small"
          variant="tertiary"
          onClick={createNewCard}
          disabled={hasReachedMaxCards}
        >
          <CirclePlusIcon
            color={
              hasReachedMaxCards
                ? 'var(--color-foreground-muted)'
                : 'var(--color-foreground-muted)'
            }
            size={16}
          />
          {t('common.add_card')}
        </Button>
      </Box>

      {showErrorBanner && (
        <Box mt="var(--space-24)">
          <Banner variant="critical" relativePosition>
            {t('dialog.errors.at_least_one', {
              0: t('actions.types.card').toLowerCase(),
            })}
          </Banner>
        </Box>
      )}

      <Stack
        className={styles.cards}
        justifyContent="center"
        spacing="var(--space-8)"
      >
        {cardsWithButtonId.map((card, index) => (
          <CarouselAccordion
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            card={card}
            mediaFieldName={`carousel.${index}.media.url`}
            handleAddButton={() => addCarouselButton(index)}
            actionId={actionId}
            moveItem={handleDragCard}
            dropItem={noop}
            updateErrors={updateErrors}
          />
        ))}

        {hasReachedMaxCards && (
          <Banner hasIcon variant="neutral" centered relativePosition>
            {t('dialog.carousel.max_cards')}
          </Banner>
        )}
      </Stack>
    </ToolkitWrapper>
  );
};

export default ToolkitActionCarousel;
