import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SetRequired } from 'type-fest';

import { businessHoursEndpoints } from '@/api/endpoints';
import {
  DEFAULT_HOURS,
  extractIntervalsFromHours,
} from '@/components/pages/BusinessHours/utils';
import {
  API as brainsAPI,
  onBrainRemoved,
  onBrainUpdated,
} from '@/hooks/useBrains';
import {
  API as businessHourAPI,
  onBusinessHourCreated,
} from '@/hooks/useBusinessHours';
import useCollections, {
  API as collectionsAPI,
  onCollectionCreated,
  onCollectionRemoved,
} from '@/hooks/useCollections';
import useDatasources, {
  API as datasourceAPI,
  onDatasourceCreated,
  onDatasourceRemoved,
} from '@/hooks/useDatasources';
import useDesks, { API as deskAPI, onDeskCreated } from '@/hooks/useDesks';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import {
  API as integrationsApi,
  onIntegrationCreated,
  useIntegrations,
} from '@/hooks/useIntegrations';
import { useTemplates } from '@/hooks/useTemplates';
import { Brain } from '@/models/brain';
import { BusinessHour, BusinessHours } from '@/models/businessHours';
import { Collection, Datasource, DatasourceType } from '@/models/collections';
import { Desk } from '@/models/desk';
import { Integration, IntegrationType } from '@/models/integration';
import { generateNextName } from '@/modules/onboarding/helper';
import {
  setCreatedBrainId,
  setDatasourceType,
  setDeskId,
  setIntegrationId,
  updateIntegrations,
} from '@/modules/onboarding/redux/actions';
import {
  selectCreatedBrainId,
  selectDatasourceType,
  selectDeskId,
  selectGoal,
  selectIntegrations,
  selectLanguage,
  selectTemplateBrainId,
  selectUrl,
} from '@/modules/onboarding/redux/selectors';
import {
  API as rulesApi,
  ReorderProps,
  onRuleReorder,
  useRules,
  onRuleCreated,
  onRuleRemoved,
} from '@/modules/rules/hooks/useRules';
import { ActionType, Rule } from '@/modules/rules/model';
import { setCollectionId, setDatasourceId } from '@/redux/session/actions';
import {
  selectAccountId,
  selectCollectionId,
  selectDatasourceId,
} from '@/redux/session/selectors';

export const useOnboarding = () => {
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const { desks } = useDesks();
  const accountId = useSelector(selectAccountId);
  const goal = useSelector(selectGoal);
  const brain_id = useSelector(selectTemplateBrainId);
  const url = useSelector(selectUrl);
  const selectedIntegrations = useSelector(selectIntegrations);
  const datasourceType = useSelector(selectDatasourceType);
  const deskId = useSelector(selectDeskId);
  const collectionId = useSelector(selectCollectionId);
  const datasource_id = useSelector(selectDatasourceId);
  const createdBrainId = useSelector(selectCreatedBrainId);
  const { integrations } = useIntegrations(deskId);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { templateBrain, createBrainFromTemplateAsync } = useTemplates();
  const { rules } = useRules(deskId);
  const { collections } = useCollections(collectionId);
  const { datasource } = useDatasources(collectionId, datasource_id);
  const { markAsComplete } = useHomeCheckList();
  const dispatchActions = useCallback(
    ({
      integration,
      brain,
      collection,
      type,
      datasource,
    }: {
      integration: Integration;
      brain: Brain;
      collection: Collection;
      type: IntegrationType;
      datasource?: Datasource;
    }) => {
      dispatch(updateIntegrations({ type: 'ADD', integration: type }));
      dispatch(setIntegrationId(integration.integration_id));
      dispatch(setCreatedBrainId(brain.brain_id));
      dispatch(setCollectionId(collection.collection_id));
      dispatch(setDatasourceType(datasourceType));
      if (datasource) {
        dispatch(setDatasourceId(datasource?.datasource_id));
      }
    },
    [datasourceType, dispatch]
  );

  // queries and mutations

  // integrations
  const { mutateAsync: createIntegration, status: integrationStatus } =
    useMutation<
      Integration,
      Error,
      SetRequired<Partial<Integration>, 'type' | 'desk_id'>
    >({
      mutationFn: integrationsApi.createIntegration,
      onSuccess: (resp) => {
        onIntegrationCreated(queryClient, resp);
      },
    });

  // business hours
  const { data: businessHours } = useQuery<BusinessHours, Error>({
    queryKey: [businessHoursEndpoints.businessHours, deskId, 'business_hours'],
    queryFn: () => businessHourAPI.listBusinessHours(deskId),

    enabled: !!accountId && !!deskId,
  });

  const { mutateAsync: addBusinessHour } = useMutation<
    BusinessHour,
    Error,
    Partial<BusinessHour>
  >({
    mutationFn: (businessHour) =>
      businessHourAPI.addBusinessHour(deskId, businessHour),
    onSuccess: (resp) => {
      onBusinessHourCreated(queryClient, deskId, resp);
    },
  });

  // rules
  const { mutateAsync: reorderRule } = useMutation<Rule, Error, ReorderProps>({
    mutationFn: (params) =>
      rulesApi.reorderRule(deskId, params.rule_id, params.position),
    onSuccess: (resp) => {
      onRuleReorder(queryClient, deskId, resp, rules);
    },
  });

  const { mutateAsync: addRule } = useMutation<Rule, Error, Partial<Rule>>({
    mutationFn: (params) => rulesApi.addRule(deskId, params),
    onSuccess: (resp) => {
      onRuleCreated(queryClient, resp, deskId);
    },
  });

  const { mutateAsync: removeRule } = useMutation<Rule, Error, string>({
    mutationFn: (id) => rulesApi.deleteRule(deskId, id),
    onSuccess: (resp) => {
      onRuleRemoved(queryClient, deskId, resp.rule_id);
    },
  });

  // desks
  const { mutateAsync: createDeskAsync } = useMutation<
    Desk,
    Error,
    Partial<Desk>
  >({
    mutationFn: deskAPI.createDesk,
    onSuccess: async (resp) => {
      onDeskCreated(queryClient, {
        ...resp,
        account_id: accountId,
      });
      dispatch(setDeskId(resp.desk_id));
    },
  });

  // collections
  const { mutateAsync: createCollection } = useMutation<
    Collection,
    Error,
    Partial<Collection>
  >({
    mutationFn: collectionsAPI.createCollection,
    onSuccess: (resp) => {
      onCollectionCreated(queryClient, resp);
    },
  });

  const { mutateAsync: deleteCollection } = useMutation<
    Collection,
    Error,
    string
  >({
    mutationFn: (id) => collectionsAPI.deleteCollection(id),
    onSuccess: (resp) => {
      onCollectionRemoved(queryClient, accountId, resp.collection_id);
    },
  });

  // brains
  const { mutateAsync: updateBrain } = useMutation<
    Brain,
    Error,
    Partial<Brain>
  >({
    mutationFn: brainsAPI.updateBrain,
    onSuccess: (resp) => {
      onBrainUpdated(queryClient, resp);
    },
  });

  const { mutate: removeBrain } = useMutation<Brain, Error, string>({
    mutationFn: (id) => brainsAPI.deleteBrain(id),
    onSuccess: (resp) => {
      onBrainRemoved(queryClient, accountId, resp.brain_id);
    },
  });

  // datasources
  const { mutateAsync: createDatasource } = useMutation<
    Datasource,
    Error,
    Partial<Datasource>
  >({
    mutationFn: (data) => datasourceAPI.createDatasource(collectionId, data),
    onSuccess: (resp) => {
      markAsComplete(AccountUserPrefsEnum.IMPORT_CONTENT, true);
      onDatasourceCreated(queryClient, resp);
    },
  });

  const { mutateAsync: removeDatasource } = useMutation<
    Datasource,
    Error,
    string
  >({
    mutationFn: (id) => datasourceAPI.deleteDatasource(collectionId, id),
    onSuccess: (resp) => {
      onDatasourceRemoved(queryClient, collectionId, resp.datasource_id);
    },
  });

  // helpers
  const setupDeskIfRequired = useCallback(async (): Promise<Desk> => {
    const name = t('onboarding.environment_name');
    let desk = desks?.find((d) => d.name === name);

    // Create a desk with business hours if it doesn't exist
    if (!desk) {
      desk = await createDeskAsync({
        name,
        is_service_desk: true,
        description: t('onboarding.environment_description', {
          0: new Date(),
        }),
      });
    }
    if (businessHours?.business_hours?.length === 0) {
      // Create default business hours
      addBusinessHour({
        name: t('business_hours.default'),
        timezone: moment.tz.guess(),
        is_default: true,
        intervals: extractIntervalsFromHours([DEFAULT_HOURS]),
        holidays: [],
      });
    }
    dispatch(setDeskId(desk.desk_id));

    return desk;
  }, [
    addBusinessHour,
    businessHours?.business_hours,
    createDeskAsync,
    desks,
    dispatch,
    t,
  ]);

  const setupIntegrationsIfRequired = useCallback(
    async (
      params: SetRequired<Partial<Integration>, 'desk_id'> & {
        types: IntegrationType[];
      }
    ): Promise<Integration[]> => {
      const { desk_id, types, ...restParams } = params;
      const uniqueTypes = [...new Set(types)];

      const integrationPromises = uniqueTypes.map(async (t) => {
        let integration = integrations?.find((i) => i?.type === t);

        if (!integration && integrationStatus !== 'pending') {
          try {
            integration = await createIntegration({
              ...restParams,
              desk_id,
              type: t,
              name: t,
              active: t === 'web',
            });
            if (t === 'web') {
              markAsComplete(AccountUserPrefsEnum.ACTIVATE_CHANNELS, true);
            }
          } catch (error) {
            console.error('Error creating integration:', error);
          }
        }

        return integration;
      });

      const result = await Promise.all(integrationPromises);

      return result;
    },
    [createIntegration, integrationStatus, integrations, markAsComplete]
  );

  const setupRuleIfRequired = useCallback(
    async (brainId: string): Promise<Rule> => {
      const brain_parent_id = brainId;
      const brain_version = 0;
      let rule;

      // If there is a rule that assigns to this brain, make sure it's the first rule
      const existingRule = rules?.rules?.find((rule) =>
        rule.actions.some(
          (action) =>
            action.type === ActionType.ASSIGN_BRAIN &&
            action.brain_parent_id === brain_parent_id
        )
      );

      // If the existing rule is already the first rule, nothing more to do
      if (existingRule && rules?.rules[0]?.rule_id === existingRule.rule_id) {
        return existingRule;
      }

      // If the existing rule is not the first rule, move it to the top
      if (existingRule) {
        rule = await reorderRule({
          rule_id: existingRule.rule_id,
          position: 1,
        });
        return rule;
      }

      // otherwise, create a new rule
      const newRule = await addRule({
        name: generateNextName(rules?.rules, t('onboarding.rule_name')),
        status: 'active',
        triggers: [{ type: 'first_message' }],
        condition: { conditions: [], operator: 'or' },
        actions: [
          { brain_parent_id, type: ActionType.ASSIGN_BRAIN, brain_version },
        ],
        metadata: {
          source: 'onboarding',
        },
      });

      // and move it to the top
      rule = await reorderRule({ rule_id: newRule.rule_id, position: 1 });

      return rule;
    },
    [addRule, rules?.rules, reorderRule, t]
  );

  const initializeBrainWithTemplate = useCallback(
    async ({
      desk_id,
      integrations,
      language,
    }: {
      desk_id?: string;
      integrations?: IntegrationType[];
      language?: string;
    } = {}): Promise<{
      brain: Brain;
      rule: Rule;
      integrations: Integration[];
    }> => {
      try {
        let desk;
        let integrationsList;

        if (desk_id) {
          desk = { desk_id };
        } else {
          desk = await setupDeskIfRequired();
        }

        if (integrations) {
          integrationsList = await setupIntegrationsIfRequired({
            desk_id: desk.desk_id,
            types: integrations || ['web'],
          });
        }

        const brain = await templateBrain(language || 'en');
        const createdBrain = await createBrainFromTemplateAsync({
          ...brain,
          brain_id: 'playground',
          name: t('onboarding.first_agent'),
          confidence_threshold: 0.5,
        });

        const rule = await setupRuleIfRequired(createdBrain.brain_id);

        return {
          brain: createdBrain,
          rule,
          integrations: integrationsList || [],
        };
      } catch (error) {
        console.error('Error initializing brain with template:', error);
        throw error;
      }
    },
    [
      createBrainFromTemplateAsync,
      setupDeskIfRequired,
      setupIntegrationsIfRequired,
      setupRuleIfRequired,
      templateBrain,
      t,
    ]
  );

  const setupCollection = async ({
    language,
  }: {
    language: string;
  }): Promise<Collection> => {
    const targetCollectionName = t('onboarding.collection_name');
    const newCollectionName = generateNextName(
      collections,
      targetCollectionName
    );
    const collection = await createCollection({
      name: newCollectionName,
      language,
    });

    dispatch(setCollectionId(collection.collection_id));
    return collection;
  };

  const removeCollection = async ({
    brain,
    collection,
  }: {
    brain: Brain;
    collection: Collection;
  }) => {
    if (brain) {
      await updateBrain({
        brain_id: brain.brain_id,
        collection_id: null,
      });
    }
    if (collection) {
      await deleteCollection(collection.collection_id);
    }
  };

  const updateBrainWithCollection = async ({
    collection_id,
    brain_id,
  }: {
    collection_id: string;
    brain_id: string;
  }): Promise<Brain> => {
    const updatedBrain = updateBrain(
      {
        brain_id,
        collection_id,
      },
      {
        onSuccess: () => {
          markAsComplete(AccountUserPrefsEnum.CONNECT_BRAIN, true);
        },
      }
    );
    return updatedBrain;
  };

  const setupCommonResources = async ({
    language,
    integrationsSelected,
  }: {
    language: string;
    integrationsSelected: IntegrationType[];
  }) => {
    const desk = await setupDeskIfRequired();
    const { brain, rule, integrations } = await initializeBrainWithTemplate({
      desk_id: desk?.desk_id,
      language,
      integrations: [...integrationsSelected, 'web'],
    });
    const collection = await setupCollection({
      language,
    });

    await updateBrainWithCollection({
      collection_id: collection.collection_id,
      brain_id: brain.brain_id,
    });
    // we only need to return the web integration to connect the client
    const integration = integrations.find((i) => i.type === 'web');

    return { integration, brain, collection, desk, rule };
  };

  const cleanUpResources = async ({
    brain,
    collection,
    ruleId,
    brainId,
    datasourceId,
  }: {
    brain?: Brain;
    collection?: Collection;
    ruleId?: string;
    brainId?: string;
    datasourceId?: string;
  }) => {
    if (datasourceId) {
      await removeDatasource(datasourceId);
    } else if (datasource_id) {
      await removeDatasource(datasource_id);
    }
    if (collection) {
      await removeCollection({ brain, collection });
    }
    if (ruleId) {
      await removeRule(ruleId);
    }
    if (brainId) {
      await removeBrain(brainId);
    }
  };

  const updateCollectionWithDatasource = async ({
    datasource_url,
    type,
  }: {
    datasource_url: string;
    type: DatasourceType;
  }): Promise<Datasource> => {
    const ds = createDatasource({
      type,
      name: t('onboarding.datasource_name'),
      config: {
        seed_urls: [datasource_url],
      },
    });

    return ds;
  };

  const applyBrain = useCallback(async () => {
    const desk = await setupDeskIfRequired();

    let brain: Brain | null = null;

    await setupIntegrationsIfRequired({
      desk_id: desk.desk_id,
      types: selectedIntegrations,
    });

    if (brain_id) {
      brain = await createBrainFromTemplateAsync({
        brain_id,
        confidence_threshold: 0.5,
      });

      await setupRuleIfRequired(brain.brain_id);
    } else if (!createdBrainId) {
      await initializeBrainWithTemplate({
        desk_id: desk.desk_id,
        language,
      });
    }
    return { brain, desk };
  }, [
    brain_id,
    createBrainFromTemplateAsync,
    createdBrainId,
    initializeBrainWithTemplate,
    language,
    selectedIntegrations,
    setupDeskIfRequired,
    setupIntegrationsIfRequired,
    setupRuleIfRequired,
  ]);

  const availableFiles =
    datasource?.document_count -
    datasource?.failed_count -
    datasource?.pending_count -
    datasource?.indexing_count;

  const getArticlePercentage = useCallback(() => {
    if (datasource?.document_count === 0) {
      return datasource?.document_count;
    }
    const percentage = (availableFiles / datasource?.document_count) * 100;
    return percentage;
  }, [availableFiles, datasource]);

  return {
    // redux selectors
    language,
    goal,
    selectedIntegrations,
    datasourceType,
    createdBrainId,
    brain_id,
    url,
    dispatchActions,
    setupCommonResources,
    cleanUpResources,
    removeCollection,
    removeBrain,
    removeDatasource,
    updateCollectionWithDatasource,
    setupDeskIfRequired,
    initializeBrainWithTemplate,
    setupIntegrationsIfRequired,
    setupRuleIfRequired,
    applyBrain,
    removeRule,
    getArticlePercentage,
  };
};
