import {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from 'react';

import isNil from 'lodash/isNil';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useDebounce from '@/hooks/useDebounce';
import { useMacros } from '@/hooks/useMacros';
import useMessages from '@/hooks/useMessages';
import { IntegrationType } from '@/models/integration';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import {
  setFocusIndex,
  setFilteredOptions,
  decrementFocusIndex,
  incrementFocusIndex,
} from '@/redux/macros/actions';
import {
  filteredOptionsSelector,
  focusIndexSelector,
} from '@/redux/macros/selectors';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';
import { MAX_MEDIA_UPLOAD_SIZE } from '@/util/constants';
import { isKeyEnter, preventClickThrough } from '@/util/util';

import { ChatContext } from '../../context';
import { useVoiceMessage } from '../FileUploadChatBox/useVoiceMessage';
import {
  convertFile,
  dropzoneAcceptConfig,
  getMessageType,
  prepareFileUpload,
  uploadFile,
} from '../helpers';
const MAX_FILES = 4;

export type UploadedData = {
  name: string;
  id: string;
  type: 'file' | 'audio' | 'image' | 'video';
  original_name: string;
  mime_type: string;
};

const channelsWithoutLiveChat: IntegrationType[] = [
  'front',
  'sunco',
  'intercom',
  'zendesk',
];

/**
 * Provides chat box state and actions.
 */
export const useChatBox = () => {
  // Redux selectors and dispatch
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const options = useSelector(filteredOptionsSelector);
  const focusIndex = useSelector(focusIndexSelector);
  const dispatch = useDispatch();

  // Custom hooks and services
  const { macros } = useMacros();
  const { t } = useTranslation();
  const { chat, setChat } = useContext(ChatContext);

  // File handling
  const [files, setFiles] = useState([]);
  const [uploadedData, setUploadedData] = useState<UploadedData[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  // Voice message handling
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    setRecordingBlob,
    isRecording,
    mediaRecorder,
    recordingTime,
    isTranscoding,
    totalRecordingTime,
  } = useVoiceMessage();

  // Conversation and messaging
  const { updateTyping, conversation, getStatus, updateConversation } =
    useConversations({ deskId, sessionId });

  const { createMessage, createStatus, createPresignedUrl } = useMessages(
    deskId,
    sessionId
  );

  // Input and typing
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 1000);
  const inputRef = useRef<HTMLDivElement>(null);
  const typingRef = useRef<boolean>(false);
  const [showQuickResponses, setShowQuickResponses] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((rejection) => {
          const errorMessage =
            rejection.errors?.[0]?.message || 'Unknown error';
          dispatch(
            addTemporalToast('error', `${rejection.file.name}: ${errorMessage}`)
          );
        });
      }

      // If no accepted files, return early
      if (!acceptedFiles.length) {
        return;
      }

      const canUpload = uploadedData.length + acceptedFiles.length <= MAX_FILES;
      if (!canUpload) {
        const errorMessage = t('chatBox.upload_error', {
          0: MAX_FILES,
        });
        dispatch(addTemporalToast('error', errorMessage));
        return;
      }

      setUploading(true);

      try {
        const preparedFiles = acceptedFiles.map((file) =>
          prepareFileUpload(file)
        );
        setFiles((oldFiles) => [...oldFiles, ...preparedFiles]);

        const uploadedFilesData = [];

        for (const file of preparedFiles) {
          try {
            // Convert file if needed (audio, image)
            const fileToUpload = await convertFile(file, conversation?.channel);
            const uploadedDataItem = await uploadFile(
              fileToUpload,
              file.fileId,
              createPresignedUrl,
              conversation?.channel
            );

            uploadedFilesData.push(uploadedDataItem);
          } catch (fileError) {
            setFiles([]);
            setUploadedData([]);
            dispatch(
              addTemporalToast(
                'error',
                `Failed to upload ${file.name}: ${fileError.message}`
              )
            );
            throw fileError;
          }
        }

        // Update uploadedData only if all files were successfully uploaded
        setUploadedData((prev) => [...prev, ...uploadedFilesData]);
      } catch (error) {
        console.error('File upload error:', error);
      } finally {
        setUploading(false);
      }
    },
    [
      uploadedData.length,
      dispatch,
      t,
      createPresignedUrl,
      conversation?.channel,
    ]
  );

  const dropzoneConfig = useMemo(
    () => ({
      accept: dropzoneAcceptConfig,
      maxSize: MAX_MEDIA_UPLOAD_SIZE,
      disabled: uploading,
      multiple: true,
      noDragEventsBubbling: true,
      onDrop,
      noClick: true,
      noKeyboard: true,
      validator: (_file) => {
        if (uploadedData.length > MAX_FILES) {
          return {
            code: 'too-many-files',
            message: t('chatBox.upload_error', { 0: MAX_FILES }),
          };
        }
        return null;
      },
    }),
    [uploading, onDrop, uploadedData.length, t]
  );

  const { getRootProps, getInputProps, open, isDragActive } =
    useDropzone(dropzoneConfig);

  useEffect(() => {
    if (!isNil(debouncedInput) && typingRef.current) {
      updateTyping('stop');
      typingRef.current = false;
    }
  }, [debouncedInput, updateTyping]);

  const filteredOptions = useMemo(
    () =>
      macros?.filter((option) =>
        `${option?.name} ${option?.text}`
          .toLowerCase()
          .includes(input.toLowerCase().replace('/', ''))
      ),
    [input, macros]
  );

  const updateInputText = useCallback(
    (text = '') => {
      if (inputRef.current) {
        inputRef.current.innerText = text;
      }
      setInput(text);
      setChat({ ...chat, [sessionId]: text });
    },
    [chat, sessionId, setChat]
  );

  useEffect(() => {
    if (conversation?.status === 'closed' && input !== '') {
      updateInputText('');
      setFiles([]);
    }
  }, [conversation?.status, input, sessionId, setFiles, updateInputText]);

  useEffect(() => {
    if (inputRef?.current) {
      updateInputText(chat[sessionId] || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, sessionId]);

  useEffect(() => {
    dispatch(setFilteredOptions(filteredOptions));
    dispatch(setFocusIndex(0));
  }, [dispatch, filteredOptions]);

  const handleClick = useCallback(
    async (e) => {
      if (uploading) {
        return;
      }

      e.preventDefault();

      const hasInput = input && input.trim() !== '';

      // Map uploadedData to attachment objects
      const attachments = uploadedData.map((data) => ({
        id: data.id,
        type: getMessageType({
          mimeType: data.mime_type,
          channel: conversation?.channel,
        }),
        title: data.original_name,
        filename: data.name,
        mime_type: data.mime_type,
      }));

      // Ensure the message can only be sent if:
      // - There are no attachments, but the input has valid text
      // - OR there are attachments, regardless of input text
      if ((attachments.length === 0 && hasInput) || attachments.length > 0) {
        const text = hasInput ? input.trim() : undefined;

        createMessage(
          {
            body: {
              text,
              attachments,
            },
          },
          {
            onSuccess: () => {
              updateInputText('');
            },
          }
        );
      }

      // Reset states
      setUploadedData([]);
      setFiles([]);
      setRecordingBlob(undefined);
      setAudioUrl(null);

      // Stop typing indication if input is not null
      if (!isNil(input)) {
        updateTyping('stop');
        typingRef.current = false;
      }
    },
    [
      uploading,
      input,
      uploadedData,
      setRecordingBlob,
      conversation?.channel,
      createMessage,
      updateInputText,
      updateTyping,
    ]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (isKeyEnter(e) && !e.shiftKey && !showQuickResponses) {
        e.preventDefault();
      }
    },
    [showQuickResponses]
  );

  const handleKeyPress = useCallback(
    async (event) => {
      if (isKeyEnter(event) && showQuickResponses) {
        event.preventDefault();
        updateInputText(options[focusIndex].text);
        setShowQuickResponses(false);
        return;
      }

      const canSendMessage =
        isKeyEnter(event) &&
        !event.shiftKey &&
        !showQuickResponses &&
        !(getStatus === 'pending' || uploading || createStatus === 'pending');

      if (canSendMessage) {
        event.preventDefault();
        handleClick(event);
        return;
      }

      if (event.code === 'Slash' && input?.length === 1) {
        setShowQuickResponses(true);
        return;
      }

      const shouldHideQuickResponses =
        showQuickResponses &&
        event.code === 'Backspace' &&
        (options?.length === 0 || input?.length === 1);

      if (shouldHideQuickResponses) {
        setShowQuickResponses(false);
        return;
      }

      if (showQuickResponses) {
        if (event.code === 'ArrowUp') {
          dispatch(decrementFocusIndex());
        } else if (event.code === 'ArrowDown') {
          dispatch(incrementFocusIndex());
        }
      }
    },
    [
      showQuickResponses,
      getStatus,
      uploading,
      createStatus,
      input?.length,
      updateInputText,
      options,
      focusIndex,
      handleClick,
      dispatch,
    ]
  );

  const handleChange = useCallback(() => {
    if (conversation?.status === 'closed' && conversation?.state?.active) {
      updateConversation({ status: 'open' });
    }
    if (!typingRef.current) {
      updateTyping('start');
      typingRef.current = true;
    }
    if (showQuickResponses && /\n/.test(inputRef.current?.innerText || '')) {
      return;
    }

    if (inputRef.current) {
      const trimmedInput = inputRef.current.innerText.trim();
      setInput(trimmedInput);
      setChat({ ...chat, [sessionId]: inputRef.current.innerText });
    }
  }, [
    chat,
    conversation?.state?.active,
    conversation?.status,
    sessionId,
    setChat,
    showQuickResponses,
    updateConversation,
    updateTyping,
  ]);

  const changeInputTo = useCallback(
    (text: string) => {
      updateInputText(text);
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [updateInputText]
  );

  const handleOnRemoveFile = useCallback((e, index: number, fileId: string) => {
    preventClickThrough(e);
    setFiles((files) => files.filter((_, i) => i !== index));
    setUploadedData((f) => f.filter((f) => f.id !== fileId));
  }, []);

  const handlePaste = useCallback(
    async (e) => {
      e.preventDefault();
      const text = e.clipboardData?.getData('text/plain');
      // Text handling
      if (text) {
        document.execCommand('insertText', false, text.trim());
        return;
      }

      // Image handling
      try {
        if (uploading) {
          return;
        }

        // Check if adding a new image would exceed the limit
        if (uploadedData.length >= MAX_FILES) {
          dispatch(
            addTemporalToast(
              'error',
              t('chatBox.upload_error', { 0: MAX_FILES })
            )
          );
          return;
        }
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            if (type.startsWith('image')) {
              if (uploadedData.length + 1 > MAX_FILES) {
                dispatch(
                  addTemporalToast(
                    'error',
                    t('chatBox.upload_error', { 0: MAX_FILES })
                  )
                );
                return;
              }
              setUploading(true);
              const blob = await clipboardItem.getType(type);
              const extension = blob.type.split('/')[1] || 'jpg';
              const file = new File([blob], `${Date.now()}.${extension}`, {
                type: blob.type,
              });

              const prepared = prepareFileUpload(file);
              setFiles((prev) => [...prev, prepared]);

              const imageData = await uploadFile(
                file,
                prepared.fileId,
                createPresignedUrl,
                conversation?.channel
              );
              setUploadedData((prev) => [...prev, imageData]);
              setUploading(false);
            }
          }
        }
      } catch (readErr) {
        console.warn('[ChatBox]Failed to read clipboard items', readErr);
      }
    },
    [
      uploading,
      uploadedData.length,
      dispatch,
      t,
      createPresignedUrl,
      conversation?.channel,
    ]
  );

  const handleSendAudio = useCallback(async () => {
    const extension = recordingBlob.type.split('/')[1];
    const audioFile = new File([recordingBlob], `${Date.now()}.${extension}`, {
      type: recordingBlob.type,
    });
    const prepared = prepareFileUpload(audioFile);

    setUploading(true);
    try {
      const audioData = await uploadFile(
        audioFile,
        prepared.fileId,
        createPresignedUrl,
        conversation?.channel
      );

      setUploadedData((prev) => [...prev, audioData]);
      setFiles((prev) => [
        ...prev,
        Object.assign(audioFile, {
          preview: URL.createObjectURL(audioFile),
          path: audioFile.name,
        }),
      ]);
    } catch (error) {
      console.error('Audio upload error:', error);
      dispatch(
        addTemporalToast('error', `Failed to upload audio: ${error.message}`)
      );
    } finally {
      setUploading(false);
    }
  }, [recordingBlob, createPresignedUrl, conversation?.channel, dispatch]);

  useEffect(() => {
    if (recordingBlob && !audioUrl && !isTranscoding) {
      const url = URL.createObjectURL(recordingBlob);
      setAudioUrl(url);
      handleSendAudio();
    }
  }, [audioUrl, handleSendAudio, isTranscoding, recordingBlob]);

  const handleStopRecording = useCallback(async () => {
    stopRecording();
  }, [stopRecording]);

  const handleDeleteRecording = useCallback(() => {
    setRecordingBlob(undefined);
    setAudioUrl(null);
    setFiles([]);
    setUploadedData([]);
  }, [setRecordingBlob]);

  const handleStartRecording = useCallback(() => {
    setFiles([]);
    setUploadedData([]);
    startRecording();
  }, [startRecording]);

  const isChannelSupported = !channelsWithoutLiveChat.includes(
    conversation?.channel
  );
  return {
    files,
    isClosed: conversation?.state?.active && conversation?.status === 'closed',
    isActive: conversation?.state?.active,
    isLoading:
      getStatus === 'pending' || uploading || createStatus === 'pending',
    isButtonDisabled:
      ((input?.length === 0 || isNil(input)) && !uploadedData.length) ||
      uploading,
    isUnavailable:
      (!conversation?.state?.active && getStatus !== 'pending') ||
      !isChannelSupported,
    inputRef,
    filteredOptions,
    showQuickResponses,
    handleClick,
    handleChange,
    changeInputTo,
    handleKeyPress,
    handleKeyDown,
    setShowQuickResponses,
    handleOnRemoveFile,
    handlePaste,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    handleSendAudio,
    audioUrl,
    handleStartRecording,
    isRecording,
    mediaRecorder,
    recordingTime,
    recordingBlob,
    isTranscoding,
    handleStopRecording,
    handleDeleteRecording,
    totalRecordingTime,
    isChannelSupported,
  };
};
