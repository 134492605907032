import { Dispatch, MouseEventHandler, SetStateAction, useRef } from 'react';

import isEmpty from 'lodash/isEmpty';
import { SaveIcon, Trash2Icon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import { AccountFilter } from '@/modules/analytics/hooks/useFavoriteFilters';
import { LogFilter } from '@/modules/analytics/hooks/useFavorites';

import styles from './FavoriteDropdown.module.scss';

type Filter = LogFilter | AccountFilter;

interface Props {
  favorites: Filter[];
  onSave: () => void;
  onDelete: (index: number) => MouseEventHandler<HTMLSpanElement>;
  onClick: (filter: Filter) => MouseEventHandler<HTMLSpanElement>;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const FavoriteDropdown = ({
  onSave,
  onClick,
  onDelete,
  favorites,
  setOpen,
}: Props) => {
  const { t } = useTranslation();

  const favoriteRef = useRef<HTMLDivElement>();

  useClickAway(favoriteRef, () => {
    if (setOpen) {
      setOpen(false);
    }
  });

  return (
    <div className={styles.dropdown} ref={favoriteRef}>
      <Button
        size="small"
        variant="tertiary"
        className={styles.save}
        onClick={onSave}
      >
        <SaveIcon color="var(--color-foreground-muted)" size={16} />
        {t('analytics.save_favorites')}
      </Button>
      {isEmpty(favorites) ? (
        <p className={styles.title}>{t('analytics.no_favourites')}</p>
      ) : (
        <>
          <p className={styles.title}>{t('analytics.my_filters')}</p>
          <div className={styles.list_container}>
            {favorites.map((fav, index) => (
              <button
                key={fav.name}
                className={styles.saved_filter}
                onClick={onClick(fav)}
              >
                {fav.name}
                <span aria-hidden="true" onClick={onDelete(index)}>
                  <Trash2Icon size={16} />
                </span>
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FavoriteDropdown;
