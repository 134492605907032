import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import styles from './RoleAction.module.scss';

type RoleActionProps = {
  translation: string;
  icon: React.ReactNode;
};

export const RoleAction = ({ translation, icon }: RoleActionProps) => {
  const { t } = useTranslation();
  return (
    <li className={styles.card}>
      <Typography
        // TODO: Replace hardcoded values when new typography variant is implemented
        lineHeight="var(--space-28)"
        fontSize={18}
        fontWeight={600}
        className={styles.card__title}
        component="h3"
        color="var(--color-foreground)"
      >
        <span>{icon}</span>
        <span>{t(`member_invitation.role_actions.${translation}.title`)}</span>
      </Typography>
      <Typography
        variant="subheading-regular"
        color="var(--color-foreground-muted)"
        component="p"
      >
        {t(`member_invitation.role_actions.${translation}.description`)}
      </Typography>
    </li>
  );
};
