import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew';
import { useSubscribers } from '@/modules/broadcast/hooks/useSubscribers';
import {
  selectBroadcast,
  selectContextVariableOptions,
  selectIsDraft,
} from '@/modules/broadcast/redux/selectors';
import { getContextVariableOptions } from '@/modules/broadcast/utils';
import { Option } from '@/modules/rules/model';

import { extractVariableNames } from './helpers';
import { Form, WhatsappTemplate } from '../../../models';

import styles from './VariablesSection.module.scss';

interface Props {
  name: 'body' | 'header' | 'footer';
  isReadOnly: boolean;
  template: WhatsappTemplate;
}

/**
 * Renders a variable field with autocomplete
 */
interface VariableFieldProps {
  field: {
    id: string;
    [key: string]: unknown;
  };
  index: number;
  formName: string;
  variableNames: string[];
  template: WhatsappTemplate;
  options: Option[];
  control: ReturnType<typeof useFormContext<Form>>['control'];
  errors: ReturnType<typeof useFormContext<Form>>['formState']['errors'];
  watch: ReturnType<typeof useFormContext<Form>>['watch'];
  isReadOnly: boolean;
  t: (key: string, options?: Record<string, unknown>) => string;
}

const VariableField = ({
  field,
  index,
  formName,
  variableNames,
  template,
  options,
  control,
  errors,
  watch,
  isReadOnly,
  t,
}: VariableFieldProps) => {
  // Get the current value using a type-safe approach
  const formValues = watch();
  const parameters = formValues[formName as keyof Form] as Array<{
    name: Option | string;
  }>;
  const currentValue = parameters?.[index]?.name;

  // Check if the current value matches any option in the options array
  const isVariable = options.some(
    (option) =>
      typeof option === 'object' &&
      option !== null &&
      option.value ===
        (typeof currentValue === 'object' && currentValue !== null
          ? (currentValue as Option).value
          : currentValue)
  );

  // Get variable name based on parameter format
  const varDisplay =
    template.parameter_format === 'NAMED' && index < variableNames.length
      ? `{{${variableNames[index]}}}`
      : `{{${index + 1}}}`; // Fallback to positional parameters

  return (
    <div
      key={field.id}
      className={cn(styles.buttonContainer, styles.withPrefix)}
    >
      <div className="p-2 w-fit h-10 flex items-center justify-center rounded-l rounded-tr-none rounded-br-none bg-neutral-50 text-ellipsis text-gray-700 text-sm border border-gray-300 -mr-0.5">
        {varDisplay}
      </div>
      <div className={cn(styles.fieldWrapper)}>
        {/* 
          Using type assertions to handle type compatibility issues
          with the AutocompleteNew component
        */}
        <AutocompleteNew
          groupByProp="type"
          control={control}
          options={
            options as unknown as Array<{
              label: string;
              value: string;
              type?: string;
            }>
          }
          name={`${formName}.${index}.name` as unknown as keyof Form}
          id="template"
          size="medium"
          placeholder={t('broadcasts.select_variable', {
            0: varDisplay,
          })}
          rules={{ required: t('validation.required') }}
          hasError={Boolean(errors[formName]?.[index]?.name)}
          errorMessage={errors[formName]?.[index]?.name?.message}
          disabled={isReadOnly}
          getOptionLabel={(option) => {
            return typeof option === 'string'
              ? option
              : option.value.toString();
          }}
          inputStyles={
            isVariable && {
              '& .MuiAutocomplete-inputRoot': {
                color: 'var(--text-default-blue)',
              },
            }
          }
          optionStyles={{ color: 'var(--text-default-blue)' }}
        />
      </div>
    </div>
  );
};

export const VariablesSection = ({ name, isReadOnly, template }: Props) => {
  const { t } = useTranslation();
  const { control, formState, watch } = useFormContext<Form>();
  const { errors } = formState;
  const isDraft = useSelector(selectIsDraft);
  const broadcast = useSelector(selectBroadcast);
  const { subscribers } = useSubscribers(broadcast.broadcast_id);
  const draftSubscribersOptions = useSelector(selectContextVariableOptions);
  const storedSubscribersOptions = getContextVariableOptions(
    subscribers?.pages?.[0].subscribers
  );
  const options = isDraft ? draftSubscribersOptions : storedSubscribersOptions;
  const formName = `${name}Parameters` as const;

  const { fields } = useFieldArray({
    control,
    name: formName,
  });

  // Extract variable names based on template parameters
  const variableNames = extractVariableNames(template, name);

  return (
    <Box mb={2}>
      <Typography component="p" variant="label-semi-bold">
        {t('try_it.add_variables')}
      </Typography>
      <Typography>{t('broadcasts.broadcast_variables')}</Typography>
      <div className={styles.buttonsContainer}>
        {fields.map((field, index) => (
          <VariableField
            key={field.id}
            field={field}
            index={index}
            formName={formName}
            variableNames={variableNames}
            template={template}
            options={options}
            control={control}
            errors={errors}
            watch={watch}
            isReadOnly={isReadOnly}
            t={t}
          />
        ))}
      </div>
    </Box>
  );
};
