import { useCallback } from 'react';

import cn from 'classnames';
import { TFunction } from 'i18next';
import { FileTextIcon, GlobeIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Intercom from '@/components/atoms/Icons/Intercom';
import Zendesk from '@/components/atoms/Icons/Zendesk';
import Input from '@/components/atoms/Input/Input';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import { datasourceRules, errorMessage } from '@/util/validator';

import styles from './ModalDatasourceAdd.module.scss';

export const datasourceOptions = (t: TFunction) => [
  {
    id: 'web',
    value: 'webpage',
    label: (
      <TitleSubtitle
        subtitle={t('collections.datasources.webpage')}
        noGutters
      />
    ),
    icon: <GlobeIcon color="var(--color-foreground-muted)" />,
  },
  {
    id: 'doc',
    value: 'documents',
    label: (
      <TitleSubtitle
        subtitle={t('collections.documents.documents')}
        noGutters
      />
    ),
    icon: <FileTextIcon color="var(--color-foreground-muted)" />,
  },
  {
    id: 'zendesk',
    value: 'knowledge base',
    label: (
      <TitleSubtitle subtitle={t('collections.knowledge_base')} noGutters />
    ),
    icon: <Zendesk size={24} color="var(--color-foreground-muted)" />,
  },
  {
    id: 'intercom',
    value: 'knowledge base',
    label: (
      <TitleSubtitle subtitle={t('collections.knowledge_base')} noGutters />
    ),
    icon: <Intercom size={24} color="var(--color-foreground-muted)" />,
  },
];

const SelectDataSourceType = ({ selected, setSelected }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const onChange = useCallback(
    (index: number) => {
      setSelected(index);
      const newName = datasourceOptions(t)[index].value;
      setValue('name', `${t('common.my')} ${newName}`);
    },
    [setValue, setSelected, t]
  );

  return (
    <>
      <div className={cn(styles.tileContainer, 'input__container')}>
        {datasourceOptions(t).map((option, index) => {
          return (
            <OptionRadioButton
              key={option.id}
              option={option}
              size="large"
              onChange={() => onChange(index)}
              isSelected={index === selected}
              isTile
            />
          );
        })}
      </div>
      <div className="input__container">
        <Input
          name="name"
          label="Name"
          register={register('name', datasourceRules.name)}
          errorMessage={errorMessage({
            field: errors.name,
            maxLength: datasourceRules.name.maxLength,
          })}
          autoFocus
        />
      </div>
    </>
  );
};

export default SelectDataSourceType;
