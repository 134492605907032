import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import AvatarGroups from '@/components/atoms/Avatar/AvatarGroup/AvatarGroup';
import useUser from '@/hooks/useUser';
import { BrainType } from '@/models/brain';
import { RootState } from '@/models/state';
import { selectOnlineViewersByBrainId } from '@/redux/presence/selectors';

import { getAIAgentType } from '../../helper';

import styles from './TileAIAgent.module.scss';

type TileAIAgentBodyProps = {
  type: BrainType;
  id: string;
};

export function TileAIAgentBody({ type, id }: TileAIAgentBodyProps) {
  const { user } = useUser();
  const { viewers } = useSelector((state: RootState) =>
    selectOnlineViewersByBrainId(state, id)
  );

  const viewersWithoutMe = useMemo(
    () => viewers.filter(({ user_id }) => user_id !== user?.user_id),
    [user?.user_id, viewers]
  );

  return (
    <div className={styles.body}>
      <Typography
        component="p"
        color="var(--color-foreground-muted)"
        variant="label-regular"
        className={styles.type}
      >
        {getAIAgentType(type)}
      </Typography>

      <AvatarGroups>
        {viewersWithoutMe.map((viewer) => (
          <Avatar
            key={viewer.user_id}
            userId={viewer.user_id}
            size="small"
            hideStatus
          />
        ))}
      </AvatarGroups>
    </div>
  );
}
