import { t } from 'i18next';
import moment from 'moment';

import { Conversation } from '@/models/chat';
import { IntegrationType } from '@/models/integration';

import { UploadedData } from './ChatBox/useChatBox';
import { MessageOrEvent } from './MessageArea';

const ONE_MINUTE = 60000;

export const compareTimestamps = (
  timestamp1: string,
  timestamp2: string,
  duration: number
): boolean => {
  const difference = Date.parse(timestamp1) - Date.parse(timestamp2);
  return Math.abs(difference) > duration;
};

const isSameAuthor = (message1, message2) =>
  message1?.author_id === message2?.author_id;

export const shouldShowTime = ({ currentMessage, nextMessage, index }) => {
  if (index === 0) return true;

  const isNextSameAuthor = isSameAuthor(currentMessage, nextMessage);
  const isNextSameAuthorType =
    currentMessage?.author_type === nextMessage?.author_type;
  const isSignificantTimeGap = compareTimestamps(
    currentMessage?.created,
    nextMessage?.created,
    ONE_MINUTE
  );

  return !isNextSameAuthorType || !isNextSameAuthor || isSignificantTimeGap;
};

export const shouldShowAvatar = ({
  currentMessage,
  nextMessage,
  prevMessage,
  index,
}) => {
  if (currentMessage.failed_at != null) {
    return true;
  }

  if (index === 0 || shouldShowTime({ currentMessage, nextMessage, index })) {
    return true;
  }

  const isPrevSameAuthor = isSameAuthor(currentMessage, prevMessage);
  const isNextSameAuthor = isSameAuthor(currentMessage, nextMessage);

  if (currentMessage.author_type !== 'visitor') {
    if (currentMessage.author_type !== nextMessage?.author_type) {
      return true;
    }

    if (currentMessage.author_type !== 'brain' && isNextSameAuthor) {
      return false;
    }

    if (currentMessage.author_type !== 'brain' && isPrevSameAuthor) {
      return true;
    }
  }

  return false;
};

export const isSameDay = (timestamp1: string, timestamp2: string) => {
  const date1 = moment(timestamp1);
  const date2 = moment(timestamp2);

  return date1.isSame(date2, 'day');
};

const fromNowCustom = (date: moment.Moment) => {
  const hoursDiff = moment().diff(date, 'hours');

  if (hoursDiff < 24) {
    return t('analytics.periods.today');
  } else {
    return date.fromNow();
  }
};

export const getTimeElement = (item) => {
  const createdDate = moment(item.created);
  const currentDate = moment();
  const oneDayAgo = currentDate.clone().subtract(1, 'days');

  if (
    createdDate.isAfter(oneDayAgo) &&
    createdDate.isSame(currentDate, 'day')
  ) {
    return {
      time_element: true,
      created: fromNowCustom(createdDate),
    };
  }

  return {
    time_element: false,
    created: createdDate.format('DD/MM/YYYY'),
  };
};

export const mergeAndSortData = (
  flatMessages: MessageOrEvent[],
  events: MessageOrEvent[]
): MessageOrEvent[] => {
  const earliestDate = new Date(
    flatMessages?.[flatMessages.length - 1]?.created
  );
  const filteredEvents = events.filter(
    (event) => new Date(event.created) >= earliestDate
  );
  const combined: MessageOrEvent[] = [...flatMessages, ...filteredEvents];

  combined.sort(
    (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
  );

  // Insert day dividers
  for (let i = 0; i < combined.length - 1; i++) {
    if (i === 0) {
      const timeElement = getTimeElement(combined[i]);
      combined.splice(i, 0, timeElement);
      i++;
      continue;
    }

    if (!isSameDay(combined[i].created, combined[i + 1].created)) {
      const timeElement = getTimeElement(combined[i + 1]);
      combined.splice(i + 1, 0, timeElement);
      i++;
    }
  }
  return combined;
};

export const getVisitorStatus = (
  conversation: Conversation
): {
  lastVisitorActivity: string;
  visitorStatus: 'online' | 'offline' | 'away' | null;
} => {
  const state = conversation?.state;

  if (!state) {
    return { visitorStatus: null, lastVisitorActivity: null };
  }

  const { last_activity_at, active } = state;
  const lastActivityMoment = moment(last_activity_at);
  const lastActivityFromNow = lastActivityMoment.fromNow();

  if (!active) {
    return { visitorStatus: null, lastVisitorActivity: lastActivityFromNow };
  }

  const currentMoment = moment();
  const diff = currentMoment.diff(lastActivityMoment, 'milliseconds');

  if (diff < ONE_MINUTE) {
    return {
      visitorStatus: 'online',
      lastVisitorActivity: lastActivityFromNow,
    };
  } else {
    return { visitorStatus: 'away', lastVisitorActivity: lastActivityFromNow };
  }
};

/**
 * Determines the message type based on MIME type and channel.
 *
 * Rules:
 * - Image files are always treated as 'image'.
 * - If forceAudioAsFile is true, all audio files are treated as 'file'.
 * - Otherwise, audio files are treated as 'audio' only if they are 'audio/mpeg', otherwise they are 'file'.
 * - Video files are treated as 'video' unless the channel is 'viber', in which case they are 'file'.
 * - All other files are treated as 'file'.
 */
export const getMessageType = ({
  mimeType,
  channel,
}: {
  mimeType: string;
  channel: IntegrationType;
}): 'image' | 'video' | 'audio' | 'file' => {
  if (mimeType.startsWith('image/')) {
    return 'image';
  }

  if (mimeType.startsWith('audio/')) {
    return 'audio';
  }

  if (mimeType.startsWith('video/')) {
    return channel === 'viber' ? 'file' : 'video';
  }

  return 'file';
};

const convertImage = async (file: File): Promise<Blob> => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch('/www/media/convert-image', {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Image conversion failed');
  }
  return await response.blob();
};

/**
 * Converts an audio blob to either WAV (for Instagram) or MP3 (for other channels)
 */
const convertAudio = async (blob: Blob, channel: IntegrationType) => {
  if (!blob) {
    throw new Error('No audio blob');
  }
  const formData = new FormData();
  formData.append('file', blob);

  const format = channel === 'instagram' ? 'wav' : 'mp3';
  const response = await fetch(`/www/media/convert-audio?format=${format}`, {
    method: 'POST',
    body: formData,
  });

  return response.blob();
};

/**
 * Determines whether an audio file should be converted based on type and channel.
 * Instagram needs wav, all the rest mp3
 */
const shouldConvertAudio = (file, channel) => {
  const isAudioFile = file.type.startsWith('audio/');
  const isNotMP3 = file.type !== 'audio/mpeg';
  const isNotWAV = file.type !== 'audio/wav';

  if (!isAudioFile) return false;

  // Convert all non-MP3 audio files
  if (isNotMP3) return true;

  // For Instagram, convert all non-WAV audio files
  if (channel === 'instagram' && isNotWAV) return true;

  return false;
};

/**
 * Determines whether an image file should be converted.
 */
const shouldConvertImage = (file) =>
  file.type.startsWith('image/') && !file.type.includes('jpeg');

/**
 * Renames a file with a new extension and MIME type.
 */
const renameFile = (file, newExtension, newMimeType) => {
  const nameWithoutExtension = file.name.replace(/\.[^/.]+$/, '') || file.name;
  return new File([file], `${nameWithoutExtension}.${newExtension}`, {
    type: newMimeType,
  });
};

/**
 * Generates a new file name with a specified extension.
 */
const getNewFileName = (fileName, newExtension) =>
  `${fileName.replace(/\.[^/.]+$/, '')}.${newExtension}`;

/**
 * Creates a new file from a converted Blob.
 */
const createNewFile = (originalFile, convertedBlob) => {
  const newExtension =
    convertedBlob.type === 'audio/wav'
      ? 'wav'
      : convertedBlob.type === 'audio/mpeg'
        ? 'mp3'
        : 'jpeg';

  const newFileName = getNewFileName(originalFile.name, newExtension);
  return new File([convertedBlob], newFileName, { type: convertedBlob.type });
};

/**
 * Converts a file based on its type and integration channel.
 */
export const convertFile = async (file, channel) => {
  // If it's already a JPEG, return it
  if (file.type === 'image/jpeg') {
    return file;
  }

  // If it's a JPG, rename it to JPEG and return it
  if (file.name.toLowerCase().endsWith('.jpg')) {
    return renameFile(file, 'jpeg', 'image/jpeg');
  }

  let convertedBlob = null;

  if (shouldConvertAudio(file, channel)) {
    convertedBlob = await convertAudio(file, channel);
  } else if (shouldConvertImage(file)) {
    convertedBlob = await convertImage(file);
  }

  return convertedBlob ? createNewFile(file, convertedBlob) : file;
};

export const dropzoneAcceptConfig = {
  'audio/wav': ['.wav'],
  'audio/mp3': ['.mp3'],
  'audio/ogg': ['.ogg'],
  'audio/mpeg': ['.mpeg'],

  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/gif': ['.gif'],
  'image/webp': ['.webp'],
  'image/png': ['.png'],

  'video/mp4': ['.mp4'],

  'application/csv': ['.csv'],
  'application/ppt': ['.ppt'],
  'application/pptx': ['.pptx'],
  'application/xls': ['.xls'],
  'application/xlsx': ['.xlsx'],
  'application/pdf': ['.pdf'],
  'application/docx': ['.docx'],
  'application/doc': ['.doc'],
  'application/txt': ['.txt'],
  'application/zip': ['.zip'],
};

// A generic helper to upload a file and return its metadata
export const uploadFile = async (
  file: File,
  fileId: string,
  createPresignedUrl: (params: {
    file_id: string;
    file_type: string;
    original_name: string;
  }) => Promise<{ upload_url: string; file_name: string; file_id: string }>,
  channel: IntegrationType
): Promise<UploadedData> => {
  const extension = file.name.split('.').pop() || '';

  const uploadParams = {
    file_id: fileId,
    file_type: extension,
    original_name: file.name,
  };

  const u = await createPresignedUrl(uploadParams);

  // Upload the file
  await fetch(u.upload_url, { method: 'PUT', body: file });

  // Determine the message type
  const type = getMessageType({
    mimeType: file.type,
    channel,
  });

  return {
    name: u.file_name,
    id: u.file_id,
    mime_type: file.type,
    type,
    original_name: file.name,
  };
};

type PreparedFile = File & {
  preview: string;
  path: string;
  fileId: string;
};

export const prepareFileUpload = (file: File): PreparedFile => {
  const enhancedFile = new File([file], file.name, {
    type: file.type,
    lastModified: file.lastModified,
  });

  return Object.assign(enhancedFile, {
    preview: URL.createObjectURL(file),
    path: file.name,
    fileId: crypto.randomUUID(),
  });
};
