import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { CalendarIcon, CheckIcon, SquarePenIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import styles from './DateFilterItem.module.scss';

interface Props {
  selected: boolean;
  onClick: (item: { title_key: string }) => void;
  item: {
    title_key: string;
    days?: number;
    single?: boolean;
  };
  isLast: boolean;
  isLastSelected: boolean;
  disabled: boolean;
}

const getSvgColor = (selected: boolean, disabled: boolean) => {
  if (selected) {
    return 'var(--color-foreground-muted)';
  }
  if (disabled) {
    return 'var(--color-foreground-muted)';
  }
  return 'var(--color-foreground-muted)';
};

const DateFilterItem = ({
  selected,
  onClick,
  item,
  isLast,
  isLastSelected,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const svgColor = getSvgColor(selected, disabled);

  return (
    <Tooltip
      enterDelay={300}
      title={disabled ? t('billing.date_filter_disabled') : ''}
      arrow
    >
      <button
        className={cn({
          [styles.button]: true,
          [styles.selected]: selected,
          [styles.isLastSelected]: isLastSelected,
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onClick={() => onClick(item)}
      >
        {isLast ? (
          <SquarePenIcon size={16} color={svgColor} />
        ) : (
          <CalendarIcon size={16} color={svgColor} />
        )}
        <p>{t(item.title_key)}</p>
        {selected && (
          <div className={styles.success}>
            <CheckIcon size={16} color="var(--color-foreground-muted)" />
          </div>
        )}
      </button>
    </Tooltip>
  );
};

export default DateFilterItem;
