import { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { CirclePlusIcon } from 'lucide-react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Input from '@/components/atoms/Input/Input';
import ReorderWrapper from '@/components/atoms/ReorderWrapper/ReorderWrapper';
import Select from '@/components/atoms/Select/Select';
import MediaField from '@/components/organisms/Dialogs/MediaField/MediaField';
import {
  CarouselCard,
  CarouselButton as CarouselButtonType,
} from '@/models/action';
import { selectHasCarouselDialogErrors } from '@/redux/dialogAlerts/selectors';
import {
  deleteCarouselCard,
  removeCardButton,
  updateCardButtons,
  updateCarouselCard,
  updateSelectedCarouselIndex,
} from '@/redux/nodes/actions';
import { selectSelectedCarouselIndex } from '@/redux/nodes/selectors';
import {
  ACCEPTED_IMAGE_FORMATS,
  ACCEPTED_VIDEO_FORMATS,
} from '@/util/constants';
import {
  capitalizeFirstLetter,
  getCarouselPlaceholder,
  noop,
} from '@/util/util';
import { carouselCardSchema } from '@/util/validator';

import CarouselButton from './CarouselButton';
import { MAX_CAROUSEL_CARD_BUTTONS, mediaOptions } from './constants';
import { move } from '../../helper';
import ToolkitAccordionWrapper from '../../ToolkitAccordionWrapper';

type Props = {
  card: CarouselCard;
  index: number;
  onFileRemoved?: () => void;
  mediaFieldName: string;
  handleAddButton?: () => void;
  actionId: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  dropItem: () => void;
  updateErrors: (name: string, value: string, index?: number) => void;
};

const CarouselAccordion = ({
  card,
  index,
  handleAddButton,
  actionId,
  moveItem,
  dropItem,
  updateErrors,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedCarouselIndex = useSelector(selectSelectedCarouselIndex);
  const [isExpanded, setIsExpanded] = useState(selectedCarouselIndex === index);
  const { title, subtitle, media, buttons } = card;
  const hasReachedMaxButtons = buttons?.length === MAX_CAROUSEL_CARD_BUTTONS;
  const blockReordering = useSelector(selectHasCarouselDialogErrors(actionId));

  const {
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      title,
      subtitle,
      media,
    },
    resolver: yupResolver(carouselCardSchema),
  });

  const getButtonIndex = useCallback(
    (button: CarouselButtonType) => () => {
      const buttonIndex = buttons.findIndex(
        (fieldButton) => fieldButton.id === button.id
      );
      return buttonIndex;
    },
    [buttons]
  );

  const getCardIndex = useCallback((index: number) => index, []);

  const handleDeleteButton = useCallback(
    (buttonIndex: number) => {
      dispatch(
        removeCardButton({
          actionId,
          cardIndex: selectedCarouselIndex,
          buttonIndex,
        })
      );
    },
    [selectedCarouselIndex, dispatch, actionId]
  );

  const handleDragButton = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const reOrderedButtons = move(
        cloneDeep(buttons as CarouselCard['buttons']),
        dragIndex,
        hoverIndex
      );

      dispatch(
        updateCardButtons({
          actionId,
          cardIndex: selectedCarouselIndex,
          buttons: reOrderedButtons,
        })
      );
    },
    [actionId, buttons, dispatch, selectedCarouselIndex]
  );

  const handleChange = useCallback(() => {
    if (isExpanded) {
      dispatch(updateSelectedCarouselIndex({ index: null }));
      setIsExpanded(false);
    } else {
      dispatch(updateSelectedCarouselIndex({ index }));
      setIsExpanded(true);
    }
  }, [dispatch, index, isExpanded]);

  useEffect(() => {
    setIsExpanded(selectedCarouselIndex === index);
  }, [index, selectedCarouselIndex]);

  const handleDeleteAccordion = useCallback(() => {
    // Clear errors
    updateErrors(`title-${index}`, '');
    updateErrors(`subtitle-${index}`, '');
    updateErrors(`url-${index}`, '');

    // Loop through buttons and clear errors
    buttons.forEach((_, idx) => {
      updateErrors(`label-${idx}-${index}`, '');
      updateErrors(`buttonUrl-${idx}-${index}`, '');
      updateErrors(`value-${idx}-${index}`, '');
    });

    dispatch(
      deleteCarouselCard({
        currentIndex: index,
        actionId,
      })
    );
  }, [actionId, buttons, dispatch, index, updateErrors]);

  const updateRedux = useCallback(
    (key: string, value: string) => {
      const updatedAction = cloneDeep(card);
      set(updatedAction, key, value);

      dispatch(
        updateCarouselCard({
          actionId,
          index,
          action: updatedAction,
        })
      );
    },
    [actionId, dispatch, card, index]
  );

  // Dialog errors
  const titleErrorMessage = capitalizeFirstLetter(errors.title?.message);
  const subtitleErrorMessage = capitalizeFirstLetter(errors.subtitle?.message);
  const urlErrorMessage = capitalizeFirstLetter(errors.media?.url?.message);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors(`title-${index}`, titleErrorMessage);
  }, [index, titleErrorMessage, updateErrors]);

  useEffect(() => {
    updateErrors(`subtitle-${index}`, subtitleErrorMessage);
  }, [index, subtitleErrorMessage, updateErrors]);

  useEffect(() => {
    updateErrors(`url-${index}`, urlErrorMessage);
  }, [urlErrorMessage, updateErrors, index]);

  return (
    <ReorderWrapper
      dragId="carousel-card"
      index={index}
      placeholderHeight={44}
      moveItem={moveItem}
      dropItem={dropItem}
      getIndex={() => getCardIndex(index)}
      canDrag={!isExpanded && !blockReordering}
    >
      <ToolkitAccordionWrapper
        onChange={handleChange}
        expanded={isExpanded}
        title={title}
        handleDelete={handleDeleteAccordion}
      >
        <Select
          name={`carousel.${index}.media.type`}
          register={register('media.type')}
          size="small-full"
          label={t('dialog.carousel.media_type')}
          onChange={(e) => updateRedux('media.type', e.target.value)}
          options={mediaOptions}
        />

        <MediaField
          register={register('media.url')}
          name="media.url"
          error={!!errors.media?.url}
          errors={errors.media as FieldErrors}
          setValue={setValue}
          label={t('field.url')}
          urlValue={media.url}
          onFileRemoved={() => {
            const type = getValues('media.type');
            const defaultImageUrl = getCarouselPlaceholder({
              type,
              id: index + 1,
            });
            updateRedux('media.url', defaultImageUrl);
          }}
          accept={
            card?.media.type === 'image'
              ? ACCEPTED_IMAGE_FORMATS
              : ACCEPTED_VIDEO_FORMATS
          }
          shouldCrop={card?.media.type === 'image'}
          aspectRatio="horizontal"
          onChange={() => {
            const updateUrl = getValues('media.url');
            updateRedux('media.url', updateUrl);
          }}
        />

        <Input
          register={register('title')}
          name={`carousel.${index}.title`}
          label={t('common.title')}
          error={!!errors.title}
          errorMessage={errors.title?.message}
          placeholder=""
          size="small"
          onChange={(e) => {
            updateRedux('title', e.target.value);
          }}
        />

        <Input
          register={register('subtitle')}
          name={`carousel.${index}.subtitle`}
          label={t('common.description')}
          error={!!errors.subtitle}
          errorMessage={errors.subtitle?.message}
          size="small"
          onChange={(e) => {
            // Prevent sending empty string to redux
            updateRedux('subtitle', e.target.value || undefined);
          }}
        />

        <Stack justifyContent="center" spacing="var(--space-8)">
          {buttons.map((button, i) => (
            <CarouselButton
              key={button.id}
              index={i}
              option={button}
              actionId={actionId}
              cardIndex={selectedCarouselIndex}
              disabled={buttons.length === 1}
              handleDeleteButton={handleDeleteButton}
              moveItem={handleDragButton}
              dropItem={noop}
              getIndex={getButtonIndex(button)}
              updateErrors={updateErrors}
            />
          ))}
        </Stack>

        <Box pt="var(--space-8)" textAlign="right">
          <Button
            size="small"
            variant="tertiary"
            onClick={handleAddButton}
            disabled={hasReachedMaxButtons}
          >
            <CirclePlusIcon
              color={
                hasReachedMaxButtons
                  ? 'var(--color-foreground-muted)'
                  : 'var(--color-foreground-muted)'
              }
              size={16}
            />
            {t('common.add_button')}
          </Button>
        </Box>
      </ToolkitAccordionWrapper>
    </ReorderWrapper>
  );
};

export default CarouselAccordion;
