import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { PauseAction } from '@/models/action';

const Pause = ({ action }: { action: PauseAction }) => {
  const { t } = useTranslation();
  return (
    <Typography color="var(--color-foreground-muted)" variant="label-regular">
      {`${action.duration ?? 0} ${t('dialog.actions.seconds_abbreviation')} `}
    </Typography>
  );
};

export default Pause;
