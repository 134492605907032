import { forwardRef } from 'react';

import { Icon } from '@/models/icon';

const Front = forwardRef<SVGSVGElement, Icon>(
  ({ size = 24, ...props }, ref) => {
    return (
      <svg
        {...props}
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 21.5686H7.25245V7.22277H21.7124V0H0V21.5686Z"
          fill="#A857F1"
        ></path>
        <path
          d="M15.4575 22C19.0708 22 22 19.0708 22 15.4575C22 11.8442 19.0708 8.91504 15.4575 8.91504C11.8442 8.91504 8.91504 11.8442 8.91504 15.4575C8.91504 19.0708 11.8442 22 15.4575 22Z"
          fill="#A857F1"
        ></path>
      </svg>
    );
  }
);

Front.displayName = 'Front';

export default Front;
