import Box from '@mui/material/Box';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import Link from '@/components/atoms/Link/Link';
import { useIntegrations } from '@/hooks/useIntegrations';
import { IntegrationType, ZendeskConfig } from '@/models/integration';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';

import { useChatBox } from './useChatBox';

import styles from './ChatBox.module.scss';

export const getLiveChatUrl = (
  channel: IntegrationType,
  subdomain?: string
) => {
  switch (channel) {
    case 'intercom':
      return 'https://app.intercom.com';
    case 'sunco':
      return 'https://www.zendesk.com/login';
    case 'front':
      return 'https://app.frontapp.com';
    case 'zendesk':
      return subdomain
        ? `https://${subdomain}.zendesk.com`
        : 'https://www.zendesk.com/login';
    default:
      return null;
  }
};

export const UnsupportedChannel = () => {
  const { t } = useTranslation();
  const { isChannelSupported } = useChatBox();
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const { conversation } = useConversations({ deskId, sessionId });

  const { integration } = useIntegrations(deskId, conversation?.integration_id);
  const subdomain = (integration?.config as ZendeskConfig)?.subdomain;

  if (isChannelSupported) {
    return null;
  }

  return (
    <Box mx={2} className={styles.templateBanner}>
      <Banner variant="info" relativePosition hasIcon={true}>
        <Trans
          i18nKey="chatBox.unsupported_channel"
          components={{
            link1: (
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={getLiveChatUrl(conversation?.channel, subdomain)}
              />
            ),
          }}
          values={[t(`channels.${conversation?.channel}`)]}
        />
      </Banner>
    </Box>
  );
};
