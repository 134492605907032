import { useCallback, useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import { saveAs } from 'file-saver';
import { SquareDashedMousePointerIcon, UploadIcon } from 'lucide-react';
import Papa from 'papaparse';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import FileUpload from '@/components/atoms/FileUpload/FileUpload';
import { EventName } from '@/models/segment';
import { selectBroadcastId } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';

import csvData from './mockCsv.json';
import { useSubscribers } from '../../hooks/useSubscribers';
import { FieldsetStatus, Form } from '../../models';
import { actions } from '../../redux/actions';
import {
  selectBroadcast,
  selectIsDraft,
  selectSubscribers,
} from '../../redux/selectors';
import { formatFileContext } from '../../utils';
import StepFieldset from '../StepFieldset/StepFieldset';

import styles from './UploadFieldset.module.scss';

const getMessage = (t) => {
  return (
    <Typography display="flex" gap="var(--space-8)" alignItems="center">
      <SquareDashedMousePointerIcon color="var(--color-foreground-muted)" />
      <span className="text-accent">{t('broadcasts.drag_and_drop')}</span>
    </Typography>
  );
};

interface Props {
  isReadOnly: boolean;
}
export const UploadFieldset = ({ isReadOnly }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getValues, setValue } = useFormContext<Form>();
  const subscribers = useSelector(selectSubscribers);
  const broadcastId = useSelector(selectBroadcastId);
  const { subscribers: APISubscribers } = useSubscribers(broadcastId);
  const { channel } = useSelector(selectBroadcast);
  const [isValidFile, setIsValidFile] = useState(true);

  const isStepValid =
    subscribers.length > 0 ||
    APISubscribers?.pages?.[0].subscribers?.length > 0;

  const isDraft = useSelector(selectIsDraft);

  const { verifySubscribers, verifyStatus } = useSubscribers();
  const [stepStatus, setStepStatus] = useState<FieldsetStatus>(
    isStepValid ? 'completed' : 'active'
  );

  useEffect(() => {
    if (isStepValid) {
      setStepStatus('completed');
    }
  }, [isStepValid]);

  const handleFileUpload = useCallback(
    (file) => {
      trackEvent(EventName.UploadBroadcastFile);
      if (file?.data) {
        const csvData = file.data.replace(/(\r\n|\n|\r)/gm, '\n');
        const formattedData = Papa.parse(csvData, {
          skipEmptyLines: true,
        });
        const data = formattedData.data as string[][];
        const firstRow = data[0] as string[]; // First row contains the variable names

        const { fileSubscribers, invalidFile } = formatFileContext(
          firstRow,
          data
        );
        if (invalidFile) {
          setIsValidFile(false);
          return;
        }
        verifySubscribers(
          {
            channel,
            subscribers: fileSubscribers,
          },
          {
            onSuccess: (resp, payload) => {
              const errorIndices = resp.map((error) => error.index);
              const validSubscribers = payload.subscribers.map((sub, index) => {
                if (errorIndices.includes(index)) {
                  return { ...sub, status_code: 'INVALID' };
                } else if (sub.status_code) {
                  dispatch(actions.addErrorCounter(1));
                }
                return sub;
              });
              dispatch(actions.addErrorCounter(errorIndices.length));
              dispatch(actions.addSubscribers(validSubscribers));
              setValue('fileName', file.name);
            },
          }
        );
      }
    },
    [dispatch, setValue, verifySubscribers, channel]
  );

  const handleFileRemoved = () => {
    dispatch(actions.removeDraftSubscribers());
    if (isDraft) {
      dispatch(actions.resetErrorCounter());
    }
    setValue('fileName', '');
    setIsValidFile(true);
  };

  const handleExampleExport = () => {
    const file = new Blob([Papa.unparse(csvData)], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(file, 'example.csv');
  };

  return (
    <StepFieldset title={t('broadcasts.audience')} step={1} status={stepStatus}>
      <Typography
        component="p"
        color="var(--color-foreground-muted)"
        className={styles.description}
      >
        {t('broadcasts.audience_description')}
      </Typography>
      <div className={styles.file_container}>
        <Typography variant="subheading-semi-bold" component="label">
          {t('broadcasts.manual_upload')}
        </Typography>
        <Button size="small" variant="tertiary" onClick={handleExampleExport}>
          <UploadIcon size={16} color="var(--color-foreground-muted)" />
          {t('broadcasts.download_csv')}
        </Button>
      </div>
      <FileUpload
        forceError={!isValidFile ? t('broadcasts.errors.invalid_csv_file') : ''}
        onFileUploaded={handleFileUpload}
        maxSizeInBytes={50000000} // 50MB
        minSizeInBytes={10}
        isLoadingProp={verifyStatus === 'pending'}
        accept={{
          'text/cvs': ['.csv'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
          ],
        }}
        message={getMessage(t)}
        parseJson={false}
        onFileRemoved={handleFileRemoved}
        fileName={getValues('fileName')}
        disabled={isReadOnly}
      />
    </StepFieldset>
  );
};
