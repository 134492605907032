import { useCallback, useEffect } from 'react';

import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import { CheckIcon, InfoIcon, XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { isRequired } from '@/util/validator';

import styles from './styles.module.scss';

const taxRegions: { value: string; label: string }[] = [
  { value: 'au_abn|Australia|AU', label: 'Australia' },
  { value: 'eu_vat|Austria|AT', label: 'Austria' },
  { value: 'eu_vat|Belgium|BE', label: 'Belgium' },
  { value: 'eu_vat|Bulgaria|BG', label: 'Bulgaria' },
  { value: 'eu_vat|Croatia|HR', label: 'Croatia' },
  { value: 'eu_vat|Cyprus|CY', label: 'Cyprus' },
  { value: 'eu_vat|Czech|CZ', label: 'Czech' },
  { value: 'eu_vat|Denmark|DK', label: 'Denmark' },
  { value: 'eu_vat|Estonia|EE', label: 'Estonia' },
  { value: 'eu_vat|Finland|FI', label: 'Finland' },
  { value: 'eu_vat|France|FR', label: 'France' },
  { value: 'eu_vat|Germany|DE', label: 'Germany' },
  { value: 'eu_vat|Greece|GR', label: 'Greece' },
  { value: 'eu_vat|Hungary|HU', label: 'Hungary' },
  { value: 'in_gst|India|IN', label: 'India' },
  { value: 'eu_vat|Ireland|IE', label: 'Ireland' },
  { value: 'eu_vat|Italy|IT', label: 'Italy' },
  { value: 'eu_vat|Latvia|LV', label: 'Latvia' },
  { value: 'eu_vat|Lithuania|LT', label: 'Lithuania' },
  { value: 'eu_vat|Luxembourg|LU', label: 'Luxembourg' },
  { value: 'eu_vat|Malta|MT', label: 'Malta' },
  { value: 'eu_vat|Netherlands|NL', label: 'Netherlands' },
  { value: 'nz_gst|New Zealand|NZ', label: 'New Zealand' },
  { value: 'no_vat|Norway|NO', label: 'Norway' },
  { value: 'eu_vat|Poland|PL', label: 'Poland' },
  { value: 'eu_vat|Portugal|PT', label: 'Portugal' },
  { value: 'eu_vat|Romania|RO', label: 'Romania' },
  { value: 'eu_vat|Slovakia|SK', label: 'Slovakia' },
  { value: 'eu_vat|Slovenia|SI', label: 'Slovenia' },
  { value: 'eu_vat|Spain|ES', label: 'Spain' },
  { value: 'eu_vat|Sweden|SE', label: 'Sweden' },
  { value: 'eu_vat|United Kingdom|GB', label: 'United Kingdom' },
  { value: 'other', label: 'Other' },
];

type Form = {
  region: string;
  value: string;
};

const TaxIdFieldset = () => {
  const { t } = useTranslation();
  const { updateTaxId, taxId, updateTaxIdStatus } = useBilling();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: { region: 'other' },
  });

  useEffect(() => {
    if (taxId?.region) {
      reset({ region: taxId.region, value: taxId.value });
    } else {
      reset({ region: 'other', value: '' });
    }
  }, [taxId, reset]);

  const onSubmit = useCallback(
    ({ region, value }: Form) => updateTaxId({ value, region }),
    [updateTaxId]
  );

  return (
    <FormFieldset
      title={t('billing.tax.region')}
      subtitle={t('billing.tax.region_subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty,
        isLoading: updateTaxIdStatus === 'pending',
      }}
      isLoading={!taxId}
      onSubmit={handleSubmit(onSubmit)}
      fullContentWidth
    >
      <div className={styles.content}>
        <Select
          label={t('billing.tax.region')}
          name="region"
          options={taxRegions}
          register={register('region', isRequired)}
          errorMessage={errors.region?.message}
        />
        <Input
          name="value"
          placeholder="GR12345678"
          label={t('billing.tax.tax_id')}
          register={register('value')}
          errorMessage={errors.value?.message}
        />
        {taxId?.verification?.status && (
          <div
            className={cn(
              styles.status,
              styles[taxId?.verification?.status ?? 'undefined']
            )}
          >
            {taxId?.verification?.status === 'verified' && (
              <Tooltip arrow title={t('common.verified')}>
                <CheckIcon />
              </Tooltip>
            )}
            {['unverified', 'unavailable'].includes(
              taxId?.verification?.status
            ) && (
              <Tooltip arrow title={t('billing.tax.invalid_tax_id')}>
                <XIcon />
              </Tooltip>
            )}
            {taxId?.verification?.status === 'pending' && (
              <Tooltip arrow title={t('billing.tax.pending')}>
                <InfoIcon />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </FormFieldset>
  );
};

export default TaxIdFieldset;
