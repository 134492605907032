import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { MessageSquareTextIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import useMembers from '@/hooks/useMembers';

import ExternalFilter from './ExternalFilter/ExternalFilter';
import useExternalFilter from '../../../hooks/useExternalFilter';

interface Props {
  dateOpen: boolean;
  filterOpen: boolean;
  setDateOpen: Dispatch<SetStateAction<boolean>>;
  setFilterOpen: Dispatch<SetStateAction<boolean>>;
}

const AgentFilters = ({
  dateOpen,
  setDateOpen,
  setFilterOpen,
  filterOpen,
}: Props) => {
  const { members, listStatus } = useMembers();
  const { t } = useTranslation();

  const filterActions = useExternalFilter({
    items: members as { [key: string]: string | string[] }[],
    dateOpen,
    filterOpen,
    setDateOpen,
    key: 'user_id',
    filterKey: 'agentIds',
  });

  const handleClickAway = useCallback(() => {
    filterActions.setShowItems(false);
    filterActions.applyItems();
  }, [filterActions]);

  useEffect(() => {
    if (filterActions.showItems && (dateOpen || filterOpen)) {
      setDateOpen(false);
      setFilterOpen(false);
    }
  }, [
    dateOpen,
    filterOpen,
    setDateOpen,
    setFilterOpen,
    filterActions.showItems,
  ]);

  return (
    <ExternalFilter
      {...filterActions}
      itemsLength={members?.length}
      listStatus={listStatus}
      onClickAway={handleClickAway}
      icon={
        <MessageSquareTextIcon
          size={16}
          color="var(--color-foreground-muted)"
        />
      }
      displayValue={filterActions.getItemsDisplayValue(
        t('analytics.select_agent'),
        t('analytics.all_agents')
      )}
      dropdownTitle={t('analytics.all_agents')}
    />
  );
};

export default AgentFilters;
