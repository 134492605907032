import { TFunction } from 'i18next';

import { Rating } from '@/components/atoms/Rating';
import { OptionBase } from '@/models/common';

export type FilterOption = OptionBase<{
  prefix?: string;
  input?: boolean;
  trueLabel?: string;
  falseLabel?: string;
  valuePrefix?: string;
  valueSuffix?: string;
}>;

export type FilterOptions = FilterOption[];

export const logsFilterOptions = (t: TFunction) =>
  [
    {
      label: t('common.channel'),
      value: 'channels',
      prefix: t('analytics.channelis'),
    },
    {
      label: t('common.desk'),
      value: 'deskIds',
      prefix: t('analytics.deskis'),
    },
    {
      label: t('analytics.charts_tooltip.percent_cover'),
      value: 'isCovered',
      trueLabel: t('analytics.covered_sessions'),
      falseLabel: t('analytics.uncovered_sessions'),
    },
    {
      label: t('analytics.charts_tooltip.percent_contain'),
      value: 'isContained',
      trueLabel: t('analytics.contained_sessions'),
      falseLabel: t('analytics.uncontained_sessions'),
    },
    {
      label: t('common.conversation_type'),
      value: 'hasCollection',
      trueLabel: t('analytics.collection_conv'),
      falseLabel: t('analytics.brain_conv'),
    },
    {
      label: t('analytics.charts_tooltip.meaningul'),
      value: 'isMeaningful',
      trueLabel: t('analytics.meaningful'),
      falseLabel: t('analytics.non_meaningful'),
    },
    {
      label: t('analytics.fields.numberusermessages'),
      value: 'minNumUserMessages',
      prefix: t('analytics.user_messages'),
      valuePrefix: t('analytics.more_than'),
    },
    {
      label: t('analytics.fields.intent_pred'),
      value: 'maxConfidence',
      prefix: t('analytics.fields.confidence'),
      valuePrefix: t('analytics.less_than'),
      valueSuffix: '%',
    },
    {
      label: t('analytics.fields.preview_sessions'),
      value: 'isTest',
      trueLabel: t('analytics.show_prev_sessions'),
      falseLabel: t('analytics.noshow_prev_sessions'),
    },
    {
      label: t('analytics.fields.session_id'),
      value: 'sessionId',
      input: true,
    },
    {
      label: t('analytics.fields.username'),
      value: 'userName',
      input: true,
    },
    {
      label: t('analytics.fields.user_id'),
      value: 'userId',
      input: true,
    },
    {
      label: t('analytics.fields.external_user_id'),

      value: 'externalUserId',
      input: true,
    },
    {
      label: t('analytics.fields.channel_user_id'),

      value: 'channelUserId',
      input: true,
    },
    {
      label: t('analytics.fields.tags'),
      value: 'tags',
      prefix: t('analytics.fields.tag'),
    },
    { label: t('analytics.ratings'), value: 'ratings' },
    {
      label: t('analytics.fields.reactions'),
      value: 'reactions',
      trueLabel: t('analytics.with_thumbs_up'),
      falseLabel: t('analytics.with_thumbs_down'),
    },
  ] as FilterOptions;

export const chatLogsFilterOptions = (t: TFunction) =>
  [
    {
      label: t('common.channel'),
      value: 'channels',
      prefix: t('analytics.channelis'),
    },
    {
      label: t('analytics.charts_tooltip.percent_cover'),
      value: 'isCovered',
      trueLabel: t('analytics.covered_sessions'),
      falseLabel: t('analytics.uncovered_sessions'),
    },
    {
      label: t('analytics.charts_tooltip.percent_contain'),
      value: 'isContained',
      trueLabel: t('analytics.contained_sessions'),
      falseLabel: t('analytics.uncontained_sessions'),
    },
    {
      label: t('common.conversation_type'),
      value: 'hasCollection',
      trueLabel: t('analytics.collection_conv'),
      falseLabel: t('analytics.no_collection_conv'),
    },
    {
      label: t('analytics.fields.language_model'),
      value: 'languageModelIds',
      prefix: t('analytics.language_model_is'),
    },
    {
      label: t('analytics.charts_tooltip.meaningul'),
      value: 'isMeaningful',
      trueLabel: t('analytics.meaningful'),
      falseLabel: t('analytics.non_meaningful'),
    },
    {
      label: t('analytics.fields.numberusermessages'),
      value: 'minNumUserMessages',
      prefix: t('analytics.user_messages'),
      valuePrefix: t('analytics.more_than'),
    },
    {
      label: t('analytics.fields.preview_sessions'),
      value: 'isTest',
      trueLabel: t('analytics.show_prev_sessions'),
      falseLabel: t('analytics.noshow_prev_sessions'),
    },
    {
      label: t('analytics.fields.session_id'),
      value: 'sessionId',
      input: true,
    },
    {
      label: t('analytics.fields.username'),
      value: 'userName',
      input: true,
    },
    {
      label: t('analytics.fields.user_id'),
      value: 'userId',
      input: true,
    },
    {
      label: t('analytics.fields.external_user_id'),

      value: 'externalUserId',
      input: true,
    },
    {
      label: t('analytics.fields.channel_user_id'),
      value: 'channelUserId',
      input: true,
    },
    {
      label: t('analytics.fields.tags'),
      value: 'tags',
      prefix: t('analytics.fields.tag'),
    },
    { label: t('analytics.ratings'), value: 'ratings' },
    {
      label: t('analytics.fields.reactions'),
      value: 'reactions',
      trueLabel: 'with thumbs up',
      falseLabel: 'with thumbs down',
    },
  ] as FilterOptions;

export const accountAnalyticsFilterOptions = (t: TFunction) =>
  [
    {
      label: t('common.channel'),
      value: 'channels',
      prefix: t('analytics.channelis'),
    },
    {
      label: t('analytics.fields.preview_sessions'),
      value: 'isTest',
      trueLabel: t('analytics.show_prev_sessions'),
      falseLabel: t('analytics.noshow_prev_sessions'),
    },
    {
      label: t('analytics.fields.numberusermessages'),
      value: 'minNumUserMessages',
      prefix: t('analytics.user_messages'),
      valuePrefix: t('analytics.more_than'),
    },
    {
      label: t('analytics.fields.agent'),
      value: 'agentIds',
      prefix: t('analytics.agentis'),
    },
    {
      label: t('analytics.fields.language_model'),
      value: 'languageModelIds',
      prefix: t('analytics.language_model_is'),
    },
  ] as FilterOption[];

const overViewFilters = [
  'brainIds',
  'deskIds',
  'channels',
  'isTest',
  'minNumUserMessages',
];

const humanChatLogsFilters = (t: TFunction) => [
  ...chatLogsFilterOptions(t).map((item) => item.value),
  'deskIds',
  'brainIds',
];

const humanAgentFilters = ['agentIds', 'deskIds', 'channels', 'isTest'];

const agentsPerformanceFilters = [
  'agentIds',
  'deskIds',
  'channels',
  'isTest',
  'minNumUserMessages',
];

const customerSatisfactionFilters = ['deskIds', 'channels', 'isTest'];

export const filterFiltersByUrl = (
  item: string,
  url: string,
  t?: TFunction
): boolean => {
  if (
    url.includes('/overview') ||
    url.includes('/usage') ||
    url.includes('/brain-effectiveness')
  ) {
    return overViewFilters.includes(item);
  }

  if (url.includes('/human-agents-effectiveness')) {
    return humanAgentFilters.includes(item);
  }
  if (url.includes('/agents-performance')) {
    return agentsPerformanceFilters.includes(item);
  }
  if (url.includes('/customer-satisfaction')) {
    return customerSatisfactionFilters.includes(item);
  }
  if (url.includes('/human-chat-logs')) {
    return humanChatLogsFilters(t).includes(item);
  }
  return true;
};

export const containedOptions = (t: TFunction) => [
  {
    label: t('analytics.show_nocontained_html'),
    value: 'false',
    id: '0',
  },
  {
    label: t('analytics.show_contained_html'),
    value: 'true',
    id: '1',
  },
];

export const meaningfulOptions = (t: TFunction) => [
  {
    label: t('analytics.show_meaningful_html'),
    value: 'true',
    id: '0',
  },
  {
    label: t('analytics.show_nomeaningful_html'),
    value: 'false',
    id: '1',
  },
];

export const collectionOptions = (t: TFunction, ai_agents: boolean) => [
  {
    label: t(
      `analytics.${ai_agents ? 'show_collection_ai_agent_html' : 'show_collection_brain_html'}`
    ),
    value: 'false',
    id: '0',
  },
  {
    label: t('analytics.show_collection_html'),
    value: 'true',
    id: '1',
  },
];

export const reactionsOptions = (t: TFunction) => [
  {
    label: t('analytics.thumbs_down_html'),
    value: 'false',
    id: '0',
  },
  {
    label: t('analytics.thumbs_up_html'),
    value: 'true',
    id: '1',
  },
];

export const coveredOptions = (t: TFunction) => [
  {
    label: t('analytics.show_nocovered_html'),
    value: 'false',
    id: '0',
  },
  {
    label: t('analytics.show_covered_html'),
    value: 'true',
    id: '1',
  },
];

export const testOptions = (t: TFunction) => [
  {
    label: t('analytics.noshow_prev_sessions'),
    value: 'false',
    id: '0',
  },
  {
    label: t('analytics.show_test_html'),
    value: 'true',
    id: '1',
  },
];

export const getRatingOptions = (value) => [
  {
    text: '1',
    icon: <Rating stars={1} />,
    checked: !!value.find((val) => val === '1'),
  },
  {
    text: '2',
    icon: <Rating stars={2} />,
    checked: !!value.find((val) => val === '2'),
  },
  {
    text: '3',
    icon: <Rating stars={3} />,
    checked: !!value.find((val) => val === '3'),
  },
  {
    text: '4',
    icon: <Rating stars={4} />,
    checked: !!value.find((val) => val === '4'),
  },
  {
    text: '5',
    icon: <Rating stars={5} />,
    checked: !!value.find((val) => val === '5'),
  },
];

export const getMessageOptions = (value, t: TFunction) => [
  {
    value: '1',
    label: `1 ${t('common.message')}`,
    disabled: value === '1',
  },
  {
    value: '2',
    label: `2 ${t('common.messages').toLowerCase()}`,
    disabled: value === '2',
  },
  {
    value: '3',
    label: `3 ${t('common.messages').toLowerCase()}`,
    disabled: value === '3',
  },
  {
    value: '4',
    label: `4 ${t('common.messages').toLowerCase()}`,
    disabled: value === '4',
  },
  {
    value: '5',
    label: `5 ${t('common.messages').toLowerCase()}`,
    disabled: value === '5',
  },
];

export const MAX_RATING = 5;
