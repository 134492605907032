import React from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { EllipsisIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { useCopyPaste } from '@/hooks/useCopyPaste';
import useHotkeys from '@/hooks/useHotkeys';
import useOperatingSystem from '@/hooks/useOperatingSystem';
import { TYPES, iconByType, isFinalAction } from '@/redux/dialogs/helper';
import { isModalOpen } from '@/redux/modals/selectors';
import { CopiedObject } from '@/util/CopyPasteController';

import { ActionMenu } from '../ActionMenu/ActionMenu';

import styles from './ActionHeader.module.scss';

type Props = {
  type: string;
  onDelete?: () => void;
  children?: React.ReactNode;
  objectToCopy: CopiedObject;
  isSelected: boolean;
  hasDialogError?: boolean;
};

const ActionHeader = ({
  type,
  children,
  onDelete,
  objectToCopy,
  isSelected,
  hasDialogError = false,
}: Props) => {
  const { handlePaste, handleCopy } = useCopyPaste();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const modalOpen = useSelector(isModalOpen);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { deleteShortcut } = useOperatingSystem();

  const DELETE_HOTKEY = {
    delete: onDelete,
  };

  const COPY_AND_PASTE_HOTKEYS = {
    'mod+v': handlePaste,
    'mod+c': () => handleCopy(objectToCopy),
  };

  // Disable copy and paste hotkeys for Reminder actions
  const hotkeys =
    type === 'reminder'
      ? DELETE_HOTKEY
      : {
          ...COPY_AND_PASTE_HOTKEYS,
          ...DELETE_HOTKEY,
        };

  useHotkeys({ hotkeys, active: isSelected && !modalOpen });

  // Override the default header text for some action types
  const getHeader = () => {
    switch (type) {
      case 'text':
        return t(`actions.types.text_message`);
      case 'event':
        return t(`actions.types.jump_to`);
      case 'reminder':
        return t('actions.types.on_inactivity');
      default:
        return t(`actions.types.${type}`);
    }
  };

  return (
    <div
      className={cn(styles.header, {
        [styles.condition]: type === 'condition',
      })}
    >
      {!children &&
        iconByType({
          id: type,
          size: 16,
          color: 'var(--color-foreground)',
          type: TYPES.ACTION,
        })}
      <p>
        {children ?? (
          <Typography variant="label-semi-bold">{getHeader()}</Typography>
        )}
      </p>

      <div className={styles.header__icons}>
        <ActionMenu
          handleClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          handleDelete={onDelete}
          handleCopy={handleCopy}
          handlePaste={handlePaste}
          objectToCopy={objectToCopy}
          disableCopy={hasDialogError}
          // Override default options for last actions
          options={
            isFinalAction(type)
              ? [
                  {
                    label: t('dialog.actions.menu.delete'),
                    shortcut: deleteShortcut,
                    onClick: onDelete,
                  },
                ]
              : []
          }
        />

        <IconButton
          ariaLabel="menu-btn"
          ariaControls="card-menu"
          ariaHasPopUp
          onClick={handleClick}
        >
          <EllipsisIcon size={20} />
        </IconButton>
      </div>
    </div>
  );
};

export default ActionHeader;
