import './AnalyticsFieldset.scss';

import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import EmptyResults from '@/components/atoms/EmptyResults/EmptyResults';
import fs from '@/components/atoms/Fieldset';
import { FieldsetProps } from '@/components/atoms/Fieldset/Fieldset';
import AnalyticsLoadingIcon from '@/components/atoms/Icons/AnalyticsLoading/AnalyticsLoadingIcon';
import { useAccount } from '@/hooks/useAccount';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { RootState } from '@/models/state';
import { getDateRangeText } from '@/util/analytics';
import { resolveBrainsPath } from '@/util/util';

import AnalyticsError from './AnalyticsError';
import AnalyticsTitle from './AnalyticsTitle/AnalyticsTitle';

export enum GraphType {
  LINE = 'line',
  BAR = 'bar',
  PIE = 'pie',
  HEATMAP = 'heatmap',
}

interface Props extends FieldsetProps {
  title?: string;
  helpText?: React.ReactNode;
  hasFooter?: boolean;
  isEmpty?: boolean;
  tooltip?: string;
  error?: ApolloError;
  actionButton?: boolean;
  indicator?: string;
  onReload?: () => void;
  overflown?: boolean;
  data?: unknown;
  hasExport?: boolean;
  size?: 'small' | 'medium';
  graphType?: GraphType;
}

const AnalyticsFieldset = ({
  title,
  subtitle,
  children,
  isLoading,
  helpText,
  fullWidth,
  hasFooter = true,
  isEmpty = false,
  error,
  tooltip,
  actionButton = false,
  indicator,
  onReload,
  overflown,
  data,
  hasExport = true,
  size,
  graphType,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { brainId } = useParams();
  const { slug } = useAccount();
  const { startDate, endDate } = useSelector(
    (state: RootState) => state.analytics,
    shallowEqual
  );
  const { ai_agents } = useFeatureFlag();

  const onActionButtonClick = useCallback(() => {
    navigate(resolveBrainsPath(`/${slug}/brains/${brainId}/logs`, ai_agents));
  }, [brainId, navigate, slug, ai_agents]);

  if (isLoading) {
    return (
      <div
        className={cn('analytics-loadingContainer', [
          `analytics-loadingContainer--${size}`,
        ])}
      >
        <AnalyticsLoadingIcon />
        <p>{t('analytics.loading_msg')}</p>
      </div>
    );
  }
  if (error) {
    return (
      <fs.Fieldset
        title={
          title ? (
            <AnalyticsTitle
              title={title}
              tooltip={tooltip}
              hasExport={hasExport}
              graphType={graphType}
            />
          ) : undefined
        }
        subtitle={subtitle}
        fullWidth={fullWidth}
      >
        <AnalyticsError onReload={onReload} />
        {hasFooter && (
          <fs.Footer>
            <fs.FooterStatus>
              {helpText || getDateRangeText(startDate, endDate)}
            </fs.FooterStatus>
          </fs.Footer>
        )}
      </fs.Fieldset>
    );
  }

  if (isEmpty) {
    return (
      <fs.Fieldset
        title={
          title ? (
            <AnalyticsTitle
              title={title}
              tooltip={tooltip}
              hasExport={hasExport}
              graphType={graphType}
            />
          ) : undefined
        }
        subtitle={subtitle}
        fullWidth={fullWidth}
        isLoading={isLoading}
        tooltip={tooltip}
      >
        <EmptyResults size="small" />

        {hasFooter && (
          <fs.Footer>
            <fs.FooterStatus>
              {helpText || getDateRangeText(startDate, endDate)}
            </fs.FooterStatus>
          </fs.Footer>
        )}
      </fs.Fieldset>
    );
  }

  return (
    <fs.Fieldset
      title={
        title ? (
          <AnalyticsTitle
            title={title}
            data={data}
            tooltip={tooltip}
            hasExport={hasExport}
            graphType={graphType}
          />
        ) : undefined
      }
      subtitle={subtitle}
      fullWidth={fullWidth}
      isLoading={isLoading}
      overflown={overflown}
    >
      {children}
      {hasFooter && (
        <fs.Footer>
          <fs.FooterStatus>
            {helpText || getDateRangeText(startDate, endDate)}
          </fs.FooterStatus>
          <fs.FooterAction>
            {actionButton && (
              <Button
                isLoading={isLoading}
                variant="tertiary"
                onClick={onActionButtonClick}
              >
                {t('analytics.openLogs')}
              </Button>
            )}
            {indicator && <p className="logs-indicator">{indicator}</p>}
          </fs.FooterAction>
        </fs.Footer>
      )}
    </fs.Fieldset>
  );
};

export default AnalyticsFieldset;
