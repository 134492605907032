import Typography from '@mui/material/Typography';

import styles from './TileAIAgent.module.scss';

export function TileAIAgentHeader({
  title,
  actions,
}: {
  title: string;
  actions: React.ReactNode;
}) {
  return (
    <header className={styles.header}>
      <Typography
        variant="subheading-semi-bold"
        color="var(--color-foreground)"
        className={styles.title}
      >
        {title}
      </Typography>

      {actions}
    </header>
  );
}
