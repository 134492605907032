import { Maximize2Icon, Minimize2Icon } from 'lucide-react';

import styles from './DialogTooltip.module.scss';

export const CollapseButton = ({ isCollapsed, onCollapse }) => (
  <button type="button" onClick={onCollapse} className={styles.iconButton}>
    {isCollapsed ? (
      <Maximize2Icon size={16} color="var(--color-background)" />
    ) : (
      <Minimize2Icon size={16} color="var(--color-background)" />
    )}
  </button>
);
