import { BoldIcon } from 'lucide-react';

import { StyleButton } from './StyleButton';

export const BoldButton = () => {
  return (
    <StyleButton
      type="bold"
      Icon={() => <BoldIcon size={18} color="var(--color-foreground)" />}
    />
  );
};
