import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Select from '@/components/atoms/Select/Select';
import { ReminderAction } from '@/models/action';
import { useReminderOptions } from '@/modules/aiAgents/components/Setup/useReminderOptions';
import { updateAction, updateNode } from '@/redux/nodes/actions';
import { reminderActionSchema } from '@/util/validator';

interface Props {
  action: ReminderAction;
}

interface Form {
  minutes: number;
}

export const InactivityTimeout = ({ action }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { minutes, trigger_node_id } = action;
  const { timeOptions, getTimerLabel } = useReminderOptions();

  const {
    register,
    formState: { errors },
  } = useForm<Form>({
    values: {
      minutes,
    },
    resolver: yupResolver(reminderActionSchema) as Resolver<Form>,
  });

  const isCustomOption = !timeOptions.some(
    (option) => option.value === String(minutes)
  );
  const getOptions = () => {
    if (isCustomOption) {
      return [
        ...timeOptions,
        { value: String(minutes), label: getTimerLabel(minutes) },
      ].sort((a, b) => +a.value - +b.value);
    }

    return timeOptions;
  };

  return (
    <Select
      placeholder={t('ai_agents.reminder.trigger_after')}
      options={getOptions()}
      size="full"
      {...register('minutes')}
      onChange={(e) => {
        const minutes = e.target.value;

        dispatch(
          updateAction({
            actionId: action.action_id,
            action: {
              minutes: parseInt(minutes),
            },
          })
        );

        // Update the respective Reminder node
        dispatch(
          updateNode({
            nodeId: trigger_node_id,
            node: {
              name: `${t('common.after')} ${getTimerLabel(parseInt(minutes))}`,
            },
          })
        );
      }}
      error={!!errors.minutes}
      errorMessage={errors.minutes?.message}
    />
  );
};
