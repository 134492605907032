import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTranslation } from 'react-i18next';

import { TabConfig } from '../types';

/**
 * Returns accessibility props for tabs
 */
const a11yProps = (index: number) => ({
  id: `devtools-tab-${index}`,
  'aria-controls': `devtools-tabpanel-${index}`,
});

interface TabNavigationProps {
  tabs: TabConfig[];
  activeTab: number;
  onTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

/**
 * Component for rendering the tab navigation in the developer tools section
 */
export const TabNavigation = ({
  tabs,
  activeTab,
  onTabChange,
}: TabNavigationProps) => {
  const { t } = useTranslation();

  return (
    <Tabs
      value={activeTab}
      onChange={onTabChange}
      aria-label={t('developer_tools.title')}
    >
      {tabs.map((tab, index) => (
        <Tab key={tab.path} label={tab.label} {...a11yProps(index)} />
      ))}
    </Tabs>
  );
};
