import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@/models/state';

export const selectDialogAlerts = (state: RootState) =>
  state.dialogAlerts.dialogAlerts ?? [];

export const selectHasError = (id: string, key = 'id') =>
  createSelector(selectDialogAlerts, (dialogErrors) => {
    return dialogErrors.some(
      (alert) => alert[key] === id && alert.alertType === 'error'
    );
  });

export const selectHasWarning = (id: string, key = 'id') =>
  createSelector(selectDialogAlerts, (dialogErrors) => {
    return dialogErrors.some(
      (alert) => alert[key] === id && alert.alertType === 'warning'
    );
  });

export const selectHasCarouselDialogErrors = (actionId: string) =>
  createSelector(selectDialogAlerts, (dialogAlerts) => {
    if (!dialogAlerts || !dialogAlerts.length) {
      return false;
    }
    return dialogAlerts?.some(
      (error) => error.type === 'carousel' && error.id === actionId
    );
  });

export const isAlertThisCardButton = (cardIndex: number, index: number) =>
  createSelector(selectDialogAlerts, (dialogAlerts) => {
    return (
      dialogAlerts.filter((alert) => {
        if (alert.type === 'carousel') {
          const [_, alertButtonIndex, alertCardIndex] =
            alert.alertField.split('-');
          return (
            Number(alertButtonIndex) === index &&
            Number(alertCardIndex) === cardIndex
          );
        }
      }).length > 0
    );
  });

export const hasDialogAnyErrors = createSelector(
  selectDialogAlerts,
  (dialogAlerts) => dialogAlerts.some((alert) => alert.alertType === 'error')
);

export const hasDialogAnyWarnings = createSelector(
  selectDialogAlerts,
  (dialogAlerts) => dialogAlerts.some((alert) => alert.alertType === 'error')
);
