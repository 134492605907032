import { Icon } from '@/models/icon';

const Brand = ({
  size = 32,
  color = 'var(--color-foreground-muted)',
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill={color}
        d="M16.992 13.582a2.68 2.68 0 0 1-2.67-2.691 2.68 2.68 0 0 1 2.67-2.691h5.339A2.68 2.68 0 0 1 25 10.89a2.68 2.68 0 0 1-2.67 2.692h-5.338ZM14.497 20.021c0 2.087-1.678 3.779-3.748 3.779C8.679 23.8 7 22.108 7 20.021c0-2.087 1.678-3.779 3.749-3.779 2.07 0 3.748 1.692 3.748 3.779ZM11.373 10.824a2.196 2.196 0 0 1-2.186 2.204A2.196 2.196 0 0 1 7 10.824c0-1.217.979-2.204 2.187-2.204 1.207 0 2.186.987 2.186 2.204ZM21.82 19.916a2.196 2.196 0 0 1-2.186 2.205 2.196 2.196 0 0 1-2.187-2.205c0-1.217.979-2.204 2.187-2.204 1.207 0 2.186.987 2.186 2.204Z"
      />
    </svg>
  );
};

export default Brand;
