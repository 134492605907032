'use client';

import { LinkPlugin } from '@udecode/plate-link/react';

import { useEditorContext } from '@/components/ui/editor/editor-context';

import { LinkFloatingToolbar } from '../../plate-ui/link-floating-toolbar';

function FloatingToolbar() {
  const { size } = useEditorContext();
  return <LinkFloatingToolbar size={size} />;
}

export const linkPlugin = LinkPlugin.extend({
  render: { afterEditable: () => <FloatingToolbar /> },
});
