import { useCallback } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { ChevronUpIcon } from 'lucide-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link, useParams, useLocation } from 'react-router-dom';

import Button from '@/components/atoms/Button/Button/Button';
import StatusDot from '@/components/atoms/StatusDot/StatusDot';
import { useAccount } from '@/hooks/useAccount';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Icon as IconType } from '@/models/icon';
import { Integration, IntegrationType } from '@/models/integration';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import './TileIntegration.scss';

interface TileIntegrationProps {
  integration?: Integration;
  description?: string;
  disabled?: boolean;
  skeleton?: boolean;
  Icon?: React.FC<IconType>;
  name?: string;
  type: IntegrationType;
}

function TileIntegration({
  integration,
  description = '',
  disabled = false,
  skeleton = false,
  Icon,
  name = '',
  type,
}: TileIntegrationProps) {
  const { deskId } = useParams();
  const { t } = useTranslation();
  const { slug } = useAccount();
  const { createIntegration, createStatus } = useIntegrations(deskId);
  const location = useLocation();
  const navigate = useNavigate();
  const lastActive = integration?.last_session_at;

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const handleConnect = useCallback(() => {
    createIntegration(
      { name: type, type, desk_id: deskId, config: {} },
      {
        onSuccess: ({ integration_id }) => {
          navigate(
            `/${slug}/environments/${deskId}/integrations/${integration_id}`
          );
        },
      }
    );
  }, [createIntegration, deskId, navigate, slug, type]);

  const renderStatus = () => {
    if (!disabled) {
      if (skeleton) {
        return <Skeleton animation="wave" width={70} height={25} />;
      }
      if (integration?.active) {
        return (
          <span>
            <StatusDot title="active" status="available" />
            <div>{t('status.active')}</div>
          </span>
        );
      }
      return (
        <span>
          <StatusDot title="Inactive" status="unavailable" />
          <div>{t('status.inactive')}</div>
        </span>
      );
    }
    return '';
  };

  const renderButton = () => {
    if (disabled) {
      return (
        <Button className="connect" data-testid="button-disable" disabled>
          {t('integrations.connect')}
        </Button>
      );
    }

    if (skeleton) {
      return (
        <Skeleton
          className="skeleton--configure"
          animation="wave"
          width={351}
          height={70}
        />
      );
    }

    if (integration) {
      return (
        <Link to={`${location.pathname}/${integration.integration_id}`}>
          <div className={cn('tile-integration--configure')}>
            <Typography variant="label-semi-bold" component="p">
              {t('integrations.configuration_details')}
            </Typography>
            <ChevronUpIcon color="var(--color-foreground-muted)" size={18} />
          </div>
        </Link>
      );
    }

    return (
      <Button
        className="connect"
        variant="secondary"
        onClick={handleConnect}
        isLoading={createStatus === 'pending'}
        disabled={!canWrite}
        isFullWidth
      >
        {t('integrations.configure')}
      </Button>
    );
  };

  return (
    <div
      className={cn('tile-integration', {
        'tile-integration--disabled': disabled,
      })}
    >
      <div className="tile-body">
        <header>
          {Icon && <Icon />}
          <p className="large">{name}</p>
        </header>
        <Typography component="p" variant="label-regular" className="small">
          {description}
        </Typography>
        <Typography component="div" variant="label-regular" className="status">
          {renderStatus()}
        </Typography>

        <Typography
          component="div"
          variant="label-caps-small"
          color="var(--color-foreground-muted)"
          className="timestamp"
        >
          {!disabled && (
            <>
              <span className="lastSession">
                {t('integrations.last_session')}
              </span>
              {lastActive && (
                <span className="time">{moment(lastActive).fromNow()}</span>
              )}
              {!lastActive && '-'}{' '}
            </>
          )}
        </Typography>
      </div>
      <footer className={cn({ isDisabled: disabled || !integration })}>
        {renderButton()}
      </footer>
    </div>
  );
}

export default TileIntegration;
