import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PlainFooterFieldset from '@/components/atoms/Fieldset/templates/PlainFooterFieldset';
import Link from '@/components/atoms/Link/Link';
import { FacebookError } from '@/models/integration';
import { selectIframeView } from '@/redux/user/selectors';
import { getDocsUrl } from '@/util/constants';

interface Props {
  onOAuthClick: () => void;
  type: string;
  error: FacebookError;
  previousFBConnection: string;
  isConnecting?: boolean;
}

const RedirectFieldset = ({
  onOAuthClick,
  error,
  type,
  previousFBConnection,
  isConnecting,
}: Props) => {
  const { t } = useTranslation();

  const suffix = type === 'facebook' ? 'facebook-pages' : 'instagram-accounts';
  const alreadyConnectedUrl = getDocsUrl(
    `/docs/integrations/${type}_messenger#connecting-more-than-one-${suffix}`
  );
  const isIframeView = useSelector(selectIframeView);

  return (
    <PlainFooterFieldset
      title={t('integrations.facebook.redirect.title', { 0: type })}
      subtitle={
        isIframeView
          ? ''
          : t('integrations.facebook.redirect.subtitle', {
              0: type,
            })
      }
      primaryButton={{
        isLoading: isConnecting,
        children: t('integrations.connect'),
        onClick: onOAuthClick,
      }}
      fullContentWidth
    >
      {!error?.message && previousFBConnection && type === 'facebook' && (
        <Alert severity="warning">
          <AlertTitle>
            {t('integrations.facebook.oauth.title', { 0: type })}
          </AlertTitle>
          {t('integrations.facebook.oauth.contextual_help_p1', {
            0: type,
            1: previousFBConnection,
          })}
          <br />
          <br />
          {t('integrations.facebook.oauth.contextual_help_p2')}
          <br />
          {isIframeView ? null : (
            <Link href={alreadyConnectedUrl} external>
              {t('common.learn_more')}
            </Link>
          )}
        </Alert>
      )}
      {error?.message && (
        <Alert severity="error">
          {error?.message}
          <br />
          {error.url && (
            <a href={error.url} target="_blank" rel="noopener">
              {t('common.learn_more')}
            </a>
          )}
        </Alert>
      )}
      {!error?.message && type === 'instagram' && (
        <Alert severity="info">
          {t('integrations.facebook.instagram_info')}
          <br />
          <Link
            href={getDocsUrl(`/docs/integrations/${type}_messenger`)}
            external
          >
            {t('common.learn_more')}
          </Link>
        </Alert>
      )}
    </PlainFooterFieldset>
  );
};

export default RedirectFieldset;
