import { useRef } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { ChevronDownIcon, MessageSquareTextIcon } from 'lucide-react';
import { useClickAway } from 'react-use';

import ButtonGroup from '@/components/atoms/Button/ButtonGroup/ButtonGroup';
import CheckboxDropdown from '@/components/atoms/CheckBoxDropdown/CheckboxDropdown';
import { OptionBase } from '@/models/common';

import DropdownTemplate from '../../DropdownTemplate';

import styles from './ExternalFilter.module.scss';

type Item = OptionBase<{ checked: boolean }>;

interface Props {
  handleClearItems: () => void;
  applyItems: () => void;
  toggleItems: () => void;
  showItems: boolean;
  itemOptions: Item[];
  handleItemsChange: (items: Item[]) => void;
  noItemsSelected: boolean;
  listStatus: string;
  itemsLength: number;
  onClickAway: () => void;
  icon: React.ReactNode;
  displayValue: string;
  dropdownTitle: string;
}

const ExternalFilter = ({
  handleClearItems,
  applyItems,
  toggleItems,
  showItems,
  itemOptions,
  handleItemsChange,
  noItemsSelected,
  listStatus,
  onClickAway,
  icon,
  displayValue,
  itemsLength,
  dropdownTitle,
}: Props) => {
  const filterRef = useRef<HTMLDivElement>();

  useClickAway(filterRef, () => {
    if (showItems) {
      onClickAway();
    }
  });

  return (
    <div className={styles.container} ref={filterRef}>
      <div className={styles.wrapper}>
        <ButtonGroup
          onClick={toggleItems}
          lastItem={
            <ChevronDownIcon color="var(--color-foreground-muted)" size={16} />
          }
          isActive={showItems}
        >
          <div
            className={cn(styles.buttonContainer, {
              [styles.isActive]: showItems,
              [styles.empty]: noItemsSelected,
            })}
          >
            {icon}
            {listStatus === 'pending' ? (
              <Skeleton height={16} width={100} />
            ) : (
              <p className={styles.bold}>{displayValue}</p>
            )}
          </div>
        </ButtonGroup>
        {showItems && (
          <div className={styles.dropdown}>
            <DropdownTemplate size="full" zeroPadding>
              <CheckboxDropdown
                title={
                  <div className={styles.checkboxTitle}>
                    <MessageSquareTextIcon
                      color="var(--color-foreground-muted)"
                      size={16}
                    />
                    {dropdownTitle}
                    <span>({itemsLength})</span>
                  </div>
                }
                items={itemOptions}
                onItemsChecked={handleItemsChange}
                onClear={handleClearItems}
                onApply={applyItems}
              />
            </DropdownTemplate>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExternalFilter;
