import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import { XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { iconByType } from '@/redux/dialogs/helper';
import { clearSelection } from '@/redux/nodes/actions';
import { preventClickThrough } from '@/util/util';

import styles from './ToolkitWrapper.module.scss';

const ToolkitWrapper = ({ type, children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onCloseClick = useCallback(
    (e) => {
      preventClickThrough(e);
      dispatch(clearSelection());
    },
    [dispatch]
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.icon}>
          {iconByType({
            id: type,
            size: 28,
            color: 'var(--color-foreground)',
          })}
        </div>
        <Typography variant="body-semi-bold" color="var(--color-foreground)">
          {t(`actions.types.${type}`)}
        </Typography>
        <span className={styles.close}>
          <IconButton ariaLabel={t('common.collapse')} onClick={onCloseClick}>
            <XIcon size={16} />
          </IconButton>
        </span>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};
export default ToolkitWrapper;
