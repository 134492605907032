export const muiStyles = {
  accordionRoot: {
    boxSizing: 'border-box',
    backgroundColor: 'var(--color-background)',
    border: 'transparent',
    marginBottom: 'var(--space-16)',
    boxShadow: 'none',
    transitionDuration: '200ms',
    scrollMarginTop: 'var(--space-16)',
    '& .MuiAccordionSummary-content': {
      flexDirection: 'column',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginTop: '38px',
      padding: 0,
    },

    '&.MuiAccordion-rounded': {
      borderRadius: 'var(--border-radius-base)',
    },

    '&::before': {
      height: '0',
    },

    '&.MuiAccordion-root.Mui-expanded:first-of-type': {
      scrollMarginTop: 'var(--space-36)',
    },

    '&.MuiAccordion-root.Mui-expanded:last-child': {
      marginBottom: 'var(--space-16)',
    },
  },

  summaryExpandedIcon: {
    padding: '0',

    '&.Mui-expanded svg': {
      stroke: 'var(--color-foreground-muted)',
    },
  },

  detailsRoot: {
    padding: 'var(--space-24) var(--space-24) var(--space-8) var(--space-24)',
  },
};
