import Badge, { BadgeProps } from '@mui/material/Badge';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import { styled } from '@mui/system';

type ItemProps = ListItemProps & { isDisabled?: boolean };

// https://mui.com/system/styled/#styled-component-options-styles-component
export const Item = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<ItemProps>(({ isDisabled }) => ({
  '&': {
    height: 'var(--space-36)',
    padding: 'var(--space-10) var(--space-24) var(--space-10) var(--space-16)',
    '&:hover': {
      background: 'var(--color-bg-muted-50)',
    },
  },
  '& .MuiListItemText-root': {
    color: 'var(--color-foreground)',
    maxWidth: 'calc(100% - var(--space-48))',
    marginLeft: 'var(--space-8)',
  },
  '& .MuiTypography-root': {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit',
    lineHeight: 'inherit',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: isDisabled ? 'var(--color-foreground-muted)' : 'inherit',
  },
  '& .MuiListItemAvatar-root': {
    minWidth: 'auto',
  },
  '& .MuiListItemSecondaryAction-root': {
    right: 'var(--space-4)',
  },
}));

export const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'status',
})<ExtendedBadgeProps>(({ theme, status }) => {
  const statusStyles = {
    online: {
      backgroundColor: 'var(--color-successful)',
      color: 'var(--color-successful)',
    },
    offline: {
      backgroundColor: 'var(--color-foreground-muted)',
      color: 'var(--color-foreground-muted)',
    },
    away: {
      backgroundColor: 'var(--color-warning)',
      color: 'var(--color-warning)',
    },
  };

  return {
    '& .MuiBadge-badge': {
      ...statusStyles[status],
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '5px',
        height: '5px',
        borderRadius: '50%',
        border: '1px solid currentColor',
        content: '""',
      },
    },
  };
});

type ExtendedBadgeProps = BadgeProps & { status: string };
