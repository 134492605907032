import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useAccount } from '@/hooks/useAccount';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useWebhooks from '@/hooks/useWebhooks';
import { DebugStep, NodeStack } from '@/models/tryIt';
import {
  selectTryItAction,
  selectTryItNode,
} from '@/modules/TryIt/redux/actions';
import { selectSelectedMessageNodesStack } from '@/modules/TryIt/redux/selectors';
import { TYPES, iconByType } from '@/redux/dialogs/helper';
import { getActionsSelector } from '@/redux/dialogs/selectors';
import { popModal } from '@/redux/modals/actions';
import { selectCondition } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter, resolveBrainsPath } from '@/util/util';

import { ContextChanges } from './ContextChanges';

import styles from '../DetailsSection.module.scss';

const getDialogsKey = (dialogs, key: string) => {
  let allConditions = [];
  if (!dialogs) return allConditions;

  dialogs.forEach((item) => {
    item.nodes.forEach((node) => {
      // Concatenate the conditions of this node to the allConditions array
      if (Array.isArray(node[key])) {
        const conditions = node[key].map((condition, index) => ({
          ...condition,
          node_id: node.node_id,
          node_name: node.name,
          dialog_id: item.dialog_id,
          dialog_name: item.name,
          index,
        }));
        allConditions = allConditions.concat(conditions);
      }
    });
  });

  return allConditions;
};

type BrainStepsProps = {
  steps: DebugStep[];
  isSubsteps?: boolean;
};

export const BrainSteps = ({ steps, isSubsteps = false }: BrainStepsProps) => {
  const navigate = useNavigate();
  const fontVariant = isSubsteps ? 'label-regular' : 'body-regular';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { slug } = useAccount();
  const brainId = useSelector(selectBrainId);
  const { dialogs } = useDialogs(brainId);
  const selectedMessageNodesStack = useSelector(
    selectSelectedMessageNodesStack
  );
  const { ai_agents } = useFeatureFlag();

  const allConditions = getDialogsKey(dialogs, 'conditions');
  const allRequisites = getDialogsKey(dialogs, 'requisites');

  const handleGoToNode = useCallback(
    (id: string) => {
      const dialog = dialogs.find((d) => d.nodes.some((n) => n.node_id === id));

      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brainId}/dialogs/${dialog.dialog_id}`,
          ai_agents
        )
      );

      dispatch(popModal());
      dispatch(selectTryItNode(id));
    },
    [brainId, dialogs, dispatch, navigate, slug, ai_agents]
  );

  const handleGoToCondition = useCallback(
    (condition) => {
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brainId}/dialogs/${condition.dialog_id}`,
          ai_agents
        )
      );

      dispatch(popModal());
      dispatch(
        selectCondition({ nodeId: condition.node_id, index: condition.index })
      );
    },
    [brainId, dispatch, navigate, slug, ai_agents]
  );

  const Step = ({
    step,
    nodes_stack,
  }: {
    step: DebugStep;
    nodes_stack: NodeStack[];
  }) => {
    const webhook_id = step?.webhook_id;
    const brain_id = webhook_id ? brainId : null;
    const { dialogs } = useDialogs(brain_id);
    const allActions = getActionsSelector(dialogs);
    const { webhook } = useWebhooks(brain_id, webhook_id);

    let body = null;
    const jumpToNode = nodes_stack?.find(
      (node) => node.node_id === step?.node_id
    );
    const condition = allConditions.find(
      (condition) => condition.condition_id === step?.condition_id
    );
    const requisite = allRequisites.find(
      (requisite) => requisite.requisite_id === step?.requisite_id
    );

    const isRequisite = !!step.requisite_id;
    const stepType =
      step.action_type || (isRequisite ? 'question' : 'condition');

    const handleGoToAction = useCallback(
      (actionId: string | undefined) => {
        const selectedAction = allActions.find(
          (a: { action_id: string }) => a.action_id === actionId
        );
        if (!brainId || !selectedAction || !actionId) {
          return;
        }
        dispatch(popModal());
        dispatch(
          selectTryItAction({
            node_id: selectedAction.node_id,
            action_id: actionId,
          })
        );

        navigate(
          resolveBrainsPath(
            `/${slug}/brains/${brainId}/dialogs/${selectedAction.dialog_id}`,
            ai_agents
          )
        );
      },
      [allActions]
    );

    switch (stepType) {
      case 'text':
        body = (
          <Typography
            variant={fontVariant}
            color="var(--color-foreground-muted)"
            component="div"
          >
            {t('try_it.details.text_message_sent')}
          </Typography>
        );
        break;
      case 'image':
      case 'carousel':
      case 'webview':
      case 'video':
      case 'file':
      case 'url':
      case 'survey':
        body = (
          <Typography
            variant={fontVariant}
            color="var(--color-foreground-muted)"
            component="div"
          >
            {t('try_it.details.action_type_sent', {
              0: capitalizeFirstLetter(step.action_type),
            })}
          </Typography>
        );
        break;
      case 'googlesheet':
      case 'email':
      case 'replay':
        body = (
          <Typography
            variant={fontVariant}
            color="var(--color-foreground-muted)"
            component="div"
          >
            {t('try_it.details.action_type_called', {
              0: capitalizeFirstLetter(step.action_type),
            })}
          </Typography>
        );
        break;
      case 'webhook':
        body = (
          <>
            <Typography
              variant={fontVariant}
              color="var(--color-foreground-muted)"
            >
              {t('actions.types.webhook')}{' '}
              <Typography
                className={styles.link}
                variant="body-regular"
                onClick={() => handleGoToAction(step.action_id)}
                role="link"
              >
                {webhook?.name}
              </Typography>{' '}
              <Typography
                variant={fontVariant}
                color="var(--color-foreground-muted)"
              >
                {t('try_it.details.response_time', {
                  0: step.duration,
                })}
              </Typography>
            </Typography>
          </>
        );
        break;
      case 'tag': {
        const getTagMessage = () => {
          const tagCount = step.tags?.length || 0;
          const joinedTags = step.tags?.join(', ');

          switch (step.tag_operation) {
            case 'remove':
              return t('try_it.details.tags_removed', {
                count: tagCount,
                0: joinedTags,
              });
            case 'clear':
              return t('try_it.details.tags_cleared');
            case 'add':
            default:
              return t('try_it.details.tags_applied', {
                count: tagCount,
                0: joinedTags,
              });
          }
        };

        body = (
          <Typography
            variant={fontVariant}
            color="var(--color-foreground-muted)"
          >
            {getTagMessage()}
          </Typography>
        );
        break;
      }

      case 'pause':
      case 'close':
      case 'handover':
        body = (
          <>
            <Typography
              className={styles.capitalize}
              variant={fontVariant}
              color="var(--color-foreground-muted)"
            >
              {t('try_it.details.conversation', {
                0: step.action_type,
              })}
            </Typography>
          </>
        );
        break;
      case 'reset':
      case 'set_variables':
        body = (
          <>
            <Typography
              variant={fontVariant}
              color="var(--color-foreground-muted)"
            >
              {t('common.context')}
              <ContextChanges context_changes={step.context_changes} />
            </Typography>
          </>
        );

        break;
      case 'event':
        body = (
          <Typography
            variant={fontVariant}
            color="var(--color-foreground-muted)"
          >
            {t('actions.types.jump_to')}{' '}
            <Typography
              className={styles.link}
              variant="body-regular"
              onClick={() => handleGoToNode(step.node_id)}
              role="link"
            >
              {jumpToNode?.name}
            </Typography>{' '}
            {t('dialog.jump_to.label')}
          </Typography>
        );
        break;
      case 'condition':
        body = (
          <Typography color="var(--color-foreground-muted)">
            {t('actions.types.condition')}{' '}
            <Typography
              className={styles.link}
              variant={fontVariant}
              onClick={() => handleGoToCondition(condition)}
              role="link"
            >
              {condition?.name}
            </Typography>{' '}
            {t('try_it.details.was_true')}
          </Typography>
        );
        break;
      case 'question':
        body = (
          <Typography color="var(--color-foreground-muted)">
            {t('try_it.details.question_to_fill_in')}{' '}
            <Typography
              variant={fontVariant}
              color="var(--color-foreground-muted)"
              bgcolor="var(--color-bg-muted-50)"
            >
              {requisite?.save_as}
            </Typography>{' '}
            {t('try_it.details.value_asked')}
          </Typography>
        );
        break;
    }

    const icon = iconByType({
      id: stepType,
      size: 16,
      type: TYPES.ACTION,
      color: 'var(--color-foreground-muted)',
      stepsMode: true,
    });

    return (
      <>
        {icon}

        {body}
      </>
    );
  };

  return (
    <>
      {steps?.map((step, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.step} key={`${JSON.stringify(step)}-${index}`}>
            <div className={styles.title}>
              <Step step={step} nodes_stack={selectedMessageNodesStack} />
            </div>
            {step &&
              step?.action_type === 'webhook' &&
              step?.sub_steps?.length > 0 && (
                <div className={cn(styles.details, styles.webhookDetails)}>
                  {step?.context_changes && (
                    <ContextChanges
                      context_changes={step.context_changes}
                      isSubsteps
                    />
                  )}
                  <BrainSteps steps={step.sub_steps} isSubsteps />
                </div>
              )}
            <div className={styles.details}></div>
          </div>
        );
      })}
    </>
  );
};
