import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WhatsappTemplate } from '@/modules/broadcast/models';

import useFocusFirstInput from './hooks/useFocusFirstInput';
import useTemplateVariableNames from './hooks/useTemplateVariableNames';
import { VariableInput } from './VariableInput';

import styles from './ModalTemplateMessage.module.scss';

interface Props<FormType> {
  headerVariablesCount: number;
  bodyVariablesCount: number;
  register: UseFormRegister<FormType>;
  errors: FieldErrors<FormType>;
  template?: WhatsappTemplate;
}
export const TemplateVariables = <FormType,>({
  headerVariablesCount,
  bodyVariablesCount,
  register,
  errors,
  template,
}: Props<FormType>) => {
  const { t } = useTranslation();
  const { getVariableName } = useTemplateVariableNames(template);
  const { setFirstInputRef } = useFocusFirstInput(
    headerVariablesCount,
    bodyVariablesCount
  );

  // Generate array of header variables
  const headerVariables = Array(headerVariablesCount)
    .fill(null)
    .map((_, index) => ({
      varName: getVariableName('header', index, headerVariablesCount),
      index,
    }));

  // Generate array of body variables
  const bodyVariables = Array(bodyVariablesCount)
    .fill(null)
    .map((_, index) => ({
      varName: getVariableName('body', index, headerVariablesCount),
      index,
    }));

  return (
    <Box my={2}>
      <Typography component="p" variant="label-semi-bold">
        {t('try_it.add_variables')}
      </Typography>
      <div className={styles.buttonsContainer}>
        {/* Header Variables */}
        {headerVariables.map(({ varName, index }) => {
          const varDisplay = `{{${varName}}}`;
          const fieldPath = `headerParameters.${index}.name` as Path<FormType>;
          const isFirstInput = index === 0;

          return (
            <VariableInput
              key={varDisplay}
              variableDisplay={varDisplay}
              fieldPath={fieldPath}
              register={register}
              error={Boolean(errors['headerParameters']?.[index]?.name)}
              setInputRef={(element) => setFirstInputRef(element, isFirstInput)}
              validationMessage={t('validation.required')}
            />
          );
        })}

        {/* Body Variables */}
        {bodyVariables.map(({ varName, index }) => {
          const varDisplay = `{{${varName}}}`;
          const fieldPath = `bodyParameters.${index}.name` as Path<FormType>;
          const isFirstInput = index === 0 && headerVariablesCount === 0;

          return (
            <VariableInput
              key={varDisplay}
              variableDisplay={varDisplay}
              fieldPath={fieldPath}
              register={register}
              error={Boolean(errors['bodyParameters']?.[index]?.name)}
              setInputRef={(element) => setFirstInputRef(element, isFirstInput)}
              validationMessage={t('validation.required')}
            />
          );
        })}
      </div>
    </Box>
  );
};
