import { useTranslation } from 'react-i18next';

// This hook is used for reusability of the reminder options
export const useReminderOptions = () => {
  const { t } = useTranslation();

  // Format the time display based on minutes
  const getTimerLabel = (minutes: number) => {
    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      return `${hours} ${t('ai_agents.reminder.hours', { count: hours })}`;
    }
    return `${minutes} ${t('rules.minutes')}`;
  };

  // Available time options for the dropdown
  const timeOptions = [
    { value: '5', label: getTimerLabel(5) },
    { value: '15', label: getTimerLabel(15) },
    { value: '30', label: getTimerLabel(30) },
    { value: '60', label: getTimerLabel(60) },
    { value: '120', label: getTimerLabel(120) },
    { value: '240', label: getTimerLabel(240) },
    {
      value: '1440',
      label: getTimerLabel(1440),
    },
  ];

  return { timeOptions, getTimerLabel };
};
