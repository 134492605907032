import * as DialogPrimitive from '@radix-ui/react-dialog';
import { DialogPortal } from '@radix-ui/react-dialog';
import cn from 'classnames';
import { XIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@/components/ui/button';
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import useDialogs from '@/hooks/useDialogs';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { LENGTH_1024, MAX_DIALOG_NAME_LENGTH } from '@/util/validator';

type FormValues = {
  dialogName: string;
  textResponse: string;
};

export type CreateSampleDialogFormValues = FormValues;

type Props = {
  dialogName: string;
  handleSubmit: (data: FormValues) => void;
};

export const ModalCreateSampleDialog = ({
  dialogName,
  handleSubmit,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { dialogNames } = useDialogs(brainId);

  const form = useForm<FormValues>({
    defaultValues: {
      dialogName,
      textResponse: t('ai_agents.reminder.text_response_default_value'),
    },
  });

  const closeDialog = () => {
    dispatch(popModal());
  };

  return (
    <DialogPrimitive.Root
      data-slot="dialog"
      open={true}
      onOpenChange={closeDialog}
    >
      <DialogPortal data-slot="dialog-portal">
        <DialogPrimitive.Overlay
          data-slot="dialog-overlay"
          className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-40 bg-black/80"
        />
        <DialogPrimitive.Content
          data-slot="dialog-content"
          className={cn(
            'bg-background data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 fixed top-[50%] left-[50%] z-50 w-full max-w-[calc(100%-2rem)] translate-x-[-50%] translate-y-[-50%] gap-4 rounded-lg border shadow-lg duration-200 sm:max-w-lg',
            'sm:max-w-[500px] p-0 overflow-hidden bg-white border-0 rounded-md'
          )}
        >
          <DialogDescription />
          <DialogHeader className="px-6 pt-6 mb-6">
            <DialogTitle className="text-xl font-semibold text-[22px] leading-[120%] tracking-normal">
              {t('ai_agents.reminder.create_sample_dialog')}
            </DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="space-y-6 px-6 pb-6"
            >
              <FormField
                control={form.control}
                name="dialogName"
                rules={{
                  required: t('validation.required'),
                  maxLength: {
                    value: MAX_DIALOG_NAME_LENGTH,
                    message: t('validation.less_than', {
                      0: MAX_DIALOG_NAME_LENGTH,
                    }),
                  },
                  // Check if the dialog name already exists
                  validate: (value) =>
                    !dialogNames.includes(value.toLowerCase()) ||
                    t('validation.name_already_exists'),
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('intent.dialog_name')}</FormLabel>
                    <FormControl>
                      <Input
                        id="dialogName"
                        {...field}
                        className="w-full border-gray-300 focus:border-[#0D6EFD] focus:ring-[#0D6EFD]/20"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="textResponse"
                rules={{
                  required: t('validation.required'),
                  maxLength: {
                    value: LENGTH_1024,
                    message: t('validation.less_than', {
                      0: LENGTH_1024,
                    }),
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('ai_agents.reminder.text_response')}
                    </FormLabel>
                    <FormControl>
                      <Input id="textResponse" {...field} className="w-full" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex gap-2 pt-2 flex-row-reverse">
                <Button
                  type="submit"
                  className="bg-[#0D6EFD] hover:bg-[#0D6EFD]/90"
                >
                  {t('common.create')}
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  onClick={closeDialog}
                  className="text-[#0D6EFD] border-[#0D6EFD] hover:bg-[#0D6EFD]/10"
                >
                  {t('common.cancel')}
                </Button>
              </div>
            </form>
          </Form>
          <DialogPrimitive.Close className="ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground absolute top-4 right-4 rounded-xs opacity-70 transition-opacity hover:opacity-100 focus:ring-2 focus:ring-offset-2 focus:outline-hidden disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:shrink-0 [&_svg:not([class*='size-'])]:size-4">
            <XIcon />
            <span className="sr-only">{t('common.close')}</span>
          </DialogPrimitive.Close>
        </DialogPrimitive.Content>
      </DialogPortal>
    </DialogPrimitive.Root>
  );
};
