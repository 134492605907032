import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import {
  CopyIcon,
  DatabaseIcon,
  EllipsisIcon,
  RefreshCcwIcon,
  Trash2Icon,
} from 'lucide-react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { datasourceEndpoints as endpoints } from '@/api/endpoints';
import Button from '@/components/atoms/Button/Button/Button';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import Table from '@/components/atoms/Table/Table';
import {
  MODAL_DATASOURCE_ADD,
  MODAL_DELETE,
} from '@/components/organisms/Modals/ModalConductor';
import { getIcon } from '@/components/organisms/Tile/TileCollection/TileCollection';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useCollections from '@/hooks/useCollections';
import useDatasources from '@/hooks/useDatasources';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { useIntercom } from '@/hooks/useIntercom';
import { Datasource, DatasourceStatus } from '@/models/collections';
import { actions } from '@/models/permissions';
import { EventName, PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import { sortColumns } from '@/util/util';

import CollectionHeader from './CollectionHeader/CollectionHeader';
import CollectionSidebar from './CollectionSidebar/CollectionSidebar';
import ConnectedBrains from './ConnectedBrains/ConnectedBrains';
import { getStatusColor } from '../Datasource/utils';

import styles from './Collection.module.scss';

const Collection = () => {
  const dispatch = useDispatch();
  const [tooltipMessage, setTooltipMessage] = useState('');
  const intervalId = useRef(null);
  const refetchRef = useRef(null);
  const { collectionId } = useParams();
  const { collection } = useCollections(collectionId);

  const { t } = useTranslation();
  useTitle(t('pages.collection', { 0: collection?.name }));

  useEffect(() => {
    pageView(PageName.COLLECTION);
  }, [collectionId]);

  const features = useFeatureFlag();
  useIntercom();

  const {
    datasources,
    listStatus,
    deleteDatasourceAsync,
    syncDatasource,
    collectionFiles,
    fileCategories,
  } = useDatasources(collectionId);

  const queryClient = useQueryClient();

  const slug = useSelector(selectAccountSlug);
  const [selectedDatasourceId, setSelectedDatasourceId] =
    useState<string>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );

  const canDelete = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.DELETE)
  );

  const handleMenuClick = useCallback((event, i) => {
    setSelectedDatasourceId(i);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedDatasourceId(null);
  }, []);

  useEffect(() => {
    return () => clearInterval(intervalId.current);
  }, []);

  const getColumns = useMemo(
    () => [
      {
        Header: t('collections.datasources.datasources'),
        accessor: 'name',
        Cell: (data) => {
          const { status, document_count, collection_id, datasource_id } = data
            .row.original as Datasource;
          const variant =
            document_count === 0 ? 'warning' : getStatusColor(status);
          const label =
            document_count === 0 ? t('common.empty') : t(`status.${status}`);

          const showBadge =
            document_count === 0 || status !== DatasourceStatus.AVAILABLE;
          return (
            <div className={styles.center}>
              <Link
                to={`/${slug}/collections/${collection_id}/datasources/${datasource_id}`}
                className={styles.link}
              >
                {getIcon(data.row.original.type, undefined, 24)}
                <Typography variant="subheading-semi-bold">
                  {data.value}
                </Typography>{' '}
                {showBadge && <StatusBadge variant={variant} label={label} />}
              </Link>
            </div>
          );
        },
      },
      {
        Header: t('collections.datasources.completed'),
        accessor: 'document_count',
        Cell: (props) => {
          const {
            document_count,
            indexing_count,
            pending_count,
            failed_count,
          } = props.row.original as Datasource;

          const total =
            document_count - indexing_count - pending_count - failed_count;
          return <div className={styles.center}>{total}</div>;
        },
      },
      {
        Header: t('collections.datasources.pending'),
        accessor: 'pending_count',
        Cell: (props) => {
          const { indexing_count, pending_count } = props.row
            .original as Datasource;
          const total = indexing_count + pending_count;
          return <div className={styles.center}>{total}</div>;
        },
      },
      {
        Header: t('collections.datasources.failed'),
        accessor: 'failed_count',
        Cell: (props) => {
          return <div className={styles.center}>{props.value}</div>;
        },
      },
      {
        Header: t('collections.last_indexed'),
        accessor: 'last_indexed_at',
        sortType: sortColumns,
        Cell: (props) => {
          const { last_indexed_at, document_count } = props.row
            .original as Datasource;

          const isEmpty = !last_indexed_at || document_count === 0;
          return (
            <div className={styles.center}>
              {isEmpty ? (
                '-'
              ) : (
                <Tooltip
                  enterDelay={500}
                  arrow
                  title={moment
                    .utc(last_indexed_at)
                    .local()
                    .format('YYYY-MM-DD HH:mm:ss')}
                >
                  <span>{moment.utc(last_indexed_at).local().fromNow()}</span>
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        Header: '',
        id: 'menu',
        Cell: (props) => {
          const row: Datasource = props.row.original;

          return (
            <IconButton
              onClick={(e) => handleMenuClick(e, row.datasource_id)}
              ariaLabel="Datasource options"
              ariaHasPopUp
            >
              <EllipsisIcon color="var(--color-foreground-muted)" />
            </IconButton>
          );
        },
      },
    ],
    [handleMenuClick, slug, t]
  );

  const handleCollectionDelete = useCallback(() => {
    setAnchorEl(null);
    const activeDatasource = datasources.find(
      (d) => d.datasource_id === selectedDatasourceId
    );
    const deleteProps = {
      subtitle: (
        <Trans
          i18nKey="collections.datasources.delete_subtitle"
          values={[activeDatasource?.name]}
        />
      ),
      title: t('collections.datasources.delete_title'),
      confirm: true,
      onDelete: () => {
        deleteDatasourceAsync(selectedDatasourceId, {
          onSuccess: () => {
            dispatch(popModal());
          },
        });
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [datasources, deleteDatasourceAsync, dispatch, selectedDatasourceId, t]);

  const handleCollectionSync = useCallback(() => {
    trackEvent(EventName.ClickSyncDatasource, {
      datasource_id: selectedDatasourceId,
    });
    const datasource = datasources.find(
      (d) => d.datasource_id === selectedDatasourceId
    );
    setAnchorEl(null);
    syncDatasource(datasource);
  }, [datasources, selectedDatasourceId, syncDatasource]);

  useEffect(() => {
    if (
      collectionFiles.pending_count > 0 ||
      collectionFiles.indexing_count > 0
    ) {
      refetchRef.current = setInterval(() => {
        queryClient.invalidateQueries({
          queryKey: [endpoints.datasources(collectionId)],
        });
      }, 2000);
    }
    return () => {
      clearInterval(refetchRef.current);
    };
  }, [
    collectionFiles.indexing_count,
    collectionFiles.pending_count,
    collectionId,
    queryClient,
  ]);

  const getArticlePercentage = useCallback(() => {
    const { total_count, available_count } = collectionFiles;
    if (total_count === 0) {
      return total_count;
    }
    const percentage = (available_count / total_count) * 100;
    return percentage;
  }, [collectionFiles]);

  const filesData = [
    {
      label: t('collections.documents.documents'),
      value: fileCategories.files,
    },
    {
      label: t('collections.webpages'),
      value: fileCategories.website,
    },
    {
      label: t('collections.articles'),
      value: fileCategories.articles,
    },
  ];

  const handleClick = () => {
    trackEvent(EventName.ClickCreateDatasource);
    dispatch(pushModal(MODAL_DATASOURCE_ADD));
  };

  return (
    <>
      <CollectionHeader onClick={handleClick} />
      <PageContentWrapper newPlain2 readOnly={!canWrite}>
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.tableContainer}>
              <ContextualHelp
                title={t('collections.datasources.contextual_title')}
                name="about_collections"
              >
                {t('collections.datasources.contextual_message')}
              </ContextualHelp>
              {listStatus === 'success' && datasources?.length == 0 ? (
                <TileEmptyPage
                  title={t('collections.no_datasource')}
                  notClickable
                  body={
                    <>
                      {t('collections.enhance_va')}
                      <br />{' '}
                      <Button onClick={handleClick} variant="secondary">
                        {t('collections.datasources.add_datasource')}
                      </Button>
                    </>
                  }
                  icon={
                    <DatabaseIcon
                      color="var(--color-foreground-muted)"
                      size={100}
                    />
                  }
                />
              ) : (
                <>
                  {datasources && (
                    <PlainFieldset fullWidth overflown>
                      <Table
                        data={datasources}
                        columns={getColumns}
                        sortBy="updated"
                        sortable
                        flexLayout
                        variant="dark"
                        headerHeight="medium"
                        noGutters
                      />
                      <CustomMenu
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={handleCollectionSync}
                          disabled={!canWrite}
                        >
                          <RefreshCcwIcon
                            size={16}
                            color="var(--color-foreground-muted)"
                          />
                          {t('collections.sync')}
                        </MenuItem>
                        <Tooltip
                          open
                          arrow
                          title={tooltipMessage ? tooltipMessage : ''}
                        >
                          <MenuItem
                            onClick={() => {
                              trackEvent(EventName.ClickCopyDatasourceId, {
                                datasource_id: selectedDatasourceId,
                              });
                              const text = selectedDatasourceId;
                              navigator.clipboard.writeText(text);
                              setTooltipMessage(t('common.copied'));
                              intervalId.current = setTimeout(() => {
                                setTooltipMessage('');
                              }, 1200);
                            }}
                            disabled={!canWrite}
                          >
                            <CopyIcon
                              size={16}
                              color="var(--color-foreground-muted)"
                            />
                            {t('collections.datasources.copy_id')}
                          </MenuItem>
                        </Tooltip>

                        <MenuItem
                          onClick={handleCollectionDelete}
                          disabled={!canDelete}
                          className={styles.danger}
                        >
                          <Trash2Icon size={16} />
                          {t('common.delete')}
                        </MenuItem>
                      </CustomMenu>
                    </PlainFieldset>
                  )}
                </>
              )}
            </div>
            <CollectionSidebar
              percentage={getArticlePercentage()}
              available={collectionFiles.available_count}
              total={collectionFiles.total_count}
              data={filesData}
              emptyMessage={
                datasources?.length === 0
                  ? t('collections.empty_datasources')
                  : ''
              }
            >
              {!features?.enable_collections_for_rules && (
                <ConnectedBrains
                  collection={collection}
                  collectionId={collectionId}
                />
              )}
            </CollectionSidebar>
          </div>
        </div>
      </PageContentWrapper>
    </>
  );
};

export default Collection;
