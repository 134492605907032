import { WhatsappTemplate } from '@/modules/broadcast/models';
import {
  getComponentsFromTemplate,
  getVariableNamesFromNamedParams,
} from '@/modules/broadcast/utils';

/**
 * Hook to manage variable names for WhatsApp templates
 */
const useTemplateVariableNames = (template?: WhatsappTemplate) => {
  const { header, body } = template
    ? getComponentsFromTemplate(template)
    : { header: undefined, body: undefined };

  const isNamedParams = template?.parameter_format === 'NAMED';

  const getHeaderVariableNames = (): string[] => {
    if (isNamedParams && header) {
      return getVariableNamesFromNamedParams(header, 'header');
    }
    return [];
  };

  const getBodyVariableNames = (): string[] => {
    if (isNamedParams && body) {
      return getVariableNamesFromNamedParams(body, 'body');
    }
    return [];
  };

  const getVariableName = (
    section: 'header' | 'body',
    index: number,
    headerVariablesCount: number
  ): string => {
    const isHeader = section === 'header';
    const variableNames = isHeader
      ? getHeaderVariableNames()
      : getBodyVariableNames();

    if (isNamedParams && index < variableNames.length) {
      return variableNames[index];
    }

    return isHeader
      ? (index + 1).toString()
      : (headerVariablesCount + index + 1).toString();
  };

  return {
    isNamedParams,
    getVariableName,
  };
};

export default useTemplateVariableNames;
