// Custom icon

import { Icon } from '@/models/icon';

export const FlowChartIcon = ({
  size = 16,
  color = 'var(--color-foreground-muted)',
  ...rest
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 12V9m-5 6v-2c0-.6.333-1 .833-1h8.334c.5 0 .833.4.833 1v2m4.95-2c.033-.329.05-.662.05-1 0-5.523-4.477-10-10-10S2 6.477 2 12c0 .338.017.671.05 1M9 21.542A9.997 9.997 0 0 0 12 22c1.045 0 2.053-.16 3-.458M9.857 5h4.286c.473 0 .857.256.857.571V8.43c0 .315-.384.571-.857.571H9.857C9.384 9 9 8.744 9 8.429V5.57C9 5.256 9.384 5 9.857 5Zm-5 10h4.286c.473 0 .857.256.857.571v2.858c0 .315-.384.571-.857.571H4.857C4.384 19 4 18.744 4 18.429V15.57c0-.315.384-.571.857-.571Zm10 0h4.286c.473 0 .857.256.857.571v2.858c0 .315-.384.571-.857.571h-4.286c-.473 0-.857-.256-.857-.571V15.57c0-.315.384-.571.857-.571Z"
      />
    </svg>
  );
};
