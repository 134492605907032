import { createContext, useContext } from 'react';

type EditorSize = 'default' | 'small';

interface EditorContextValue {
  size: EditorSize;
}

const EditorContext = createContext<EditorContextValue>({ size: 'default' });

export const useEditorContext = () => useContext(EditorContext);

export const EditorProvider = EditorContext.Provider;
