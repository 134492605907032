import MuiTabs, { TabsProps } from '@mui/material/Tabs';

const muiStyles = {
  '.MuiTabs-fixed': {
    display: 'flex',
    alignItems: 'flex-end',
  },
  '.MuiTabs-flexContainer': {
    margin: '2px',
  },
  '.MuiTabs-scrollButtons': {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    alignSelf: 'center',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
};

enum TabSize {
  'x-small' = '8px',
  small = '10px',
  medium = '16px',
}

export type Props = TabsProps & {
  size?: string;
  tabIndicatorColor?: string;
};

const Tabs: React.FC<Props> = ({
  value,
  tabIndex,
  onChange,
  size = 'small',
  children,
  tabIndicatorColor = 'var(--color-accent)',
  ...props
}) => (
  <MuiTabs
    value={value}
    tabIndex={tabIndex}
    onChange={onChange}
    aria-label={props['aria-label']}
    TabIndicatorProps={{
      sx: { backgroundColor: tabIndicatorColor },
    }}
    sx={{
      ...muiStyles,
      '.MuiTabs-flexContainer .MuiTab-root': {
        padding: TabSize[size],
      },
    }}
    {...props}
  >
    {children}
  </MuiTabs>
);

export default Tabs;
