import { useCallback, useEffect, useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import { integrationByType } from '@/components/organisms/Integrations';
import { OptionBase } from '@/models/common';
import { RootState } from '@/models/state';
import {
  LogsFilterType,
  AccountAnalyticsFilterType,
  AnalyticsType,
} from '@/modules/analytics/models';
import { selectFiltersByType } from '@/modules/TryIt/redux/selectors';
import { urlSafeBase64Encode } from '@/util/util';

import {
  logsFilterOptions,
  accountAnalyticsFilterOptions,
  filterFiltersByUrl,
  chatLogsFilterOptions,
} from '../constants';
import { applyFilters } from '../redux/actions';

export const trimStringPropertiesByType = (arr) => {
  return arr.reduce((accumulator, item) => {
    if (typeof item === 'object' && item !== null && 'type' in item) {
      const typeName = item['type'];
      if (item[typeName] && typeof item[typeName] === 'string') {
        accumulator.push({ ...item, [typeName]: item[typeName].trim() });
      } else if (typeof item[typeName] === 'string' && !item[typeName]) {
        return accumulator;
      } else accumulator.push(item);
    }
    return accumulator;
  }, []);
};

type Form = {
  filters: {
    type: LogsFilterType | AccountAnalyticsFilterType;
    isCovered?: boolean;
    isTest?: boolean;
    isContained?: boolean;
    isMeaningful?: boolean;
    minNumUserMessages?: string;
    channels?: string[];
    deskIds?: OptionBase[];
    brainIds?: OptionBase[];
    agentIds?: OptionBase[];
    sessionId?: string;
    tags?: string[];
    ratings?: string[];
    maxConfidence?: string;
    userId?: string;
    channelUserId?: string;
    externalUserId?: string;
  }[];
};

export const formatChannelFilters = (filters) => {
  return filters.map((filt) => {
    if (filt.type === 'channels') {
      const channels = filt.channels.map((channel: string) => {
        for (const key of Object.keys(integrationByType)) {
          if (channel?.toLowerCase() === key) {
            return key;
          }
        }
      });
      return { ...filt, channels };
    }
    return filt;
  });
};

const useContentFilters = (
  type: AnalyticsType,
  setFilterOpen: (value: boolean) => void
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [urlParams] = useSearchParams();

  const location = useLocation();

  const filters = useSelector(
    (state: RootState) => selectFiltersByType(state, type),
    isEqual
  );

  const formMethods = useForm<Form>({
    defaultValues: { filters },
  });

  const { control, getValues, reset } = formMethods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filters',
  });

  const filterOptions = useMemo(() => {
    if (type === AnalyticsType.LOGS) {
      return logsFilterOptions(t).map((filter) =>
        filter.label === 'Desk'
          ? {
              label: t('environments.environment'),
              value: 'deskIds',
              prefix: t('environments.environment_is'),
            }
          : filter
      );
    }

    if (location.pathname.includes('/human-chat-logs')) {
      return chatLogsFilterOptions(t);
    }

    return accountAnalyticsFilterOptions(t)
      .filter((f) => {
        if (
          f.value === 'agentIds' &&
          location.pathname.includes('/human-agents-effectiveness')
        ) {
          return false;
        }
        return filterFiltersByUrl(f.value, location.pathname, t);
      })
      .map((filter) =>
        filter.label === 'Desk'
          ? {
              label: t('environments.environment'),
              value: 'deskIds',
              prefix: t('environments.environment_is'),
            }
          : filter
      );
  }, [location.pathname, t, type]);

  useEffect(() => {
    if (type === AnalyticsType.ACCOUNT) {
      reset({
        filters: filters.filter((f) => {
          if (f.agentIds) {
            return (
              location.pathname.includes('/agents-performance') &&
              !location.pathname.includes('/human-agents-effectiveness')
            );
          }

          return (
            !f.deskIds &&
            !f.brainIds &&
            !f.agentIds &&
            filterFiltersByUrl(f.type, location.pathname, t)
          );
        }),
      });
    } else {
      reset({ filters });
    }
  }, [filters, location.pathname, reset, t, type]);

  const availableOptions = useMemo(() => {
    return filterOptions.reduce(
      (acc, opt) =>
        fields?.find((filt) => opt.value in filt) ? acc : [...acc, opt],
      []
    );
  }, [fields, filterOptions]);

  const handleFilterSave = useCallback(() => {
    let values = getValues('filters');

    values = trimStringPropertiesByType(values);

    const newFilter = formatChannelFilters(values);
    let finalFilter = [...newFilter];

    if (type === AnalyticsType.ACCOUNT) {
      const existingDeskFilter = filters.find(
        (filter) => filter.type === 'deskIds'
      );

      const existingBrainFilter = filters.find(
        (filter) => filter.type === 'brainIds'
      );

      const existingAgentFilter = filters.find(
        (filter) => filter.type === 'agentIds'
      );

      if (existingBrainFilter) {
        finalFilter = [existingBrainFilter, ...finalFilter];
      }

      if (existingDeskFilter) {
        finalFilter = [existingDeskFilter, ...finalFilter];
      }

      if (existingAgentFilter) {
        finalFilter = [existingAgentFilter, ...finalFilter];
      }
    }
    dispatch(applyFilters({ filters: cloneDeep(finalFilter), type }));

    navigate(
      {
        ...window.location,
        search: `${urlParams.get('date') ? `date=${urlParams.get('date')}` : ''}${
          !isEmpty(finalFilter)
            ? `&filters=${urlSafeBase64Encode(JSON.stringify(finalFilter))}`
            : ''
        }`,
      },
      {
        replace: true,
      }
    );
    setFilterOpen(false);
  }, [dispatch, filters, getValues, navigate, setFilterOpen, type, urlParams]);

  const handleFilterClear = useCallback(() => {
    const existingDeskFilter = filters.find(
      (filter) => filter.type === 'deskIds'
    );

    const existingBrainFilter = filters.find(
      (filter) => filter.type === 'brainIds'
    );
    const existingAgentFilter = filters.find(
      (filter) => filter.type === 'agentIds'
    );

    let finalFilter = [];
    if (type === AnalyticsType.ACCOUNT) {
      if (existingBrainFilter) {
        finalFilter = [existingBrainFilter, ...finalFilter];
      }
      if (existingDeskFilter) {
        finalFilter = [existingDeskFilter, ...finalFilter];
      }
      if (existingAgentFilter) {
        finalFilter = [existingAgentFilter, ...finalFilter];
      }
    } else {
      reset({ filters: finalFilter });
    }

    dispatch(applyFilters({ filters: finalFilter, type }));
    navigate({
      search: `${urlParams.get('date') ? `date=${urlParams.get('date')}` : ''}${
        !isEmpty(finalFilter)
          ? `&filters=${urlSafeBase64Encode(JSON.stringify(finalFilter))}`
          : ''
      }`,
    });
    setFilterOpen(false);
  }, [dispatch, filters, navigate, reset, setFilterOpen, type, urlParams]);

  return {
    fields,
    filters,
    formMethods,
    filterOptions,
    availableOptions,
    append,
    remove,
    getValues,
    handleFilterSave,
    handleFilterClear,
  };
};

export default useContentFilters;
