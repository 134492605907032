import { useCallback } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import retry from 'async-await-retry';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { SetRequired } from 'type-fest';

import {
  desksEndpoints,
  integrationsEndpoints as endpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { useAccount } from '@/hooks/useAccount';
import { onDeskUpdated } from '@/hooks/useDesks';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import { Desk } from '@/models/desk';
import {
  Integration,
  IntegrationId,
  NewIntegration,
  WhatsAppIntegration,
} from '@/models/integration';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { popModal } from '@/redux/modals/actions';

import { useRules } from '../modules/rules/hooks/useRules';

export const API = Object.freeze({
  listIntegrations: async <T extends Integration>(
    deskId: string
  ): Promise<Integrations<T>> => callGet(endpoints.integrations(deskId)),

  getIntegration: async <T extends Integration>(
    deskId: string,
    integrationId: string
  ): Promise<T> => callGet(endpoints.integration(deskId, integrationId)),

  createIntegration: async <T extends Integration>({
    desk_id,
    ...integration
  }: NewIntegration<T>): Promise<Integration> =>
    callPost(endpoints.integrations(desk_id), integration),

  updateIntegration: async <T extends Integration>({
    desk_id,
    integration_id,
    ...integration
  }: IntegrationId<T>): Promise<T> => {
    return callPut(endpoints.integration(desk_id, integration_id), integration);
  },

  deleteIntegration: async ({
    desk_id,
    integration_id,
  }: IntegrationId<Integration>): Promise<IntegrationId<Integration>> =>
    callDelete(endpoints.integration(desk_id, integration_id)),

  verifyIntegration: async (
    desk_id: string,
    integration_id: string
  ): Promise<Integration> =>
    callPost(endpoints.verify(desk_id, integration_id), {}),
});

type MutationOptions = {
  hideErrors?: boolean;
};

type WithMutationOptions<T> = T & {
  mutationOptions?: MutationOptions;
};

export type Integrations<T = Integration> = {
  integrations: T[];
};

export const onIntegrationCreated = (
  queryClient: QueryClient,
  integration: Integration
) => {
  const queryKey = [endpoints.integrations(integration.desk_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Integrations>(queryKey, (prev) => ({
      integrations: [...(prev?.integrations || []), integration],
    }));
  }

  const deskQueryKey = [desksEndpoints.desk(integration.desk_id)];
  const previousDesk = queryClient.getQueryData<Desk>(deskQueryKey);
  const newDesk: Desk = {
    ...previousDesk,
    integrations: [...(previousDesk?.integrations || []), integration],
  };
  onDeskUpdated(queryClient, newDesk);
};

export const onIntegrationUpdated = (
  queryClient: QueryClient,
  integration: Integration
) => {
  queryClient.setQueryData<Integration>(
    [endpoints.integration(integration.desk_id, integration.integration_id)],
    (prev) => ({ ...prev, ...integration })
  );

  const queryKey = [endpoints.integrations(integration.desk_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Integrations>(
      [endpoints.integrations(integration.desk_id)],
      (prev: Integrations) => ({
        integrations: prev?.integrations?.map((item) =>
          item.integration_id === integration.integration_id
            ? { ...item, ...integration }
            : item
        ),
      })
    );
  }
  queryClient.invalidateQueries({
    queryKey: [desksEndpoints.desk(integration.desk_id)],
  });
};

export const onIntegrationRemoved = (
  queryClient: QueryClient,
  desk_id: string,
  integration_id: string
) => {
  const queryKey = [endpoints.integrations(desk_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Integrations>(queryKey, (prev: Integrations) => ({
      integrations: (prev?.integrations || []).filter(
        (acc) => acc.integration_id !== integration_id
      ),
    }));
  }
  queryClient.removeQueries({
    queryKey: [endpoints.integration(desk_id, integration_id)],
  });

  const previousDesk = queryClient.getQueryData<Desk>([
    desksEndpoints.desk(desk_id),
  ]);
  const newDesk: Desk = {
    ...previousDesk,
    integrations: previousDesk?.integrations?.filter(
      (int) => int.integration_id !== integration_id
    ),
  };
  onDeskUpdated(queryClient, newDesk);
};

export const useIntegrations = <T extends Integration = Integration>(
  deskId: string,
  integrationId?: string
) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { markAsComplete } = useHomeCheckList();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { slug } = useAccount();
  const { deleteRule } = useRules(deskId);

  const { data: integrations, status: listStatus } = useQuery<
    Integrations<T>,
    Error
  >({
    queryKey: [endpoints.integrations(deskId)],
    queryFn: () => API.listIntegrations(deskId),
    enabled: Boolean(deskId),
  });

  const { data: integration, status: getStatus } = useQuery<T, Error>({
    queryKey: [endpoints.integration(deskId, integrationId)],
    queryFn: () => API.getIntegration(deskId, integrationId),
    enabled: Boolean(deskId && integrationId),
  });

  const verifyIntegrationAndUpdateQuery = useCallback(
    async (integration: Integration): Promise<Integration> => {
      if (integration.type === 'web' || !integration.active) {
        return;
      }
      try {
        await retry(
          async (i: Integration) => {
            const result = await API.getIntegration(
              i.desk_id,
              i.integration_id
            );
            onIntegrationUpdated(queryClient, result);
            queryClient.setQueryData<Integration>(
              [
                `${endpoints.integration(result.desk_id, result.integration_id)}/verify`,
              ],
              (prev) => ({ ...prev, ...result })
            );

            if (
              result.status === 'unverified' ||
              result.status === 'verifying'
            ) {
              return Promise.reject(result);
            }
            return Promise.resolve(result);
          },
          [integration],
          {
            // Initial attempt plust the number of retries
            retriesMax: 4,
            // Retries after 2s, 4s and 8s
            interval: 2000,
            exponential: true,
            factor: 2,
          }
        );
      } catch (error) {
        console.error('Integration failed to verify ', error);
      }
    },
    [queryClient]
  );

  const { mutate: verifyIntegration, status: verifyStatus } = useMutation<
    Integration,
    Error
  >({
    mutationFn: () => API.verifyIntegration(deskId, integrationId),
    onSuccess: async (resp) => {
      // Refetch to get the new integration's verification status
      verifyIntegrationAndUpdateQuery(resp);
    },
    onError: (error) => {
      console.error('Error verifying integration', error);
    },
  });

  const {
    mutate: updateIntegration,
    status: updateStatus,
    error: updateError,
  } = useMutation<T, Error, WithMutationOptions<IntegrationId<T>>>({
    mutationFn: ({ mutationOptions: _, ...integration }) => {
      return API.updateIntegration<T>(integration as IntegrationId<T>);
    },
    onSuccess: async (resp) => {
      if (resp?.active) {
        markAsComplete(AccountUserPrefsEnum.ACTIVATE_CHANNELS);
      }

      dispatch(addTemporalToast('success', t('integrations.updated')));
    },
    onError: (error, variables) => {
      if (variables?.mutationOptions?.hideErrors) {
        return;
      }
      dispatch(addErrorTemporalToast(error));
    },
    onSettled: async (resp) => {
      try {
        verifyIntegrationAndUpdateQuery(resp);
      } catch (error) {
        console.warn('Verification failed, but update was successful:', error);
      }
    },
  });

  const clearWhatsAppTestingRules = useCallback(
    (queryClient: QueryClient, integration_id: string) => {
      const deletedIntegration = queryClient.getQueryData<WhatsAppIntegration>([
        endpoints.integration(deskId, integration_id),
      ]);
      if (
        deletedIntegration.type === 'whatsapp' &&
        deletedIntegration.config.test_numbers &&
        deletedIntegration.config.test_numbers.length > 0
      ) {
        deletedIntegration.config.test_numbers.forEach((tn) => {
          if (tn.rule_id) {
            deleteRule(tn.rule_id);
          }
        });
      }
    },
    [deleteRule, deskId]
  );

  const { mutate: createIntegration, status: createStatus } = useMutation<
    Integration,
    Error,
    SetRequired<Partial<Integration>, 'type' | 'desk_id'>
  >({
    mutationFn: API.createIntegration,
    onSuccess: (resp) => {
      onIntegrationCreated(queryClient, resp);
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteIntegration, status: deleteStatus } = useMutation<
    Pick<Integration, 'desk_id' | 'integration_id'>,
    Error,
    Pick<Integration, 'desk_id' | 'integration_id'>
  >({
    mutationFn: API.deleteIntegration,
    onSuccess: (resp) => {
      clearWhatsAppTestingRules(queryClient, resp.integration_id);
      dispatch(popModal());
      navigate(`/${slug}/environments/${deskId}/integrations`, {
        replace: true,
      });

      onIntegrationRemoved(queryClient, deskId, resp.integration_id);
      dispatch(addTemporalToast('success', t('integrations.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const getIntegration = useCallback(
    async (desk_id: string, integration_id: string): Promise<T> => {
      const cachedIntegration = queryClient.getQueryData<T>([
        endpoints.integration(desk_id, integration_id),
      ]);

      if (cachedIntegration) {
        return cachedIntegration;
      }
      const integration = await API.getIntegration<T>(desk_id, integration_id);

      queryClient.setQueryData<T>(
        [
          endpoints.integration(
            integration.desk_id,
            integration.integration_id
          ),
        ],
        (prev) => ({ ...prev, ...integration })
      );

      return integration;
    },
    [queryClient]
  );
  return {
    integrations: integrations?.integrations,
    listStatus,
    integration,
    getStatus,
    createStatus,
    deleteStatus,
    updateStatus,
    updateError,
    verifyStatus,

    getIntegration,
    createIntegration,
    deleteIntegration,
    updateIntegration,
    verifyIntegration,
  };
};
