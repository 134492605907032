import { ListIcon } from 'lucide-react';

import { StyleButton } from './StyleButton';

export const UnorderedListButton = () => {
  return (
    <StyleButton
      type="ul_list"
      Icon={() => <ListIcon size={14} color="var(--color-foreground)" />}
      size={14}
    />
  );
};
