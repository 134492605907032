import React, { forwardRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { PaperclipIcon } from 'lucide-react';

import CarouselOld from '@/components/organisms/Dialogs/CarouselOld/CarouselOld';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { CarouselCard } from '@/models/action';
import {
  ConversationSource,
  Attachment as AttachmentType,
  AuthorType,
} from '@/models/chat';
import { splitArray } from '@/util/util';

import { Attachment } from '../Attachment/Attachment';
import Attachments from '../Attachment/Attachments';

import styles from './Response.module.scss';

interface ExtendedProps {
  type?: string;
  text?: string;
  url?: string;
  id?: string;
  name?: string;
  action_id?: string;
  cards?: CarouselCard[];
  label?: string;
  options?: { text: string; label: string }[];
  attachments?: AttachmentType[];
  hasAvatar?: boolean;
  source?: ConversationSource;
  authorType?: `${AuthorType}`;
}

export const Response = forwardRef<
  HTMLSpanElement | HTMLDivElement,
  ExtendedProps
>((props, ref) => {
  const { html } = useMarkdownToHtml(props.text || '');
  // Destructure known props and capture the remaining as rest.
  const {
    type,
    attachments,
    text,
    url,
    id,
    name,
    cards,
    options,
    label,
    hasAvatar,
    source,
    authorType,
    ...rest
  } = props;

  if (attachments?.length > 0) {
    return (
      <div
        ref={ref as React.Ref<HTMLDivElement>}
        {...rest}
        className={cn(styles.bubble, styles.brain, styles.text, {
          [styles.hasAvatar]: hasAvatar,
        })}
      >
        <Box mb={text ? 1 : 0}>
          {splitArray(attachments, 4).map((chunk, chunkIndex) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={chunkIndex}
              className={cn(styles.attachmentContainer, {
                [styles.hasText]: !!text,
              })}
            >
              <Attachments>
                {chunk.map((attachment) => (
                  <Attachment
                    key={attachment?.id ?? attachment.url}
                    attachment={attachment}
                    source={source}
                    isSingleItem={attachments.length === 1}
                    authorType={authorType}
                  />
                ))}
              </Attachments>
            </div>
          ))}
        </Box>
        <Typography
          variant="body-regular"
          color="var(--color-foreground)"
          className={styles.aligntRight}
        >
          {text}
        </Typography>
      </div>
    );
  }

  switch (type) {
    default:
    case 'disambiguation':
      return (
        <>
          <span
            ref={ref as React.Ref<HTMLSpanElement>}
            {...rest}
            className={cn(styles.bubble, styles.brain, styles.text, {
              [styles.hasAvatar]: hasAvatar,
            })}
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {options && (
            <div className={styles.optionsContainer}>
              {options.map((option) => (
                <button
                  key={`option-${option.text}`}
                  className={cn(styles.bubble, styles.option)}
                  onClick={() => {}}
                  type="button"
                >
                  {option.label}
                </button>
              ))}
            </div>
          )}
        </>
      );
    case 'url':
      return (
        <p
          ref={ref as React.Ref<HTMLParagraphElement>}
          {...rest}
          className={cn(styles.bubble, styles.brain, styles.url)}
        >
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </p>
      );
    case 'audio':
      return (
        <p
          ref={ref as React.Ref<HTMLParagraphElement>}
          {...rest}
          className={cn(styles.bubble, styles.brain, styles.isMedia)}
        >
          <audio src={url} controls />
        </p>
      );
    case 'image':
      return (
        <span
          ref={ref as React.Ref<HTMLSpanElement>}
          {...rest}
          className={cn(styles.bubble, styles.brain, styles.image)}
        >
          {id ? (
            <Attachment
              source={source}
              attachment={{
                id,
                type: 'image',
                mime_type: 'image/jpeg',
              }}
              authorType={authorType}
            />
          ) : (
            <img src={url} alt={name} referrerPolicy="no-referrer" />
          )}
        </span>
      );
    case 'video':
      return (
        <p
          ref={ref as React.Ref<HTMLParagraphElement>}
          {...rest}
          className={cn(styles.bubble, styles.brain, styles.isMedia)}
        >
          {id ? (
            <Attachment
              source={source}
              attachment={{
                id,
                type: 'video',
                mime_type: 'video/mp4',
              }}
              authorType={authorType}
            />
          ) : (
            <video src={url} controls />
          )}
        </p>
      );
    case 'file':
      return (
        <p
          ref={ref as React.Ref<HTMLParagraphElement>}
          {...rest}
          className={cn(styles.bubble, styles.brain, styles.file)}
        >
          {id ? (
            <Attachment
              source={source}
              attachment={{
                id,
                type: 'file',
                mime_type: '',
              }}
              authorType={authorType}
            />
          ) : (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <PaperclipIcon size={16} color="var(--color-foreground-muted)" />
              {name ? name : text}
            </a>
          )}
        </p>
      );
    case 'carousel':
      return (
        <div
          ref={ref as React.Ref<HTMLDivElement>}
          {...rest}
          className={cn(styles.carouselContainer, styles.isChat, {
            [styles.oneCard]: cards && cards.length === 1,
          })}
        >
          <CarouselOld isChat cards={cards} slidesPerView={2} />
        </div>
      );
    case 'webview':
    case 'survey':
      return (
        <div
          ref={ref as React.Ref<HTMLDivElement>}
          {...rest}
          className={styles.webviewContainer}
        >
          <p>{name}</p>
          <div className={cn(styles.webviewButtonContainer, styles.disabled)}>
            <p>{label}</p>
          </div>
        </div>
      );
  }
});

Response.displayName = 'Response';
