import { typography as body } from './typography/body';
import { typography as heading1 } from './typography/heading1';
import { typography as heading2 } from './typography/heading2';
import { typography as heading3 } from './typography/heading3';
import { typography as subheading } from './typography/subheading';
import { typography as supporting } from './typography/supporting';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body: React.CSSProperties;
    ['h1-bold']: React.CSSProperties;
    ['h1-semi-bold']: React.CSSProperties;
    ['h1-regular']: React.CSSProperties;
    ['h2-bold']: React.CSSProperties;
    ['h2-semi-bold']: React.CSSProperties;
    ['h2-regular']: React.CSSProperties;
    ['h3-bold']: React.CSSProperties;
    ['h3-semi-bold']: React.CSSProperties;
    ['h3-regular']: React.CSSProperties;
    ['subheading-bold']: React.CSSProperties;
    ['subheading-semi-bold']: React.CSSProperties;
    ['subheading-regular']: React.CSSProperties;
    ['body-bold']: React.CSSProperties;
    ['body-semi-bold']: React.CSSProperties;
    ['body-medium']: React.CSSProperties;
    ['body-regular']: React.CSSProperties;
    ['label-bold']: React.CSSProperties;
    ['label-semi-bold']: React.CSSProperties;
    ['label-regular']: React.CSSProperties;
    ['label-caps-large']: React.CSSProperties;
    ['label-caps-small']: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body?: React.CSSProperties;
    ['h1-bold']?: React.CSSProperties;
    ['h1-semi-bold']?: React.CSSProperties;
    ['h1-regular']?: React.CSSProperties;
    ['h2-bold']?: React.CSSProperties;
    ['h2-semi-bold']?: React.CSSProperties;
    ['h2-regular']?: React.CSSProperties;
    ['h3-bold']?: React.CSSProperties;
    ['h3-semi-bold']?: React.CSSProperties;
    ['h3-regular']?: React.CSSProperties;
    ['subheading-bold']?: React.CSSProperties;
    ['subheading-semi-bold']?: React.CSSProperties;
    ['subheading-regular']?: React.CSSProperties;
    ['body-bold']?: React.CSSProperties;
    ['body-semi-bold']?: React.CSSProperties;
    ['body-medium']?: React.CSSProperties;
    ['body-regular']?: React.CSSProperties;
    ['label-bold']?: React.CSSProperties;
    ['label-semi-bold']?: React.CSSProperties;
    ['label-regular']?: React.CSSProperties;
    ['label-caps-large']?: React.CSSProperties;
    ['label-caps-small']?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true;
    ['h1-bold']: true;
    ['h1-semi-bold']: true;
    ['h1-regular']: true;
    ['h2-bold']: true;
    ['h2-semi-bold']: true;
    ['h2-regular']: true;
    ['h3-bold']: true;
    ['h3-semi-bold']: true;
    ['h3-regular']: true;
    ['subheading-bold']: true;
    ['subheading-semi-bold']: true;
    ['subheading-regular']: true;
    ['body-bold']: true;
    ['body-semi-bold']: true;
    ['body-medium']: true;
    ['body-regular']: true;
    ['label-bold']: true;
    ['label-semi-bold']: true;
    ['label-regular']: true;
    ['label-caps-large']: true;
    ['label-caps-small']: true;
  }
}

const FONT_PRIMARY = [
  '"Geist"',
  'system-ui',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'sans-serif',
].join(', ');

const typography = {
  fontSize: 14,
  fontFamily: FONT_PRIMARY,

  h1: {
    fontSize: 'var(--font-h1)',
    fontWeight: 500,
    color: 'var(--color-text-primary-foreground)',
  },
  h2: {
    fontSize: 'var(--font-h2)',
    fontWeight: 500,
    letterSpacing: '-1px',
    color: 'var(--color-text-primary-foreground)',
  },
  h3: {
    fontSize: 'var(--font-h1)',
    fontWeight: 700,
    letterSpacing: '2px',
    color: 'var(--color-foreground)',
  },

  h6: {
    fontSize: 'var(--font-h6)',
    fontWeight: 600,
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: 600,
  },
  subtitle2: {
    fontSize: '12px',
    color: 'var(--color-foreground-muted)',
  },
  body: {
    color: 'var(--color-foreground)',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
  },
  bodyMedium: {
    color: 'var(--color-foreground)',
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '0',
    lineHeight: 'var(--space-20)',
  },
  ...heading1,
  ...heading2,
  ...heading3,
  ...subheading,
  ...body,
  ...supporting,
};

export default typography;
