import { useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { BookIcon } from 'lucide-react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import { renderDialogIcon } from '@/components/organisms/SubNav/TreeView/Item';
import useDialogs from '@/hooks/useDialogs';
import { EventAction } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { JUMP_TO_DOCS_URL } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';
import { eventActionSchema } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

type Form = {
  trigger_node:
    | string
    | {
        dialog_name: string;
        value: string;
        label: string;
        node_type: string;
      };
};

const ToolkitJumpTo = () => {
  const { t } = useTranslation();
  const { brainId, dialogId } = useParams();
  const dispatch = useDispatch();

  const { actionId, triggerNodeId, node_id } = useSelector((state) => {
    const selectedAction = selectSelectedAction(
      state as RootState
    ) as EventAction;

    const {
      action_id: actionId,
      trigger_node_id: triggerNodeId,
      type,
    } = selectedAction;

    return {
      actionId,
      triggerNodeId,
      node_id: selectNodeByActionId(state as RootState, actionId).node_id,
      type,
    };
  }, shallowEqual);

  const { detailedOptions } = useDialogs(brainId, dialogId);

  const defaultEvent = useMemo(() => {
    const option = detailedOptions.find((o) => o.value === triggerNodeId);
    if (!option) {
      return null;
    }
    return option;
  }, [detailedOptions, triggerNodeId]);

  const {
    control,
    formState: { errors },
    trigger,
    setValue,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      trigger_node: defaultEvent,
    },
    resolver: yupResolver(eventActionSchema) as Resolver<Form>,
  });

  const triggerNodeErrorMessage = errors?.trigger_node?.message;

  const handleChange = useCallback(
    (_, option) => {
      dispatch(
        updateAction({
          actionId,
          action: {
            trigger_node_id: option?.value,
          },
        })
      );
    },
    [actionId, dispatch]
  );

  const updateErrors = useCallback(
    (key: string) => (value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: node_id,
            title: t('actions.types.event'),
            body: capitalizeFirstLetter(value),
            type: 'jump_to',
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, node_id, t]
  );

  // Update the trigger node when the trigger node
  // from the select input in the dialog is updated.
  useEffect(() => {
    const option =
      detailedOptions.find((option) => option.value === triggerNodeId) ?? null;

    setValue('trigger_node', option, {
      shouldValidate: true,
    });
  }, [detailedOptions, setValue, triggerNodeId]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    updateErrors('url')(triggerNodeErrorMessage);
  }, [triggerNodeErrorMessage, updateErrors]);

  return (
    <ToolkitWrapper type="jump_to">
      <AutocompleteNew
        control={control}
        options={detailedOptions}
        name="trigger_node"
        label={t('dialog.jump_to.label')}
        id="trigger_node"
        hasError={!!errors.trigger_node}
        errorMessage={capitalizeFirstLetter(errors.trigger_node?.message)}
        placeholder={t('dialog.jump_to.placeholder')}
        groupByProp="dialog_name"
        groupByLabelProp={false}
        renderIconInFront={renderDialogIcon}
        getOptionDisabled={(option) => option.value === node_id}
        width={312}
        autoHighlight
        onChange={handleChange}
      />

      <Box mt="var(--space-24)">
        <ContextualHelp
          title={t('dialog.jump_to.contextual_title')}
          name="about_events"
          links={[
            {
              label: t('docs.docs'),
              url: JUMP_TO_DOCS_URL,
              icon: <BookIcon size={16} />,
            },
          ]}
        >
          {t('dialog.jump_to.contextual_message')}
        </ContextualHelp>
      </Box>
    </ToolkitWrapper>
  );
};

export default ToolkitJumpTo;
