import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { languageAndFlagByCountryCode } from '@/util/languageCodes';

import { FlagEmoji } from '../TileAIAgent/FlagEmoji';

import styles from './QuickLanguageOption.module.scss';

type QuickLanguageOptionProps = {
  language: string;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const QuickLanguageOption = ({
  language,
  handleClick,
  isActive = false,
  ...rest
}: QuickLanguageOptionProps) => {
  const capitalizedShortLanguageCode = language.split('-')[0].toUpperCase();

  return (
    <Typography
      className={cn(styles.button, {
        [styles.active]: isActive,
      })}
      variant="body-medium"
      color="var(--color-foreground)"
      component="button"
      onClick={handleClick}
      {...rest}
    >
      <FlagEmoji flag={languageAndFlagByCountryCode[language]?.flag} />
      {capitalizedShortLanguageCode}
    </Typography>
  );
};
