import { BoxIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import IfIcon from '@/components/atoms/Icons/IfIcon';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import { Rule } from '@/modules/rules/model';
import { selectAccountSlug } from '@/redux/session/selectors';

import styles from './Versions.module.scss';
type Props = {
  rule: Rule & {
    desk_name: string;
  };
};

export const RuleLink = ({ rule }: Props) => {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const link = `/${slug}/environments/${rule?.desk_id}/rules/${rule?.rule_id}`;
  const isRuleInactive = rule?.status === 'inactive';

  return (
    <>
      <Link to={link} className={styles.link}>
        <BoxIcon size={16} color="var(--color-foreground-muted)" />{' '}
        {`${rule.desk_name}`} / <IfIcon color="var(--color-foreground-muted)" />{' '}
        {`${rule?.name}`}
      </Link>
      {isRuleInactive && (
        <span className={styles.badge}>
          <StatusBadge
            withIcon={false}
            variant={'neutral'}
            label={t('rules.inactive')}
          />
        </span>
      )}
    </>
  );
};
