import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import { useAccount } from '@/hooks/useAccount';
import { usePreferences } from '@/hooks/usePreferences';
import { submitWithTrimming } from '@/util/util';

import styles from './HumanAgents.module.scss';

type Form = {
  hours: number;
  minutes: number;
};

export const formSchema = yup.object().shape({
  hours: yup
    .number()
    .integer()
    .min(0)
    .max(4) // Base max value
    .test(
      'max-hours-conditional',
      'Hours cannot exceed 3 if minutes are not 0',
      function (value) {
        const { minutes } = this.parent; // Access the minutes field
        if (minutes > 0 && value > 3) {
          return false; // Fail validation
        }
        return true; // Pass validation
      }
    ),
  minutes: yup.number().integer().min(0).max(59),
});

export const HumanAgents = () => {
  const { t } = useTranslation();
  const {
    accountPreferences,
    updateAccountPreferences,
    updateAccountPreferencesStatus: updateStatus,
  } = usePreferences();
  const { account } = useAccount();
  const away_threshold = (accountPreferences?.away_threshold as number) ?? 20;
  const formMethods = useForm<Form>({
    mode: 'onChange',
    resolver: yupResolver(formSchema) as Resolver<Form>,
  });

  const {
    register,
    trigger,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = formMethods;

  const onSubmit = useCallback(
    ({ hours, minutes }: Form) => {
      const away_threshold = hours * 60 + minutes;
      updateAccountPreferences({
        away_threshold,
      });
    },
    [updateAccountPreferences]
  );

  useEffect(() => {
    const hours = Math.floor(away_threshold / 60);
    const minutes = away_threshold % 60;
    reset({
      hours,
      minutes,
    });
  }, [away_threshold, reset, account?.account_id]);

  const minutes = watch('minutes');

  useEffect(() => {
    trigger('hours');
  }, [minutes, trigger]);

  const hasError = errors.hours || errors.minutes;
  return (
    <FormFieldset
      title={t('account.human_agents.title')}
      subtitle={t('account.human_agents.subtitle')}
      primaryButton={{
        children: t('common.save'),
        disabled: !isDirty || !isValid,
        isLoading: updateStatus === 'pending',
      }}
      onSubmit={submitWithTrimming(formMethods, onSubmit)}
      helpText={
        hasError ? (
          <Typography variant="label-regular" color="var(--color-destructive)">
            {t('account.human_agents.error')}
          </Typography>
        ) : (
          ''
        )
      }
    >
      <Typography
        variant="label-caps-large"
        className={styles.label}
        color="var(--color-foreground-muted)"
      >
        {t('account.human_agents.set_after').toLowerCase()}
      </Typography>

      <div className={styles.container}>
        <div
          className={cn(styles.boxContainer, {
            [styles.hasError]: errors.hours,
          })}
        >
          <Input
            register={register('hours', {
              setValueAs: (v) => (v === '' ? 0 : parseInt(v, 10)),
            })}
            error={!!errors.hours?.message}
            placeholder="0"
            size="small"
            type="number"
          />
          <Typography
            className={styles.label}
            color="var(--color-foreground-muted)"
          >
            {t('common.hours').toLowerCase()}
          </Typography>
        </div>
        <div
          className={cn(styles.boxContainer, {
            [styles.hasError]: errors.minutes,
          })}
        >
          <Input
            register={register('minutes', {
              setValueAs: (v) => (v === '' ? 0 : parseInt(v, 10)),
            })}
            error={!!errors.minutes?.message}
            placeholder="0"
            size="small"
            type="number"
          />
          <Typography
            className={styles.label}
            color="var(--color-foreground-muted)"
          >
            {t('common.minutes').toLowerCase()}
          </Typography>
        </div>
      </div>
    </FormFieldset>
  );
};
