import Tooltip from '@mui/material/Tooltip';
import { ExternalLinkIcon, Trash2Icon } from 'lucide-react';

import { DialogTooltipProps } from '@/models/dialogTooltip';

import { CollapseButton } from './CollapseButton';
import { ConvertButton } from './ConvertButton';
import { TooltipButton } from './TooltipButton';

import styles from './DialogTooltip.module.scss';

const DialogTooltip = ({
  children,
  onDelete = null,
  onNavigate = null,
  onTriggerConvert = null,
  onCollapse = null,
  isCollapsed = null,
  show = true,
  ...props
}: DialogTooltipProps) => {
  const { type } = props;

  const title = (
    <div className={styles.dialogTooltip} data-testid="dialog-tooltip">
      {type === 'condition' && onCollapse && (
        <CollapseButton isCollapsed={isCollapsed} onCollapse={onCollapse} />
      )}
      {onDelete && (
        <TooltipButton title="Delete" onClick={onDelete}>
          <Trash2Icon size={16} color="var(--color-background)" />
        </TooltipButton>
      )}
      {(type === 'intent' || type === 'event') && (
        <>
          {onNavigate && (
            <TooltipButton title="Navigate" onClick={onNavigate}>
              <ExternalLinkIcon size={16} color="var(--color-background)" />
            </TooltipButton>
          )}
          {onTriggerConvert && (
            <ConvertButton type={type} onTriggerConvert={onTriggerConvert} />
          )}
        </>
      )}
    </div>
  );

  if (!show) {
    return children;
  }
  return (
    <Tooltip
      title={title}
      placement="top"
      slotProps={{
        tooltip: {
          sx: { backgroundColor: 'transparent', padding: '0px', zIndex: 1 },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default DialogTooltip;
