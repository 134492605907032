import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { setIframeView } from '@/redux/user/actions';
import { selectUser } from '@/redux/user/selectors';

const hgs_blue = '#009EF7';
const hgs_hover_blue = '#098fdb';
const globalVar = {
  '--text-default-blue': hgs_blue,
  '--color-accent': hgs_blue,
  '--color-informational': hgs_blue,
  '--text-hover-blue': hgs_hover_blue,
  '--iframe-nav-tooltip': '#cfeafc',
  '--iframe-nav-tooltip-text': '#019ef7',
};
const useIframeStyles = () => {
  const [URLSearchParams] = useSearchParams();
  const iframe = URLSearchParams.get('iframe') === 'true';
  const source = useSelector(selectUser)?.metadata?.source;
  const dispatch = useDispatch();
  useEffect(() => {
    if (iframe || source === 'iframe') {
      dispatch(setIframeView(true));

      // Iterates each css var name in order to re assign its value with the HGS ones.
      Object.keys(globalVar).forEach((key) => {
        document.documentElement.style.setProperty(key, globalVar[key]);
      });
    }
  }, [dispatch, iframe, source]);
};

export default useIframeStyles;
