import Tooltip from '@mui/material/Tooltip/Tooltip';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import Pie from '@/components/organisms/Analytics/Pie/Pie';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { capitalizeFirstLetter } from '@/util/util';

import useMeaningfulSessions from '../../../hooks/useMeaningfulSessions';

import styles from './MeaningfulSession.module.scss';

const MeaningfulSession = () => {
  const { t } = useTranslation();
  const { error, isLoading, pie, list, onRefetch } = useMeaningfulSessions();
  const title = t('analytics.meaningful');
  const { ai_agents } = useFeatureFlag();

  const isFieldsetEmpty = !error && isEmpty(list) && !isLoading;

  return (
    <AnalyticsFieldset
      title={title}
      fullWidth
      error={error}
      isEmpty={isFieldsetEmpty}
      hasFooter={false}
      onReload={onRefetch}
      isLoading={isLoading}
      tooltip={t('analytics.meaningful_tooltip')}
      data={pie}
    >
      <div className={styles.container}>
        {pie && !isEmpty(list) && (
          <>
            <div className={styles.pie}>
              <Pie data={pie} />
            </div>
            <ul className={styles.list}>
              <li>
                <p>
                  {t('analytics.total_conversations')} :{' '}
                  <Tooltip
                    arrow
                    title={
                      list.total_sessions.value !== list.total_sessions.label
                        ? list.total_sessions.value
                        : ''
                    }
                  >
                    <span>{list.total_sessions.label}</span>
                  </Tooltip>
                </p>
              </li>
              <li>
                <p>
                  {t('analytics.non_meaningful')} :{' '}
                  <Tooltip
                    arrow
                    title={
                      list.nonmeaningful_sessions.label !==
                      list.nonmeaningful_sessions.value
                        ? list.nonmeaningful_sessions.value
                        : ''
                    }
                  >
                    <span>{list.nonmeaningful_sessions.label}</span>
                  </Tooltip>
                </p>
              </li>

              <li className={styles.hasSubcategories}>
                <p>
                  {t('analytics.meaningful')} :{' '}
                  <Tooltip
                    arrow
                    title={
                      list.meaningful_sessions.label !==
                      list.meaningful_sessions.value
                        ? list.meaningful_sessions.value
                        : ''
                    }
                  >
                    <span>{list.meaningful_sessions.label}</span>
                  </Tooltip>
                </p>

                <ul className={styles.sublist}>
                  <li className="item">
                    <span>
                      {capitalizeFirstLetter(
                        t(
                          `analytics.${ai_agents ? 'ai_agent_conv' : 'brain_conv'}`
                        )
                      )}
                      :{' '}
                    </span>
                    <Tooltip
                      arrow
                      title={
                        list.meaningful_brain_sessions.value !==
                        list.meaningful_brain_sessions.label
                          ? list.meaningful_brain_sessions.value
                          : ''
                      }
                    >
                      <span>{list.meaningful_brain_sessions.label}</span>
                    </Tooltip>
                  </li>
                  <li className="item">
                    <span>
                      {' '}
                      {capitalizeFirstLetter(t('analytics.collection_conv'))}
                      :{' '}
                    </span>
                    <Tooltip
                      arrow
                      title={
                        list.meaningful_collection_sessions.value !==
                        list.meaningful_collection_sessions.label
                          ? list.meaningful_collection_sessions.value
                          : ''
                      }
                    >
                      <span>{list.meaningful_collection_sessions.label}</span>
                    </Tooltip>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        )}
      </div>
    </AnalyticsFieldset>
  );
};

export default MeaningfulSession;
