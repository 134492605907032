import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { ClockIcon } from 'lucide-react';
import { Trans } from 'react-i18next';

import styles from './Events.module.scss';
type Props = {
  minutes: number;
};

/**
 * Renders a reminder message with inactivity duration
 */
export const Reminder = memo(({ minutes }: Props) => {
  return (
    <div className={styles.reminder}>
      <ClockIcon size={14} color="var(--color-foreground-muted)" />
      <Typography variant="label-regular" color="var(--color-foreground-muted)">
        <Trans
          i18nKey="ai_agents.reminder.human_chat"
          values={{ 0: minutes }}
        />
      </Typography>
    </div>
  );
});

Reminder.displayName = 'Reminder';
