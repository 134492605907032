import { memo, useCallback, useId, useState } from 'react';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { EllipsisVerticalIcon, EyeIcon, EyeOffIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { HoverMenu } from '@/components/atoms/HoverMenu/HoverMenu';
import { ViewType } from '@/models/views';
import { IconComponent } from '@/modules/humanChat/utils';
import { isKeyEnter } from '@/util/util';

import { Item, StyledBadge } from './components';
import { MenuItem as MenuItemProps } from '../../../models';

import styles from './NavItem.module.scss';

export const NavItem = memo(
  ({
    icon: Icon,
    text,
    onClick,
    sum,
    hasNotification,
    selected = false,
    disabled = false,
    options,
    type,
  }: MenuItemProps & {
    selected: boolean;
    hasNotification: boolean;
    disabled: boolean;
    type?: ViewType;
  }) => {
    const id = useId();
    const { t } = useTranslation();
    const [showEye, setShowEye] = useState(false);
    const handleOnKeyDown = useCallback(
      (event) => {
        if (onClick && isKeyEnter(event)) {
          onClick();
        }
      },
      [onClick]
    );

    const handleOnHover = useCallback(() => {
      setShowEye((p) => !p);
    }, []);

    const IconWithType = () => {
      if (!showEye) {
        return <IconComponent icon={Icon} />;
      } else if (type === ViewType.PRIVATE) {
        return <EyeOffIcon size={16} color="var(--color-foreground-muted)" />;
      } else {
        return <EyeIcon size={16} color="var(--color-foreground-muted)" />;
      }
    };

    const listItemButton = (
      <Item
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnHover}
        customDisabled={disabled}
        className={cn(styles.item, {
          [styles.active]: selected,
        })}
        secondaryAction={
          options?.length > 0 ? (
            <HoverMenu
              placement={'right-start'}
              title={
                <List disablePadding>
                  {options.map((option) => (
                    <MenuItem key={option.label} onClick={option.fn}>
                      <Typography color="var(--color-foreground)">
                        {option.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </List>
              }
            >
              <button className={styles.moreButton}>
                <EllipsisVerticalIcon
                  size={20}
                  color={'var(--color-foreground)'}
                />
              </button>
            </HoverMenu>
          ) : (
            <Typography
              color="var(--color-foreground-muted)"
              variant="label-regular"
            >
              {sum}
            </Typography>
          )
        }
      >
        <ListItemButton
          disableRipple
          onClick={onClick}
          onKeyDown={handleOnKeyDown}
          disableGutters
          disabled={disabled}
        >
          {Icon && (
            <StyledBadge
              overlap="circular"
              variant="dot"
              invisible={!hasNotification}
            >
              <ListItemIcon>
                {type ? <IconWithType /> : <IconComponent icon={Icon} />}
              </ListItemIcon>
            </StyledBadge>
          )}

          <ListItemText
            id={id}
            primary={text}
            primaryTypographyProps={{
              variant: 'body-regular',
              color: 'var(--color-foreground)',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          />
        </ListItemButton>
      </Item>
    );

    return disabled ? (
      <Tooltip
        enterDelay={500}
        enterNextDelay={500}
        title={t('conversations.no_permission')}
      >
        <div>{listItemButton}</div>
      </Tooltip>
    ) : (
      listItemButton
    );
  }
);

NavItem.displayName = 'NavItem';
