import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Statuses } from '@/models/api';

import styles from './StatusDot.module.scss';

interface Props {
  status: Statuses;
  showLabel?: boolean;
  title?: string;
  animate?: boolean;
}
const StatusDot = ({
  status,
  showLabel = false,
  title = '',
  animate = false,
}: Props) => {
  const { t } = useTranslation();
  const statusTitle = title || t(`status.${status}`);
  return (
    <span
      className={cn(styles.status, styles[status], {
        [styles.animate]: animate,
      })}
      title={title || statusTitle}
    >
      <div className={styles.circle} />

      {showLabel && statusTitle}
    </span>
  );
};

export default StatusDot;
