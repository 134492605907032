import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Location, useNavigate } from 'react-router';

import { selectAccountSlug } from '@/redux/session/selectors';

import { TabConfig } from '../types';

/**
 * Custom hook for managing tab navigation in the developer tools section
 * Handles tab state, URL synchronization, and navigation
 */
export const useTabNavigation = (tabs: TabConfig[], location: Location) => {
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);

  // Create a mapping between paths and indices
  const pathToIndexMap = useMemo(
    () => Object.fromEntries(tabs.map((tab, index) => [tab.path, index])),
    [tabs]
  );

  // Create a mapping between indices and paths
  const indexToPathMap = useMemo(
    () => Object.fromEntries(tabs.map((tab, index) => [index, tab.path])),
    [tabs]
  );

  /**
   * Determines the initial tab value based on the current URL path
   */
  const getInitialTabValue = useCallback((): number => {
    // Extract the last part of the path
    const pathParts = location.pathname.split('/');
    const lastPart = pathParts[pathParts.length - 1];

    // Return the corresponding index or default to 0 (apikeys)
    return pathToIndexMap[lastPart] ?? 0;
  }, [location.pathname, pathToIndexMap]);

  // Local state
  const [tabValue, setTabValue] = useState(getInitialTabValue());

  /**
   * Handles tab change and updates the URL
   */
  const handleTabChange = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      const path = indexToPathMap[newValue] || 'apikeys';
      const newPath = `/${slug}/devtools/${path}`;

      navigate(newPath);
      setTabValue(newValue);
    },
    [indexToPathMap, navigate, slug]
  );

  /**
   * Synchronizes the tab value with the URL path
   * Updates the tab value when the URL changes
   */
  useEffect(() => {
    const currentTabValue = getInitialTabValue();
    if (currentTabValue !== tabValue) {
      setTabValue(currentTabValue);
    }
  }, [getInitialTabValue, tabValue]);

  return { tabValue, handleTabChange };
};
