// Custom icon

import { Icon } from '@/models/icon';

const IfIcon = ({
  size = 16,
  color = 'var(--color-foreground-muted)',
  ...props
}: Icon) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 9H9.08333M9.08333 9H11M9.08333 9V15M9.08333 15H7M9.08333 15H11M17.5 9H14V12M14 12V15M14 12H16.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IfIcon;
