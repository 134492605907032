import { memo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { CircleCheckIcon, ClockIcon, InfoIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import styles from './MessageAreaPrologue.module.scss';

const MessageAreaPrologue = ({
  conversation,
  reopenConversation,
  closeConversation,
}) => {
  const { t } = useTranslation();
  const isExpired = !conversation?.state?.active;
  const isResolved = conversation?.status === 'closed';
  const isSpam = conversation?.status === 'spam';
  const isTrash = conversation?.status === 'trash';
  const isExpiredAndResolved = isExpired && isResolved;
  const isOpen = conversation?.status === 'open';

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );

  const getConditionalContent = () => {
    if (isExpiredAndResolved) {
      return (
        <>
          <CircleCheckIcon size={16} color="var(--color-foreground)" />
          <Typography className={styles.text} color="var(--color-foreground)">
            {t('conversation.resolved')}
          </Typography>
        </>
      );
    } else if (isExpired && !isResolved && !isSpam && !isTrash) {
      return (
        <>
          <ClockIcon size={16} color="var(--color-foreground)" />
          <Typography className={styles.text} color="var(--color-foreground)">
            {t('conversation.expired')}
          </Typography>
          {canWrite && (
            <Button
              variant="tertiary"
              onClick={closeConversation}
              className={styles.buttons}
            >
              {t('conversation.resolve')}
            </Button>
          )}
        </>
      );
    } else if (isResolved || isSpam || isTrash) {
      return (
        <>
          <CircleCheckIcon size={16} color="var(--color-foreground)" />
          <Typography className={styles.text} color="var(--color-foreground)">
            {t('conversation.resolved')}
          </Typography>
          {canWrite && !isSpam && !isTrash && (
            <Button
              variant="tertiary"
              onClick={reopenConversation}
              className={styles.buttons}
            >
              {t('conversation.reopen')}
            </Button>
          )}
        </>
      );
    } else if (isOpen) {
      return (
        <>
          <InfoIcon size={16} color="var(--color-foreground)" />
          <Typography className={styles.text} color="var(--color-foreground)">
            {t('conversation.open')}
          </Typography>
          {canWrite && (
            <Button
              variant="tertiary"
              onClick={closeConversation}
              className={styles.buttons}
            >
              {t('conversation.resolve')}
            </Button>
          )}
        </>
      );
    }
  };

  if (!conversation) {
    return null;
  }

  return (
    <>
      <div
        className={cn(styles.container, {
          [styles.readOnly]: !canWrite,
          [styles.expired]: isExpired && !isExpiredAndResolved,
          [styles.resolved]:
            isResolved || isExpiredAndResolved || isSpam || isTrash,
          [styles.open]: isOpen,
        })}
      >
        {getConditionalContent()}
      </div>
    </>
  );
};

export default memo(MessageAreaPrologue);
