import { Trash2Icon } from 'lucide-react';
import { FieldErrorsImpl, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Input from '@/components/atoms/Input/Input';

import styles from './RegexCard.module.scss';

type RegexOption = {
  slug: string;
  trigger_message: string;
  opening_message: string;
};

interface RegexCardProps<FormType> {
  option: RegexOption;
  index: number;
  onDelete: () => void;
  onBlur?: () => void;
  register: UseFormRegister<FormType>;
  errors: FieldErrorsImpl<{ options: RegexOption }>;
  readOnly?: boolean;
}

const RegexCard = <FormType,>({
  option,
  index,
  errors,
  onBlur,
  onDelete,
  register,
  readOnly = false,
}: RegexCardProps<FormType>) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.levelOne}>
          <Input
            error={!!errors.options?.[index]?.slug}
            label={t('integrations.start.regexp')}
            defaultValue={option.slug}
            name={`options.${index}.slug` as const}
            placeholder={t('integrations.start.type')}
            onBlur={onBlur}
            readOnly={readOnly}
            register={register(`options.${index}.slug` as Path<FormType>, {
              required: t('common.required_field'),
              maxLength: {
                value: 512,
                message: t('validation.less_than', { 0: 512 }),
              },
            })}
            size="large"
            errorMessage={
              (errors?.options && errors?.options[index]?.slug?.message) || null
            }
          />
        </div>
        <div className={styles.levelTwo}>
          <div>
            <Input
              error={!!errors.options?.[index]?.opening_message}
              label={t('integrations.start.teaser')}
              defaultValue={option.opening_message}
              name={`options.${index}.opening_message` as const}
              placeholder={t('integrations.start.type_message')}
              onBlur={onBlur}
              readOnly={readOnly}
              register={register(
                `options.${index}.opening_message` as Path<FormType>,
                {
                  required: t('validation.required'),
                  maxLength: {
                    value: 64,
                    message: t('validation.at_least', { 0: 64 }),
                  },
                }
              )}
              errorMessage={
                (errors?.options &&
                  errors?.options[index]?.opening_message?.message) ||
                null
              }
            />
          </div>
          <div>
            <Input
              error={!!errors.options?.[index]?.trigger_message}
              label={t('integrations.start.auto')}
              defaultValue={option.trigger_message}
              name={`options.${index}.trigger_message` as const}
              placeholder={t('integrations.start.type_message')}
              onBlur={onBlur}
              readOnly={readOnly}
              register={register(
                `options.${index}.trigger_message` as Path<FormType>,
                {
                  required: t('validation.required'),
                  maxLength: {
                    value: 64,
                    message: t('validation.at_least', { 0: 64 }),
                  },
                }
              )}
              errorMessage={
                (errors?.options &&
                  errors?.options[index]?.trigger_message?.message) ||
                null
              }
            />
          </div>
        </div>
        <span className={styles.delete}>
          <IconButton
            ariaLabel={t('integrations.start.remove_regexp')}
            onClick={onDelete}
          >
            <Trash2Icon color="var(--color-destructive)" size={16} />
          </IconButton>
        </span>
      </div>
    </>
  );
};

export default RegexCard;
