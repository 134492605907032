import { useCallback, useEffect, useRef, useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Typography from '@mui/material/Typography';
import { CalendarIcon } from 'lucide-react';
import moment from 'moment';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import { useAccount } from '@/hooks/useAccount';
import { calculateDaysBetweenDates, delay } from '@/util/util';

import DateFilterItem from './DateFilterItem/DateFilterItem';
import {
  useDateFilter,
  periodOptions as options,
} from '../../../hooks/useDateFilter';

import styles from './DateFilter.module.scss';

import './DatePicker.scss';

interface Props {
  dateOpen: boolean;
  setDateOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  disabled: boolean;
}

const getDisabledStatus = (days: number, max: number) => {
  //Custom range
  if (!days) {
    return false;
  }
  if (days + 1 <= max) {
    return false;
  }
  return true;
};

const DateFilter = ({ dateOpen, setDateOpen, disabled }: Props) => {
  const { t } = useTranslation();
  const [selectedPeriod, setSelectedPeriod] = useState<number>(undefined);
  const {
    startDate,
    endDate,
    setShowCalendar,
    handleCalendarSave,
    handleCalendarClear,
    handlePeriodClick,
    setCalendarState,
    showCalendar,
    calendarState,
  } = useDateFilter(setDateOpen);

  const { account } = useAccount();
  const logsLimit = account?.max_log_retention;

  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate'>(
    'startDate'
  );
  const calendarRef = useRef(null);
  const menuRef = useRef(null);

  useClickAway(menuRef, () => {
    setDateOpen(false);
    setShowCalendar(false);
  });

  useClickAway(calendarRef, async () => {
    await delay(200);
    setShowCalendar(false);
  });

  const getDaysBetweenDates = useCallback(calculateDaysBetweenDates, []);

  const getDaysFromToday = useCallback((date: string) => {
    const start = moment(date);
    const end = moment();
    const duration = end.diff(start, 'days');
    return duration;
  }, []);

  useEffect(() => {
    const endDateFromToday = getDaysFromToday(endDate);
    const index = options.findIndex((period) => {
      return getDaysBetweenDates(startDate, endDate) === period.days;
    });

    if (endDateFromToday === 0) {
      if (index === -1 || (index === 1 && startDate !== endDate)) {
        setSelectedPeriod(options.length - 1);
        return;
      }

      setSelectedPeriod(index);
      return;
    }

    if (endDateFromToday === 1 && startDate === endDate) {
      setSelectedPeriod(1);
      return;
    }

    setSelectedPeriod(options.length - 1);
  }, [endDate, getDaysBetweenDates, getDaysFromToday, startDate]);

  const isOutsideRange = useCallback(
    (day) =>
      day.isAfter(moment().endOf('day')) ||
      day.isBefore(moment().subtract(logsLimit, 'days')),
    [logsLimit]
  );

  const getInitialMonth = useCallback(() => moment(startDate), [startDate]);

  return (
    <div className={styles.date_container} ref={menuRef}>
      <Button
        disabled={disabled}
        size="medium"
        onClick={() => {
          setDateOpen((prev) => !prev);
        }}
      >
        <CalendarIcon size={16} color="var(--color-background)" />
        <Typography
          component="p"
          variant="label-semi-bold"
          className={styles.button_text}
        >
          {moment(endDate).diff(startDate, 'days') === 0 ? (
            <>{moment(startDate).format('MMM DD, YYYY')}</>
          ) : (
            <>
              {moment(startDate).format('MMM DD, YYYY')} -{' '}
              {moment(endDate).format('MMM DD, YYYY')}
            </>
          )}
        </Typography>
      </Button>
      {dateOpen && (
        <div className={styles.menu}>
          {options.map((period, index) => {
            return (
              <div
                className={styles.menu_item}
                key={`period-${t(period.title_key)}`}
              >
                <DateFilterItem
                  selected={selectedPeriod === index}
                  isLastSelected={index === options.length - 1 && showCalendar}
                  onClick={handlePeriodClick}
                  item={period}
                  isLast={index === options.length - 1}
                  disabled={getDisabledStatus(period.days, logsLimit)}
                />
                {index === options.length - 1 && showCalendar && (
                  <div ref={calendarRef} className={styles.calendar}>
                    <DayPickerRangeController
                      minimumNights={0}
                      onFocusChange={(input: FocusedInputShape | null) => {
                        if (!input) {
                          setFocusedInput('startDate');
                        } else {
                          setFocusedInput(input);
                        }
                      }}
                      focusedInput={focusedInput}
                      startDate={
                        calendarState?.startDate
                          ? moment(calendarState?.startDate)
                          : null
                      }
                      endDate={
                        calendarState?.endDate
                          ? moment(calendarState?.endDate)
                          : null
                      }
                      isOutsideRange={isOutsideRange}
                      minDate={moment().subtract(logsLimit, 'days')}
                      maxDate={moment().add(1, 'month')}
                      onDatesChange={(period) => {
                        setCalendarState(period);
                      }}
                      initialVisibleMonth={getInitialMonth}
                      numberOfMonths={2}
                      calendarInfoPosition="top"
                      hideKeyboardShortcutsPanel
                      enableOutsideDays
                    />
                    <div className={styles.footer}>
                      <Button
                        size="small"
                        variant="tertiary"
                        onClick={handleCalendarClear}
                      >
                        {t('common.clear')}
                      </Button>
                      <Button size="small" onClick={handleCalendarSave}>
                        {t('common.save')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DateFilter;
