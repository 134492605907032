import {
  Fragment,
  useCallback,
  useId,
  useState,
  useMemo,
  useEffect,
} from 'react';

import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { TFunction } from 'i18next';
import {
  ArrowDownWideNarrowIcon,
  FilterIcon,
  PanelLeftIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { RowInfiniteLoader } from '@/components/organisms/InfiniteLoader/RowInfiniteLoader';
import { useAccount } from '@/hooks/useAccount';
import useHotkeys from '@/hooks/useHotkeys';
import { usePleaseStay } from '@/hooks/usePleaseStay';
import { LastMessage } from '@/models/chat';
import MessagePreview from '@/modules/humanChat/components/MessagePreview/MessagePreview';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';
import { selectIframeView } from '@/redux/user/selectors';
import { decodeHTMLEntities } from '@/util/util';

import { DynamicLabelButton } from './DynamicLabelButton';
import MessagePreviewSkeleton from './MessagePreviewSkeleton/MessagePreviewSkeleton';
import { useConversations } from '../../hooks/useConversations';
import { useMessageList } from '../../hooks/useMessageList';
import { setSideBarOpen, setView } from '../../redux/actions';
import { selectSideBarOpen, selectView } from '../../redux/selectors';

import styles from './MessageList.module.scss';

const formatLastMessage = (
  message: LastMessage,
  t: TFunction,
  userName?: string
) => {
  if (message?.author_type === 'brain' && message?.body?.type !== 'text') {
    return (
      <>
        {t('conversation.sent')} {message?.body?.type}{' '}
      </>
    );
  }
  if (
    message?.author_type === 'visitor' &&
    message.body?.attachments?.length > 0
  ) {
    return (
      <>
        {userName} {t('conversation.sent').toLowerCase()}{' '}
        {message?.body?.attachments[0].type}
      </>
    );
  }

  if (
    message?.author_type === 'agent' &&
    message.body?.attachments?.length > 0
  ) {
    return (
      <>
        {userName} {t('conversation.sent').toLowerCase()}{' '}
        {message?.body?.attachments[0].type}
      </>
    );
  }

  const formatedText = decodeHTMLEntities(message?.text);
  return formatedText;
};

const HOTKEY = '[';

export const MessageList = () => {
  const id = useId();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);
  const showLeftSidebar = useSelector(selectSideBarOpen);
  const setShowLeftSidebar = useCallback(() => {
    dispatch(setSideBarOpen(!showLeftSidebar));
  }, [dispatch, showLeftSidebar]);

  const { title } = useMessageList();

  const {
    selectedStatus,
    selectedAgent,
    selectedDepartment,
    selectedBrain,
    selectedSort,
    selectedView,
  } = useSelector(selectView);

  const isUsingView = selectedView !== '';

  const { slug } = useAccount();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuType, setMenuType] = useState<'sort' | 'status'>('sort');
  const [currentLabels, setCurrentLabels] = useState({
    sort: 'common.newest',
    status: 'common.open',
  });

  const hotkeys = {
    [HOTKEY]: setShowLeftSidebar,
  };

  useHotkeys({ hotkeys, active: true });

  useEffect(() => {
    if (selectedStatus === 'open' || selectedStatus === 'closed') {
      setCurrentLabels((prevLabels) => ({
        ...prevLabels,
        status: `common.${selectedStatus}`,
      }));
    }
    if (selectedSort) {
      setCurrentLabels((prevLabels) => ({
        ...prevLabels,
        sort: `common.${selectedSort}`,
      }));
    }
  }, [selectedSort, selectedStatus]);

  const isSpamOrTrash = selectedStatus === 'spam' || selectedStatus === 'trash';

  const {
    conversations,
    hasNextPage,
    listStatus,
    fetchNextPage,
    unassignedCount,
  } = useConversations({
    deskId,
    sessionId,
    selectedAgent,
    selectedDepartment,
    selectedBrain,
    selectedSort,
    selectedStatus,
    selectedView,
  });
  const open = Boolean(anchorEl);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    type: 'sort' | 'status'
  ) => {
    setMenuType(type);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  usePleaseStay(!!unassignedCount);

  const handleMessageClick = useCallback(
    (id: string) => {
      navigate(`/${slug}/chats/${deskId}/conversations/${id}`);
    },
    [deskId, navigate, slug]
  );

  const isLoading = listStatus === 'pending';

  const createAction = useCallback(
    (key: string, value: string) => () => {
      handleClose();
      setCurrentLabels((prevLabels) => ({ ...prevLabels, [key]: value }));

      if (key === 'status') {
        dispatch(
          setView({
            selectedStatus: value === 'common.open' ? 'open' : 'closed',
          })
        );
      } else {
        dispatch(
          setView({
            selectedSort: value === 'common.newest' ? 'newest' : 'oldest',
          })
        );
      }
    },
    [dispatch, handleClose]
  );

  const menuItemsMap = useMemo(
    () => ({
      sort: [
        {
          label: 'common.newest',
          action: createAction('sort', 'common.newest'),
        },
        {
          label: 'common.oldest',
          action: createAction('sort', 'common.oldest'),
        },
      ],
      status: [
        { label: 'common.open', action: createAction('status', 'common.open') },
        {
          label: 'common.closed',
          action: createAction('status', 'common.closed'),
        },
      ],
    }),
    [createAction]
  );
  const isIframeView = useSelector(selectIframeView);
  const hasNoConversations = conversations?.length === 0 && !isLoading;

  const itemCount = conversations?.length + (hasNextPage ? 1 : 0);

  const isItemLoaded = useCallback(
    (index: number) => {
      if (hasNextPage) {
        return index < conversations?.length;
      }
      return true;
    },
    [conversations?.length, hasNextPage]
  );

  const loadMoreItems = useCallback(() => {
    if (!isLoading && hasNextPage) {
      fetchNextPage();
    }
  }, [isLoading, hasNextPage, fetchNextPage]);

  return (
    <div className={cn(styles.sidebar, { [styles.open]: showLeftSidebar })}>
      <div className={cn(styles.header, { [styles.open]: showLeftSidebar })}>
        {!isIframeView && (
          <IconButton
            ariaLabel={t('conversation.toggle_sidebar', { 0: HOTKEY })}
            onClick={setShowLeftSidebar}
            tooltip={t('conversation.toggle_sidebar', { 0: HOTKEY })}
          >
            <PanelLeftIcon color="var(--color-foreground-muted)" />
          </IconButton>
        )}

        <Typography
          color="var(--color-foreground)"
          variant="subheading-semi-bold"
          className={styles.headerTitle}
        >
          {title as string}
        </Typography>
      </div>
      <div className={styles.subheader}>
        <DynamicLabelButton
          icon={
            <ArrowDownWideNarrowIcon
              size={16}
              color="var(--color-foreground-muted)"
            />
          }
          currentLabel={t(currentLabels['sort'])}
          type="sort"
          handleMenuClick={handleMenuClick}
        />

        {!isSpamOrTrash && !isUsingView && (
          <DynamicLabelButton
            icon={
              <FilterIcon size={16} color="var(--color-foreground-muted)" />
            }
            currentLabel={t(currentLabels['status'])}
            type="status"
            handleMenuClick={handleMenuClick}
          />
        )}

        <CustomMenu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuItemsMap[menuType].map(({ label, action }) => (
            <MenuItem key={label} onClick={action}>
              {t(label)}
            </MenuItem>
          ))}
        </CustomMenu>
      </div>
      <div className={styles.messagesContainer}>
        <div
          className={cn(styles.innerContainer, {
            [styles.hasConversations]: conversations?.length > 0,
            [styles.empty]: hasNoConversations,
          })}
        >
          {hasNoConversations && (
            <Typography>{t('conversation.no_conversations')}</Typography>
          )}
          {isLoading &&
            Array(5)
              .fill('')
              // eslint-disable-next-line react/no-array-index-key
              .map((_, i) => <MessagePreviewSkeleton key={i} />)}
          <RowInfiniteLoader
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            isItemLoaded={isItemLoaded}
            getItemSize={() => 86}
            className={styles.list}
          >
            {(index, style) => {
              if (conversations[index]) {
                const {
                  session_id,
                  last_message_at,
                  last_message,
                  agent_unread_count,
                  channel,
                  context,
                  assignee_agent_id,
                  assignee_brain_id,
                  status,
                } = conversations[index];
                const visitorName =
                  context?.user?.display_name ?? t('common.anonymous');
                return (
                  <MessagePreview
                    key={session_id}
                    timestamp={last_message_at}
                    userName={visitorName}
                    message={formatLastMessage(last_message, t, visitorName)}
                    unreadCount={agent_unread_count}
                    channel={channel}
                    onClick={handleMessageClick}
                    sessionId={session_id}
                    isSelected={sessionId === session_id}
                    assigneeAgentId={assignee_agent_id}
                    assigneeBrainId={assignee_brain_id}
                    status={status}
                    style={style}
                  />
                );
              }
              if (hasNextPage) {
                return <MessagePreviewSkeleton style={style} />;
              }
              return null;
            }}
          </RowInfiniteLoader>
        </div>
      </div>
    </div>
  );
};
