import Skeleton from '@mui/material/Skeleton';

import PlainFieldset from '@/components/atoms/Fieldset/templates/PlainFieldset';

export const PluginsSkeleton = () => {
  const skeletonRows = Array(5).fill(0);

  return (
    <div>
      <PlainFieldset fullWidth overflown addPB>
        <div className="w-full">
          <div className="flex justify-between items-center mb-4">
            <Skeleton
              variant="rounded"
              width={192}
              height={24}
              animation="wave"
            />
          </div>

          {/* Table header */}
          <div className="flex w-full border-b border-gray-200 dark:border-gray-700 pb-2 mb-4">
            <div className="w-1/6 px-4">
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                animation="wave"
              />
            </div>
            <div className="w-1/6 px-4">
              <Skeleton
                variant="rounded"
                width={64}
                height={20}
                animation="wave"
              />
            </div>
            <div className="w-1/6 px-4">
              <Skeleton
                variant="rounded"
                width={96}
                height={20}
                animation="wave"
              />
            </div>
            <div className="w-1/6 px-4">
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                animation="wave"
              />
            </div>
            <div className="w-1/6 px-4">
              <Skeleton
                variant="rounded"
                width={112}
                height={20}
                animation="wave"
              />
            </div>
            <div className="w-1/6 px-4">
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                animation="wave"
              />
            </div>
            <div className="w-12 px-4"></div>
          </div>

          {skeletonRows.map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className="flex w-full py-4 border-b border-gray-200 dark:border-gray-700"
            >
              <div className="w-1/6 px-4">
                <Skeleton
                  variant="rounded"
                  width={128}
                  height={20}
                  animation="wave"
                />
              </div>
              <div className="w-1/6 px-4">
                <Skeleton
                  variant="rounded"
                  width={160}
                  height={20}
                  animation="wave"
                />
              </div>
              <div className="w-1/6 px-4">
                <div className="flex space-x-2">
                  <Skeleton
                    variant="rounded"
                    width={64}
                    height={24}
                    animation="wave"
                  />
                  <Skeleton
                    variant="rounded"
                    width={64}
                    height={24}
                    animation="wave"
                  />
                </div>
              </div>
              <div className="w-1/6 px-4">
                <Skeleton
                  variant="rounded"
                  width={64}
                  height={20}
                  animation="wave"
                />
              </div>
              <div className="w-1/6 px-4">
                <div className="flex items-center space-x-2">
                  <Skeleton
                    variant="circular"
                    width={32}
                    height={32}
                    animation="wave"
                  />
                  <Skeleton
                    variant="rounded"
                    width={80}
                    height={20}
                    animation="wave"
                  />
                </div>
              </div>
              <div className="w-1/6 px-4">
                <Skeleton
                  variant="rounded"
                  width={96}
                  height={20}
                  animation="wave"
                />
              </div>
              <div className="w-12 px-4">
                <Skeleton
                  variant="rounded"
                  width={32}
                  height={32}
                  animation="wave"
                />
              </div>
            </div>
          ))}
        </div>
      </PlainFieldset>
    </div>
  );
};
