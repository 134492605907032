import { Buffer } from 'buffer';

import { MouseEvent, SyntheticEvent } from 'react';

import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import moment from 'moment-timezone';
import { UseFormReturn, Path, FieldErrors, PathValue } from 'react-hook-form';
import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';

import { callGet } from '@/api/fetcher';
import { Dialogs } from '@/models/dialog';
import { Entities, Entity } from '@/models/entity';
import { Integration } from '@/models/integration';
import { Intent, Intents } from '@/models/intent';
import { RoleType } from '@/models/member';
import { Node } from '@/models/node';
import { TableRow } from '@/models/table';
import {
  languageCodes,
  countryCodes,
  languageAndFlagByCountryCode,
} from '@/util/languageCodes';

import {
  ACCEPTED_VIDEO_FORMATS,
  ADMIN,
  BUILDER,
  CHANNELS_URL,
  CHANNELS_WS_URL,
  CHAT_AGENT,
  CHAT_MANAGER,
  MAX_ENDING_URL_CHARS,
  MAX_UNCROPED_WEBHOOK_URL_CHARS,
  THREE_DOTS_LENGTH,
  WEB_CLIENT_URL,
} from './constants';
import { TAG_LENGTH, urlPattern } from './validator';

type Prev = Dialogs | Intents | Entities;

/**
 * A no-operation function.
 */
export const noop = () => {};

/**
 * Prevents an event from propagating and performing its default action.
 *
 * @param {SyntheticEvent} evt - The synthetic event to be handled.
 */
export const preventClickThrough = (evt: SyntheticEvent) => {
  evt.preventDefault();
  evt.stopPropagation();
};

/**
 * Checks if the event's target is the same as the current target.
 *
 * @param {SyntheticEvent} event - The event to evaluate.
 * @returns {boolean} - Returns true if the target is the same as the current target.
 */
export const isEventTargetCurrentTarget = (event: SyntheticEvent) =>
  event.target === event.currentTarget;

/**
 * Fetches a URL for a carousel placeholder image.
 *
 * @param {Object} param - An object containing type and id.
 * @param {string} param.type - The type of the carousel.
 * @param {string} param.id - The unique identifier.
 * @returns {string} - The URL for the carousel placeholder image.
 */
export const getCarouselPlaceholder = ({ type = 'image', id }) =>
  `https://res.cloudinary.com/dey0ylu2x/image/upload/v1607095100/carousel/${type}${id}.png`;

/**
 * Retrieves the flag emoji for a given locale.
 *
 * @param {string} [locale] - The locale string in the format language-COUNTRY.
 * @returns {string | null} - The flag emoji if found, else null.
 */
export const getFlagByLocale = (locale?: string): string | null => {
  if (!locale) {
    return null;
  }
  const code = locale.split('-')[0].toLowerCase();
  return languageAndFlagByCountryCode[code]?.flag || null;
};

/**
 * Retrieves the language name for a given locale.
 *
 * @param {string} [locale] - The locale string in the format language-COUNTRY.
 * @returns {string | null} - The language name if found, else null.
 */
export const getLanguageByLocale = (locale?: string): string | null => {
  if (!locale) {
    return null;
  }
  const code = locale.split('-')[0].toLowerCase();
  return languageCodes[code] || null;
};

/**
 * Retrieves the country name by its code.
 *
 * @param {string} [code] - The country code.
 * @returns {string | null} - The country name if found, else null.
 */
export const getCountryByCode = (code?: string): string | null => {
  if (!code) {
    return null;
  }
  return countryCodes[code.toLowerCase()]?.name || null;
};

/**
 * Checks if the current environment is development or local.
 *
 * @returns {boolean} - Returns true if the environment is development or local.
 */
export const isDevOrLocal = () =>
  window?.location?.hostname === 'localhost' ||
  window?.location?.hostname?.includes('dev') ||
  window?.location?.hostname?.includes('local');

/**
 * Returns the web client SDK URL based on the current environment.
 * The URL is hosted on jsDelivr and the latest version is used by default.
 *
 * @returns {string} - The SDK URL.
 */
export const getWebClientSDK = () => {
  return `https://cdn.jsdelivr.net/npm/@moveo-ai/web-client@${isDevOrLocal() ? 'next' : 'latest'}/dist/web-client.min.js`;
};

/**
 * Constructs the preview widget URL with the appropriate parameters.
 * Host could be added if it's not production.
 *
 * @param {Integration} int - The integration object containing integration details.
 * @returns {string | null} - The constructed preview URL or null if the integration is missing.
 */
export const previewWidgetUrl = (int: Integration): string | null => {
  if (!int) {
    return null;
  }

  const params = new URLSearchParams({ integrationId: int.integration_id });

  // Add host in the preview URL if not in aws production where the subdomain is empty
  if (!CHANNELS_URL.includes('channels.moveo.ai')) {
    params.append('host', CHANNELS_WS_URL);
  }

  return `${WEB_CLIENT_URL}/preview?${params.toString()}`;
};

/**
 * Removes entries from an object that have empty string values.
 *
 * @param {object} data - The redux initial state.
 * @returns {object} - The input object with empty string values removed.
 */
export const removeEmptyValues = <T>(data: T): T => {
  for (const [key, value] of Object.entries(data)) {
    if (value === '') {
      delete data[key];
    }
  }
  return data;
};

/**
 * Removes keys from an object where the values are null or undefined.
 *
 * @param {Record<string, valueType>} data - The input object.
 * @returns {Record<string, valueType>} - The object without null or undefined values.
 */
export const removeNullValues = <valueType>(
  data: Record<string, valueType>
): Record<string, valueType> => {
  for (const [key, value] of Object.entries(data)) {
    if (isNil(value)) {
      delete data[key];
    }
  }
  return data;
};

/**
 * Recursively removes all keys from the given object that have empty object values.
 *
 * @param {Record<string, any>} obj - The object from which to remove keys.
 * @returns {Record<string, any>} - The modified object with empty objects removed.
 */
export function removeEmptyObjects(
  obj: Record<string, unknown>
): Record<string, unknown> {
  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      removeEmptyObjects(obj[key] as Record<string, unknown>);

      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  });
  return obj;
}

/**
 * Deletes nested variables in an object given a path array. It sets the value to null
 * and propagates the null value up to the nearest parent with other non-null values, or to the root.
 *Example:
 * `p1.p2.p3` to ['p1','p2','p3'] of a null property

 * @param {Object} object - The object to modify.
 * @param {string[]} itemPath - An array representing the path to the nested variables.
 * @returns {Object} - The updated object.
 */
export const deleteNestedVariables = (object, itemPath: string[]) => {
  const deleted = itemPath.splice(-1)[0];
  const current = itemPath.join('.');
  if (current === '') {
    return { ...object, [deleted]: null };
  }
  const currObj = get(object, current);
  let levelIsNull = true;
  Object.values(currObj).forEach((value) => {
    if (value !== null) {
      levelIsNull = false;
    }
  });
  if (levelIsNull) {
    set(object, current, null);
    return deleteNestedVariables(object, itemPath);
  }
  return object;
};

/**
 * Middleware to trim form string inputs and execute submit or invalid validation routines.
 *
 * @param {UseFormReturn} formMethods - Methods to manipulate form state.
 * @param {(data: unknown) => void} onSubmit - Callback function to execute on valid submit.
 * @returns {Function} - Handler function to be used with form submission.
 */
export const submitWithTrimming = <FormType>(
  formMethods: UseFormReturn<FormType>,
  onSubmit: (data: unknown) => void
) => {
  const submitMiddleware = async (data) => {
    for (const [key] of Object.entries(data)) {
      if (typeof data[key] === 'string') {
        formMethods.setValue(
          key as Path<FormType>,
          data[key].trim() as PathValue<FormType, Path<FormType>>
        );
      }
    }
    const form = formMethods.getValues();
    onSubmit(form);
    formMethods.reset(form);
  };

  const onInvalidSubmit = async (errors: FieldErrors<FormType>) => {
    for (const [key] of Object.entries(errors)) {
      if (typeof errors[key]?.ref?.value === 'string') {
        formMethods.setValue(
          key as Path<FormType>,
          errors[key].ref.value.trim()
        );
      }
    }
    formMethods.handleSubmit(submitMiddleware)();
  };
  return formMethods.handleSubmit(submitMiddleware, onInvalidSubmit);
};

/**
 * Retrieves other items from the same collection except the one specified by queryId.
 *
 * @param {string} category - The category in which to perform the search.
 * @param {string} categoryId - The identifier for items in the category.
 * @param {string} queryId - The ID of the item to exclude from the results.
 * @param {Prev} prev - The previous state from which to search.
 * @returns {Array<object>} - An array of items in the same collection, excluding the specified item.
 */
export const sameCollection = (
  category: string,
  categoryId: string,
  queryId: string,
  prev: Prev
) => {
  const deletedCategoryIndex = prev[`${category}`].findIndex(
    (x) => x[`${categoryId}`] === queryId
  );
  const collectionOfDeleted =
    prev[`${category}`][deletedCategoryIndex].collection;
  return prev[`${category}`]
    .filter((x) => x['collection'] === collectionOfDeleted)
    .filter((x) => x[`${categoryId}`] !== queryId);
};

/**
 * Validates a Base64 encoded string by decoding and re-encoding it to check for consistency.
 *
 * @param {string} str - The string to validate.
 * @returns {boolean} - Returns true if the string is valid Base64, false otherwise.
 */
export const validateBase64 = (str: string) => {
  const decoded = Buffer.from(str, 'base64').toString('utf8');
  const encoded = Buffer.from(decoded).toString('base64');
  return str === encoded;
};

/**
 * Decodes a Base64 encoded string to UTF-8.
 *
 * @param {string} key - The string to decode.
 * @returns {string | undefined} - The decoded UTF-8 string, or undefined if the key is invalid.
 */
export const decodeBase64 = (key: string) => {
  if (typeof key === 'undefined' || key === null) {
    return;
  }
  return Buffer.from(key, 'base64').toString('utf8');
};

/**
 * Encodes a string or Buffer to Base64.
 *
 * @param {string | Buffer} key - The data to encode.
 * @returns {string | undefined} - The Base64 encoded string, or undefined if the key is invalid.
 */
export const encodeBase64 = (key: string | Buffer) => {
  if (typeof key === 'undefined' || key === null) {
    return;
  }
  return Buffer.from(key).toString('base64');
};

/**
 * Encodes data as URL-safe Base64.
 *
 * @param {Buffer | string} unencoded - The data to encode.
 * @returns {string} - The URL-safe Base64 encoded string.
 */
export const urlSafeBase64Encode = (unencoded: Buffer | string): string => {
  const encoded = encodeBase64(unencoded);
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

/**
 * Decodes a URL-safe Base64 encoded string.
 *
 * @param {string} encoded - The Base64 encoded data.
 * @returns {string} - The decoded string.
 */
export const urlSafeBase64Decode = (encoded: string): string => {
  let encodedReplaced = encoded.replace('-', '+').replace('_', '/');
  while (encodedReplaced.length % 4) {
    encodedReplaced += '=';
  }
  return decodeBase64(encodedReplaced);
};

/**
 * Constructs a GraphQL set representation from a list of strings.
 *
 * @param {string[]} list - The list of strings.
 * @returns {string | null} - The constructed GraphQL set string or null if the list is empty.
 */
export const getGraphQLSet = (list: string[]) => {
  if (list?.length === 0 || !list) {
    return null;
  }
  const joined = list
    .filter((x) => typeof x === 'string' && x.length > 0)
    .join();

  if (joined.length === 0) {
    return null;
  }
  return `{${joined}}`;
};

/**
 * Delays execution for a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to delay.
 * @returns {Promise<void>} - A Promise that resolves after the delay.
 */
export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Determines the media type from a URL based on its extension.
 *
 * @param {string} url - The URL to check.
 * @returns {string} - The type of media file: 'image', 'video', 'audio', or 'file'.
 */
export const selectType = (url: string) => {
  const extension = url.split('.').pop();
  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
    return 'image';
  }
  if (extension === 'mp4') {
    return 'video';
  }
  if (
    extension === 'mp3' ||
    extension === 'wav' ||
    extension === 'wma' ||
    extension === 'mpeg'
  ) {
    return 'audio';
  }
  return 'file';
};

/**
 * Parses a filter object and returns its corresponding GraphQL representation.
 *
 * @param {Object} filter - The filter object.
 * @returns {any} - The parsed filter value in GraphQL format.
 */
export const parseFilter = (filter) => {
  const value = filter[filter.type];
  // compicated structures defined here
  if (
    filter.type === 'deskIds' ||
    filter.type === 'brainIds' ||
    filter.type === 'agentIds'
  ) {
    return getGraphQLSet(value.map((val) => val.value));
  } else if (typeof value === 'object') {
    // filter value type of array
    return getGraphQLSet(value);
  } else if (filter.type === 'maxConfidence') {
    return value / 100;
  } else {
    return value;
  }
};

interface KeyboardEvent<T = Element> extends SyntheticEvent<T> {
  code: string;
  altKey: boolean;
  charCode: number;
  ctrlKey: boolean;
  getModifierState(key: string): boolean;
  key: string;
  keyCode: number;
  locale: string;
  location: number;
  metaKey: boolean;
  repeat: boolean;
  shiftKey: boolean;
  which: number;
}

/**
 * Checks if a key event corresponds to the Enter key.
 *
 * @param {KeyboardEvent} e - The keyboard event.
 * @returns {boolean} - Returns true if the Enter key is pressed.
 */
export const isKeyEnter = (e: KeyboardEvent) => e.key === 'Enter';

/**
 * Checks if a key event corresponds to the Tab key.
 *
 * @param {KeyboardEvent} e - The keyboard event.
 * @returns {boolean} - Returns true if the Tab key is pressed.
 */
export const isKeyTab = (e: KeyboardEvent) => e.key === 'Tab';

/**
 * Converts timezones to a desired format for Business Hours and Rules.
 *
 * @param {string[]} timezones - Array of timezones e.g. ["Europe/Budapest"].
 * @returns {Array<Object>} - An array of objects with formatted timezone labels and values.
 */
export function timezoneMaker(timezones: string[]) {
  if (timezones.length === 0) {
    return [];
  }

  return timezones.map((timezone) => {
    const split = timezone.split('/');
    const lastItem = split[split.length - 1];
    const final = lastItem.split(/[-_ ]/).join('_').toLowerCase();
    return {
      label: `(GMT${moment.tz(timezone).format('Z')}) ${i18n.t(final, { ns: 'timezones' })}`,
      value: timezone,
    };
  });
}

/**
 * Adds a dollar sign to a string if it doesn't already start with one.
 * For example: messi -> $messi
 */
export const checkForDollar = (str: string) =>
  str.startsWith('$') ? str : `$${str}`;

/**
 * Formats a number into string representation in K, M, or plain integer.
 *
 * @param {number} value - The number to format.
 * @param {boolean} shouldRound - Indicates if rounding is required.
 * @returns {string | null} - The formatted number or null if invalid input.
 */
export const numberFormatter = (value: number, shouldRound = true) => {
  if (value < 0 || typeof value !== 'number') {
    return null;
  }

  if (shouldRound && Math.abs(value) > 999 && Math.abs(value) <= 999999) {
    const number = Math.abs(value) / 1000;
    return `${parseFloat(number.toFixed(1))}K`;
  }
  if (shouldRound && Math.abs(value) > 999999) {
    const number = Math.abs(value) / 1000000;

    return `${parseFloat(number.toFixed(1))}M`;
  }
  return Math.sign(value) * Math.abs(value);
};

/**
 * Formats a duration, given in minutes, to a human-readable string.
 *
 * @param {Function} t - Translation function.
 * @param {number | string} [countMinutesRaw] - The duration in minutes.
 * @param {string} [defaultValue] - The default string if the input is invalid.
 * @returns {string | null} - The formatted duration or default value.
 */
export const durationFormat = (
  t,
  countMinutesRaw?: number | string,
  defaultValue?: string
) => {
  if (!countMinutesRaw) {
    return defaultValue ?? null;
  }
  let minutes: number;

  if (typeof countMinutesRaw === 'string') {
    minutes = parseFloat(countMinutesRaw);
  } else {
    minutes = countMinutesRaw;
  }
  if (minutes < 0) {
    return null;
  }
  const countHours = Math.floor(minutes / 60);
  const countMinutes = Math.floor(minutes % 60);
  const countSeconds = parseFloat(
    ((minutes - Math.floor(minutes)) * 60).toFixed(2)
  );

  const formatedTime: string[] = [];
  if (countHours > 0) {
    formatedTime.push(`${countHours + t('time.hours')}`);
  }
  if (countMinutes > 0) {
    formatedTime.push(`${countMinutes + t('time.minutes')}`);
  }
  if (countSeconds >= 1) {
    formatedTime.push(`${~~countSeconds + t('time.seconds')}`);
  } else if (formatedTime.length == 0) {
    formatedTime.push(`${countSeconds + t('time.seconds')}`);
  }

  return formatedTime.join(' ');
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} s - The string to capitalize.
 * @returns {string} - The string with the first letter capitalized.
 */
export const capitalizeFirstLetter = (s: string) =>
  (s && s[0].toUpperCase() + s.slice(1)) || '';

/**
 * Removes from a dialog entities and intents that do not exist in the brain.
 *
 * @param {{ nodes: Node[] }} newDialog - The dialog to be cleared.
 * @param {Intent[]} intents - The brain's intents.
 * @param {Entity[]} entities - The brain's entities.
 */
export const clearNewDialog = (
  newDialog: { nodes: Node[] },
  intents: Intent[],
  entities: Entity[]
) => {
  for (const node of newDialog.nodes) {
    if (node.type === 'intent' && node.intent) {
      if (!find(intents, { intent: node.intent })) {
        node.intent = null;
      }
    }
    if (node.conditions) {
      for (const condition of node.conditions) {
        if (condition.rules) {
          for (const rule of condition.rules) {
            if (
              rule?.name &&
              rule.name[0] === '@' &&
              !find(entities, { entity: rule?.name?.replace('@', '') })
            ) {
              rule.name = '';
            }
          }
        }
      }
    }
    if (node.requisites) {
      for (const requisite of node.requisites) {
        if (
          requisite?.check_for &&
          requisite.check_for[0] === '@' &&
          !find(entities, {
            entity: requisite?.check_for?.replace('@', ''),
          })
        ) {
          requisite.check_for = '';
        }
      }
    }
  }
};

/**
 * Selects between singular and plural forms of a word.
 *
 * @param {string} singular - The singular form.
 * @param {string} plural - The plural form.
 * @param {boolean} isSingular - Condition to choose the form.
 * @returns {string} - The selected form based on the condition.
 */
export const selectSingularPlural = (
  singular: string,
  plural: string,
  isSingular: boolean
): string => (isSingular ? singular : plural);

/**
 * Checks if a variable is a promise (has a 'then' method).
 *
 * @param {any} promise - The variable to check.
 * @returns {boolean} - True if the variable is a promise, otherwise false.
 */
export const isPromise = (promise) =>
  !!promise && typeof promise.then === 'function';

/**
 * Returns the URL for webhooks based on the current environment.
 *
 * @returns {string} - The webhooks URL.
 */
export const getWebviewsUrl = () =>
  isDevOrLocal()
    ? 'https://webhooks.dev.moveo.ai'
    : 'https://webhooks.moveo.ai';

const VARIABLES_FOR_SLOW_QUERIES = [
  'minNumUserMessages',
  'channels',
  'integrationIds',
  'brainVersions',
  'agentIds',
];

/**
 * Returns true if the analytics query to use should be based on the old/slow model.
 * New/Fast queries are based on the presence of a timescale database.
 *
 * @param {Object} variables - Variables used in the analytics query.
 * @param {Object} [options] - Additional options for analytics.
 * @returns {boolean} - True if old query model is used, otherwise false.
 */
export const shouldUseSlowQueries = (
  variables,
  options?: { is_brain_effectiveness?: boolean }
): boolean => {
  // Based on a environment variable from the server
  // Disable fast queries for environments where the backend does not have
  // a timescale instance
  const disableFastQueries = window.ANALYTICS_DISABLE_FAST_QUERIES;
  if (disableFastQueries === 'true') {
    return true;
  }
  // Check the variables used in the query
  const usedVariables = Object.keys(variables);
  if (VARIABLES_FOR_SLOW_QUERIES.some((sv) => usedVariables.includes(sv))) {
    return true;
  }

  if (options?.is_brain_effectiveness && 'isTest' in variables) {
    return true;
  }

  return false;
};

/**
 * Calculates the median of an array of numbers, ensuring a minimum return value.
 *
 * @param {number[]} array - The array of numbers.
 * @param {number} [minimum=0] - The minimum value to return.
 * @returns {number} - The calculated median or minimum value.
 */
export const getMedian = (array: number[], minimum = 0) => {
  let value = minimum;

  if (!array || !array.length) {
    return value;
  }
  const sorted = array.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    value = (sorted[middle - 1] + sorted[middle]) / 2;
  } else {
    value = sorted[middle];
  }
  return value < minimum ? minimum : value;
};

/**
 * Checks if a string matches the URL pattern.
 *
 * @param {string} url - The URL to validate.
 * @returns {boolean} - True if valid URL, otherwise false.
 */
export const isUrl = (url) => urlPattern.test(url);

/**
 * Returns a new array of unique objects from an input array of objects, based on a specified key.
 *
 * @param {Array} arr - The input array of objects.
 * @param {string} key - The key to use for uniqueness comparison.
 * @returns {Array} - A new array of unique objects.
 */
export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

/**
 * Removes all the occurrences of the '$' symbol from a string.
 *
 * @param {string} input - The input string.
 * @returns {string} - The string without '$' symbols.
 */
export const removeDollarSign = (input: string) =>
  (input || '').replace(/\$/g, '');

/**
 * Removes all the occurrences of the '@' symbol from a string.
 *
 * @param {string} input - The input string.
 * @returns {string} - The string without '@' symbols.
 */
export const removeAtSign = (input: string) => (input || '').replace(/@/g, '');

/**
 * Adds a dollar sign to the beginning of a string if it doesn't already have one.
 *
 * @param {string} input - The string to process.
 * @returns {string} - The processed string with a dollar sign.
 */
export const addDollarSign = (input: string) => {
  if (input?.startsWith('$') || input === '') {
    return input;
  } else {
    return '$' + input;
  }
};

/**
 * Wraps words that start with a dollar sign with curly braces.
 *
 * @param {string} input - The input string to be processed.
 * @returns {string} - The input string with all words starting with a dollar sign wrapped with curly braces.
 */
export const wrapDollarSignWithCurlyBraces = (input: string): string => {
  const regex = /\$[\w\d]+((\.|-)[\w\d]+)?/g; // Matches words starting with $ and optional .word part

  if (regex.test(input)) {
    const wrapped = input.replace(regex, '{{$&}}'); // Wrap all matched dollar signs with curly braces
    return wrapped;
  } else {
    return input; // If there is no dollar sign, return the original string
  }
};

/**
 * Reverts the wrapping of words that start with a dollar sign with curly braces.
 *
 * @param {string} input - The input string to be processed.
 * @returns {string} - The input string with all words wrapped with curly braces and a dollar sign replaced with the dollar sign only.
 */
export const revertCurlyBraceWrappedDollarSigns = (input: string): string => {
  const regex = /{\$[\w\d]+}/g; // The regular expression to match words wrapped with curly braces and a dollar sign

  if (regex.test(input)) {
    const reverted = input.replace(/{{(\$\w+)}}/g, '$1'); // Keep only the dollar sign word and remove the curly braces
    return reverted;
  } else {
    return input; // If there is no word wrapped with curly braces and a dollar sign, return the original string
  }
};

/**
 * Determines if a URL is a video based on its file extension against accepted formats.
 *
 * @param {string} url - The URL to check.
 * @returns {boolean} - Returns true if the URL is a video.
 */
export function isVideo(url: string) {
  if (!url) {
    return false;
  }

  const extensionPattern = /\.([0-9a-z]+)(?=[?#]|$)/i;
  const extension = url.match(extensionPattern);

  if (!extension) {
    return false;
  }

  for (const format in ACCEPTED_VIDEO_FORMATS) {
    if (ACCEPTED_VIDEO_FORMATS[format].includes(extension[0].toLowerCase())) {
      return true;
    }
  }

  return false;
}

/**
 * Checks if the event is a left-click event.
 *
 * @param {MouseEvent} event - The event object to be checked.
 * @returns {boolean} - Returns `true` if the event is a left-click event (button value is 0).
 */
export const isLeftClickEvent = (event: MouseEvent) => {
  return event?.button === 0;
};

const ids = ['node_id', 'action_id', 'condition_id'];

/**
 * Updates the IDs of an object or an array of objects.
 * It recursively traverses and replaces any ID with a new UUID.
 *
 * @param {Object|Array} obj - The object or array of objects to update.
 * @returns {Object|Array} - The updated object or array of objects with new UUIDs.
 */
export function updateIds(obj) {
  const clonedObj = cloneDeep(obj);
  function traverse(o) {
    for (const i in o) {
      if (typeof o[i] === 'object' && o[i] !== null) {
        if (ids.includes(i)) {
          o[i] = uuidv4();
        }
        traverse(o[i]);
      } else if (Array.isArray(o[i])) {
        o[i].forEach((item) => {
          traverse(item);
        });
      } else {
        if (ids.includes(i)) {
          o[i] = uuidv4();
        }
      }
    }
  }

  traverse(clonedObj);
  return clonedObj;
}

/**
 * Regular expression for validating common symbols.
 */
export const commonSymbolsRegex =
  /^[a-zA-Z0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/;

/**
 * Renders a URL cropped to fit within specified character limits while preserving its end.
 *
 * @param {string} [url] - The URL to crop.
 * @param {number} [maxVisible=MAX_UNCROPED_WEBHOOK_URL_CHARS] - Maximum visible characters.
 * @param {number} [maxEnding=MAX_ENDING_URL_CHARS] - Maximum characters to keep at the end.
 * @returns {string} - The cropped URL.
 */
export const renderCroppedURL = (
  url?: string,
  maxVisible = MAX_UNCROPED_WEBHOOK_URL_CHARS,
  maxEnding = MAX_ENDING_URL_CHARS
) => {
  if (!url) {
    return '';
  }

  if (url.length <= maxVisible) {
    return url;
  }

  let formattedUrl = url;
  if (url[url.length - 1] === '/') {
    formattedUrl = url.slice(0, -1);
  }

  const urlParts = formattedUrl.split('/');
  const finalUrlPart = urlParts[urlParts.length - 1];

  const endingURLCharacters: number = Math.min(finalUrlPart.length, maxEnding);
  const startingURLCharacters =
    maxVisible - THREE_DOTS_LENGTH - endingURLCharacters;

  const croppedURL = `${formattedUrl.slice(
    0,
    startingURLCharacters
  )}.../${formattedUrl.slice(-endingURLCharacters)}`;

  return croppedURL;
};

/**
 * Calculates the number of days between two dates.
 *
 * @param {string} startDate - The start date.
 * @param {string} [endDate] - The end date. Defaults to the current date if not provided.
 * @returns {number} - The duration in days between the two dates.
 */
export const calculateDaysBetweenDates = (
  startDate: string,
  endDate?: string
) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = end.diff(start, 'days');
  return duration;
};

/**
 * Converts bytes to a human-readable format (bytes, KB, MB, GB, TB).
 *
 * @param {number} bytes - The size in bytes.
 * @returns {string} - The size in a more readable format or '-' if invalid.
 */
export const bytesToSize = (bytes: number) => {
  if (!bytes || bytes < 0) {
    return '-';
  }
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

/**
 * Finds and returns the first object in an array where one of the object's property values matches the given value.
 *
 * @param {object[]} array - The array of objects to search.
 * @param {string|number} valueToFind - The value (string or number) to match against the object's properties.
 * @returns {object|null} - The first object that contains a property with the given value, or null if not found.
 *
 * @example
 * const data = [{a: 1, b: 2}, {a: 3, b: 4}, {a: 5, b: 2}];
 * findObjectByValue(data, 4); // Returns: {a: 3, b: 4}
 * findObjectByValue(data, 6); // Returns: null
 */
export const findObjectByValue = (
  array: Array<object>,
  valueToFind: string | number
) => {
  // Iterate through the array of objects
  for (let i = 0; i < array.length; i++) {
    // Get the current object from the array
    const obj = array[i];

    // Iterate through each property in the object
    for (const key in obj) {
      // If the property value matches the input string, return the object
      if (obj[key] === valueToFind) {
        return obj;
      }
    }
  }

  // If no match is found, return null
  return null;
};

/**
 * Returns an array of names from the provided items, excluding the specified name.
 *
 * @param {Array<unknown>} items - The array of items to extract names from.
 * @param {string} excludeName - The name to exclude from the returned array.
 * @param {string} [nameKey='name'] - The key to use to extract the name from each item. Defaults to 'name'.
 * @returns {Array<string>} - An array of names, excluding the specified name.
 *
 * @example
 * const data = [{name: 'John', age: 30}, {name: 'Jane', age: 25}, {name: 'Joe', age: 35}];
 * getRestrictedNames(data, 'Jane'); // Returns: ['John', 'Joe']
 * getRestrictedNames(data, 'John', 'name'); // Returns: ['Jane', 'Joe']
 */
export const getRestrictedNames = (
  items: Array<unknown>,
  excludeName: string,
  nameKey = 'name'
) => {
  if (!items || !excludeName) {
    return [];
  }

  return (
    items
      ?.map((item) => item[nameKey])
      .filter((name) => name.toLowerCase() !== excludeName.toLowerCase()) ?? []
  );
};

/**
 * Generates the next name for a given item in a list of items with a common base name.
 *
 * @param {Array<{ name: string }>} items - The list of items to search.
 * @param {string} baseName - The base name to generate the next sequence number for.
 * @returns {string} - The next available name in the sequence.
 */
export const generateNextName = (
  items: { name: string }[] = [],
  baseName: string
): string => {
  const regex = new RegExp(`${baseName} (\\d+)$`, 'i');

  let maxNumber = -1;
  let baseNameExists = false;

  for (const item of items) {
    if (item.name.toLowerCase() === baseName.toLowerCase()) {
      baseNameExists = true;
    }
    const match = item.name.match(regex);
    if (match) {
      const currentNumber = parseInt(match[1], 10);
      maxNumber = Math.max(maxNumber, currentNumber);
    }
  }

  if (maxNumber === -1 && !baseNameExists) {
    return baseName;
  }

  if (maxNumber === -1 && baseNameExists) {
    return `${baseName} 1`; // if only baseName exists
  }

  return `${baseName} ${maxNumber + 1}`;
};

/**
 * Converts a name to a tag by slugifying and truncating it to a specified length.
 *
 * @param {string} str - The name to convert.
 * @returns {string | null} - The converted tag or null if the input is invalid.
 */
export const convertNameToTag = (str: string) => {
  if (!str) {
    return null;
  }
  return slugify(str, {
    replacement: '_', // Replace spaces with hyphens
    remove: /[^\w\s-]/g, // Remove all non-alphanumeric and non-hyphen characters
    lower: true, // Convert to lowercase
    strict: true, // Enforce strict mode
  }).slice(0, TAG_LENGTH);
};

/**
 * Smoothly scrolls the page to an element indicated by its ID.
 *
 * @param {string} elementId - The ID of the element to scroll into view.
 * @param {ScrollIntoViewOptions} [options={}] - Optional scrolling options to override default behavior.
 */
export function scrollToElementById(
  elementId: string,
  options: ScrollIntoViewOptions = {}
) {
  const element = document.getElementById(elementId);

  if (!element) {
    return;
  }

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'end',
    ...options,
  });
}

/**
 * Validates if an email address is suitable for user creation, checking whether it's personal or disposable.
 *
 * @param {string} email - The email address to validate.
 * @returns {Promise<boolean>} - Resolves to true if the email is valid and not disposable, otherwise false.
 */
export const isValidEmail = async (email: string): Promise<boolean> => {
  return callGet(
    `/www/api/auth/validate-email/?email=${encodeURIComponent(email)}`
  ).then((data) => data.valid);
};

/**
 * Decodes HTML entities in a string into their corresponding characters.
 *
 * This function creates a temporary DOM element (textarea), assigns the
 * provided text to its `innerHTML`, and then retrieves the decoded value
 * from the element's `value` property. This effectively converts HTML
 * entity codes (e.g., `&amp;`, `&#39;`) to their respective characters (e.g., `&`, `'`).
 *
 * @param {string} text - The string containing HTML entities that need to be decoded.
 * @returns {string} - The decoded string with HTML entities converted to their corresponding characters.
 *
 * @example
 * "I&#39;m transferring ..." => "I'm transferring ..."
 */
export const decodeHTMLEntities = (text: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

/**
 * Splits an array into chunks of specified size.
 *
 * @template T
 * @param {T[]} items - The array to split.
 * @param {number} size - The size of each chunk.
 * @returns {T[][]} - The array split into chunks.
 */
export const splitArray = <T>(items: T[], size: number): T[][] => {
  return items.reduce((resultArray: T[][], item, index) => {
    const chunkIndex = Math.floor(index / size);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

/**
 * Resolves a path by conditionally transforming it based on the ai_agents flag.
 *
 * @param {string} path - The original path to transform.
 * @param {boolean} ai_agents - Flag to determine if path should be transformed for AI agents.
 * @returns {string} - The transformed path if ai_agents is true, otherwise the original path.
 */
export const resolveBrainsPath = (path: string, ai_agents: boolean): string => {
  if (ai_agents) {
    return (
      path
        // Replace brains with ai-agents
        .replace('/brains', '/ai-agents')
        // Add /conversation to intents, dialogs, webhooks and entities
        .replace(
          /(\/intents|\/dialogs|\/webhooks|\/entities)/,
          '/conversation$1'
        )
        // Replace /logs and /versions with /review/logs and /review/versions
        .replace(/\/(logs|versions)/, '/review/$1')
        // Replace /collections with /knowledge
        .replace('/collections', '/knowledge')
    );
  }

  return path;
};

/**
 * Returns an object indicating the user's roles based on their role IDs and account role.
 *
 * @param {string[]} role_ids - Array of role IDs associated with the user.
 * @param {string} accountRole - The account role type (e.g., owner, admin).
 * @returns {Object} - An object indicating whether the user is a builder, chat manager, admin, or chat agent.
 */
export const getRoles = (role_ids: string[], accountRole: string) => {
  const isChatAgent = role_ids?.includes(CHAT_AGENT);
  const isBuilder = role_ids?.includes(BUILDER);
  const isChatManager = role_ids?.includes(CHAT_MANAGER);
  const isAdminOrOwner =
    role_ids?.includes(ADMIN) || accountRole === RoleType.OWNER;
  return {
    isBuilder,
    isChatManager,
    isAdminOrOwner,
    isChatAgent,
  };
};

/**
 * Sorts two table rows based on the values in a specified column.
 *
 * @param {TableRow} row1 - The first row to compare.
 * @param {TableRow} row2 - The second row to compare.
 * @param {string} columnName - The name of the column to use for comparison.
 * @returns {number} - Returns 1 if row1's value is greater, -1 otherwise.
 */
export const sortColumns = (
  row1: TableRow,
  row2: TableRow,
  columnName: string
) => (row1.values[columnName] > row2.values[columnName] ? 1 : -1);
