import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { popModal } from '@/redux/modals/actions';

import styles from './ModalUnsavedChanges.module.scss';

type ModalUnsavedChangesProps = {
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  onClose: () => void;
};

const ModalUnsavedChanges = ({
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onClose,
}: ModalUnsavedChangesProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(popModal());
  };

  const handlePrimaryButtonClick = () => {
    onPrimaryButtonClick();
    closeModal();
  };

  const handleSecondaryButtonClick = () => {
    onSecondaryButtonClick();
    closeModal();
  };

  const handleClose = () => {
    onClose();
    closeModal();
  };

  return (
    <div>
      <Modal
        open
        onClose={handleClose}
        aria-labelledby="unsaved-changes-title"
        aria-describedby="unsaved-changes-description"
      >
        <Box className={styles.modalContainer}>
          {/* Header with Close Icon */}
          <Box className={styles.headerContainer}>
            <Typography
              id="unsaved-changes-title"
              component="h6"
              variant="h2-semi-bold"
              color="var(--color-foreground)"
            >
              {t('ai_agents.unsaved_changes.title')}
            </Typography>

            <IconButton className={styles.closeButton} onClick={handleClose}>
              <XIcon size={16} color="var(--color-foreground)" />
            </IconButton>
          </Box>

          {/* Modal Content */}
          <Typography
            id="unsaved-changes-description"
            component="p"
            className={styles.modalContent}
          >
            {t('ai_agents.unsaved_changes.body')}
          </Typography>

          {/* Action Buttons */}
          <Box className={styles.actionButtons}>
            <Button onClick={handleSecondaryButtonClick} variant="tertiary">
              {t('common.discard')}
            </Button>
            <Button onClick={handlePrimaryButtonClick}>
              {t('common.go_back')}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalUnsavedChanges;
