// Custom icon

import { Icon } from '@/models/icon';

export const CircleChevronsRightIcon = ({
  size = 16,
  color = 'var(--color-foreground-muted)',
  ...rest
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.75 15.75 16.5 12l-3.75-3.75m-5.25 7.5L11.25 12 7.5 8.25M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
      />
    </svg>
  );
};
