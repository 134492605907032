import { Fragment, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import uniqBy from 'lodash/uniqBy';
import { CircleDotIcon, CrosshairIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import {
  AmendPayload,
  CollectionMessage,
  Entity,
  NodeType,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage, LogSessionContentRow } from '@/modules/analytics/models';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { selectTryItNode } from '@/modules/TryIt/redux/actions';
import {
  selectSelectedMessageIndex,
  selectSessionNodes,
  selectTryItSessionId,
} from '@/modules/TryIt/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { resolveBrainsPath } from '@/util/util';

import { BrainSteps } from './BrainSteps';
import { PossibleMatchedIntents } from '../../../Messages/PossibleMatchedIntents/PossibleMatchedIntents';
import { StandaloneWrapper } from '../StandaloneWrapper';

import styles from '../DetailsSection.module.scss';

type BrainDetailsSectionProps = {
  content: LogSessionContentRow;
};

export type BrainDetailsSectionContentProps = {
  slug: string;
  nodes: NodeType[] | Partial<NodeType>[];
  index: number;
  handleGoToNode: (id: string) => void;
  closeModal: () => void;
  session_id?: string;
  standAloneQuestion?: string;
  activeBrainId?: string;
  messages: LogMessage[];
};

export const BrainDetailsSectionContent = ({
  slug,
  nodes,
  index,
  handleGoToNode,
  closeModal,
  session_id,
  activeBrainId,
  messages,
}: BrainDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const tryItSessionId = useSelector(selectTryItSessionId);

  let userMessage = (nodes[index]?.messages[0] as CollectionMessage)?.text;

  // We override the user message if it's a global reminder
  if (!userMessage) {
    userMessage = t('try_it.reminder_triggered');
  }

  const planner_intents = nodes[index]?.planner_intents;
  const isPlannerIntentOfftopic =
    planner_intents?.includes('offtopics') && planner_intents?.length === 1;

  const plannerIntent = !isPlannerIntentOfftopic && planner_intents?.[0];
  const possibleMatchedIntents =
    nodes[index]?.intents ||
    (messages?.[index]?.message as UserMessage)?.intents;

  const entities = uniqBy(
    (nodes[index]?.messages[0] as UserMessage)?.entities,
    'entity'
  ) as unknown as Entity[];

  const isRequisite =
    nodes[index]?.steps && nodes[index]?.steps?.[0]?.type !== 4;

  const intent =
    (nodes[index]?.messages?.[0] as UserMessage)?.intents?.[0] ||
    nodes[index]?.intents?.[0];

  const triggeredNode = nodes[index]?.nodes_stack?.find(
    (node) => node.node_id === nodes[index]?.steps?.[0]?.node_id
  );
  const brainSteps = !isRequisite
    ? nodes[index]?.steps?.slice(1)
    : nodes[index]?.steps;

  const showAddTrainingPhrase = intent?.confidence !== 1;

  const { brain } = useBrains(activeBrainId);
  const { ai_agents } = useFeatureFlag();
  const amendPayload: AmendPayload = {
    request_id:
      nodes[index].request_id || nodes[index].nodes_stack[0]?.request_id,

    task: 'standalone',
    session_id: session_id || tryItSessionId,
    language: brain?.language,
  };

  const isAmended = !!nodes[index]?.corrections?.find(
    (c) => c.task === 'standalone'
  );

  const isIntentAmended = !!nodes[index]?.corrections?.find(
    (c) =>
      c.task === 'planner' &&
      possibleMatchedIntents?.some((i) => i.intent === c.correction)
  );

  return (
    <Stack data-testid="newDebugLogs">
      <StandaloneWrapper
        userMessage={userMessage}
        amendPayload={amendPayload}
        isAmended={isAmended}
      />
      {entities.length > 0 && (
        <div className={styles.step}>
          <div className={styles.title}>
            <CrosshairIcon size={16} color="var(--color-foreground-muted)" />
            <Typography
              variant="body-regular"
              color="var(--color-foreground-muted)"
              component="div"
            >
              {t('try_it.entity', {
                count: entities?.length,
              })}{' '}
              {entities?.map((entity, index) => (
                <Fragment key={entity.value}>
                  <NavLink
                    to={resolveBrainsPath(
                      `/${slug}/brains/${activeBrainId}/entities/${entity.entity}`,
                      ai_agents
                    )}
                    className={styles.link}
                    onClick={closeModal}
                  >
                    {entity.entity}
                  </NavLink>
                  {index < entities.length - 1 && ', '}
                </Fragment>
              ))}{' '}
              {t('try_it.matched')}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </div>
      )}
      {!isRequisite && (
        <>
          <div className={styles.step}>
            <PossibleMatchedIntents
              key={amendPayload.request_id}
              brainId={activeBrainId}
              isPlannerIntentOfftopic={isPlannerIntentOfftopic}
              intentName={intent?.intent}
              plannerIntent={plannerIntent}
              possibleMatchedIntents={possibleMatchedIntents}
              showAddTrainingPhrase={showAddTrainingPhrase}
              userMessage={userMessage}
              amendPayload={amendPayload}
              isAmended={isIntentAmended}
            />
          </div>
          {brainSteps?.length > 0 && (
            <div className={styles.step}>
              <div className={styles.title}>
                <CircleDotIcon
                  size={16}
                  color="var(--color-foreground-muted)"
                />
                <Typography
                  variant="body-regular"
                  color="var(--color-foreground-muted)"
                  component="div"
                >
                  {t('common.node')}{' '}
                  <Typography
                    className={styles.link}
                    variant="body-regular"
                    color="var(--color-informational)"
                    onClick={() => handleGoToNode(triggeredNode.node_id)}
                    role="link"
                  >
                    {triggeredNode?.name}
                  </Typography>{' '}
                  {t('try_it.triggered')}
                </Typography>
              </div>
              <div className={styles.details}></div>
            </div>
          )}
        </>
      )}

      <BrainSteps steps={brainSteps} />
    </Stack>
  );
};

export const BrainDetailsSection = ({ content }: BrainDetailsSectionProps) => {
  const navigate = useNavigate();
  const brainId = useSelector(selectBrainId);
  const index = useSelector(selectSelectedMessageIndex);
  const nodes = useSelector(selectSessionNodes);

  const { session_id, brain_id: logBrainId } = content || {};

  const messages = content?.messages.filter(
    (msg) =>
      msg.event === 'message:brain_send' ||
      msg.event === 'message:broadcast_send'
  );

  const activeBrainId = brainId || logBrainId;
  const { dialogs } = useDialogs(activeBrainId);
  const { slug } = useAccount();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();

  const closeModal = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const handleGoToNode = useCallback(
    (id: string) => {
      const dialog = dialogs?.find((d) =>
        d.nodes.some((n) => n.node_id === id)
      );

      // If the dialog is deleted, we show an error message
      if (!dialog?.dialog_id) {
        dispatch(addTemporalToast('error', t('errors.deleted_dialog')));
        return;
      }

      // We get error here
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${activeBrainId}/dialogs/${dialog.dialog_id}`,
          ai_agents
        )
      );

      closeModal();
      dispatch(selectTryItNode(id));
    },
    [activeBrainId, closeModal, dialogs, dispatch, navigate, slug, t, ai_agents]
  );

  return (
    <BrainDetailsSectionContent
      {...{
        slug,
        nodes,
        index,
        messages,
        handleGoToNode,
        closeModal,
        session_id,
        activeBrainId,
      }}
    />
  );
};
