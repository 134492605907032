import { useEffect, useRef } from 'react';

/**
 * Hook to manage focus for the first input field
 */
const useFocusFirstInput = (headerCount: number, bodyCount: number) => {
  const firstInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus();
    }
  }, [headerCount, bodyCount]);

  const setFirstInputRef = (
    element: HTMLInputElement | null,
    isFirst: boolean
  ) => {
    if (isFirst) {
      firstInputRef.current = element;
    }
  };

  return { setFirstInputRef };
};

export default useFocusFirstInput;
