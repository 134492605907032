import { useCallback, useEffect, useMemo, useRef } from 'react';

import Typography from '@mui/material/Typography';
import { AlertTriangleIcon } from 'lucide-react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ButtonProps } from '@/components/atoms/Button/Button/Button';
import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import ProgressBar from '@/components/atoms/ProgressBar/ProgressBar';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import {
  ADD_CARD,
  MODAL_CONFIRMATION,
  MODAL_UPGRADE_PLAN,
} from '@/components/organisms/Modals/ModalConductor';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { getPercentage } from '@/modules/billing/hooks/useBillingState';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { enableConfetti } from '@/redux/confetti/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import { isModalOpen } from '@/redux/modals/selectors';
import { CONTACT_SALES_URL, PLAN_IDS } from '@/util/constants';

import { PlanTable } from './PlanTable';

import styles from './styles.module.scss';

type Form = { plan: (typeof PLAN_IDS)[keyof typeof PLAN_IDS] };

const PlanFieldset = () => {
  const { t } = useTranslation();
  const {
    upcomingInvoice,
    billingState,
    paymentMethods,
    createSubscriptionAsync,
    deleteSubscription,
    updateSubscription,
  } = useBilling();
  const { ai_agents } = useFeatureFlag();

  const { days_left, next_payment } = billingState ?? {};

  const currentPeriodEnds = useMemo(
    () => moment(billingState?.current_period_ends).format('MMM D Y'),
    [billingState?.current_period_ends]
  );

  const nextPayment = useMemo(
    () => moment(next_payment).subtract(1, 'days').format('MMM D YYYY'),
    [next_payment]
  );

  const {
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm<Form>({ mode: 'onChange' });

  const { state: locationState } = useLocation();
  const { shouldTriggerAction = 1 } = locationState || {};
  const lastTriggeredAction = useRef(1);
  const modalOpen = useSelector(isModalOpen);

  useEffect(() => {
    if (billingState?.plan_id) {
      reset({
        plan: billingState.plan_id as (typeof PLAN_IDS)[keyof typeof PLAN_IDS],
      });
    }
  }, [billingState?.plan_id, reset]);

  const dispatch = useDispatch();

  const handleResumePlan = useCallback(async () => {
    if (!paymentMethods?.length) {
      return dispatch(
        pushModal(ADD_CARD, {
          onSuccess: async () => {
            await createSubscriptionAsync(billingState?.plan_id);
            dispatch(popModal());
            dispatch(addTemporalToast('success', t('billing.plan_started')));
            dispatch(enableConfetti());
          },
        })
      );
    } else {
      return await createSubscriptionAsync(billingState?.plan_id);
    }
  }, [
    billingState?.plan_id,
    createSubscriptionAsync,
    dispatch,
    paymentMethods?.length,
    t,
  ]);

  const handleStart = useCallback(async () => {
    const handleSubscriptionChange = async (plan) => {
      if (billingState?.plan_id === 'trial') {
        await createSubscriptionAsync(plan);
      } else {
        await updateSubscription(plan);
      }
      dispatch(popModal());
      dispatch(addTemporalToast('success', t('billing.plan_started')));
      dispatch(enableConfetti());
      return;
    };

    const onSuccessCallback = async (plan) => {
      if (!paymentMethods?.length) {
        dispatch(popModal());
        dispatch(
          pushModal(ADD_CARD, {
            onSuccess: () => handleSubscriptionChange(plan),
          })
        );
        return;
      } else {
        await handleSubscriptionChange(plan);
      }
    };

    dispatch(
      pushModal(MODAL_UPGRADE_PLAN, {
        onSuccess: onSuccessCallback,
      })
    );
  }, [
    billingState?.plan_id,
    createSubscriptionAsync,
    dispatch,
    paymentMethods?.length,
    t,
    updateSubscription,
  ]);

  const handleChangePlan = useCallback(() => {
    const handleSubscriptionChange = async (plan) => {
      await updateSubscription(plan);
      dispatch(popModal());
      dispatch(enableConfetti());
    };

    const onSuccessCallback = async (plan) => {
      if (!paymentMethods?.length) {
        return dispatch(
          pushModal(ADD_CARD, {
            onSuccess: handleSubscriptionChange,
          })
        );
      } else {
        await handleSubscriptionChange(plan);
      }
    };

    dispatch(
      pushModal(MODAL_UPGRADE_PLAN, {
        onSuccess: onSuccessCallback,
      })
    );
  }, [dispatch, paymentMethods?.length, updateSubscription]);

  const handleCancel = useCallback(() => {
    if (!billingState || billingState?.plan_type !== 'stripe') {
      return;
    }
    dispatch(
      pushModal(MODAL_CONFIRMATION, {
        title: t('billing.plan_cancel'),
        danger: true,
        primaryButtonText: t('common.continue'),
        secondaryButtonText: t('common.back'),
        subtitle: t('billing.last_payment_subtitle', {
          0: nextPayment,
        }),
        callout: {
          children: t(`billing.callout${ai_agents ? '_ai_agent' : ''}`),
          variant: 'error',
        },
        onConfirm: () => {
          deleteSubscription(undefined, {
            onSuccess: () => {
              dispatch(popModal());
            },
          });
        },
      })
    );
  }, [billingState, dispatch, t, nextPayment, ai_agents, deleteSubscription]);

  const subtitle = useMemo(() => {
    if (!billingState) {
      return t('billing.change_plan_button');
    }

    if (billingState.has_expired) {
      return billingState?.plan_type === 'trial' ? (
        <span className={styles.errWithIcon}>
          <AlertTriangleIcon size={16} color="var(--color-destructive)" />
          {t('billing.plan_trial_expired')}
        </span>
      ) : (
        <Trans
          i18nKey="billing.plan_expired"
          values={[billingState.plan_name]}
        />
      );
    }
    if (billingState.is_canceled) {
      return (
        <Trans
          i18nKey="billing.plan_cancelled"
          values={[billingState.plan_name, currentPeriodEnds]}
        />
      );
    }

    if (billingState.plan_type === 'trial') {
      return (
        <Trans
          i18nKey="billing.plan_trial_expires_in"
          values={[billingState?.days_left]}
        />
      );
    }

    if (billingState.plan_type !== 'stripe') {
      return (
        <Trans
          i18nKey="billing.your_plan"
          values={[billingState.plan_name]}
          sx={{ whiteSpace: 'nowrap' }}
        />
      );
    }

    return (
      <>
        <Trans
          i18nKey="billing.next_payment"
          values={[billingState.plan_name, nextPayment]}
          sx={{ whiteSpace: 'nowrap' }}
        />
      </>
    );
  }, [billingState, currentPeriodEnds, nextPayment, t]);

  const helpText = useMemo(() => {
    if (!billingState || billingState.plan_type === 'trial') {
      return (
        <div className={styles.helpText}>
          <div>
            {t('billing.custom_needs')}{' '}
            <a
              href={CONTACT_SALES_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('billing.contact_sales')}
            </a>
          </div>
        </div>
      );
    }
    if (billingState.plan_type === 'custom') {
      return null;
    }

    if (billingState.is_canceled) {
      return (
        <div className={styles.helpText}>
          <a href={CONTACT_SALES_URL} target="_blank" rel="noopener noreferrer">
            {t('billing.contact_sales')}
          </a>
        </div>
      );
    }

    return (
      <div className={styles.helpText}>
        <button onClick={handleCancel} type="button">
          {t('billing.plan_cancel')}
        </button>
        <div>
          <a href={CONTACT_SALES_URL} target="_blank" rel="noopener noreferrer">
            {t('billing.contact_sales')}
          </a>
        </div>
      </div>
    );
  }, [billingState, handleCancel, t]);

  const primaryAction: {
    button: ButtonProps;
    handler: (e?: React.FormEvent<HTMLFormElement>) => void;
  } = useMemo(() => {
    const isTrial = billingState?.plan_type === 'trial';
    const isCustom = billingState?.plan_type === 'custom';
    const hasExpired = billingState?.has_expired;
    const isCanceled = billingState?.is_canceled;

    const getDefaultAction = () => ({
      button: {
        children: isTrial
          ? `✨ ${t('billing.upgrade')}`
          : t('billing.choose_plan'),
        disabled: !isDirty && !isValid,
      },
      handler: handleSubmit(handleStart),
    });

    const getCustomPlanAction = () => ({
      button: { children: t('billing.contact_sales') },
      handler: () => window.open(CONTACT_SALES_URL, '_blank'),
    });

    const getCanceledPlanAction = () => ({
      button: { children: t('billing.resume_plan') },
      handler: () => handleResumePlan(),
    });

    const getChangePlanAction = () => ({
      button: { children: t('billing.change_plan') },
      handler: handleChangePlan,
    });

    if (
      !billingState ||
      isTrial ||
      (billingState.plan_type !== 'custom' && hasExpired)
    ) {
      return getDefaultAction();
    }

    if (isCustom && billingState.plan_id !== 'enterprise' && hasExpired) {
      return getCanceledPlanAction();
    }
    if (isCustom) {
      return getCustomPlanAction();
    }
    if (isCanceled) {
      return getCanceledPlanAction();
    }
    return getChangePlanAction();
  }, [
    billingState,
    t,
    isDirty,
    isValid,
    handleSubmit,
    handleStart,
    handleResumePlan,
    handleChangePlan,
  ]);

  const shouldShowProgressbar = useMemo(() => {
    if (billingState?.plan_type === 'trial') {
      return true;
    }
    return false;
  }, [billingState]);

  useEffect(() => {
    if (
      !modalOpen &&
      shouldTriggerAction !== lastTriggeredAction.current &&
      billingState?.plan_id
    ) {
      lastTriggeredAction.current = shouldTriggerAction;
      primaryAction.handler();
    }
  }, [shouldTriggerAction, billingState?.plan_id, modalOpen, primaryAction]);

  return (
    <FormFieldset
      title={
        <div className={styles.title}>
          <Typography variant="subtitle1" className={styles.tipography}>
            {t('billing.plan')}
          </Typography>
          <StatusBadge label={billingState?.plan_name} variant="neutral" />
          {billingState?.is_canceled && (
            <StatusBadge label={t('billing.cancelled')} variant="warning" />
          )}
        </div>
      }
      subtitle={subtitle}
      helpText={helpText}
      primaryButton={primaryAction.button}
      onSubmit={primaryAction.handler}
      isLoading={!billingState}
      fullContentWidth
    >
      {!shouldShowProgressbar && !!upcomingInvoice && <PlanTable />}
      {shouldShowProgressbar && (
        <ProgressBar
          percentage={getPercentage(days_left)}
          variant={billingState?.has_expired ? 'error' : 'info'}
        />
      )}
    </FormFieldset>
  );
};

export default PlanFieldset;
