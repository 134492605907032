import { DownloadIcon, HeadsetIcon, ZapIcon } from 'lucide-react';

import { Props } from '@/models/icon';

import { CreateAIAgentOptions } from '../models';

export const AIAgentIcon = ({
  size = 24,
  color = 'var(--color-foreground-muted)',
  type = 'support',
  ...rest
}: Props & { type: CreateAIAgentOptions }) => {
  if (type === 'activation') {
    return (
      // Custom icon
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...rest}
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 15s1-1 4-1a6.904 6.904 0 0 1 1.5.164M4 15V3s.283-.283 1-.548M4 15v7M16 4a6.91 6.91 0 0 1-1-.074M16 4c.362 0 .695-.015 1-.04M16 4v6M8 2c.346 0 .678.027 1 .074M8 2v6m0-6c-.362 0-.695.015-1 .04M20 9s-.283.283-1 .548M16 10a6.91 6.91 0 0 1-1-.073v-6M16 10c.362 0 .695-.015 1-.04M8 8c.346 0 .678.027 1 .073v-6M8 8c-.362 0-.695.015-1 .04M4 9s.283-.283 1-.548m4-6.378c2.152.313 3.848 1.539 6 1.853m-1 11.787c.324.089.656.162 1 .213.322.046.654.073 1 .073 3 0 4-1 4-1V3s-.283.283-1 .548m-5 12.166V11c-.32 0-.657-.053-1-.143m1 4.857c-.34-.094-.672-.205-1-.325m-3-1.103a8.904 8.904 0 0 0-.5-.122m.5.122c.34.094.672.205 1 .325m-1-.325V9.372m-.5 4.792V9c.136.109.305.236.5.372m7-5.412v6m0-6a9.177 9.177 0 0 0 1-.137M17 9.96a9.177 9.177 0 0 0 1-.137m1-6.275v6m0-6c-.27.1-.6.197-1 .275m1 5.725c-.27.1-.6.197-1 .275m-7 4.788v-4.61m0 4.61c.336.124.668.256 1 .389m1 .389v-4.532m0 4.532A37.13 37.13 0 0 1 12 15M6 2.177v6m0-6a9.177 9.177 0 0 1 1-.137m-1 .137c-.4.078-.73.175-1 .275m1 5.725a9.177 9.177 0 0 1 1-.137m-1 .137c-.4.078-.73.175-1 .275m6 1.55c.316.178.654.351 1 .501m-1-.502c-.373-.21-.714-.43-1-.63m3 1.486a7.048 7.048 0 0 1-1-.354M7 2.04v6M5 2.452v6m13-4.629v6m-6 .68V15"
        />
      </svg>
    );
  } else if (type === 'support') {
    return <HeadsetIcon color={color} size={size} {...rest} />;
  } else if (type === 'debt_collection') {
    return (
      // Custom icon
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...rest}
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M17.652 6.526C17.652 5.131 14.148 4 9.826 4 5.504 4 2 5.131 2 6.526m15.652 0v4.21c0 .67-.824 1.313-2.292 1.787-.721.233-1.569.416-2.49.541m4.782-6.538c0 1.047-1.972 1.945-4.782 2.328M2 6.526v4.21c0 .67.825 1.313 2.292 1.787.721.233 1.569.416 2.49.541M2 6.526c0 1.047 1.972 1.945 4.783 2.328m0 0c.935.128 1.964.199 3.043.199 1.08 0 2.108-.07 3.044-.199m-6.087 0v4.21m0 0c.954.13 1.987.2 3.043.2s2.09-.07 3.044-.2m0-4.21v4.21m9.13.2v4.21c0 .67-.825 1.312-2.292 1.786-.721.233-1.569.415-2.49.541M22 13.263c0 1.047-1.972 1.945-4.783 2.328M22 13.263c0-.992-1.77-1.85-4.348-2.264m-6.522 4.592c.936.128 1.964.199 3.044.199s2.108-.071 3.043-.199m-6.087 0c-2.81-.383-4.782-1.281-4.782-2.328v4.21c0 .67.824 1.313 2.292 1.787.721.233 1.569.415 2.49.541m0-4.21v4.21m0 0c.954.13 1.988.199 3.044.199s2.09-.069 3.043-.199m0-4.21v4.21"
        />
      </svg>
    );
  } else if (type === 'engagement') {
    return (
      // Custom icon
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...rest}
      >
        <g clipPath="url(#a-engagement)">
          <path
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M7.467 12.708 5.284 8.99A1.725 1.725 0 0 1 8.26 7.243m1.746 2.974-2.62-4.461a1.725 1.725 0 1 1 2.975-1.747l.873 1.487m0 0 1.965 3.347m-1.965-3.347a1.724 1.724 0 1 1 2.975-1.746L17.7 9.699m-11.544.778a1.725 1.725 0 0 0-2.974 1.746l2.62 4.462a6.898 6.898 0 0 0 9.442 2.456l1.487-.873c2.082-1.223 2.971-2.605 3.433-4.356l1.105-4.249a1.724 1.724 0 0 0-3.336-.861l-.54 2.077M4.046 19.5a6.9 6.9 0 0 0 4.213 3.183M22.5 5.5c-.5-.5-1.5-1-2.5-1"
          />
        </g>
        <defs>
          <clipPath id="a-engagement">
            <path fill="#fff" d="M24 0H0v24h24z" />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (type === 'upsell') {
    return (
      // Custom icon
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...rest}
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 3v16a2 2 0 0 0 2 2h16M18.5 8 12 14.5l-3-3L6.5 14m12-6v4m0-4h-4"
        />
      </svg>
    );
  } else if (type === 'import') {
    return <DownloadIcon color={color} size={size} />;
  }

  return <ZapIcon color={color} size={size} />;
};
