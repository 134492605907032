import { useCallback, useEffect, useMemo, useState } from 'react';

import dotize from 'dotize';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { callUpload } from '@/api/fetcher';
import Button from '@/components/atoms/Button/Button/Button';
import Input from '@/components/atoms/Input/Input';
import KeyValueRow from '@/components/atoms/KeyValueTable/KeyValueRow/KeyValueRow';
import { MODAL_MERGE_CONFLICTS } from '@/components/organisms/Modals/ModalConductor';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { useBundles } from '@/modules/bundles/hooks/useBundles';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { setBundleId, setDeskId } from '@/redux/session/actions';
import {
  selectAccountId,
  selectDeskId,
  selectFileUploadMetadata,
} from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';
import { FIFTEEN_MB, HUNDRED_BYTES } from '@/util/constants';
import { preventClickThrough } from '@/util/util';

import BundleHeader from '../../components/BundleHeader';
import {
  setDraftBundle,
  addContext,
  deleteContext,
  updateContext,
  revertDirty,
} from '../../redux/actions';
import {
  selectBundle,
  selectBundleContext,
  selectBundleContextKeys,
  selectBundleKeysWithNested,
} from '../../redux/selectors';
import { findCommonKeys } from '../../utils';

import styles from './Bundle.module.scss';

const ONE_MEGABYTE = 1000000;

const Bundle = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchFilter, setSearchFilter] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);
  const deskId = useSelector(selectDeskId);
  const { deskId: desk_id } = useParams();
  const draftBundle = useSelector(selectBundle);
  const location = useLocation();
  const { bundleId } = useParams();
  const { bundle, isSuccess } = useBundles(deskId || desk_id, bundleId);
  const context = useSelector(selectBundleContext);
  const contextKeys = useSelector(selectBundleContextKeys);
  const nestedKeys = useSelector(selectBundleKeysWithNested);
  const { slug } = useAccount();
  const isDraft = location.pathname.includes('draft');
  const name = draftBundle?.name;
  const accountId = useSelector(selectAccountId);
  const metadata = useSelector(selectFileUploadMetadata);

  const uploadPath = `/www/media/${accountId}/upload`;
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'bundles', actions.WRITE)
  );

  useEffect(() => {
    if (bundleId) {
      dispatch(setBundleId(bundleId));
    }
    return () => {
      dispatch(setBundleId(null));
    };
  }, [dispatch, bundleId]);

  const onJsonAccepted = useCallback(
    (files) => {
      const reader = new FileReader();
      reader.onload = () => {
        const json = JSON.parse(reader.result as string);
        const commonKeys = findCommonKeys(json, context);
        if (commonKeys?.length === 0) {
          dispatch(
            setDraftBundle({
              ...draftBundle,
              context: {
                ...draftBundle?.context,
                ...dotize.convert(json),
              },
              dirty: true,
            })
          );
        } else {
          dispatch(
            pushModal(MODAL_MERGE_CONFLICTS, {
              conflicts: commonKeys,
              draftBundle,
              json,
            })
          );
        }
      };
      reader.readAsText(files[0]);
    },
    [context, dispatch, draftBundle]
  );

  const { getInputProps, open } = useDropzone({
    accept: { 'application/json': ['.json'] },
    maxSize: ONE_MEGABYTE,
    onDropAccepted: (files) => onJsonAccepted(files),
  });

  useEffect(() => {
    pageView(PageName.BUNDLE);
  }, []);

  useEffect(() => {
    if (!deskId) {
      dispatch(setDeskId(desk_id));
    }
  }, [deskId, desk_id, dispatch]);

  useEffect(() => {
    if (bundle) {
      dispatch(
        setDraftBundle({
          dirty: false,
          ...bundle,
          context: dotize.convert(bundle.context),
        })
      );
    }
    return () => {
      dispatch(revertDirty({ dirtyState: false }));
    };
  }, [dispatch, bundle]);

  useEffect(() => {
    if (isDraft && deskId && !draftBundle.name) {
      navigate(`/${slug}/environments/${deskId}/context_bundles`);
    }
  }, [deskId, draftBundle, isDraft, navigate, slug]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value?.toLowerCase());
  };

  const handleAddContext = useCallback(
    (
      itemKey: string,
      itemValue: string,
      options?: Record<string, string | number>
    ) => {
      const index = options?.index as number;
      if (
        itemKey.includes('.') &&
        contextKeys.includes(itemKey.split('.')[0])
      ) {
        setHasError(true);
        setErrorIndex(index || null);
        return;
      }
      if (contextKeys.includes(itemKey) || nestedKeys.includes(itemKey)) {
        setHasError(true);
        setErrorIndex(index || null);
        return;
      }
      dispatch(
        addContext({
          itemKey,
          itemValue,
          dirty: isDraft ? true : undefined,
        })
      );
      setHasError(false);
      setErrorIndex(null);
    },
    [contextKeys, dispatch, isDraft, nestedKeys]
  );

  const handleUpdateContext = useCallback(
    (
      oldKey: string,
      itemKey: string,
      itemValue: string,
      options?: Record<string, string | number>
    ) => {
      const { focus } = options;
      if (
        focus !== 'value' &&
        (contextKeys.includes(itemKey) || nestedKeys.includes(itemKey))
      ) {
        setHasError(true);
        return;
      }

      !hasError && dispatch(updateContext({ itemKey, itemValue, oldKey }));
    },
    [contextKeys, dispatch, hasError, nestedKeys]
  );

  const handleKeyBlur = useCallback(
    (keyRef: string, options?: Record<string, string | number>) => {
      const index = options?.index as number;
      if (contextKeys.includes(keyRef) || nestedKeys.includes(keyRef)) {
        setHasError(true);
        setErrorIndex(index);
        return;
      }
      setHasError(false);
      setErrorIndex(null);
    },
    [contextKeys, nestedKeys]
  );

  const handleDelete = useCallback(
    (itemKey) => {
      dispatch(deleteContext({ itemKey }));
    },
    [dispatch]
  );

  const handleFileUpload = async (
    newKey: string,
    oldKey: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    preventClickThrough(e);
    const file = e.target.files[0];
    if (file.size > FIFTEEN_MB) {
      dispatch(
        addTemporalToast(
          'error',
          t('file_upload.fix_too_large', {
            0: `${FIFTEEN_MB / 1000000}`,
          })
        )
      );
      return;
    }
    if (file.size < HUNDRED_BYTES) {
      dispatch(
        addTemporalToast(
          'error',
          t('file_upload.fix_too_small', { 0: `${HUNDRED_BYTES / 1000}` })
        )
      );
      return;
    }
    e.target.value = '';

    const formData = new FormData();
    formData.append('file', file);
    if (metadata) {
      formData.append('metadata', metadata);
    }
    callUpload(uploadPath, formData).then((resp) => {
      if (oldKey === '') {
        dispatch(
          addContext({
            itemKey: newKey,
            itemValue: resp.url,
            dirty: true,
          })
        );
      } else {
        dispatch(
          updateContext({
            itemKey: newKey,
            itemValue: resp.url,
            oldKey: newKey,
          })
        );
      }
    });
  };

  const filteredContextKeys = useMemo(
    () =>
      contextKeys.filter(
        (key) =>
          key?.toLowerCase().includes(searchFilter) ||
          context[key]?.toLowerCase().includes(searchFilter)
      ),
    [context, contextKeys, searchFilter]
  );
  const isReadOnly = !canWrite;

  return (
    <Layout>
      <BundleHeader name={name} bundleId={bundleId} />
      <PageContentWrapper
        newPlain2={!isReadOnly}
        newPlain3={isReadOnly}
        readOnly={!canWrite}
      >
        <div className={styles.container}>
          {(isSuccess || isDraft) && (
            <>
              <div className={styles.input}>
                <Input
                  placeholder={t('bundle.search')}
                  size="large"
                  onChange={handleSearchChange}
                  value={searchFilter}
                />
                <div>
                  <input {...getInputProps()} />
                  <Button
                    variant="tertiary"
                    onClick={open}
                    disabled={isReadOnly}
                  >
                    {t('bundles.upload')}
                  </Button>
                </div>
              </div>
              <div className={styles.innerTitle}>
                <span>{t('bundles.item_list')}</span>
              </div>
              <div className={styles.wrapper}>
                {filteredContextKeys.map((x, i) => (
                  <KeyValueRow
                    key={x}
                    itemKey={x}
                    itemValue={context[x]}
                    index={i}
                    keys={contextKeys}
                    onAdd={handleAddContext}
                    onUpdate={handleUpdateContext}
                    onKeyBlur={handleKeyBlur}
                    onDelete={handleDelete}
                    error={hasError && errorIndex === i}
                    readOnly={!canWrite}
                    onUpload={handleFileUpload}
                  />
                ))}
                <KeyValueRow
                  key={contextKeys.length}
                  itemKey=""
                  itemValue=""
                  index={contextKeys.length}
                  keys={contextKeys}
                  onAdd={handleAddContext}
                  onUpdate={handleUpdateContext}
                  onKeyBlur={handleKeyBlur}
                  onDelete={handleDelete}
                  showDelete={false}
                  error={hasError && errorIndex === contextKeys.length}
                  readOnly={!canWrite}
                  onUpload={handleFileUpload}
                />
              </div>
            </>
          )}
        </div>
      </PageContentWrapper>
    </Layout>
  );
};

export default Bundle;
