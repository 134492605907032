import { useCallback, useEffect, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import uniqBy from 'lodash/uniqBy';
import { BoxIcon } from 'lucide-react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Banner } from '@/components/atoms/Banner/Banner';
import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import Loading from '@/components/atoms/Loading/Loading';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import useCollections from '@/hooks/useCollections';
import {
  Datasource,
  DatasourceType,
  IntercomConfig,
} from '@/models/collections';

import { Form } from '../utils';

import styles from './ImportIntercom.module.scss';

const ImportIntercom = () => {
  const { t } = useTranslation();
  const [selectedDatasource, setSeletedDatasource] = useState<Datasource>(null);
  const { setValue } = useFormContext<Form>();
  const [selectedOption, setSelectedOption] = useState('0');
  const [datasources, setDatasources] = useState<Datasource[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { listAllDatasources } = useCollections();

  useEffect(() => {
    setIsLoading(true);
    listAllDatasources(DatasourceType.INTERCOM_KB).then((datasources) => {
      setIsLoading(false);
      const uniques = uniqBy(datasources, 'config.access_token');
      setDatasources(uniques);
      setSeletedDatasource(uniques[0]);
      const config = uniques[0]?.config as IntercomConfig;
      setValue('intercom', config);
    });
  }, [listAllDatasources, setValue]);

  const handleChange = useCallback(
    async (item: { value: string }) => {
      setSeletedDatasource(
        datasources.find((d) => d.datasource_id === item.value)
      );
    },
    [datasources]
  );

  const handleChangeRadioOptions = useCallback(
    (value: string) => {
      setSelectedOption(value);
      if (value === '0') {
        const config = datasources[0]?.config as IntercomConfig;
        setValue('intercom', config);
      } else {
        setValue('intercom', null);
      }
    },
    [datasources, setValue]
  );

  const options = useMemo(
    () =>
      datasources?.map((d) => {
        const config = d?.config as IntercomConfig;

        return {
          label: config?.page_name || d.name,
          value: d.datasource_id,
          icon: <BoxIcon size={16} />,
        };
      }),
    [datasources]
  );

  const radioOptions = [
    {
      label: t('collections.datasources.intercom_option0'),
      value: '0',
      id: '0',
    },
    {
      label: t('collections.datasources.intercom_option1'),
      value: '1',
      id: '1',
    },
  ];

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Loading />
      </div>
    );
  }
  const noPreviousDatasources = !isLoading && datasources.length === 0;
  return (
    <div>
      <div className={styles.content}>
        {!noPreviousDatasources && (
          <>
            <TitleSubtitle
              title={t('collections.datasources.intercom_title')}
              subtitle={t('collections.datasources.intercom_subtitle')}
            />
            <div className={cn(styles.radioWrapper)}>
              {radioOptions.map((option) => {
                return (
                  <OptionRadioButton
                    option={option}
                    key={option.id}
                    isSelected={option.value === selectedOption}
                    onChange={() => handleChangeRadioOptions(option.value)}
                  />
                );
              })}
            </div>
            {selectedOption === '0' && (
              <DropdownList
                options={options}
                size="xlarge"
                height="xlarge"
                optionClick={handleChange}
                selected={selectedDatasource?.datasource_id}
              >
                <div className={styles.label}>
                  <Typography
                    variant="body-semi-bold"
                    color="var(--color-foreground-muted)"
                  >
                    {(selectedDatasource?.config as IntercomConfig)
                      ?.page_name || selectedDatasource?.name}
                  </Typography>
                </div>
              </DropdownList>
            )}
          </>
        )}

        {(selectedOption === '1' || noPreviousDatasources) && (
          <Banner
            title={t('collections.datasources.intercom_redirect')}
            relativePosition
            variant="neutral"
          >
            <Typography>
              {t('collections.datasources.intercom_access')}
            </Typography>
            <br />
          </Banner>
        )}
      </div>
    </div>
  );
};

export default ImportIntercom;
