import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { ZoomInIcon, ZoomOutIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import ToolkitControlsWrapper from '@/components/organisms/Dialogs/Node/ToolkitControls/ToolkitControlsWrapper/ToolkitControlsWrapper';

const ToolkitControlsZoom = ({
  onZoomInClick,
  onZoomOutClick,
  zoomPercentage,
}) => {
  const { t } = useTranslation();

  const items = useMemo(
    () => [
      {
        label: t('common.zoom_in'),
        icon: <ZoomInIcon size={16} color="var(--color-foreground)" />,
        onClick: onZoomInClick,
        shouldFocus: true,
      },
      {
        label: t('common.percentage', { 0: zoomPercentage }),
        icon: (
          <Typography
            component="span"
            variant="label-caps-small"
            color="var(--color-foreground-muted)"
          >
            {zoomPercentage}
          </Typography>
        ),
      },
      {
        label: t('common.zoom_out'),
        icon: <ZoomOutIcon size={16} color="var(--color-foreground)" />,
        onClick: onZoomOutClick,
        shouldFocus: true,
      },
    ],
    [onZoomInClick, onZoomOutClick, t, zoomPercentage]
  );

  return <ToolkitControlsWrapper items={items} />;
};

export default ToolkitControlsZoom;
