import { createSelector } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import { isConditionNested } from '@/components/organisms/Condition/util';

import { initialState } from './actions';
import { Condition } from '../../../ts/models/condition';
import { RootState } from '../../../ts/models/state';
export const keysToOmit = ['isConditionOpen', 'isError', 'isHover'];

export const selectIsConditionOpen = (state: RootState, indexes) => {
  const { outerIndex, index } = indexes;
  const isNested = isConditionNested(state.condition?.condition);
  if (isNested) {
    return state.condition?.condition?.conditions[outerIndex]?.conditions[index]
      ?.isConditionOpen;
  } else {
    return state.condition?.condition?.conditions[index]?.isConditionOpen;
  }
};

export const selectCondition = createSelector(
  (state: RootState) => state.condition.condition,
  (condition) => omit(condition, keysToOmit) as Condition
);

export const selectIsAnyConditionOpen = (state: RootState) => {
  const isAnyConditionOpenRecursive = (obj: Condition) => {
    return obj.conditions.some((condition) => {
      if (condition.conditions && Array.isArray(condition.conditions)) {
        return isAnyConditionOpenRecursive(condition as Condition);
      }
      return condition.isConditionOpen === true;
    });
  };

  return isAnyConditionOpenRecursive(state.condition.condition);
};

export const selectIsConditionError = (state: RootState) => {
  const isConditionErrorRecursive = (obj: Condition): boolean => {
    return obj.conditions.some((condition) => {
      if (condition.conditions && Array.isArray(condition.conditions)) {
        return isConditionErrorRecursive(condition as Condition);
      }
      return Boolean(condition?.isError);
    });
  };

  return isConditionErrorRecursive(state.condition.condition);
};

export const selectConditionHover = (state: RootState) =>
  state.condition.condition.isHover;

export const selectIsConditionEmpty = (state: RootState) => {
  return isEqual(state.condition.condition, initialState.condition);
};
