import cn from 'classnames';
import { StarIcon } from 'lucide-react';

interface RatingProps {
  stars: number;
  starSize?: 'small' | 'medium' | 'large';
  className?: string;
}

export const Rating = ({
  stars,
  starSize = 'medium',
  className,
}: RatingProps) => {
  // Map starSize to actual pixel values
  const sizeMap = {
    small: 12,
    medium: 16,
    large: 20,
  };

  const iconSize = sizeMap[starSize];

  return (
    <div
      className={cn('flex items-center justify-start h-4', className)}
      role="img"
      aria-label={`Rating: ${stars} out of 5 stars`}
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <StarIcon
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          size={iconSize}
          className={cn(
            'transition-colors',
            index < stars
              ? 'fill-yellow-400 text-yellow-400'
              : 'fill-none text-gray-300'
          )}
        />
      ))}
    </div>
  );
};
