import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import Link from '@/components/atoms/Link/Link';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';

import FieldsetFooter from './FieldsetFooter';
import FieldsetFooterAction from './FieldsetFooterAction';

import './Fieldset.scss';

type LinkType = {
  label: string;
  url: string;
};

export interface FieldsetProps {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  subtitle?: React.ReactNode;
  isLoading?: boolean;
  fullWidth?: boolean;
  tooltip?: string;
  externalLink?: LinkType;
  hasBadge?: boolean;
  integrationActive?: boolean;
  overflown?: boolean;
  addPB?: boolean;
}

const Fieldset: React.FC<FieldsetProps> = ({
  children,
  title,
  subtitle,
  isLoading,
  fullWidth,
  tooltip,
  externalLink,
  hasBadge,
  integrationActive,
  overflown,
  addPB = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn({
        fieldset: true,
        [`fieldset--fullWidth`]: fullWidth,
      })}
    >
      <div className={cn({ fieldset__content: true, overflown, addPB })}>
        {isLoading ? (
          <>
            <Skeleton height={45} width="50%" />
            <Skeleton height={25} />
            <FieldsetFooter>
              <Skeleton height={15} width={56} />
              <FieldsetFooterAction>
                <Skeleton height={25} width={64} />
              </FieldsetFooterAction>
            </FieldsetFooter>
          </>
        ) : (
          <>
            {typeof title !== 'string' ? (
              title
            ) : (
              <div className="fieldset__title">
                {title && (
                  <>
                    <Typography
                      color="var(--color-foreground)"
                      variant="subtitle1"
                    >
                      {title}
                    </Typography>
                    {hasBadge && (
                      <span>
                        <StatusBadge
                          withIcon={false}
                          label={
                            integrationActive
                              ? t(
                                  'integrations.fieldsets.active_fieldset.active'
                                )
                              : t(
                                  'integrations.fieldsets.active_fieldset.inactive'
                                )
                          }
                          variant={integrationActive ? 'success' : 'attention'}
                        />
                      </span>
                    )}
                  </>
                )}
                {tooltip && <HelpTooltip tooltip={tooltip} />}
              </div>
            )}
            {subtitle && (
              <Typography
                variant="body"
                component="h3"
                className="fieldset__subtitle"
              >
                {subtitle}
                {externalLink && (
                  <>
                    <br />
                    <Link
                      key={externalLink.label}
                      href={externalLink.url}
                      external
                    >
                      {externalLink.label}
                    </Link>
                  </>
                )}
              </Typography>
            )}
            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default Fieldset;
