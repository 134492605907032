import { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { BookIcon, CircleHelpIcon, CircleIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import MoveoSupport from '@/components/atoms/Icons/Global/MoveoSupport';
import { CustomMenu } from '@/components/atoms/Menu/Menu';
import { useWebClient } from '@/hooks/useWebClient';
import { EventName } from '@/models/segment';
import { trackEvent } from '@/segment/segment';
import {
  MOVEO_CHANNELS_WS_URL,
  MOVEO_INTEGRATION_ID,
  STATUS_URL,
  SUPPORT_URL,
} from '@/util/constants';

import styles from './HelpMenu.module.scss';

export const HelpMenu = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { initialiseWebchat, webClientInstance } = useWebClient({
    // Host is hardcoded to AWS Production since the `integrationId` is
    // available only in production
    host: MOVEO_CHANNELS_WS_URL,
    integrationId: MOVEO_INTEGRATION_ID,
    launcher: { show: false },
  });

  useEffect(() => {
    if (webClientInstance) {
      webClientInstance.openWindow();
    }
  }, [webClientInstance]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenStatus = useCallback(() => {
    trackEvent(EventName.ClickPlatformStatus);
    handleClose();

    // Open status in new tab
    window.open(STATUS_URL, '_blank', 'noopener,noreferrer');
  }, [handleClose]);

  const handleOpenDocs = useCallback(() => {
    trackEvent(EventName.ClickDocumentation);
    handleClose();

    // Open docs in new tab
    window.open(SUPPORT_URL, '_blank', 'noopener,noreferrer');
  }, [handleClose]);

  const handleOpenWebchat = useCallback(() => {
    initialiseWebchat();
    handleClose();
  }, [handleClose, initialiseWebchat]);

  const handleClick = useCallback((event) => {
    trackEvent(EventName.OpenHelpMenu);
    setAnchorEl(event.currentTarget);
  }, []);

  return (
    <>
      <Button
        id="help-button"
        aria-controls={open ? 'help-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.container}
        disableRipple
      >
        <CircleHelpIcon color="var(--color-background)" />
      </Button>

      <CustomMenu
        id="help-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableRestoreFocus
        MenuListProps={{
          'aria-labelledby': 'help-button',
        }}
      >
        {/* Uncomment when the backend for Notifications has been implemented */}
        {/* <MenuItem disableGutters >
          <MenuStar />
          {t('main_navigation.whats_new')}
        </MenuItem> */}
        <MenuItem onClick={handleOpenDocs} disableGutters>
          <BookIcon color="var(--color-foreground-muted)" size={16} />
          {t('docs.docs')}
        </MenuItem>
        <MenuItem onClick={handleOpenWebchat} disableGutters>
          <MoveoSupport size={16} />
          {t('home.contact_support')}
        </MenuItem>
        <MenuItem disableGutters onClick={handleOpenStatus}>
          <CircleIcon color="var(--color-foreground-muted)" size={16} />
          {t('docs.platform_status')}
        </MenuItem>
      </CustomMenu>
    </>
  );
};

HelpMenu.displayName = 'HelpMenu';
