// Selects all the text within an HTML input element
export const selectAllText = (input: HTMLInputElement) => {
  if (input.value) {
    input.setSelectionRange(0, input.value?.length);
  }
};

export const getBorderColor = (
  hasError: boolean,
  isEditMode: boolean,
  canEdit: boolean
) => {
  if (!canEdit) return 'transparent';
  if (hasError) return 'var(--color-stroke-destructive)';
  if (isEditMode) return 'var(--color-stroke)';
  return 'transparent';
};

export const getHoverBorderColor = (
  hasError: boolean,
  isEditMode: boolean,
  canEdit: boolean
) => {
  if (hasError) return 'var(--color-stroke-destructive)';
  if (isEditMode) return 'var(--color-stroke)';
  if (canEdit) return 'var(--color-stroke)';
  return 'transparent';
};
