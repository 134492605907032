import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Link from '@/components/atoms/Link/Link';
import { useAccount } from '@/hooks/useAccount';

import { useBundleSection } from '../../hooks/useBundleSection';
import { updateContext } from '../../redux/actions';
import { selectIsTryItReplay } from '../../redux/selectors';
import { ContextSectionWrapper } from '../ContextSectionWrapper/ContextSectionWrapper';

export type ContextBundle = {
  bundle: string;
  desk: string;
  [key: string]: string;
} | null;

type Props = {
  canEdit: boolean;
  bundle: ContextBundle;
};

export const BundleSection = ({ canEdit, bundle }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { slug } = useAccount();
  const isReplay = useSelector(selectIsTryItReplay);
  const {
    context,
    isBundleEmpty,
    bundles,
    selectedDeskId,
    selectedBundleId,
    autocompleteOptions,
  } = useBundleSection(bundle);

  const { setValue, control } = useForm<ContextBundle>({
    mode: 'onChange',
    defaultValues: {
      contextBundle: null,
    },
  });

  const resetBundle = useCallback(() => {
    setValue('contextBundle', null);
    const newContext = context[context.length - 1] ?? {};

    dispatch(
      updateContext([
        ...context.slice(0, -1),
        {
          ...newContext,
          global: {
            bundle: null,
          },
        },
      ])
    );
  }, [context, dispatch, setValue]);

  const handleChange = useCallback(
    (_, option) => {
      const newContext = context[context.length - 1] ?? {};
      const bundleVariables = bundles.find(
        (b) => b.name === option.label
      )?.context;

      dispatch(
        updateContext([
          ...context.slice(0, -1),
          {
            ...newContext,
            global: {
              bundle: {
                ...bundleVariables,
                bundle: option.label,
                desk: option.desk_name,
              },
            },
          },
        ])
      );
    },
    [bundles, context, dispatch]
  );

  if ((!canEdit || isReplay) && isBundleEmpty) return null;

  const hideResetIcon = !canEdit || isReplay || (isBundleEmpty && canEdit);

  return (
    <ContextSectionWrapper
      title={t('try_it.bundle')}
      onClick={resetBundle}
      hideIcon={hideResetIcon}
    >
      {isBundleEmpty && canEdit ? (
        <AutocompleteNew
          control={control}
          options={autocompleteOptions}
          name="contextBundle"
          placeholder={t('try_it.select_bundle')}
          groupByProp="desk_name"
          groupByLabelProp={false}
          width={403}
          onChange={handleChange}
          size="xs"
        />
      ) : (
        <Box className="wrapper" display="flex">
          <Box
            className="wrapper__keys"
            ml="var(--space-10)"
            mr="var(--space-8)"
          >
            <Typography
              component="p"
              variant="body-regular"
              color="var(--color-foreground-muted)"
              mb="calc(var(--space-12) / 2)"
            >
              {t('common.desk')}:
            </Typography>

            <Typography
              component="p"
              variant="body-regular"
              color="var(--color-foreground-muted)"
            >
              {t('try_it.bundle')}:
            </Typography>
          </Box>
          <Box className="wrapper__values" pl="calc(var(--space-12) / 2)">
            <Typography
              component="p"
              variant="body-regular"
              color="var(--color-informational)"
              mb="calc(var(--space-12) / 2)"
            >
              <Link href={`/${slug}/environments/${selectedDeskId}`}>
                {bundle?.desk}
              </Link>
            </Typography>

            <Typography
              component="p"
              variant="body-regular"
              color="var(--color-informational)"
            >
              <Link
                href={`/${slug}/environments/${selectedDeskId}/context_bundles/${selectedBundleId}`}
              >
                {bundle?.bundle}
              </Link>
            </Typography>
          </Box>
        </Box>
      )}
    </ContextSectionWrapper>
  );
};
