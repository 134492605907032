import { useEffect, useState } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import cn from 'classnames';
import { MinusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import Input from '@/components/atoms/Input/Input';
import { OptionBase } from '@/models/common';

import styles from './CheckboxDropdown.module.scss';

type Item = OptionBase<{ checked: boolean; icon?: React.ReactNode }>;

export interface Props {
  title: React.ReactNode | string;
  tooltip?: string;
  items?: Item[];
  onItemsChecked?: (items: Item[]) => void;
  onClear: () => void;
  onApply: () => void;
  size?: 'small' | 'medium';
}

const CheckBoxDropdown = ({
  title,
  tooltip = '',
  items,
  onItemsChecked,
  onClear,
  onApply,
  size = 'small',
}: Props) => {
  const { t } = useTranslation();
  const [searchFilter, setSearchFilter] = useState('');

  const muiStyles = {
    root: {
      padding: 0,
    },
  };

  const [checkedItems, setCheckedItems] = useState<Item[]>([]);

  useEffect(() => {
    setCheckedItems(items);
  }, [items]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(event.target.value.toLowerCase());
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const { checked } = e.target;
    const newCheckedItems = checkedItems?.map((item, i) => {
      if (item.value === value) {
        return {
          ...checkedItems[i],
          checked,
        };
      }
      return item;
    });

    if (onItemsChecked) {
      onItemsChecked(newCheckedItems);
    }
    setCheckedItems(newCheckedItems);
  };

  const handleAllChange = () => {
    if (checkedItems) {
      const checkedItemsLength = checkedItems?.filter(
        (item) => item.checked
      ).length;

      const newCheckedItems = checkedItems.map((item) => ({
        ...item,
        checked: checkedItemsLength === 0,
      }));

      if (onItemsChecked) {
        onItemsChecked(newCheckedItems);
      }
      setCheckedItems(newCheckedItems);
    }
  };

  const getCheckedIcon = () => {
    const checkedItemsLength = checkedItems?.filter(
      (item) => item.checked
    ).length;

    if (checkedItemsLength !== 0 && checkedItemsLength !== items?.length) {
      return { icon: <MinusIcon />, checked: false };
    }

    return {
      checked: checkedItemsLength === items?.length,
    };
  };
  const { checked } = getCheckedIcon();

  const hasItemChecked =
    checkedItems?.filter((item) => item.checked).length > 0;
  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <Input
          placeholder={t('common.search')}
          size="small"
          onChange={handleSearchChange}
          value={searchFilter}
        />
      </div>
      <div className={cn(styles.item, styles.titleItem)}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleAllChange}
              name="checkbox"
              color="primary"
              sx={muiStyles.root}
              size="small"
            />
          }
          label=""
        />
        <div className={styles.titleContainer}>
          <div className={styles.value}>{title}</div>

          {tooltip && <HelpTooltip tooltip={tooltip} />}
        </div>
      </div>
      <div className={styles.body}>
        {checkedItems
          ?.filter((item) =>
            item.label.toLowerCase().includes(searchFilter.toLowerCase())
          )
          ?.map((item) => {
            return (
              <div
                className={cn(styles.item, styles[size], {
                  [styles.withIcon]: !!item.icon,
                })}
                key={item.value}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.checked}
                      onChange={(e) => handleChange(e, item.value)}
                      name="checkbox"
                      color="primary"
                      size="small"
                      sx={muiStyles.root}
                    />
                  }
                  label=""
                />

                {item.icon && <div className={styles.icon}>{item.icon}</div>}
                <div
                  className={cn(styles.dataContainer, {
                    [styles.checked]: item.checked,
                  })}
                >
                  <p className={styles.value}>{item.label}</p>
                </div>
              </div>
            );
          })}
      </div>
      <div className={styles.footer}>
        <Button
          size="xs"
          variant="tertiary"
          onClick={onClear}
          disabled={!hasItemChecked}
        >
          {t('common.clear')}
        </Button>
        <Button size="xs" onClick={onApply}>
          {t('common.apply')}
        </Button>
      </div>
    </div>
  );
};

export default CheckBoxDropdown;
