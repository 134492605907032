import { useCallback } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { Action, ReminderAction } from '@/models/action';
import { clearDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';
import { selectDraftDialogNodes } from '@/redux/dialogs/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { removeAction, removeNode } from '@/redux/nodes/actions';

const useDeleteAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialogErrors = useSelector(selectDialogAlerts);
  const draftDialogNodes = useSelector(selectDraftDialogNodes);

  // Recursive function to delete a reminder action
  const deleteReminderAction = useCallback(
    (action: ReminderAction) => {
      // We remove action without clearing the dialog errors of the reminder action itself
      // because reminders don't have dialog errors
      dispatch(removeAction({ actionId: action.action_id }));

      // Find the corresponding reminder node of the reminder action
      const reminderNode = draftDialogNodes.filter(
        (node) => action.trigger_node_id === node.node_id
      )?.[0];

      const reminderNodeActions = reminderNode?.actions || [];
      if (reminderNodeActions.length === 0) {
        dispatch(removeNode({ nodeId: action.trigger_node_id }));
        return;
      }

      // Clear dialog errors of nested actions
      reminderNodeActions.forEach((action) => {
        if (dialogErrors.length) {
          dispatch(
            clearDialogAlerts({
              id: action.action_id,
            })
          );
        }
      });

      // Find if the reminder node has a nested reminder action
      const nestedReminderAction = reminderNodeActions.find(
        (action) => action.type === 'reminder'
      );

      // If the reminder node has a nested reminder action, we remove it
      if (nestedReminderAction) {
        deleteReminderAction(nestedReminderAction);
      }

      // We remove node without clearing the dialog errors of the reminder node itself
      // because reminders don't have dialog errors
      // We remove the Node at the end because if it is removed at the start the recursion
      // will not work getting an error in the removeAction. The actions need to have a node
      // in order to be removed. So, don't change the order of execution
      dispatch(removeNode({ nodeId: action.trigger_node_id }));
    },
    [dialogErrors.length, dispatch, draftDialogNodes]
  );

  const deleteAction = useCallback(
    (action: Action) => {
      const type = action.type;
      const actionType = type === 'event' ? 'jump to' : type;
      const isReminderAction = type === 'reminder';
      const subtitle = isReminderAction ? (
        <Trans i18nKey="dialog.delete_reminder_action" />
      ) : (
        <Trans i18nKey="dialog.delete_action" values={[actionType]} />
      );

      const deleteProps = {
        subtitle,
        confirm: false,
        onDelete: () => {
          // Reminder action flow
          if (isReminderAction) {
            deleteReminderAction(action);
          } else {
            // Normal action flow
            dispatch(removeAction({ actionId: action.action_id }));
            if (dialogErrors.length) {
              dispatch(
                clearDialogAlerts({
                  id: action.action_id,
                })
              );
            }
          }

          dispatch(popModal());
        },
        secondaryButtonText: t('common.cancel'),
      };

      dispatch(pushModal(MODAL_DELETE, deleteProps));
    },
    [deleteReminderAction, dialogErrors.length, dispatch, t]
  );

  return { deleteAction, deleteReminderAction };
};

export default useDeleteAction;
