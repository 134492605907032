// Custom icon

export const MessagesCircleIcon = ({
  size = 16,
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 18.53a5.992 5.992 0 0 0 5.067-.191L21 19.672l-1.333-3.933A6 6 0 0 0 18.8 9M6.933 15.339a6 6 0 1 0-2.6-2.6L3 16.672l3.933-1.333Z"
      />
    </svg>
  );
};
