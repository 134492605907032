import { PropsWithChildren } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import styles from './Summary.module.scss';

type SummaryProps = {
  title?: string;
  icon?: React.ReactNode;
  footer?: React.ReactNode;
};

export const Summary = ({
  title,
  icon,
  children,
  footer,
}: PropsWithChildren<SummaryProps>) => {
  const { t } = useTranslation();

  return (
    <section className={styles.summary}>
      <Typography
        component="header"
        variant="body-semi-bold"
        color="var(--color-foreground-muted)"
        className={styles.summary__title}
      >
        {title ?? t('ai_agents.summary').toUpperCase()}
        {icon}
      </Typography>

      <ul className={styles.summary__steps}>{children}</ul>

      {footer && <div className={styles.summary__footerWrapper}>{footer}</div>}
    </section>
  );
};

type SummaryStepProps = {
  icon?: React.ReactNode;
  text?: string;
  onClick?: () => void;
};

const BUTTON_STYLE = {
  '&:hover': {
    color: 'var(--color-accent)',
  },
};

const SummaryStep = ({
  icon,
  text,
  children,
  onClick,
}: PropsWithChildren<SummaryStepProps>) => {
  const component = onClick ? 'button' : 'span';
  const customStyle = onClick ? BUTTON_STYLE : {};
  return (
    <li className={styles.summary__step}>
      {icon}

      <Typography
        onClick={onClick}
        component={component}
        color="var(--color-foreground-muted)"
        variant="label-caps-large"
        sx={customStyle}
      >
        {text}
      </Typography>

      {children}
    </li>
  );
};

Summary.Step = SummaryStep;
