import { ReactElement } from 'react';

import {
  AlertTriangleIcon,
  CircleAlertIcon,
  CircleCheckIcon,
  InfoIcon,
} from 'lucide-react';

export type Variants = keyof typeof IconsByState;

export const IconsByState: Record<string, ReactElement> = {
  neutral: <InfoIcon size={16} color="var(--color-blue-950)" />,
  success: <CircleCheckIcon size={16} color="var(--color-green-600)" />,
  info: <InfoIcon size={16} color="var(--color-blue-600)" />,
  warning: <CircleAlertIcon size={16} color="var(--color-yellow-600)" />,
  attention: <CircleAlertIcon size={16} color="var(--color-orange-600)" />,
  critical: <AlertTriangleIcon size={16} color="var(--color-red-600)" />,
} as const;

export const backgroundColorGetter = (variant: string) => {
  switch (variant) {
    case 'neutral':
      return 'var(--color-gray-100)';
    case 'success':
      return 'var(--color-green-100)';
    case 'info':
      return 'var(--color-blue-100)';
    case 'warning':
      return 'var(--color-yellow-100)';
    case 'attention':
      return 'var(--color-orange-100)';
    case 'critical':
      return 'var(--color-red-100)';
    default:
      return 'var(--color-blue-100)';
  }
};

export const primaryBackgroundColorGetter = (variant: string) => {
  switch (variant) {
    case 'neutral':
      return 'var(--color-gray-100)';
    case 'success':
      return 'var(--color-green-100)';
    case 'info':
      return 'var(--color-blue-100)';
    case 'warning':
      return 'var(--color-yellow-100)';
    case 'attention':
      return 'var(--color-orange-100)';
    case 'critical':
      return 'var(--color-red-100)';
    default:
      return 'var(--color-blue-100)';
  }
};
