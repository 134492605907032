import { useCallback } from 'react';

import { Maximize2Icon, Minimize2Icon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DialogTooltip from '@/components/organisms/Dialogs/DialogTooltip/DialogTooltip';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { clearDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectDialogAlerts } from '@/redux/dialogAlerts/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import { removeConditions } from '@/redux/nodes/actions';
import { preventClickThrough } from '@/util/util';

import styles from './ConditionIntersection.module.scss';

interface Props {
  nodeId: string;
  isCollapsed: boolean;
  setCollapsed: (val: boolean) => void;
}

const ConditionIntersection = ({
  nodeId,
  isCollapsed,
  setCollapsed,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialogErrors = useSelector(selectDialogAlerts);

  // Deletes all conditions for a node
  const onDelete = useCallback(() => {
    const deleteProps = {
      subtitle: t('dialog.delete_condition_warning'),
      confirm: false,
      onDelete: () => {
        dispatch(removeConditions({ nodeId }));
        if (dialogErrors.length) {
          dispatch(clearDialogAlerts({ nodeId }));
        }
        dispatch(popModal());
      },
      secondaryButtonText: t('common.cancel'),
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [dialogErrors.length, dispatch, nodeId, t]);

  const handleCollapse = useCallback(
    (e) => {
      preventClickThrough(e);
      setCollapsed(!isCollapsed);
    },
    [isCollapsed, setCollapsed]
  );

  return (
    <>
      <DialogTooltip onDelete={onDelete} type="condition">
        <div className={styles.wrapper}>
          <button
            onClick={handleCollapse}
            className={styles.conditionSymbol}
            data-testid="condition-intersection-btn"
          >
            <div className={styles.container}>
              {isCollapsed ? (
                <Maximize2Icon size={16} color="var(--color-background)" />
              ) : (
                <Minimize2Icon size={16} color="var(--color-background)" />
              )}
            </div>
          </button>
        </div>
      </DialogTooltip>

      {isCollapsed && (
        <div className={styles.arrow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="28"
            fill="none"
            viewBox="0 0 8 28"
          >
            <path
              fill="var(--color-foreground-muted)"
              d="M3.5 1a.5.5 0 0 1 1 0h-1Zm.854 26.354a.5.5 0 0 1-.708 0L.464 24.172a.5.5 0 1 1 .708-.707L4 26.293l2.828-2.828a.5.5 0 1 1 .708.707l-3.182 3.182ZM4.5 1v26h-1V1h1Z"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default ConditionIntersection;
