import { Action, combineReducers } from 'redux';

import { RootState } from '@/models/state';
import analytics from '@/modules/analytics/redux/actions';
import bundle from '@/modules/bundles/redux/actions';
import notifications from '@/modules/notifications/redux/actions';
import rule from '@/modules/rules/redux/actions';

import appVersion from './appVersion/actions';
import auditLogs from './auditLogs/actions';
import autoAI from './auto-ai/actions';
import banners from './banners/actions';
import chat from './chat/actions';
import clipboard from './clipBoard/actions';
import condition from './condition/actions';
import confetti from './confetti/actions';
import dialogAlerts from './dialogAlerts/actions';
import expressions from './expressions/actions';
import helpers from './helpers/actions';
import accordion from './integrations/actions';
import macros from './macros/actions';
import modals from './modals/actions';
import nodes from './nodes/actions';
import permissions from './permissions/actions';
import presence from './presence/actions';
import recommendations from './recommendations/actions';
import session from './session/actions';
import usedNodesCache from './usedNodes/actions';
import user from './user/actions';
import values from './values/actions';
import aiAgents from '../modules/aiAgents/redux/actions';
import broadcasts from '../modules/broadcast/redux/actions';
import chatNew from '../modules/humanChat/redux/actions';
import onboarding from '../modules/onboarding/redux/actions';
import tryIt from '../modules/TryIt/redux/actions';

export const defaultState = {};

const reducer = () =>
  combineReducers({
    accordion,
    aiAgents,
    analytics,
    appVersion,
    auditLogs,
    autoAI,
    banners,
    broadcasts,
    bundle,
    chat,
    chatNew,
    clipboard,
    condition,
    confetti,
    dialogAlerts,
    expressions,
    helpers,
    macros,
    modals,
    nodes,
    notifications,
    onboarding,
    permissions,
    presence,
    recommendations,
    rule,
    session,
    tryIt,
    usedNodesCache,
    user,
    values,
  });

/**
 * Wrap the root reducer to clear the state during a logout
 * See https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
 */
const rootReducer = () => {
  const appReducer = reducer();

  return (state: RootState, action: Action) => {
    if (action?.type === 'user/logout/fulfilled') {
      // eslint-disable-next-line no-param-reassign
      state = undefined;
    }
    return appReducer(state, action);
  };
};

export default rootReducer;
