import Typography from '@mui/material/Typography';

import styles from './InlineStatus.module.scss';

interface Props {
  value: string;
  text: string;
  color?: string;
}

const InlineStatus = ({
  color = 'var(--color-informational)',
  value,
  text,
}: Props) => {
  return (
    <Typography variant="label-regular" className={styles.wrapper}>
      <span style={{ backgroundColor: color }} className={styles.value}>
        {value}
      </span>
      <span style={{ color: color }}>{text}</span>
    </Typography>
  );
};

export default InlineStatus;
