import { ChangeEvent, useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { BookIcon } from 'lucide-react';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import OptionRadioButton from '@/components/atoms/OptionRadioButton/OptionRadioButton';
import ToolkitWrapper from '@/components/organisms/Toolkit/ToolkitWrapper';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import { Action } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { REPLAY_RESPONSE_DOCS_URL } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';
import { replayActionSchema } from '@/util/validator';

import styles from './ToolkitActionReplay.module.scss';

const REPLAY_OPTIONS = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
} as const;

type Form = {
  replay: string;
  customMessage: string;
};

const ToolkitActionReplay = () => {
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();
  const dispatch = useDispatch();
  const {
    actionId,
    texts: customMessage,
    type,
  } = useSelector((state: RootState) => {
    const selectedAction = selectSelectedAction(state) as Extract<
      Action,
      { type: 'replay' }
    >;
    return {
      actionId: selectedAction.action_id,
      texts: selectedAction.texts,
      type: selectedAction.type,
    };
  }, shallowEqual);
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const actionHandoverOptions = [
    {
      id: 0,
      value: REPLAY_OPTIONS.DEFAULT,
      label: t('actions.replay.options.default'),
    },
    {
      id: 1,
      value: REPLAY_OPTIONS.CUSTOM,
      label: t('actions.replay.options.custom'),
    },
  ];

  const {
    formState: { dirtyFields, errors },
    register,
    unregister,
    trigger,
    watch,
    setValue,
    setFocus,
  } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      replay: customMessage
        ? actionHandoverOptions[1].value
        : actionHandoverOptions[0].value,
      customMessage,
    },
    resolver: yupResolver(replayActionSchema) as Resolver<Form>,
  });
  useFocusOnInput('replay', errors, setFocus);

  const watchReplayOption = watch('replay');

  const updateReplayAction = useCallback(
    (text: string | Array<string>) => {
      let textValue = [text];

      if (Array.isArray(text)) {
        textValue = text;
      }
      if (!text) {
        textValue = undefined;
      }

      dispatch(
        updateAction({
          actionId,
          action: {
            texts: textValue,
          },
        })
      );
    },
    [actionId, dispatch]
  );

  const handleReplayOptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const text =
        e.target.value === REPLAY_OPTIONS.DEFAULT
          ? undefined
          : t('actions.generate_text', { 0: '' });

      updateReplayAction(text);
    },
    [t, updateReplayAction]
  );

  const handleCustomMessageChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const text = e.target.value || [];

      updateReplayAction(text);
    },
    [updateReplayAction]
  );

  // Register and unregister the 'customMessage' field
  // based on the 'replay' field
  useEffect(() => {
    if (watchReplayOption === REPLAY_OPTIONS.CUSTOM) {
      register('customMessage');
      setValue('customMessage', customMessage?.[0]);
    } else {
      unregister('customMessage');
    }
  }, [customMessage, register, setValue, unregister, watchReplayOption]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.replay'),
            body: capitalizeFirstLetter(errors.customMessage?.message),
            type,
          },
        })
      );
    }
  }, [
    actionId,
    dirtyFields,
    dirtyFields.customMessage,
    dispatch,
    errors.customMessage?.message,
    t,
    type,
    parentNodeId,
  ]);

  return (
    <ToolkitWrapper type="replay">
      <ContextualHelp
        preventClose
        name="action-replay"
        title={t('actions.replay.title')}
        links={[
          {
            label: t('docs.docs'),
            url: REPLAY_RESPONSE_DOCS_URL,
            icon: <BookIcon size={16} />,
          },
        ]}
      >
        {t(`actions.replay.body${ai_agents ? '_ai_agent' : ''}`)}
      </ContextualHelp>

      {actionHandoverOptions.map((option) => (
        <div className={styles.option} key={`${option.id}-${option.value}`}>
          <OptionRadioButton
            key={`${option.id}-${option.value}`}
            option={option}
            size="medium"
            register={register('replay')}
            isSelected={watchReplayOption === option.value}
            onChange={handleReplayOptionChange}
          />
        </div>
      ))}
      {watchReplayOption === REPLAY_OPTIONS.CUSTOM && (
        <>
          <div className={styles.banner}>
            <Banner dismissable={false} variant="neutral" relativePosition>
              {t('actions.replay.banner_text')}
            </Banner>
          </div>

          <TextAreaAsInput
            register={register('customMessage')}
            label={t('actions.replay.custom_message_label')}
            placeholder={t('actions.replay.custom_message_placeholder')}
            height="medium"
            autofocus
            trimValue
            onChange={handleCustomMessageChange}
            error={!!errors?.customMessage}
            errorMessage={capitalizeFirstLetter(errors?.customMessage?.message)}
          />
        </>
      )}
    </ToolkitWrapper>
  );
};

export default ToolkitActionReplay;
