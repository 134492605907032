import {
  AlertTriangleIcon,
  BellIcon,
  CircleUserRoundIcon,
  ClockIcon,
  CreditCardIcon,
  InboxIcon,
  LockIcon,
  ShieldCheckIcon,
  UserRoundIcon,
} from 'lucide-react';

import FileDollarIcon from '@/components/atoms/Icons/FileDollarIcon';

export const personalSettings = [
  {
    icon: <CircleUserRoundIcon size={16} />,
    title_key: 'pages.profile',
    link: '/profile/information',
  },
  {
    icon: <InboxIcon size={16} />,
    title_key: 'pages.invitations_simple',
    link: '/profile/invitations',
  },
  {
    icon: <BellIcon size={16} />,
    title_key: 'pages.notifications_simple',
    link: '/profile/notifications',
  },
];

export const accountSettings = [
  {
    icon: <CircleUserRoundIcon size={16} />,
    title_key: 'common.information',
    link: '/account/information',
  },
  {
    icon: <CreditCardIcon size={16} />,
    title_key: 'pages.billing_simple',
    link: '/account/billing',
  },
  {
    icon: <FileDollarIcon />,
    title_key: 'pages.invoices',
    link: '/account/invoices',
  },
  {
    icon: <LockIcon size={16} />,
    title_key: 'permissions.manage_access',
    link: '/account/manage',
  },
  {
    icon: <UserRoundIcon size={16} color="var(--color-foreground-muted)" />,
    title_key: 'pages.human_agents',
    link: '/account/human-agents',
  },
  {
    icon: <ClockIcon size={16} />,
    title_key: 'pages.activity_logs',
    link: '/account/activity-logs',
  },
  {
    icon: <ShieldCheckIcon size={16} />,
    title_key: 'common.data_privacy',
    link: '/account/data-privacy',
  },
  {
    icon: <AlertTriangleIcon size={16} />,
    title_key: 'common.danger_zone',
    link: '/account/danger',
  },
];
