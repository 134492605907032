import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectSessionContent } from '@/modules/TryIt/redux/selectors';

import { InformationFieldNew } from '../InformationField/InformationFieldNew';

import styles from './UserSection.module.scss';

const UserSection = () => {
  const { t } = useTranslation();

  const {
    user_name = t('common.anonymous'),
    user_id,
    user_email,
    channel_user_id,
    external_user_id,
  } = useSelector(selectSessionContent);
  return (
    <div className={styles.wrapper}>
      <InformationFieldNew
        type="text"
        label={t('common.name')}
        value={user_name}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.user_id')}
        value={user_id}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.user_email')}
        value={user_email}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.channel_user_id')}
        value={channel_user_id}
      />
      <InformationFieldNew
        type="text"
        label={t('analytics.fields.external_user_id')}
        value={external_user_id}
      />
    </div>
  );
};

export default UserSection;
