import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
} from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { UseFormRegisterReturn } from 'react-hook-form';

import HelpTooltip from '../HelpTooltip/HelpTooltip';

import styles from './Input.module.scss';

export interface TextAreaAsInputProps
  extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'size'> {
  label?: string;
  error?: boolean;
  register?: UseFormRegisterReturn;
  tooltip?: React.ReactNode | string;
  errorMessage?: React.ReactNode | string;
  size?: 'small' | 'medium' | 'large';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  height?: 'small' | 'medium' | 'large' | 'auto';
  fullWidth?: boolean;
  trimValue?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  rows?: number;
  enableScroll?: boolean;
  autofocus?: boolean;
  maxCharacters?: number;
  value?: string;
}

const TextAreaAsInput = forwardRef<HTMLTextAreaElement, TextAreaAsInputProps>(
  (
    {
      error = false,
      label,
      name = 'field',
      required,
      tooltip = '',
      type = 'shown',
      onChange = () => {},
      id = `${name}-input`,
      register,
      errorMessage,
      onBlur,
      onClick,
      iconLeft,
      iconRight,
      size = 'medium',
      height = 'small',
      fullWidth = false,
      trimValue = false,
      readOnly,
      placeholder,
      rows,
      autofocus = false,
      enableScroll = false,
      maxCharacters,
      value = '',
      ...props
    },
    ref
  ) => {
    const hasError = error || !!errorMessage;

    return (
      <div
        className={cn(styles.input, styles[size], {
          [styles.fullWidth]: fullWidth,
        })}
      >
        {label && (
          <label
            className={cn({
              [styles.input__label]: true,
              [styles.input__label__disabled]: props.disabled,
            })}
            htmlFor={id}
          >
            {label}
            <HelpTooltip tooltip={tooltip} />
          </label>
        )}
        {iconLeft && <div className={styles.icon_left}>{iconLeft}</div>}
        <textarea
          placeholder={placeholder}
          name={name}
          id={id}
          required={required}
          ref={ref}
          rows={rows}
          autoComplete="off"
          readOnly={readOnly}
          autoFocus={autofocus}
          className={cn(styles.textarea, styles[height], {
            [styles.input__input]: true,
            [styles.readOnly]: readOnly,
            [styles.input__input__error]: hasError,
            [`input__${type}`]: true,
            [styles.input__input__icon_left]: !!iconLeft,
            [styles.input__input__icon_right]: !!iconRight,
            [styles.scrollable]: enableScroll,
          })}
          onClick={onClick}
          {...props}
          {...register}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
            register?.onChange(event);
            if (onChange) {
              onChange(event);
            }
          }}
          onBlur={(event: FocusEvent<HTMLTextAreaElement>) => {
            if (trimValue) {
              event.target.value = event.target.value.trim();
            }
            register?.onBlur(event);
            if (onBlur) {
              onBlur(event);
            }
          }}
        />
        {iconRight && <div className={styles.icon_right}>{iconRight}</div>}
        <div className={styles.footerContainer}>
          {errorMessage && (
            <p className={styles.input__input__error__message}>
              {errorMessage}
            </p>
          )}
          {maxCharacters && (
            <Typography
              variant="label-regular"
              color="var(--color-foreground-muted)"
              className={styles.counter}
            >
              {value.length}/{maxCharacters}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

TextAreaAsInput.displayName = 'TextAreaAsInput';

export default TextAreaAsInput;
