import { useCallback, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { PlusCircleIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import Header from '@/components/organisms/Header/Header';
import { MODAL_PLUGIN_CREATE } from '@/components/organisms/Modals/ModalConductor';
import Layout from '@/components/templates/Layout/Layout';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { APIKeys } from '@/modules/developerTools/pages/APIKeys/APIKeys';
import { LanguageModels } from '@/modules/developerTools/pages/languageModels/LanguageModels';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';

import { TabNavigation } from './components/TabNavigation';
import { useTabNavigation } from './hooks/useTabNavigation';
import { Plugins } from './pages/plugins';
import { Wrapper } from './pages/Wrapper';
import { TabConfig } from './types';

import styles from './DeveloperToolsRouter.module.scss';

export const DeveloperToolsRouter = () => {
  // Custom hooks
  const { t } = useTranslation();
  const features = useFeatureFlag();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'plugins', actions.WRITE)
  );
  // We want to make sure that if a feature flag is enabled and the other disabled,
  // we won't mess up the tab order
  const allTabs = useMemo(
    (): TabConfig[] => [
      {
        path: 'apikeys',
        component: <APIKeys />,
        label: t('developer_tools.api_keys'),
        moduleType: 'credentials',
      },
      {
        path: 'models',
        featureFlag: 'ai_agents',
        component: <LanguageModels />,
        label: t('developer_tools.models'),
        moduleType: 'language_models',
      },
      {
        path: 'plugins',
        featureFlag: 'plugins',
        component: <Plugins />,
        label: t('developer_tools.plugins'),
        moduleType: 'plugins',
      },
    ],
    [t]
  );

  // We don't want to show tabs that are behind feature flags
  const enabledTabs = useMemo(
    () =>
      allTabs.filter(
        (tab) =>
          !tab.featureFlag ||
          features?.[tab.featureFlag as keyof typeof features]
      ),
    [allTabs, features]
  );

  const { tabValue, handleTabChange } = useTabNavigation(enabledTabs, location);

  /**
   * Redirects to the first tab if the user accesses the devtools page directly
   * Ensures a specific tab is always selected
   */
  useEffect(() => {
    if (location.pathname.endsWith('/devtools')) {
      navigate(`/${slug}/devtools/apikeys`);
    }
  }, [location.pathname, navigate, slug]);

  const handleButtonClick = useCallback(() => {
    dispatch(pushModal(MODAL_PLUGIN_CREATE));
  }, [dispatch]);

  return (
    <Layout>
      <Header>
        <Header.Title
          title={
            <Typography variant="h2-semi-bold" color="var(--color-foreground)">
              {t('developer_tools.title')}
            </Typography>
          }
        />
        <Box mt={0.5}>
          <Header.Navigation>
            <TabNavigation
              tabs={enabledTabs}
              activeTab={tabValue}
              onTabChange={handleTabChange}
            />
            {enabledTabs[tabValue]?.path === 'plugins' && (
              <Button
                onClick={handleButtonClick}
                className="ml-auto"
                disabled={!canWrite}
              >
                <PlusCircleIcon size={16} />
                <Typography>{t('developer_tools.create_event')}</Typography>
              </Button>
            )}
          </Header.Navigation>
        </Box>
      </Header>

      <div
        className={cn(styles.wrapper, {
          [styles.plugins]: enabledTabs[tabValue]?.path === 'plugins',
        })}
      >
        <Routes>
          {enabledTabs.map((tab, index) => (
            <Route
              key={tab.path}
              path={`/${tab.path}`}
              element={
                <Wrapper
                  thisTab={index}
                  tabValue={tabValue}
                  module={tab.moduleType}
                >
                  {tab.component}
                </Wrapper>
              }
            />
          ))}
        </Routes>
      </div>
    </Layout>
  );
};
