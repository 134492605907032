import { cn, withRef } from '@udecode/cn';
import { getHandler } from '@udecode/plate';
import { useElement, PlateElement } from '@udecode/plate/react';
import type { TMentionElement } from '@udecode/plate-mention';
import { Fragment } from 'react/jsx-runtime';
import { useFocused, useSelected } from 'slate-react';

export const MentionElement = withRef<
  typeof PlateElement,
  {
    prefix?: string;
    renderLabel?: (mentionable: TMentionElement) => string;
    onClick?: (mentionNode: unknown) => void;
  }
>(({ children, className, prefix, renderLabel, onClick, ...props }, ref) => {
  const element = useElement<TMentionElement>();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <PlateElement
      ref={ref}
      className={cn(
        'inline-block cursor-pointer rounded-md align-baseline text-sm font-medium',
        selected && focused && 'ring-2 ring-ring',
        element.children[0].bold === true && 'font-bold',
        element.children[0].italic === true && 'italic',
        element.children[0].underline === true && 'underline',
        className
      )}
      onClick={getHandler(onClick, element)}
      data-slate-value={element.value}
      contentEditable={false}
      draggable
      {...props}
    >
      <Fragment>
        {children}
        {prefix}
        {renderLabel ? renderLabel(element) : element.value}
      </Fragment>
    </PlateElement>
  );
});
