import { createRef, useCallback, useRef } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import { CalendarIcon, CircleMinusIcon, CirclePlusIcon } from 'lucide-react';
import moment from 'moment';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { businessHoursRules } from '@/util/validator';

import HolidaysEmpty from './HolidaysEmpty';

import styles from './BusinessHour.module.scss';

const Holidays = ({
  fields,
  classes,
  handleCreate,
  control,
  errors,
  setShowDatePicker,
  showDatePicker,
  buttonText,
  focusedInput,
  setFocusedInput,
  handleCalendarSave,
  handleDelete,
}) => {
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'business_hours', actions.WRITE)
  );

  const handleButtonClick = useCallback(
    (index) => {
      setShowDatePicker((prev) => {
        const newArray = [...prev];
        newArray[index] = { isOpen: !prev[index].isOpen };
        return newArray;
      });
    },
    [setShowDatePicker]
  );

  const optionRefs = useRef([]);
  optionRefs.current = fields.map(
    (_, i) => optionRefs.current[i] ?? createRef()
  );

  return (
    <>
      {fields.length === 0 && (
        <HolidaysEmpty onClick={handleCreate} disabled={!canWrite} />
      )}
      {fields.length > 0 && (
        <>
          <div className={styles.titleWrapper}>
            <span>{t('common.name')}</span>
            <span>{t('common.date')}</span>
          </div>
          <div>
            {fields.map((item, index) => {
              return (
                <div className={styles.wrapper} key={item.id}>
                  <div>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <form autoComplete="off">
                            <InputBase
                              id={fields.index?.holidayName}
                              placeholder={t('common.name_placeholder')}
                              fullWidth
                              sx={classes.root}
                              error={!!errors[index]?.holidayName}
                              onChange={onChange}
                              value={value}
                              inputRef={ref}
                              disabled={!canWrite}
                            />
                          </form>
                        );
                      }}
                      control={control}
                      rules={businessHoursRules.holidayName}
                      name={`holidays.${index}.holidayName`}
                    />
                    <div className={styles.error}>
                      {errors[index]?.date.holidayName}
                    </div>
                  </div>

                  <div className={styles.calendarWrapper}>
                    <Button
                      size="large"
                      onClick={() => handleButtonClick(index)}
                      variant="secondary"
                      isFullWidth
                      disabled={!canWrite}
                    >
                      <CalendarIcon
                        size={16}
                        color={'var(--color-foreground-muted)'}
                      />
                      <p className={styles.button_text}>{buttonText(index)}</p>
                    </Button>
                    {showDatePicker[index]?.isOpen && (
                      <ClickAwayListener
                        onClickAway={() => handleButtonClick(index)}
                      >
                        <div
                          ref={optionRefs.current[index]}
                          className={styles.calendar}
                        >
                          <Controller
                            render={({ field: { onChange, value, ref } }) => {
                              return (
                                <DayPickerRangeController
                                  ref={ref}
                                  numberOfMonths={1}
                                  minimumNights={0}
                                  startDate={moment(value.startDate)}
                                  endDate={moment(value.endDate)}
                                  // january of this year is the min date
                                  minDate={moment().startOf('year')}
                                  // december of this year is the max date
                                  maxDate={moment().endOf('year')}
                                  onDatesChange={(dates) => onChange(dates)}
                                  initialVisibleMonth={() =>
                                    moment(value.startDate)
                                  }
                                  // we don't want to show the year
                                  renderMonthElement={(props) => {
                                    return (
                                      <strong>
                                        {props.month.format('MMMM')}
                                      </strong>
                                    );
                                  }}
                                  focusedInput={focusedInput}
                                  hideKeyboardShortcutsPanel
                                  onFocusChange={(
                                    input: FocusedInputShape | null
                                  ) => {
                                    if (!input) {
                                      setFocusedInput('startDate');
                                    } else {
                                      setFocusedInput(input);
                                    }
                                  }}
                                />
                              );
                            }}
                            control={control}
                            name={`holidays.${index}.dates`}
                          />

                          <div className={styles.footer}>
                            <Button
                              size="small"
                              onClick={(e) => handleCalendarSave(e, index)}
                              disabled={!canWrite}
                            >
                              {t('common.save')}
                            </Button>
                          </div>
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>

                  <IconButton
                    ariaLabel={t('common.create')}
                    onClick={handleCreate}
                    disabled={!canWrite}
                  >
                    <CirclePlusIcon
                      size={16}
                      color="var(--color-foreground-muted)"
                    />
                  </IconButton>

                  <IconButton
                    ariaLabel={t('common.delete')}
                    onClick={() => handleDelete(index)}
                    disabled={!canWrite}
                  >
                    <CircleMinusIcon
                      size={16}
                      color="var(--color-foreground-muted)"
                    />
                  </IconButton>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default Holidays;
