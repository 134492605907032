import { t } from 'i18next';
import { Accept, ErrorCode } from 'react-dropzone';

import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';

export type AcceptedType = File & {
  path: string;
};

export const MAX_FILES = 20;
export const MAX_FILE_NAME_LENGTH = 128;

/**
 * Process the files to check if they are valid and not duplicates.
 *
 * @param acceptedFiles - The files to process
 * @param existingFilenames - The list of existing filenames
 * @returns A promise that resolves to an object containing the non-restricted files and the duplicate files
 */
export const processFiles = (
  acceptedFiles: AcceptedType[],
  existingFilenames = []
): Promise<{
  nonRestrictedFiles: AcceptedType[];
  duplicateFiles: AcceptedType[];
  message?: string;
}> => {
  return new Promise((resolve, reject) => {
    const nonRestrictedFiles = [];
    const duplicateFiles = [];

    if (acceptedFiles.length === 0) {
      resolve({
        nonRestrictedFiles,
        duplicateFiles,
      });
      return;
    }

    for (const file of acceptedFiles) {
      if (!file || !file.path) {
        reject({
          message: 'Invalid file format',
        });
        return;
      }

      if (file.path.length > MAX_FILE_NAME_LENGTH) {
        reject({
          message: t('collections.file_name_too_long', {
            0: MAX_FILE_NAME_LENGTH,
          }),
        });
        return;
      }

      if (!existingFilenames.includes(file.path)) {
        nonRestrictedFiles.push(file);
      } else {
        duplicateFiles.push(file);
      }
    }

    if (nonRestrictedFiles.length + duplicateFiles.length > MAX_FILES) {
      reject({
        message: t('chatBox.upload_error', { 0: MAX_FILES }),
      });
      return;
    }

    if (duplicateFiles.length > 0) {
      reject({
        message: 'duplicates',
        duplicateFiles,
        nonRestrictedFiles,
      });
      return;
    }

    resolve({ nonRestrictedFiles, duplicateFiles });
  });
};

export const handleErrors = (
  rejectedFiles,
  dispatch: (func: unknown) => void,
  minSizeInBytes: number,
  maxSizeInBytes: number,
  accept: Accept
) => {
  rejectedFiles.forEach((file) => {
    if (file?.errors[0]?.code === ErrorCode.FileTooSmall) {
      dispatch(
        addErrorTemporalToast(
          t('collections.fix_too_small', {
            0: rejectedFiles[0].file.name,
            1: `${minSizeInBytes / 1000}`,
          })
        )
      );
    }

    if (file?.errors[0].code === ErrorCode.FileTooLarge) {
      dispatch(
        addErrorTemporalToast(
          t('collections.fix_too_large', {
            0: rejectedFiles[0].file.name,
            1: `${maxSizeInBytes / 1000000}`,
          })
        )
      );
    }

    if (file?.errors[0].code === ErrorCode.FileInvalidType) {
      const allowedFiles = Object.values(accept)
        .flatMap((f) => f)
        .map((f) => f.split('.')[1].toUpperCase())
        .join(', ');
      dispatch(
        addErrorTemporalToast(
          `${t('collections.documents.fileerror')}: ${allowedFiles}`
        )
      );
    }
  });
};
