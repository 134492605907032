import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { BoxIcon } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import IfIcon from '@/components/atoms/Icons/IfIcon';
import { Rule } from '@/modules/rules/model';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

import styles from './ModalPublishVersion.module.scss';

type Props = {
  rule: Rule & { desk_name: string };
};

export const RuleLink = ({ rule }: Props) => {
  const slug = useSelector(selectAccountSlug);
  const link = `/${slug}/environments/${rule?.desk_id}/rules/${rule?.rule_id}`;
  const dispatch = useDispatch();

  const handleOnClick = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  return (
    <span
      className={cn(styles.link, {
        hasBadge: rule.status === 'inactive',
      })}
    >
      <Link to={link} onClick={handleOnClick}>
        <BoxIcon size={16} color="var(--color-foreground-muted)" />{' '}
        <Typography>{`${rule?.desk_name}`}</Typography> /{' '}
        <IfIcon color="var(--color-foreground-muted)" />{' '}
        <Typography>{`${rule?.name}`}</Typography>
      </Link>
    </span>
  );
};
