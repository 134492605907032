import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { preventClickThrough } from '@/util/util';

import styles from './InactivityTimeoutOption.module.scss';

type Props = {
  onClick: (option) => void;
  isSelected: boolean;
  option: {
    label: string;
    value: number;
  };
  disabled: boolean;
};

export const InactivityTimeoutOption = ({
  onClick,
  isSelected,
  option,
  disabled,
}: Props) => {
  const handleClick = useCallback(
    (e) => {
      preventClickThrough(e);
      onClick(option);
    },
    [onClick, option]
  );

  return (
    <button
      className={cn(
        `px-4 py-2 border rounded-md flex justify-center items-center ${disabled ? 'cursor-not-allowed opacity-50' : ''}`,
        styles.button,
        {
          [styles.selected]: isSelected,
        }
      )}
      onClick={handleClick}
      disabled={disabled}
    >
      <Typography
        variant="body-medium"
        color={
          isSelected
            ? 'var(--color-informational)'
            : 'var(--color-foreground-muted)'
        }
      >
        {option.label}
      </Typography>
    </button>
  );
};
