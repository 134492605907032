import React from 'react';

import cn from 'classnames';
import {
  BlocksIcon,
  BookmarkIcon,
  BoxIcon,
  HouseIcon,
  MegaphoneIcon,
  ZapIcon,
} from 'lucide-react';
import { useSelector } from 'react-redux';

import IfIcon from '@/components/atoms/Icons/IfIcon';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { selectDeskId } from '@/redux/session/selectors';
import { resolveBrainsPath } from '@/util/util';

import NavigationItem from '../NavigationItem/NavigationItem';

import styles from './NavigationIframe.module.scss';

interface itemProps {
  title: string;
  path: string;
  icon: React.ReactNode;
}

const MainNavigationIframe = () => {
  const deskId = useSelector(selectDeskId);
  const { ai_agents } = useFeatureFlag();
  const navigationItems: Array<itemProps> = [
    { title: 'main_navigation.home', path: '/', icon: <HouseIcon size={16} /> },
    {
      title: ai_agents ? 'common.ai_agents' : 'common.brains',
      path: resolveBrainsPath('/brains', ai_agents),
      icon: <ZapIcon size={16} />,
    },
    {
      title: 'main_navigation.collections',
      path: '/collections',
      icon: <BookmarkIcon size={16} />,
    },
    {
      title: 'main_navigation.broadcast_other',
      path: '/broadcasts',
      icon: <MegaphoneIcon size={16} />,
    },
    {
      title: 'main_navigation.integrations',
      path: `/environments/${deskId}/integrations`,
      icon: <BlocksIcon size={16} />,
    },
    {
      title: 'main_navigation.rules',
      path: `/environments/${deskId}/rules`,
      icon: <IfIcon color="currentColor" size={16} />,
    },
    {
      title: 'main_navigation.environments',
      path: `/environments/${deskId}/settings`,
      icon: <BoxIcon size={16} />,
    },
  ];

  return (
    <nav className={cn(styles.navbar)}>
      <ul className={styles.navigationList}>
        {navigationItems.map((item, index) => {
          return (
            <NavigationItem
              key={item.title}
              i18nKey={item.title}
              index={index}
              icon={item.icon}
              link={item.path}
              isIframe={true}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default MainNavigationIframe;
