import React, {
  ComponentProps,
  forwardRef,
  useId,
  useRef,
  useState,
  useEffect,
} from 'react';

import cn from 'classnames';
import { ChevronDownIcon, ChevronUpIcon, XIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

/**
 * Option interface for dropdown items
 */
interface DropdownOption {
  id: string;
  label: string;
  description: string;
}

interface DropdownSelectProps
  extends Omit<ComponentProps<'div'>, 'inputProps' | 'onChange'> {
  className?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  options: DropdownOption[];
  selectedOptions?: string[];
  onChange?: (selectedIds: string[]) => void;
  placeholder?: string;
}

/**
 * A dropdown select component that allows searching, selecting, and displaying multiple options
 */
const DropdownSelect = forwardRef<HTMLDivElement, DropdownSelectProps>(
  (
    {
      className,
      options = [],
      selectedOptions = [],
      onChange,
      placeholder = 'Search...',
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [selected, setSelected] = useState<string[]>(selectedOptions);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownId = useId();
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Update selected options when selectedOptions prop changes
    useEffect(() => {
      setSelected(selectedOptions);
    }, [selectedOptions]);

    // Handle click outside to close dropdown
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent): void => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsDropdownOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    /**
     * Focus the search input
     */
    const focusInput = (): void => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    };

    // Filter options based on search query
    const filteredOptions = options.filter(
      (option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
        option.id.toLowerCase().includes(searchQuery.toLowerCase())
    );

    /**
     * Toggle selection of an option
     * @param optionId - The ID of the option to toggle
     */
    const toggleOption = (optionId: string): void => {
      let newSelected: string[];

      if (selected.includes(optionId)) {
        newSelected = selected.filter((id) => id !== optionId);
      } else {
        newSelected = [...selected, optionId];
        // Clear search query after selection
        setSearchQuery('');
      }

      setSelected(newSelected);

      // Call onChange prop if provided
      if (onChange) {
        onChange(newSelected);
      }

      // Keep the dropdown open after selection
      // Focus the input after selection
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    };

    /**
     * Remove a selected option
     * @param optionId - The ID of the option to remove
     * @param e - Optional mouse event to stop propagation
     */
    const removeSelection = (optionId: string, e?: React.MouseEvent): void => {
      e?.stopPropagation();

      const newSelected = selected.filter((id) => id !== optionId);
      setSelected(newSelected);

      // Call onChange prop if provided
      if (onChange) {
        onChange(newSelected);
      }

      // Focus the input after removal
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    };

    return (
      <div className="relative max-h-9" ref={dropdownRef}>
        <div
          onClick={focusInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              focusInput();
            }
          }}
          role="combobox"
          aria-expanded={isDropdownOpen}
          aria-controls={dropdownId}
          aria-haspopup="listbox"
          tabIndex={0}
          ref={ref}
          className={cn(
            'border-input file:text-foreground placeholder:text-muted-foreground selection:bg-primary selection:text-primary-foreground flex flex-wrap items-center gap-1 h-9 w-full min-w-0 rounded-md border bg-transparent px-3 py-1 text-base shadow-xs transition-[color,box-shadow] outline-none cursor-text',
            'focus-visible:border-ring focus-visible:ring-ring/50 focus-visible:ring-[3px]',
            'aria-invalid:ring-destructive/20 dark:aria-invalid:ring-destructive/40 aria-invalid:border-destructive',
            className
          )}
          {...props}
        >
          {selected.map((optionId) => {
            const option = options.find((o) => o.id === optionId);
            return (
              <div
                key={optionId}
                className="bg-[#E3F3FF] text-black rounded-md px-2 py-[2px] flex items-center text-sm"
              >
                {option?.id}
                <button
                  onClick={(e) => removeSelection(optionId, e)}
                  className="ml-1"
                  aria-label={`Remove ${option?.label || optionId}`}
                >
                  <XIcon size={14} />
                </button>
              </div>
            );
          })}
          <input
            ref={searchInputRef}
            type="text"
            placeholder={selected.length === 0 ? placeholder : ''}
            value={searchQuery}
            onChange={(e) => {
              const newValue = e.target.value;
              setSearchQuery(newValue);
              if (!isDropdownOpen) setIsDropdownOpen(true);
            }}
            onClick={() => setIsDropdownOpen(true)}
            className="flex-grow outline-none min-w-[80px]"
          />
          <button
            type="button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="ml-auto"
            aria-label={isDropdownOpen ? 'Close dropdown' : 'Open dropdown'}
          >
            {isDropdownOpen ? (
              <ChevronUpIcon size={20} />
            ) : (
              <ChevronDownIcon size={20} />
            )}
          </button>
        </div>

        {isDropdownOpen && (
          <div
            id={dropdownId}
            className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
            role="listbox"
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div key={option.id} className="p-2 hover:bg-gray-100">
                  <label
                    className="flex items-start gap-2 cursor-pointer"
                    htmlFor={`option-${option.id}`}
                    aria-label={option.label}
                  >
                    <input
                      id={`option-${option.id}`}
                      type="checkbox"
                      checked={selected.includes(option.id)}
                      onChange={() => toggleOption(option.id)}
                      className="mt-1"
                    />
                    <div>
                      <div className="font-semibold font-sans text-s leading-5 foreground pb-1">
                        {option.label}
                      </div>
                      <div className="text-xs foreground-muted leading-4 font-normal font-sans">
                        {option.description}
                      </div>
                    </div>
                  </label>
                </div>
              ))
            ) : (
              <div className="p-2 text-center text-gray-500">
                {t('common.not_found')}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

DropdownSelect.displayName = 'DropdownSelect';

export { DropdownSelect };
