import Tooltip from '@mui/material/Tooltip';
import { CircleHelpIcon } from 'lucide-react';

type Props = {
  tooltip: React.ReactNode | string;
};

function HelpTooltip({ tooltip = '' }: Props) {
  if (!tooltip) {
    return null;
  }
  return (
    <Tooltip arrow title={tooltip}>
      <CircleHelpIcon
        size={12}
        color="var(--color-foreground-muted)"
        className="inline ml-1"
      />
    </Tooltip>
  );
}

export default HelpTooltip;
