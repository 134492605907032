import { useId, useCallback } from 'react';

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { PinIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { PinFilledIcon } from '@/components/atoms/Icons/PinFilledIcon';
import { MenuItem } from '@/modules/humanChat/models';
import { IconComponent } from '@/modules/humanChat/utils';

import { Item } from './components';

type DrawerItemProps = {
  item: MenuItem;
  hovered: string;
  setHovered: (value: string) => void;
  pinnedItems: string[];
};

const iconStyles = { size: 16, color: 'var(--color-foreground-muted)' };

export const DrawerItem = ({
  item,
  setHovered,
  hovered,
  pinnedItems,
}: DrawerItemProps) => {
  const id = useId();
  const { t } = useTranslation();
  const isItemHovered = hovered === item.id;

  const isChecked = pinnedItems?.includes(item?.id);

  const handleToggle = useCallback(() => {
    if (!item?.pinned) {
      item?.onPinClick(item.id);
    }
  }, [item]);

  return (
    <Item
      isDisabled={item?.disabled}
      key={item.id}
      secondaryAction={
        isItemHovered || item?.pinned || isChecked ? (
          <Tooltip
            enterDelay={700}
            arrow
            title={item?.pinned ? t('departments.cant_unpin') : ''}
            sx={{ cursor: item?.pinned ? 'not-allowed' : 'pointer' }}
          >
            <Checkbox
              edge="end"
              onChange={handleToggle}
              checked={item?.pinned || isChecked}
              inputProps={{ 'aria-labelledby': id }}
              disableRipple={item?.pinned}
              icon={<PinIcon {...iconStyles} />}
              checkedIcon={<PinFilledIcon {...iconStyles} />}
            />
          </Tooltip>
        ) : null
      }
      disableGutters
      disablePadding
      onMouseEnter={() => setHovered(item.id)}
      onMouseLeave={() => setHovered(null)}
    >
      <IconComponent icon={item?.icon} />

      <ListItemText id={id} primary={item.text} />
    </Item>
  );
};
