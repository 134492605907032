import { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import Header from '@/components/organisms/Header/Header';
import { getIntegrationByType } from '@/components/organisms/Integrations';
import { useIntegrations } from '@/hooks/useIntegrations';
import {
  selectAccountSlug,
  selectDeskId,
  selectIntegrationId,
} from '@/redux/session/selectors';
import { previewWidgetUrl } from '@/util/util';

const IntegrationsHeader = () => {
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const integrationId = useSelector(selectIntegrationId);
  const slug = useSelector(selectAccountSlug);
  const { integration, getStatus } = useIntegrations(deskId, integrationId);
  const navigate = useNavigate();

  const webWidget = useMemo(
    () => (integration?.type === 'web' ? integration : null),
    [integration]
  );

  const handlePreviewWebChatClick = () => {
    window.open(previewWidgetUrl(webWidget), '_blank');
  };

  const handleArrowClick = useCallback(() => {
    navigate(`/${slug}/environments/${deskId}/integrations`);
  }, [deskId, navigate, slug]);

  const Icon = getIntegrationByType(integration?.type)?.Icon;

  const actions =
    integration?.type === 'web' && integration?.active ? (
      <Button onClick={handlePreviewWebChatClick}>
        {t('integrations.web_preview')}
      </Button>
    ) : null;

  if (integrationId) {
    return (
      <Header>
        <Header.BreadCrumbs />

        <Header.Body>
          <Header.Title
            title={
              <Typography
                variant="h2-semi-bold"
                color="var(--color-foreground)"
              >
                {t([`channels.${integration?.type}`, 'pages.not_found'])}
              </Typography>
            }
            icon={Icon ? <Icon /> : undefined}
            onBackClick={handleArrowClick}
            isLoading={getStatus === 'pending'}
          />

          <Header.Actions>{actions}</Header.Actions>
        </Header.Body>
      </Header>
    );
  }

  return (
    <Header>
      <Header.Body>
        <Header.Title
          title={
            <Typography
              variant="h2-semi-bold"
              mr="var(--space-24)"
              color="var(--color-foreground)"
            >
              {t('common.integrations')}
            </Typography>
          }
          withDesk
        />
      </Header.Body>
    </Header>
  );
};

export default IntegrationsHeader;
