import { forwardRef, SelectHTMLAttributes } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import sort from 'lodash/sortBy';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import HelpTooltip from '../HelpTooltip/HelpTooltip';

import styles from './Select.module.scss';

interface Option {
  value: string | boolean | number;
  label: string;
  disabled?: boolean;
}

interface OptionToTranslate {
  value: string | boolean | number;
  label_key: string;
  disabled?: boolean;
}

export interface SelectProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  label?: string;
  options: Array<Option | OptionToTranslate>;
  error?: boolean;
  register?: UseFormRegisterReturn;
  tooltip?: string;
  enablePlaceholder?: boolean;
  errorMessage?: React.ReactNode;
  size?: 'small' | 'small-full' | 'medium' | 'large' | 'full';
  height?: 'xlarge';
  isLoading?: boolean;
  placeholder?: string;
  sortBy?: string | false;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      name = 'untitled_select',
      options = [],
      onChange,
      onBlur,
      error = false,
      register,
      required = false,
      tooltip,
      enablePlaceholder,
      id = `${name}-input`,
      errorMessage,
      disabled = false,
      value = undefined,
      size = 'medium',
      isLoading = false,
      height,
      sortBy = 'label',

      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const placeholder = props.placeholder || t('common.select_an_option');

    const hasError = error || !!errorMessage;

    const sortedOptions = sortBy ? sort(options, [sortBy]) : options;

    return (
      <div
        className={cn(styles.container, styles[size], {
          [styles.withLabel]: label,
        })}
      >
        {label && (
          <label
            className={cn(styles.label, {
              [styles.labelDisabled]: disabled,
            })}
            htmlFor={id}
          >
            {label}
            {required && '*'}
            <HelpTooltip tooltip={tooltip} />
          </label>
        )}

        {isLoading ? (
          <Skeleton height={40} width={180} />
        ) : (
          <div className={styles.selectContainer}>
            <select
              className={cn(styles.input, {
                [styles.inputError]: hasError,
                [styles.small]: size === 'small',
                [styles.heightXLarge]: height === 'xlarge',
              })}
              ref={ref}
              name={name}
              id={id}
              data-testid={id}
              value={value}
              disabled={disabled}
              {...props}
              {...register}
              onChange={(event) => {
                register?.onChange(event);
                if (onChange) {
                  onChange(event);
                }
              }}
              onBlur={onBlur}
            >
              <option value="" disabled={!enablePlaceholder}>
                {placeholder}
              </option>

              {sortedOptions.map((o) => (
                <option
                  key={String(o.value)}
                  value={String(o.value)}
                  disabled={o.disabled}
                >
                  {/* Allow label_key to be passed as option and translated later on */}
                  {'label_key' in o ? t(o.label_key) : o.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;
