import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { Resolver, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Banner } from '@/components/atoms/Banner/Banner';
import Link from '@/components/atoms/Link/Link';
import Select from '@/components/atoms/Select/Select';
import { ReminderAction } from '@/models/action';
import { RootState } from '@/models/state';
import { useReminderOptions } from '@/modules/aiAgents/components/Setup/useReminderOptions';
import { selectSelectedAction } from '@/redux/dialogs/selectors';
import { updateAction, updateNode } from '@/redux/nodes/actions';
import { INACTIVITY_TIMEOUT_DOCS_URL } from '@/util/constants';
import { reminderActionSchema } from '@/util/validator';

import ToolkitWrapper from '../ToolkitWrapper';

interface Form {
  minutes: number;
}

export const ToolkitInactivityTimeout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { timeOptions, getTimerLabel } = useReminderOptions();

  const { actionId, minutes, trigger_node_id } = useSelector((state) => {
    const selectedAction = selectSelectedAction(
      state as RootState
    ) as ReminderAction;

    const { action_id: actionId, minutes, trigger_node_id } = selectedAction;

    return {
      actionId,
      minutes,
      trigger_node_id,
    };
  }, shallowEqual);

  const {
    formState: { errors },
    register,
  } = useForm<Form>({
    mode: 'onChange',
    values: {
      minutes,
    },
    resolver: yupResolver(reminderActionSchema) as Resolver<Form>,
  });

  const isCustomOption = !timeOptions.some(
    (option) => option.value === String(minutes)
  );

  const getOptions = () => {
    if (isCustomOption) {
      return [
        ...timeOptions,
        { value: String(minutes), label: getTimerLabel(minutes) },
      ].sort((a, b) => +a.value - +b.value);
    }

    return timeOptions;
  };

  return (
    <ToolkitWrapper type="reminder">
      <Select
        sortBy=""
        placeholder={t('ai_agents.reminder.trigger_after')}
        options={getOptions()}
        size="full"
        {...register('minutes')}
        onChange={(e) => {
          const minutes = e.target.value;

          dispatch(
            updateAction({
              actionId,
              action: {
                minutes: parseInt(minutes),
              },
            })
          );

          // Update the respective Reminder node
          dispatch(
            updateNode({
              nodeId: trigger_node_id,
              node: {
                name: `${t('common.after')} ${getTimerLabel(parseInt(minutes))}`,
              },
            })
          );
        }}
        error={!!errors.minutes}
        errorMessage={errors.minutes?.message}
      />

      <Box mt="var(--space-24)">
        <Banner relativePosition variant="neutral">
          <Trans
            i18nKey="dialog.inactivity_timeout.banner_text"
            components={{
              link1: <Link external href={INACTIVITY_TIMEOUT_DOCS_URL} />,
            }}
          />
        </Banner>
      </Box>
    </ToolkitWrapper>
  );
};
