import Typography from '@mui/material/Typography';
import dotize from 'dotize';
import { useTranslation } from 'react-i18next';

import { useConversationContext } from '@/hooks/useConversationContext';
import { customInputValueRender } from '@/modules/TryIt/components/VariablesSection/helper';
import { capitalizeFirstLetter } from '@/util/util';

import { ContextVariableInput } from '../ContextVariable/ContextVariableInput';

import styles from '../RightSidebar.module.scss';

export const ContextVariablesPanel = () => {
  const { t } = useTranslation();
  const { filteredContextVars } = useConversationContext();
  const dotizedContext = dotize.convert(filteredContextVars);
  const isContextEmpty = Object.entries(dotizedContext).length === 0;

  return (
    <>
      <header className={styles.header}>
        <Typography
          color="var(--color-foreground-muted)"
          variant="body-semi-bold"
        >
          {capitalizeFirstLetter(t('common.context_variables'))}
        </Typography>
      </header>

      {isContextEmpty && (
        <Typography
          color="var(--color-foreground-muted)"
          variant="label-regular"
          component="p"
        >
          {t('conversation.empty_states.no_context_variables')}
        </Typography>
      )}

      <ul className={styles.contextVariables}>
        {Object.entries(dotizedContext).map(([key, value]) => (
          <li key={key}>
            <ContextVariableInput
              label={key}
              value={dotize.convert(customInputValueRender(value))}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
