import fetch from 'cross-fetch';

import { AmendPayload, MessageSent, Response } from '@/models/tryIt';
import { X_MOVEO_ACCOUNT_SLUG } from '@/util/constants';

import { callPost } from './fetcher';

/* eslint-disable no-param-reassign */
const MAX_RETRIES = 3;

type Options = RequestInit & { retryDelay?: number };

type Body = { [key: string]: unknown };

/**
 * Convenience methods for calling the APIs
 *
 * includes .get(), .put(), .post(), and .delete() methods
 *
 * Assumes all bodies are JSON
 *
 * @param path - The API Path
 * @param [opts] - The options
 */
const client = (
  path: string,
  opts: Options = {}
): Promise<{ [key: string]: unknown }> => {
  opts.retryDelay = opts.retryDelay ?? 200;
  const options: Options = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Accept-Language': (window as any)._language || 'en', // eslint-disable-line
      'Content-Type': opts?.body ? 'application/json' : '',
      'X-Moveo-Test': 'true',
      [X_MOVEO_ACCOUNT_SLUG]: window.X_MOVEO_ACCOUNT_SLUG,
    },
    ...opts,
  };

  const url = path;
  return new Promise((resolve, reject) => {
    const retryFetch = (retryCount: number) => {
      retryCount -= 1;
      fetch(url, options)
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              return resolve({});
            }
            return resolve(response.json());
          }

          // Check if the response is 401 and reloading the page
          // This prevent `useQuery` errors from happening without the user realizing.
          if (response.status === 401 && !path.endsWith('auth/user')) {
            console.error('Reload the page due to a 401 response');
            window.location.reload();
            return;
          }

          return response
            .json()
            .catch((error) => {
              // in case the json cant be parsed
              throw Object.assign(
                new Error(
                  `Network request failed with: ${response.status} - ${response.statusText}. Please try again later.`
                ),
                {
                  statusText: response.statusText,
                  statusCode: response.status,
                  error,
                  requestId: response.headers.get('x-request-id'),
                }
              );
            })
            .then((json) => {
              const errMessage =
                json.error || json.description || JSON.stringify(json);
              throw Object.assign(new Error(errMessage), json, {
                statusText: response.statusText,
                statusCode: response.status,
                requestId: response.headers.get('x-request-id'),
              });
            });
        })
        .catch((error) => {
          if (
            retryCount > 0 &&
            error.statusCode >= 500 &&
            error.statusCode < 600
          ) {
            setTimeout(() => {
              retryFetch(retryCount);
            }, opts.retryDelay);
            return;
          }
          reject(error);
        });
    };
    if (opts.method) {
      retryFetch(['GET', 'DELETE'].includes(opts.method) ? MAX_RETRIES : 0);
    } else {
      retryFetch(0);
    }
  });
};

client.get = client;

client.post = (path: string, body: Body, options = {}) =>
  client(
    path,
    Object.assign(options, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  );

client.put = (path: string, body: Body, options = {}) =>
  client(
    path,
    Object.assign(options, {
      method: 'PUT',
      body: JSON.stringify(body),
    })
  );

client.delete = (path: string, options = {}) =>
  client(
    path,
    Object.assign(options, {
      method: 'DELETE',
    })
  );

//  User
export const updateUser = (body: Body, config?: Options) =>
  client.put(`/www/api/v1/user`, body, config);

export const deleteUser = (config?: Options) =>
  client.delete('/www/api/v1/user', config);

export const loadUser = (config?: Options) =>
  client.get('/www/api/auth/user', config);

export const logout = () => client.get('/www/logout');

// TEST
export const sendMessage = async (
  brain_id: string,
  message: MessageSent,
  config?: Options,
  host = '/www'
): Promise<{ data: Response; headers: Record<string, string> }> => {
  return await callPost(
    `${host}/api/v1/brains/${brain_id}/message`,
    message,
    config,
    {
      includeHeaders: true,
    }
  );
};

type CorrectionBody = {
  correction: string;
} & AmendPayload;

export const sendCorrection = async (
  body: CorrectionBody
): Promise<{
  status: string;
}> => {
  const newBody = {
    ...body,
    timestamp: Date.now(),
  };
  return await callPost('/www/api/v1/brains/corrections', newBody);
};

// User Exists
export const userExists = (body: { email: string }) =>
  client.post('/www/api/auth/exists', body);

export const getRtmToken = () => client.get('/www/rtm');

export const pagerDutyNotify = (body: {
  summary: string;
  component_stack: string;
  url: string;
  query: string;
}) => client.post('/www/pagerduty', body);
