import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import CustomChip from '@/components/atoms/Chip/Chip';
import { GooglesheetAction } from '@/models/action';

import styles from './GoogleSheet.module.scss';

const GoogleSheet = ({ action }: { action: GooglesheetAction }) => {
  const { t } = useTranslation();
  const { inputs = [], sheet_id } = action;
  const totalInputs = inputs.length;
  const hiddenInputs = totalInputs - 2;

  return (
    <>
      <Typography
        component="div"
        variant="label-regular"
        color="var(--color-foreground-muted)"
      >
        {t('actions.googlesheet.sheet', { 0: sheet_id })}
      </Typography>
      <div className={styles.inputs}>
        {inputs.slice(0, 2).map((label: string) => (
          <CustomChip
            key={label}
            size="small"
            customVariant="neutral"
            label={label}
            className={styles.input}
          />
        ))}
        {totalInputs > 2 && (
          <Typography className={styles.plus} variant="label-regular">
            {`+${hiddenInputs}`}
          </Typography>
        )}
        {isEmpty(inputs) && <p>{t('dialog.no_input')}</p>}
      </div>
    </>
  );
};

export default GoogleSheet;
