import { useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import { EllipsisVerticalIcon } from 'lucide-react';

import IconButton from '@/components/atoms/IconButton/IconButton';
import { CustomMenu } from '@/components/atoms/Menu/Menu';

import styles from './Header.module.scss';

export const Menu = ({ menuItems }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        ariaLabel="more"
        ariaControls={open ? 'rules-header-menu' : undefined}
        ariaHasPopUp
        onClick={handleClick}
      >
        <EllipsisVerticalIcon size={20} />
      </IconButton>
      <CustomMenu
        id="rules-header-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        {menuItems.map((option) => (
          <MenuItem
            key={option.name}
            onClick={option.onClick}
            disabled={option.disabled}
            className={cn(styles.option, styles[option?.type])}
          >
            {option.icon}
            <span>{option.name}</span>
          </MenuItem>
        ))}
      </CustomMenu>
    </>
  );
};
