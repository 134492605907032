import { useCallback, useMemo } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { MODAL_TEMPLATE_MESSAGE } from '@/components/organisms/Modals/ModalConductor';
import useMessages from '@/hooks/useMessages';
import { useConversations } from '@/modules/humanChat/hooks/useConversations';
import { popModal, pushModal } from '@/redux/modals/actions';
import { selectDeskId, selectSessionId } from '@/redux/session/selectors';

export const useTemplateMessage = () => {
  const deskId = useSelector(selectDeskId);
  const sessionId = useSelector(selectSessionId);

  const dispatch = useDispatch();
  const { conversation } = useConversations({
    deskId,
    sessionId,
  });

  const { messages } = useMessages(deskId, sessionId);
  const firstBatchOfMessages = messages?.pages?.[0]?.messages;

  const mostRecentUserMessage = useMemo(
    () =>
      firstBatchOfMessages
        ?.sort((a, b) => {
          const dateA = new Date(a.created).getTime();
          const dateB = new Date(b.created).getTime();
          return dateB - dateA;
        })
        ?.find(
          (message) => message.author_type === 'visitor' && !message.failed_at
        ),
    [firstBatchOfMessages]
  );

  const handleClick = useCallback(() => {
    dispatch(
      pushModal(MODAL_TEMPLATE_MESSAGE, {
        desk_id: conversation?.desk_id,
        integration_id: conversation?.integration_id,
        onPrimaryButtonClick: () => {
          dispatch(popModal());
        },
      })
    );
  }, [conversation?.desk_id, conversation?.integration_id, dispatch]);

  const isExpired = !conversation?.state?.active;
  const isWhatsapp = conversation?.channel === 'whatsapp';

  const isLastMessageAfterOneDay =
    moment().diff(moment.utc(mostRecentUserMessage?.created), 'days') >= 1;

  const showTemplateMessage =
    isLastMessageAfterOneDay && isWhatsapp && !isExpired;

  return {
    showTemplateMessage,
    handleClick,
  };
};
