/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import Link from '@/components/atoms/Link/Link';

import styles from './InAppHelp.module.scss';

export type InAppHelpTheme = 'dark' | 'light' | 'ai';

type Props = {
  placement?: TooltipProps['placement'];
  primaryAction?: string;
  secondaryAction?: { text: string; link: string };
  steps: Step[];
  children?: React.ReactNode;
  defaultOpen?: boolean;
  cardStyles?: Record<string, string>;
  arrowStyles?: Record<string, string>;
  onClose?: () => void;
  theme?: InAppHelpTheme;
};

export type Step = {
  title?: string;
  icon?: React.ReactNode;
  body: React.ReactNode;
  imageSource?: string;
};

const getTheme = (theme: InAppHelpTheme) => {
  if (theme === 'light') {
    return [styles.wrapper, 'var(--color-text-primary-foreground)'];
  }

  if (theme === 'ai') {
    return [`${styles.wrapper} ${styles['wrapper--ai']}`, 'var(--color-ai)'];
  }

  return [
    `${styles.wrapper} ${styles['wrapper--dark']}`,
    'var(--color-blue-950)',
  ];
};
const InAppHelp = ({
  placement = 'bottom-end',
  primaryAction,
  secondaryAction,
  theme = 'light',
  steps,
  children,
  defaultOpen = false,
  cardStyles = {},
  arrowStyles = {},
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(steps[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const STEPS_LENGTH = steps.length;
  const { imageSource, title, body, icon } = step;
  const [wrapperClass, themeColor] = getTheme(theme);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const handleClick = () => {
    if (currentIndex === STEPS_LENGTH - 1) {
      setOpen(false);
      onClose();
      return;
    }

    setStep(steps[currentIndex + 1]);
    setCurrentIndex((index) => index + 1);
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: 'transparent',
            borderRadius: 0,
            padding: 0,
            boxShadow: 'var(--tile-box-shadow-default)',
            margin: 'var(--space-22) 0',
            ...cardStyles,

            '& .MuiTooltip-arrow': {
              color: `${themeColor}`,
              fontSize: 'var(--space-22)',
              ...arrowStyles,

              '&::before': {
                boxShadow: 'var(--tile-box-shadow-default)',
              },
            },
          },
        },
      }}
      arrow
      open={open}
      placement={placement}
      title={
        <>
          <div className={wrapperClass}>
            {imageSource && (
              <div className={styles.image}>
                <img src={imageSource} alt="" />
              </div>
            )}

            {STEPS_LENGTH > 1 && (
              <div className={styles.secondaryLabel}>
                <Typography variant="label-regular">
                  {`${t('in_app_help.step')} ${
                    currentIndex + 1
                  } / ${STEPS_LENGTH}`}
                </Typography>
              </div>
            )}

            <div className={styles.mainLabel}>
              {icon && icon}
              <div>
                {title && <Typography variant="body-bold">{title}</Typography>}
                {body}
              </div>
            </div>

            <div className={styles.actionButtons}>
              {secondaryAction && (
                <Link
                  fontSize={14}
                  href={secondaryAction.link}
                  className={styles.secondaryAction}
                >
                  {secondaryAction.text}
                </Link>
              )}
              <button className={styles.primaryAction}>
                <Typography onClick={handleClick} variant="body-bold">
                  {currentIndex === STEPS_LENGTH - 1
                    ? t('in_app_help.close_button')
                    : primaryAction || t('in_app_help.next')}
                </Typography>
              </button>
            </div>
          </div>
        </>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export default InAppHelp;
