import { t } from 'i18next';
import {
  BlocksIcon,
  BookmarkIcon,
  ChartPieIcon,
  FolderCodeIcon,
  MegaphoneIcon,
  RocketIcon,
  WandSparklesIcon,
  ZapIcon,
} from 'lucide-react';

import IfIcon from '@/components/atoms/Icons/IfIcon';
import { MessageQuestionIcon } from '@/components/atoms/Icons/MessageQuestionIcon';
import Brand from '@/components/atoms/Icons/Navigation/Brand';

const ICON_COLOR = 'var(--color-foreground-muted)';

export const navigationItems = (deskId: string, ai_agents: boolean = false) => [
  {
    icon: <Brand color="var(--color-background)" />,
    link: '/',
    i18nKey: 'main_navigation.home',
  },
  {
    icon: (
      <WandSparklesIcon
        color="var(--color-background)"
        className="p-1.5"
        size={32}
      />
    ),
    i18nKey: 'main_navigation.build',
    submenuItems: [
      {
        icon: <ZapIcon size={16} color={ICON_COLOR} />,
        title: ai_agents ? t('common.ai_agents') : t('common.brains'),
        link: ai_agents ? '/ai-agents' : '/brains',
      },
      {
        icon: <BookmarkIcon size={16} color={ICON_COLOR} />,
        title: t('main_navigation.collections'),
        link: '/collections',
      },
      {
        icon: <MegaphoneIcon size={16} color={ICON_COLOR} />,
        title: t('common.broadcasts'),
        link: '/broadcasts',
      },
    ],
  },
  {
    icon: (
      <RocketIcon size={32} color="var(--color-background)" className="p-1.5" />
    ),
    i18nKey: 'main_navigation.deploy',
    submenuItems: [
      {
        icon: <BlocksIcon size={16} color={ICON_COLOR} />,
        title: t('main_navigation.integrations'),
        link: deskId ? `/environments/${deskId}/integrations` : '/integrations',
      },
      {
        icon: <IfIcon color={ICON_COLOR} />,
        title: t('main_navigation.rules'),
        link: deskId ? `/environments/${deskId}/rules` : '/rules',
      },
      {
        icon: <FolderCodeIcon size={18} color={ICON_COLOR} />,
        title: t('developer_tools.title'),
        link: '/devtools',
      },
    ],
  },
  {
    icon: (
      <ChartPieIcon
        className="p-1.5"
        size={32}
        color="var(--color-background)"
      />
    ),
    link: '/analytics',
    i18nKey: 'main_navigation.improve',
  },
  {
    icon: (
      <MessageQuestionIcon
        className="p-1.5"
        size={32}
        color="var(--color-background)"
      />
    ),
    link: '/chats',
    i18nKey: 'main_navigation.live_chat',
  },
];
