import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { AlertTriangleIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { ConversationSource } from '@/models/chat';
import { BroadcastAction } from '@/modules/broadcast/models';
import { Attachment } from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachment';
import Attachments from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachments';
import { selectSessionSource } from '@/modules/TryIt/redux/selectors';
import { splitArray } from '@/util/util';

import styles from '../MessageNode/MessageNode.module.scss';

interface AttachmentType {
  id?: string;
  url: string;
  type: 'audio' | 'video' | 'image' | 'file';
  name?: string;
  size?: number;
  width?: number;
  height?: number;
  // Using unknown instead of any to satisfy ESLint
  [key: string]: string | number | boolean | undefined;
}

interface Props {
  text: string;
  author_type: string;
  author_id: string;
  template?: BroadcastAction;
  body: Record<string, string | AttachmentType[] | unknown>;
}

interface OptionType {
  text: string;
  label: string;
}

const MessageSender = ({
  authorType,
  authorId,
  errorCode,
}: {
  authorType: string;
  authorId: string;
  errorCode?: string;
}) => {
  const { t } = useTranslation();

  if (errorCode) {
    return (
      <Tooltip title={t(`error_codes.${errorCode}`)} placement="top">
        <AlertTriangleIcon color="red" size={24} />
      </Tooltip>
    );
  }

  if (authorType === 'agent') {
    return <Avatar size="medium" userId={authorId} />;
  }

  return null;
};

/**
 * Renders message attachments grid
 */
const MessageAttachments = ({
  attachments,
  hasText,
  source,
}: {
  attachments: AttachmentType[];
  hasText: boolean;
  source?: ConversationSource;
}) => {
  if (!attachments.length) return null;

  return (
    <Box mb={hasText ? 1 : 0}>
      {splitArray(attachments, 4).map((chunk, chunkIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={chunkIndex}>
          <Attachments>
            {chunk.map((attachment) => (
              <Attachment
                key={attachment?.id ?? attachment.url}
                attachment={attachment}
                source={source}
                isSingleItem={attachments.length === 1}
                bg="light"
                authorType="agent"
              />
            ))}
          </Attachments>
        </div>
      ))}
    </Box>
  );
};

/**
 * Renders message options buttons
 */
const MessageOptions = ({ options }: { options?: OptionType[] }) => {
  if (!options?.length) return null;

  return (
    <div className={styles.optionsContainer}>
      {options.map((option) => (
        <button
          key={`option-${option.text}`}
          className={cn(styles.bubble, styles.option, styles.optionDisabled)}
          onClick={() => {}}
          type="button"
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

/**
 * Renders an audio message
 */
const AudioMessage = ({ url }: { url: string }) => <audio src={url} controls />;

/**
 * Renders a text message with attachments
 */
const TextMessage = ({
  html,
  attachments,
  hasText,
  source,
}: {
  html: string;
  attachments: AttachmentType[];
  hasText: boolean;
  source?: ConversationSource;
}) => (
  <div className={cn(styles.bubble, styles.agent)}>
    <MessageAttachments
      attachments={attachments}
      hasText={hasText}
      source={source}
    />

    {html && (
      <Typography
        variant="body-regular"
        component="p"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )}
  </div>
);

/**
 * AgentMessage component handles different types of agent messages (text, audio, attachments)
 */
export const AgentMessage = ({
  text,
  template,
  author_type,
  author_id,
  body,
}: Props) => {
  const source = useSelector(selectSessionSource);
  const { html } = useMarkdownToHtml(text);
  const errorCode = body?.error_code as string | undefined;

  // Extract button options from template if available
  const options = template?.components
    ?.find((component) => component.type.toLowerCase() === 'buttons')
    ?.buttons?.map((option) => ({
      text: option.text,
      label: option.text,
    }));

  const attachments = (
    Array.isArray(body?.attachments) ? body?.attachments : []
  ) as AttachmentType[];

  if (body?.type === 'audio') {
    return <AudioMessage url={body.url as string} />;
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <MessageSender
          authorType={author_type}
          authorId={author_id}
          errorCode={errorCode}
        />

        <div className={styles.response}>
          <TextMessage
            html={html}
            attachments={attachments}
            hasText={!!text}
            source={source}
          />
        </div>
      </Box>

      <MessageOptions options={options} />
    </>
  );
};

export default AgentMessage;
