import 'cross-fetch/polyfill';
import { createRoot } from 'react-dom/client';

import 'symbol-observable';
import 'intersection-observer';
import App from './client/components/templates/App/App';
import './client/i18n/i18n';

const container =
  document.getElementById('root') || document.createElement('div');
const root = createRoot(container);
root.render(<App />);
