import { memo, useState, useCallback } from 'react';

import { WebhookIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import HelpTooltip from '@/components/atoms/HelpTooltip/HelpTooltip';
import Spinner from '@/components/atoms/Spinner/Spinner';
import Tab from '@/components/atoms/Tab/Tab';
import TabPanel from '@/components/atoms/TabPanel/TabPanel';
import Tabs from '@/components/atoms/Tabs/Tabs';
import { Response } from '@/components/organisms/TryIt/MessageNode/MessageBubble/MessageBubble';
import { noop } from '@/util/util';

import ErrorInfo from './ErrorInfo';
import JSONPretty from './JsonPretty/JsonPretty';
import { monikai } from './JsonPretty/monikai';
import TestResults from './TestResults';
import Warning from './Warning';

import styles from './Webhook.module.scss';

const TestSection = ({
  testResults,
  testStatus,
  udpateContext,
  updateDisabled,
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const showError = testResults?.error !== null || testResults?.duration > 5000;

  const statusCode = useCallback(() => {
    return testResults?.error?.status_code
      ? testResults?.error?.status_code
      : testResults && 200;
  }, [testResults]);

  return (
    <div className={styles.testSection}>
      {!testResults && testStatus === 'pending' && (
        <Spinner className={styles.spinner} size="small" />
      )}
      {!testResults && testStatus !== 'pending' && (
        <div className={styles.testMessage}>
          <div>
            <WebhookIcon size={100} color="var(--color-foreground-muted)" />
          </div>
          <div>{t('webhook.test_help')}</div>
        </div>
      )}

      {testResults && (
        <>
          <TestResults
            statusCode={statusCode()}
            executionTime={testResults?.duration}
          />

          {showError && <ErrorInfo info={testResults} />}

          <div className={styles.testTabWrapper}>
            <Tabs
              value={tabIndex}
              onChange={(_, newValue) => {
                setTabIndex(newValue);
              }}
            >
              <Tab value={0} label={t('webhook.test.preview')} />
              <Tab value={1} label={t('webhook.json')} />
            </Tabs>
          </div>

          <TabPanel value={tabIndex} index={0}>
            {testResults.error && <Warning />}
            {testResults.error === null && (
              <>
                {Object.keys(testResults.context || {}).length > 0 && (
                  <>
                    <div className={styles.resultsHeader}>
                      <span>{t('common.context_variables')}</span>
                      <span>
                        <HelpTooltip tooltip={t('webhook.test.upon_saving')} />
                      </span>
                      <Button
                        size="xs"
                        onClick={udpateContext}
                        className={styles.button}
                        disabled={updateDisabled}
                      >
                        {t('webhook.test.update_context')}
                      </Button>
                      {updateDisabled && (
                        <HelpTooltip tooltip={t('webhook.test.save_first')} />
                      )}
                    </div>
                    <div className={styles.contextWrapper}>
                      {Object.keys(testResults.context || {}).map(
                        (context, index) => (
                          <div
                            className={styles.context}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${context}_${index}`}
                          >
                            <div className={styles.key}>{context}</div>
                            <div className={styles.value}>
                              {JSON.stringify(testResults?.context[context])}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )}

                {testResults?.responses.length > 0 && (
                  <>
                    <div className={styles.resultsHeader}>
                      <span>responses</span>
                    </div>
                    <div className={styles.responsesContainer}>
                      <div className={styles.responses}>
                        {testResults?.responses?.map((response, index) => {
                          return (
                            <div
                              className={styles[response.type]}
                              // eslint-disable-next-line react/no-array-index-key
                              key={`${response}_${index}`}
                            >
                              <Response
                                onOptionClick={noop}
                                onPostback={noop}
                                {...response}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            {testResults.error && <Warning />}
            {testResults.error === null && (
              <div className={styles.jsonWrapper}>
                {testResults && (
                  <JSONPretty json={testResults} theme={monikai} />
                )}
              </div>
            )}
          </TabPanel>
        </>
      )}
    </div>
  );
};

export default memo(TestSection);
