import cn from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';

import Node from '@/components/organisms/Dialogs/Node/Node';
import NodePlaceholder from '@/components/organisms/Dialogs/Node/Placeholder/Placeholder';
import { RootState } from '@/models/state';
import {
  selectBranchesByNodeId,
  selectDraftDialogNodes,
} from '@/redux/dialogs/selectors';

import BranchIntersection from './BranchIntersection';
import BranchLine from './BranchLine';

import styles from './BranchList.module.scss';

function getAlignment(value, branchSize) {
  if (branchSize > 2 || branchSize === 2) {
    if (value === 0) {
      return 'left';
    } else if (value === branchSize - 1) {
      return 'right';
    }
  }
  return 'center';
}

// nodeId here is the parent node_id that this branch belongs to
function BranchList({ nodeId }: { nodeId: string }) {
  const branches = useSelector(
    (state: RootState) => selectBranchesByNodeId(state, nodeId),
    shallowEqual
  );
  const draftDialogNodes = useSelector(selectDraftDialogNodes);

  if (!branches || branches.length === 0) {
    return <NodePlaceholder parentId={nodeId} />;
  }

  const isReminderNode =
    draftDialogNodes.filter(
      (node) => node.parent_id === nodeId && node.isReminderNode
    ).length > 0;
  const hasParentNodeConditions = !!draftDialogNodes.find(
    (node) => node.node_id === nodeId && node.conditions
  );

  return (
    <>
      <div
        className={cn(styles.branchList, {
          [styles['branchList--reminder']]:
            isReminderNode && !hasParentNodeConditions,
        })}
      >
        {!isReminderNode && <BranchIntersection />}
        {branches.map((id: string, index: number) => (
          <div key={id} className={styles.wrapper}>
            <BranchLine
              direction={getAlignment(index, branches?.length || 0)}
              branches={branches?.length}
              isReminderNode={isReminderNode}
            />
            <Node
              key={id}
              internal
              nodeId={id}
              showBranch={index === branches.length - 1}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default BranchList;
