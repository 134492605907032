import { HTMLInputTypeAttribute } from 'react';

import cn from 'classnames';
import { CircleMinusIcon, CirclePlusIcon } from 'lucide-react';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Input from '@/components/atoms/Input/Input';
import { errorMessage } from '@/util/validator';

import styles from './Inputs.module.scss';

interface Item {
  id: string;
  name: string;
}
interface Values {
  name: string;
}

interface Props<FormType> {
  fields: Item[];
  errors: FieldErrors<FormType>;
  register: UseFormRegister<FormType>;
  values: Values[];
  onCreate: () => void;
  onDelete: (index: number) => void;
  namePrefix: string;
  validator?: unknown;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  prefix?: string;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
  onChange?: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}
const Inputs = <FormType,>({
  fields,
  errors,
  register,
  values,
  onCreate,
  onDelete,
  namePrefix,
  validator,
  onPaste,
  prefix,
  placeholder,
  type = 'text',
  onChange,
  onKeyDown,
}: Props<FormType>) => {
  const { t } = useTranslation();
  return (
    <>
      {fields.map((item: Item, index: number) => (
        <div className={styles.input} key={item.id}>
          <div
            className={cn('input__container', styles.wrapper, {
              [styles.withPrefix]: !!prefix,
            })}
          >
            {prefix && (
              <Input size="large" value={prefix} readOnly tabIndex={-1} />
            )}
            <Input
              key={item.id}
              register={register(
                `${namePrefix}.${index}.name` as Path<FormType>,
                validator
              )}
              errorMessage={errorMessage({
                field: errors?.[index]?.name,
              })}
              type={type}
              onPaste={onPaste}
              placeholder={placeholder}
              size="large"
              trimValue
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          </div>
          {index == 0 ? (
            <IconButton
              ariaLabel={t('common.create')}
              onClick={onCreate}
              disabled={
                values?.[index]?.name === '' ||
                !!values?.[index - 1] ||
                !!errors?.[namePrefix]
              }
            >
              <CirclePlusIcon size={16} color="var(--color-foreground-muted)" />
            </IconButton>
          ) : (
            <IconButton
              ariaLabel={t('common.delete')}
              onClick={() => onDelete(index)}
            >
              <CircleMinusIcon
                size={16}
                color="var(--color-foreground-muted)"
              />
            </IconButton>
          )}
        </div>
      ))}
    </>
  );
};

export default Inputs;
