import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { ResetAction } from '@/models/action';

const Reset = ({ action }: { action: ResetAction }) => {
  const { t } = useTranslation();
  const { variables = [] } = action;

  return (
    <Typography color="var(--color-foreground-muted)" variant="label-regular">
      {variables.length > 0
        ? t('dialog.variables', {
            0: variables.length,
            count: variables.length,
          })
        : t('dialog.all_variables')}
    </Typography>
  );
};

export default Reset;
