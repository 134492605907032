import { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Trash2Icon } from 'lucide-react';
import { Resolver, useFieldArray, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Button from '@/components/atoms/Button/Button/Button';
import Select from '@/components/atoms/Select/Select';
import {
  MODAL_DIALOG_PREVIEW,
  MODAL_CREATE_SAMPLE_DIALOG,
} from '@/components/organisms/Modals/ModalConductor';
import { CreateSampleDialogFormValues } from '@/components/organisms/Modals/ModalCreateSampleDialog';
import { renderDialogIcon } from '@/components/organisms/SubNav/TreeView/Item';
import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { BrainReminder } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { languageSupportsEnhance } from '@/redux/dialogs/helper';
import { popModal, pushModal } from '@/redux/modals/actions';
import { clearDialogNodes, setDialog } from '@/redux/nodes/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { setDialogId } from '@/redux/session/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { reminderFormSchema } from '@/util/validator';

import { useReminderOptions } from './useReminderOptions';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

import styles from './Setup.module.scss';

interface Props {
  number: number;
}

interface NodeOption {
  dialog_name: string;
  value: string;
  label: string;
  node_type: string;
}

interface FormReminder extends Pick<BrainReminder, 'reminder_id' | 'minutes'> {
  node: NodeOption;
}

interface Form {
  reminders: FormReminder[];
}

interface DialogOption {
  value: string;
  dialog_id: string | null;
  dialog_name?: string;
}

const FORM_ID = 'ai-agent-reminder';
export const AI_AGENT_REMINDER_FORM_ID = FORM_ID;

// Default values for a new timer
const DEFAULT_TIMER_VALUES = {
  reminder_id: uuidv4(),
  minutes: null,
  node: null,
} as const;

export const Reminder = ({ number }: Props) => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);
  const [fetchDialogId, setFetchDialogId] = useState<string | null>(null);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  // Custom hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { detailedOptions, dialog } = useDialogs(brainId, fetchDialogId);
  const { brain, updateBrain } = useBrains(brainId);
  const { enhance_response } = useFeatureFlag();
  const { guidelines = {} } = brain || {};
  const { reminders = [] } = guidelines || {};
  const { timeOptions, getTimerLabel } = useReminderOptions();

  // Local state
  const [isAddTimerEnabled, setIsAddTimerEnabled] = useState(false);

  // Transform API reminders data into form data structure
  const formReminders = reminders.map((reminder) => ({
    reminder_id: reminder.reminder_id,
    minutes: reminder.minutes,
    node: detailedOptions.find((option) => option.value === reminder.node_id),
  }));

  // RHF
  const {
    control,
    handleSubmit,
    register,
    watch,
    trigger,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<Form>({
    mode: 'onSubmit',
    // Initialize with default timer if no reminders exist, otherwise use existing reminders
    values: {
      reminders:
        reminders.length === 0 ? [DEFAULT_TIMER_VALUES] : formReminders,
    },
    resolver: yupResolver(reminderFormSchema) as Resolver<Form>,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'reminders',
  });

  const onSubmit = (data: Form) => {
    let reminders: BrainReminder[];
    // If only one empty reminder exists, save as empty array
    if (
      data.reminders.length === 1 &&
      !data.reminders[0].minutes &&
      !data.reminders[0].node
    ) {
      reminders = [];
    } else {
      // Transform form data back to API structure
      reminders = data.reminders
        .map((reminder) => ({
          reminder_id: reminder.reminder_id,
          minutes: +reminder.minutes,
          node_id: reminder.node.value,
        }))
        // Sort by minutes in ascending order
        .sort((a, b) => a.minutes - b.minutes);
    }

    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...guidelines,
        reminders,
      },
    });
  };

  const watchTimers = watch('reminders');
  // This error messages is for the complex validation logic for
  // allowing or disallowing empty fields to be validated
  const rootErrorMessage = errors.reminders?.root?.message;

  // Check if all timers have both minutes and node selected
  const areTimersValid = useCallback(() => {
    return watchTimers.every((timer) => timer.minutes && timer.node);
  }, [watchTimers]);

  // Validate timer configurations and update add timer button state
  const validateTimers = useCallback(() => {
    const hasReachedMaxTimers = watchTimers.length === 3;

    if (hasReachedMaxTimers) {
      setIsAddTimerEnabled(false);
      return;
    }

    if (areTimersValid()) {
      setIsAddTimerEnabled(true);
    } else {
      setIsAddTimerEnabled(false);
    }
  }, [areTimersValid, watchTimers]);

  // Update add timer button state when reminders change
  useEffect(() => {
    validateTimers();
  }, [fields, validateTimers]);

  useEffect(() => {
    if (dialog) {
      dispatch(setDialog(dialog));
    }

    return () => {
      dispatch(clearDialogNodes());
    };
  }, [dialog, dispatch]);

  const CREATE_SAMPLE_DIALOG = {
    // This is a workaround to separate the create sample dialog from the other options
    dialog_name: '----------------------',
    dialog_id: null,
    value: 'create_sample_dialog',
    label: t('ai_agents.reminder.create_sample_dialog'),
    node_type: 'create_sample_dialog',
  };

  const finalOptions = [...detailedOptions, CREATE_SAMPLE_DIALOG];
  const { createDialog } = useDialogs(brainId);

  const handleAutoCompleteChange = (index: number, data: DialogOption) => {
    validateTimers();

    // Handle "Create Sample Dialog"
    if (data?.value === 'create_sample_dialog' && data?.dialog_id === null) {
      // Reset the node value to null to prevent from selecting
      // the "Create Sample Dialog" option
      update(index, {
        ...watchTimers[index],
        node: null,
      });

      // Create the modal props
      const dialogName = watchTimers[index].minutes
        ? `${t('ai_agents.reminder.title')} ${getTimerLabel(watchTimers[index].minutes)}`
        : '';
      const modalProps = {
        dialogName,
        handleSubmit: (data: CreateSampleDialogFormValues) => {
          createDialog(
            {
              dialog: {
                name: data.dialogName,
                collection: 'Reminders',
                nodes: [
                  {
                    name: data.dialogName,
                    type: 'event',
                    enhance: enhance_response
                      ? languageSupportsEnhance(brain?.language)
                      : false,
                    actions: [
                      {
                        type: 'text',
                        texts: [data.textResponse],
                        action_id: uuidv4(),
                      },
                    ],
                    node_id: uuidv4(),
                    requisites: [],
                  },
                ],
              },
              shouldNavigate: false,
            },
            {
              onSuccess: (dialog) => {
                dispatch(popModal());
                // Update the node value with the new dialog
                update(index, {
                  ...watchTimers[index],
                  node: {
                    dialog_name: dialog.name,
                    value: dialog.nodes[0].node_id,
                    label: dialog.nodes[0].name,
                    node_type: 'event',
                  },
                });
              },
            }
          );
        },
      };

      // Open the modal
      dispatch(pushModal(MODAL_CREATE_SAMPLE_DIALOG, modalProps));
    }
  };

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <FormCard.Header
        title={t('ai_agents.reminder.title')}
        subtitle={t('ai_agents.reminder.subtitle')}
        icon={
          <NumberIcon
            color="var(--color-foreground-muted)"
            size="large"
            number={number}
          />
        }
      />
      <FormCard.Content>
        <Typography
          component="h4"
          variant="label-caps-large"
          color="var(--color-foreground-muted)"
          mb="var(--space-8)"
        >
          {t('ai_agents.reminder.timers')}
        </Typography>

        <Box
          component="ul"
          display="flex"
          flexDirection="column"
          gap="var(--space-24)"
        >
          {fields.map((field, index) => {
            const isLastTimer = index + 1 === fields.length;
            const isFirstTimerEmpty =
              index === 0 &&
              !watchTimers[index]?.minutes &&
              !watchTimers[index]?.node;
            // Show remove button if it is the last timer and the first timer is not empty
            const showRemoveButton = isLastTimer && !isFirstTimerEmpty;
            const showPreviewButton = !!watchTimers[index]?.node;

            // Compute selected minutes from other selects (exclude current field)
            const otherSelectedMinutes = watchTimers
              .filter((_, i) => i !== index)
              .map((timer) => String(timer.minutes));

            // Create an array of reminders that are not included in the timeOptions
            // This is to allow custom reminders to be added
            const customReminders =
              watchTimers
                .filter(
                  (timer) =>
                    timer.minutes &&
                    // Check if the timer is not included in the standard time options
                    !timeOptions.some(
                      (option) => option.value === String(timer.minutes)
                    )
                )
                .map((timer) => ({
                  value: String(timer.minutes),
                  label: getTimerLabel(timer.minutes),
                })) || [];

            // Create updated time options with disabled property if already selected elsewhere
            const updatedTimeOptions = timeOptions
              .concat(customReminders)
              .map((option) => ({
                ...option,
                disabled: otherSelectedMinutes.includes(option.value),
              }))
              // Sort by value in ascending order in case the custom reminders are added
              .sort((a, b) => +a.value - +b.value);

            return (
              <li key={field.id}>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={cn(styles.timer, {
                    [styles.minutesEmpty]: !watchTimers[index]?.minutes,
                  })}
                >
                  <NumberIcon
                    color="var(--color-foreground-muted)"
                    size="large"
                    number={index + 1}
                  />
                  <Box className={styles.inputsWrapper}>
                    <Select
                      sortBy={false}
                      placeholder={t('ai_agents.reminder.trigger_after')}
                      options={updatedTimeOptions}
                      {...register(`reminders.${index}.minutes`)}
                      onChange={(e) => {
                        register(`reminders.${index}.minutes`).onChange(e);

                        validateTimers();
                      }}
                      error={!watchTimers[index].minutes && !!rootErrorMessage}
                      errorMessage={
                        !watchTimers[index].minutes && rootErrorMessage
                          ? rootErrorMessage
                          : undefined
                      }
                    />

                    <div>
                      <AutocompleteNew
                        name={`reminders.${index}.node`}
                        control={control}
                        options={finalOptions}
                        size="medium"
                        hasError={
                          !watchTimers[index].node && !!rootErrorMessage
                        }
                        errorMessage={
                          !watchTimers[index].node && rootErrorMessage
                            ? rootErrorMessage
                            : undefined
                        }
                        placeholder={t('dialog.jump_to.placeholder')}
                        groupByLabelProp={false}
                        groupByProp="dialog_name"
                        renderIconInFront={renderDialogIcon}
                        autoHighlight
                        onChange={(_: unknown, data: DialogOption) =>
                          handleAutoCompleteChange(index, data)
                        }
                        enableNewEntry
                        disabled={!canWrite}
                      />
                    </div>
                  </Box>

                  {showPreviewButton && (
                    <Box marginTop="2px">
                      <Button
                        type="button"
                        variant="tertiary"
                        onClick={() => {
                          const dialogId = detailedOptions.find(
                            (option) =>
                              option.value === watchTimers[index].node.value
                          )?.dialog_id;
                          const modalProps = {
                            name: watchTimers[index].node.dialog_name,
                            dialogId,
                          };
                          setFetchDialogId(dialogId);
                          dispatch(setDialogId(dialogId));

                          dispatch(pushModal(MODAL_DIALOG_PREVIEW, modalProps));
                        }}
                      >
                        {t('dialog.actions.preview')}
                      </Button>
                    </Box>
                  )}

                  {showRemoveButton && (
                    <IconButton
                      sx={{
                        marginTop: '8px',
                        alignSelf: 'start',
                      }}
                      disabled={!canWrite}
                      onClick={async () => {
                        // If there is only one timer, reset the form
                        if (fields.length === 1) {
                          update(0, DEFAULT_TIMER_VALUES);
                          return;
                        }
                        remove(index);
                        await trigger();
                      }}
                    >
                      <Trash2Icon size={16} />
                    </IconButton>
                  )}
                </Box>

                {!!watchTimers[index].minutes && !!watchTimers[index].node && (
                  <Typography
                    component="p"
                    variant="label-regular"
                    color="var(--color-foreground-muted)"
                    mt="var(--space-8)"
                    ml="var(--space-36)"
                    minHeight="var(--space-16)"
                  >
                    <>
                      <Trans
                        i18nKey="ai_agents.reminder.helper_text"
                        values={{
                          0: getTimerLabel(watchTimers[index].minutes),
                          1: watchTimers[index].node.dialog_name,
                        }}
                      />
                    </>
                  </Typography>
                )}
              </li>
            );
          })}
        </Box>

        <Box mt="var(--space-24)" ml="var(--space-16)">
          <Button
            type="button"
            variant="tertiary"
            onClick={() => {
              append({
                reminder_id: uuidv4(),
                minutes: null,
                node: null,
              });
            }}
            // The form validation is different from the disabled state of the button
            disabled={!isAddTimerEnabled || !canWrite}
          >
            {t('ai_agents.reminder.add_timer')}
          </Button>
        </Box>
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
