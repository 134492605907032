import { useTranslation } from 'react-i18next';

import HeatMap from '@/components/organisms/Analytics/HeatMap/HeatMap';
import AnalyticsFieldset, {
  GraphType,
} from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';

import useMessagesHeatMap from '../../../hooks/useMessagesHeatMap';

const HeatMapFieldset = () => {
  const { t } = useTranslation();
  const { error, isLoading, messages, maxMessageCount, refetch } =
    useMessagesHeatMap();
  const title = t('analytics.messagesByTime');

  const handleReload = () => {
    refetch();
  };

  if (!messages || error) {
    return (
      <AnalyticsFieldset
        onReload={handleReload}
        title={title}
        isLoading={isLoading}
        fullWidth
        error={error}
      >
        <p>{`${error}`}</p>
      </AnalyticsFieldset>
    );
  }

  const isEmpty = maxMessageCount === 0;

  return (
    <AnalyticsFieldset
      title={title}
      fullWidth
      isEmpty={isEmpty}
      hasFooter={false}
      data={messages}
      graphType={GraphType.HEATMAP}
    >
      <HeatMap data={messages} />
    </AnalyticsFieldset>
  );
};

export default HeatMapFieldset;
