import { memo, useCallback, useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import DOMPurify from 'isomorphic-dompurify';
import isNil from 'lodash/isNil';
import { AlertTriangleIcon } from 'lucide-react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import ActionPlaceholder from '@/components/organisms/Dialogs/Action/Placeholder/Placeholder';
import ActionHeader from '@/components/organisms/Dialogs/ActionHeader/ActionHeader';
import Box from '@/components/organisms/Dialogs/Box/Box';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { HANDLE_REGEX } from '@/components/organisms/RichTextEditor/utils';
import useDeleteAction from '@/hooks/useDeleteAction';
import { CarouselAction } from '@/models/action';
import { selectHasError } from '@/redux/dialogAlerts/selectors';
import { TYPES } from '@/redux/dialogs/helper';
import { selectFirstActionIdByNodeId } from '@/redux/dialogs/selectors';
import { popModal, pushModal } from '@/redux/modals/actions';
import {
  addAction,
  moveAction,
  removeAction,
  selectCarousel,
  updateSelectedCarouselIndex,
} from '@/redux/nodes/actions';
import {
  selectIsActionSelected,
  selectIsIdInFlow,
  selectIsNodeConditionRequisiteSelected,
  selectSelectedCarouselIndex,
} from '@/redux/nodes/selectors';
import { preventClickThrough } from '@/util/util';

import CarouselSlider from './CarouselSlider/CarouselSlider';

import styles from './Carousel.module.scss';

interface CarouselProps {
  action: CarouselAction;
  actionId: string;
  nodeId: string;
  conditionIndex: number;
  requisiteIndex?: number;
  beforeActionId?: string;
  isAfterRequisite?: boolean;
  actionIndex?: number;
}

enum DIRECTION {
  PREVIOUS = 'previous',
  NEXT = 'next',
}

const Carousel = ({
  action,
  actionId,
  nodeId,
  conditionIndex,
  requisiteIndex = null,
  beforeActionId = null,
}: CarouselProps) => {
  const { type } = action;
  const dispatch = useDispatch();
  const selectedCarouselIndex = useSelector(selectSelectedCarouselIndex);
  const isSelected = useSelector(selectIsActionSelected(actionId));
  const [isDragging, setIsDragging] = useState(false);
  const { deleteAction } = useDeleteAction();
  const firstActionId = useSelector(selectFirstActionIdByNodeId(nodeId));
  const [activeCard, setActiveCard] = useState(selectedCarouselIndex ?? 0);
  const isActionInFlow = useSelector(selectIsIdInFlow(actionId));
  const isAnythingSelected = useSelector(
    selectIsNodeConditionRequisiteSelected
  );
  const hasDialogError = useSelector(selectHasError(actionId));
  const { title, subtitle, buttons = [] } = action?.cards[activeCard] || {};

  const [htmlSubtitle, setHtmlSubtitle] = useState('');
  useEffect(() => {
    // Highlight context variables in the subtitle
    const highlightedSubtitle = subtitle?.replace(
      HANDLE_REGEX,
      '<span style="color: var(--color-informational);">$&</span>'
    );
    const sanitizedHtmlSubtitle = DOMPurify.sanitize(highlightedSubtitle);
    setHtmlSubtitle(sanitizedHtmlSubtitle);
  }, [htmlSubtitle, subtitle]);

  const [_, dragRef] = useDrag({
    item: {
      actionId,
      nodeId,
      requisiteIndex,
      conditionIndex,
      move: true,
    },
    type: TYPES.ACTION,
    canDrag: () =>
      !document?.activeElement?.className?.match(/DraftEditor|input/),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleCarouselDelete = useCallback(() => {
    const deleteProps = {
      subtitle: <Trans i18nKey="dialog.delete_action" values={[type]} />,
      confirm: false,
      onDelete: () => {
        dispatch(removeAction({ actionId }));
        dispatch(popModal());
      },
    };
    dispatch(pushModal(MODAL_DELETE, deleteProps));
  }, [actionId, type, dispatch]);

  const onClick = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isSelected) {
        return;
      }
      dispatch(selectCarousel({ actionId, index: activeCard ?? 0 }));
    },
    [isSelected, dispatch, actionId, activeCard]
  );

  const onKeyUp = useCallback(
    (e) => {
      preventClickThrough(e);
      if (e.key === 'Delete') {
        handleCarouselDelete();
      }
    },
    [handleCarouselDelete]
  );

  const navigateSlider = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    direction: DIRECTION
  ) => {
    preventClickThrough(e);
    dispatch(selectCarousel({ actionId }));

    if (direction === DIRECTION.PREVIOUS && action.cards[activeCard - 1]) {
      setActiveCard(activeCard - 1);
      dispatch(updateSelectedCarouselIndex({ index: activeCard - 1 }));
    }
    if (direction === DIRECTION.NEXT && action.cards[activeCard + 1]) {
      setActiveCard(activeCard + 1);
      dispatch(updateSelectedCarouselIndex({ index: activeCard + 1 }));
    }
  };

  const handlePaginationClick = (e, index: number) => {
    preventClickThrough(e);
    dispatch(selectCarousel({ actionId, index }));

    setActiveCard(index);
    dispatch(updateSelectedCarouselIndex({ index }));
  };

  const dropActionInNode = useCallback(
    (item) => {
      if (item.move) {
        return dispatch(
          moveAction({
            source: item,
            target: {
              nodeId,
              conditionIndex,
              beforeActionId,
            },
          })
        );
      }

      const newAction = {
        ...item.props,
        type: item.subType,
        action_id: item.id,
      };

      return dispatch(
        addAction({
          nodeId,
          conditionIndex,
          newAction,
        })
      );
    },
    [nodeId, dispatch, conditionIndex, beforeActionId]
  );
  const [{ canDrop }] = useDrop({
    accept: !isNil(requisiteIndex) ? TYPES.QUESTION : TYPES.ACTION,
    drop: dropActionInNode,
    canDrop: () => {
      return true;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (isSelected) {
      setActiveCard(selectedCarouselIndex ?? 0);
    }
  }, [isSelected, selectedCarouselIndex]);

  return (
    <>
      <ActionPlaceholder
        nodeId={nodeId}
        beforeActionId={actionId}
        conditionIndex={conditionIndex}
      />

      <section
        id={actionId}
        className={cn(styles.wrapper, {
          [styles['wrapper--placeholder']]: canDrop,
          [styles.opacity]: !isActionInFlow && !isAnythingSelected,
          [styles['wrapper--firstAction']]: firstActionId === actionId,
        })}
      >
        {hasDialogError && (
          <div className={cn(styles.icon)}>
            <AlertTriangleIcon size={16} color="var(--color-destructive)" />
          </div>
        )}

        <Box
          className={cn({
            [styles.selected]: isSelected,
            [styles.hasError]: isSelected && hasDialogError,
            [styles.isDragging]: isDragging,
            [styles.opacity]: !isActionInFlow && !isAnythingSelected,
          })}
          ref={dragRef}
          role="button"
          tabIndex={0}
          onKeyUp={onKeyUp}
          onClick={onClick}
          onDragStart={() => setIsDragging(true)}
          onDragEnd={() => setIsDragging(false)}
          data-testid="carousel"
        >
          <ActionHeader
            type={type}
            objectToCopy={action}
            isSelected={isSelected}
            onDelete={() => deleteAction(action)}
            hasDialogError={hasDialogError}
          />

          <CarouselSlider
            activeCard={activeCard}
            cards={action?.cards}
            navigateSlider={navigateSlider}
            isDragging={isDragging}
            handlePaginationClick={handlePaginationClick}
          />

          <div className={styles.body}>
            <p className={styles.body__title}>
              <Typography variant="body-regular">{title}</Typography>
            </p>

            <p className={styles.body__subtitle}>
              <Typography
                color="var(--color-foreground-muted)"
                variant="label-regular"
              >
                <span dangerouslySetInnerHTML={{ __html: htmlSubtitle }} />
              </Typography>
            </p>

            {buttons.map(({ label }, index) => (
              <Button
                className={styles.body__button}
                size="small"
                // eslint-disable-next-line react/no-array-index-key
                key={`${label}-${index}`}
                isFullWidth
              >
                {label}
              </Button>
            ))}
          </div>
        </Box>
      </section>
    </>
  );
};

export default memo(Carousel);
