import { useSelector } from 'react-redux';

import { selectIsTryItReplay } from '../redux/selectors';
import { isMessageFromKnowledge } from '../utils/helper';

export type ResponseTypeFlags = {
  isBroadcastResponse: boolean;
  isAgentResponse: boolean;
  isBrainResponse: boolean;
  isUnknownResponse: boolean;
  isCollectionResponse: boolean;
  isDisambiguationResponse: boolean;
  isErrorResponse: boolean;
  isInstructionsResponse: boolean;
};

export const useResponseTypeFlags = ({ index, node }): ResponseTypeFlags => {
  const isReplay = useSelector(selectIsTryItReplay);

  // First condition is for TryIt and the second for Brain logs
  const hasError =
    node?.messages.some((message) => message.type === 'error') ||
    node?.messages.some(
      (message) => message.intents === null && !message.reminder
    );

  // If there is an error, no need to check any other conditions
  if (hasError) {
    return {
      isBroadcastResponse: false,
      isAgentResponse: false,
      isBrainResponse: false,
      isInstructionsResponse: false,
      isUnknownResponse: false,
      isCollectionResponse: false,
      isDisambiguationResponse: false,
      isErrorResponse: true,
    };
  }

  let isUnknownResponse = false;

  if (
    node?.collection?.request_code === 6 &&
    (node?.collection?.response_code === 2 ||
      node?.collection?.response_code === 3)
  ) {
    isUnknownResponse = true;
  } else {
    const initialNodeId = isReplay ? node?.id : node?.steps?.[0]?.node_id;

    if (initialNodeId) {
      const nodeType = node?.nodes_stack?.find(
        (node) => node.node_id === initialNodeId
      )?.type;

      if (nodeType === 'unknown') {
        isUnknownResponse = true;
      }
    }
  }

  const isAgentResponse = node?.author_type === 'agent';
  const isBrainResponse = typeof node?.name === 'string' && !isUnknownResponse;
  const isBroadcastResponse = node?.author_type === 'broadcast';
  const isCollectionResponse =
    node?.collection?.fragments?.length > 0 &&
    node?.collection?.response_code === 0;

  const isInstructionsResponse = isMessageFromKnowledge(node?.collection);

  const isDisambiguationResponse =
    !isBroadcastResponse &&
    !isBrainResponse &&
    !isInstructionsResponse &&
    !isUnknownResponse &&
    !isCollectionResponse &&
    !isAgentResponse &&
    index !== null &&
    !hasError;

  return {
    isBroadcastResponse,
    isAgentResponse,
    isBrainResponse,
    isInstructionsResponse,
    isUnknownResponse,
    isCollectionResponse,
    isDisambiguationResponse,
    isErrorResponse: hasError,
  };
};
