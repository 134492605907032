import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import {
  Log,
  LogSessionContentRow,
  LogsQueryVariables,
} from '@/modules/analytics/models';
import { selectFilters } from '@/modules/analytics/redux/selectors';
import { selectAccountId, selectBrainId } from '@/redux/session/selectors';
import { getAnalyticsRange } from '@/util/analytics';
import { getGraphQLSet, parseFilter } from '@/util/util';

import { setSessionContent } from '../modules/TryIt/redux/actions';

type QueryVariables = {
  excludeStartTime?: string;
} & LogsQueryVariables;

export interface LogSessionsPreview {
  rows: Log[];
}

export type LogSessionContent = {
  rows: LogSessionContentRow[];
};

export type UserSessionsRecent = {
  log_sessions_preview_v2: Log[];
};

export interface LogSessionsPreviewAggregate {
  rows: { aggregate: { count: number } };
}

export interface LogsHook {
  isLoading?: boolean;
  previewError?: ApolloError;
  contentError?: ApolloError;
  numberOfData?: number;
  refetchPreview?: () => void;
  refetchContent?: () => void;
  loadMore?: () => Promise<ApolloQueryResult<LogSessionsPreview>> | null;
  data?: Log[];
  content?: LogSessionContentRow;
}

export const SESSIONS_PREVIEW = gql`
  query GetSessions(
    $accountId: uuid
    $brainIds: _uuid
    $channels: _text
    $channelUserId: String
    $deskIds: _uuid
    $endDate: timestamp
    $externalUserId: String
    $hasCollection: Boolean
    $integrationIds: _uuid
    $isContained: Boolean
    $isCovered: Boolean
    $isMeaningful: Boolean
    $isTest: Boolean
    $languageModelIds: _text
    $minNumUserMessages: Int
    $ratings: _int4
    $sessionId: String
    $startDate: timestamp
    $tags: _text
    $userEmail: String
    $userId: String
    $userName: String
    $limit: Int
    $offset: Int
    $min_num_thumbs_up: Int
    $min_num_thumbs_down: Int
  ) {
    rows: get_sessions(
      offset: $offset
      limit: $limit
      args: {
        account_id: $accountId
        brain_parent_ids: $brainIds
        channels: $channels
        channel_user_id: $channelUserId
        desk_ids: $deskIds
        end_time: $endDate
        external_user_id: $externalUserId
        has_collection: $hasCollection
        integration_ids: $integrationIds
        is_contained: $isContained
        is_covered: $isCovered
        is_meaningful: $isMeaningful
        is_test: $isTest
        language_model_ids: $languageModelIds
        min_num_user_messages: $minNumUserMessages
        ratings: $ratings
        session_id: $sessionId
        start_time: $startDate
        tags: $tags
        user_email: $userEmail
        user_id: $userId
        user_name: $userName
        min_num_thumbs_up: $min_num_thumbs_up
        min_num_thumbs_down: $min_num_thumbs_down
      }
    ) {
      channel
      desk_id
      end_time
      is_contained
      is_covered
      is_test
      participated_agents
      participated_brains
      participated_language_models
      rating
      session_id
      start_time
      tags
      thumbs_down
      thumbs_up
      total_user_messages
      user_id
      user_name
    }
  }
`;

export const SESSION_CONTENT = gql`
  query GetSessionContent(
    $sessionId: String
    $startDate: timestamp
    $endDate: timestamp
  ) {
    rows: get_session_content(args: { session_id: $sessionId }) {
      messages
      brain_id
      brain_parent_id
      brain_version
      channel
      channel_user_id
      desk_id
      end_time
      expired_time
      external_user_id
      integration_id
      is_contained
      is_covered
      is_test
      participated_brains
      participated_collections
      participated_agents
      rating
      feedback
      session_id
      start_time
      tags
      rule_ids
      total_user_messages
      user_id
      user_name
      user_email
    }
  }
`;

export const SESSIONS_AGGREGATE = gql`
  query GetDailySessionsAggregate(
    $accountId: uuid
    $brainIds: _uuid
    $channels: _text
    $channelUserId: String
    $deskIds: _uuid
    $endDate: timestamp
    $externalUserId: String
    $hasCollection: Boolean
    $integrationIds: _uuid
    $isContained: Boolean
    $isCovered: Boolean
    $isMeaningful: Boolean
    $isTest: Boolean
    $languageModelIds: _text
    $minNumUserMessages: Int
    $ratings: _int4
    $sessionId: String
    $startDate: timestamp
    $tags: _text
    $userEmail: String
    $userId: String
    $userName: String
    $limit: Int
    $min_num_thumbs_up: Int
    $min_num_thumbs_down: Int
  ) {
    rows: get_sessions_aggregate(
      limit: $limit
      args: {
        account_id: $accountId
        brain_parent_ids: $brainIds
        channels: $channels
        channel_user_id: $channelUserId
        desk_ids: $deskIds
        end_time: $endDate
        external_user_id: $externalUserId
        has_collection: $hasCollection
        integration_ids: $integrationIds
        is_contained: $isContained
        is_covered: $isCovered
        is_meaningful: $isMeaningful
        is_test: $isTest
        language_model_ids: $languageModelIds
        min_num_user_messages: $minNumUserMessages
        ratings: $ratings
        session_id: $sessionId
        start_time: $startDate
        tags: $tags
        user_email: $userEmail
        user_id: $userId
        user_name: $userName
        min_num_thumbs_up: $min_num_thumbs_up
        min_num_thumbs_down: $min_num_thumbs_down
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const useLogs = (limit = 25, sessionId?: string, user_id = null): LogsHook => {
  const accountId = useSelector(selectAccountId);
  const brainId = useSelector(selectBrainId);
  const dispatch = useDispatch();

  const { startDate, endDate, logs, filtersLoaded } =
    useSelector(selectFilters);

  const variables = Object.assign(
    {},
    {
      accountId,
      brainIds: getGraphQLSet([brainId]),
      ...getAnalyticsRange(startDate, endDate),
    },
    ...logs.map((filter) => {
      if (filter.type == 'reactions') {
        return {
          min_num_thumbs_up: filter[filter.type] === true ? 1 : 0,
          min_num_thumbs_down: filter[filter.type] === false ? 1 : 0,
        };
      }
      return {
        [filter.type]: parseFilter(filter),
      };
    })
  );
  const { data: numberOfData } = useQuery<
    LogSessionsPreviewAggregate,
    QueryVariables
  >(SESSIONS_AGGREGATE, {
    variables: {
      ...variables,
    },
    skip: !accountId || !brainId || !filtersLoaded,
  });

  const {
    loading: previewLoading,
    data: previewData,
    error: previewError,
    fetchMore,
    refetch: refetchPreview,
  } = useQuery<LogSessionsPreview, QueryVariables>(SESSIONS_PREVIEW, {
    variables: {
      ...variables,
      limit,
      offset: 0,
    },
    skip: !!user_id || !brainId || !accountId || !filtersLoaded,
  });

  const {
    loading: contentLoading,
    data: contentData,
    error: contentError,
    refetch: refetchContent,
  } = useQuery<LogSessionContent, { sessionId: string }>(SESSION_CONTENT, {
    variables: {
      sessionId,
    },
    onCompleted: (data) => {
      dispatch(setSessionContent(data?.rows?.[0]));
    },
    skip: !sessionId || !accountId,
  });

  const finalData = previewData?.rows ?? [];

  const loadMore = () => {
    if (
      startDate &&
      // Prevent extra call with empty data
      finalData.length < numberOfData?.rows?.aggregate?.count
    ) {
      return fetchMore({
        query: SESSIONS_PREVIEW,
        variables: {
          ...variables,
          limit,
          offset: finalData.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!isEmpty(prev)) {
            const finalData = [...prev.rows, ...fetchMoreResult.rows];
            return { ...prev, rows: finalData };
          }
          return fetchMoreResult;
        },
      });
    }
    return null;
  };

  if (previewLoading || previewError) {
    return {
      isLoading: previewLoading,
      previewError,
      refetchPreview,
    };
  }

  if (contentLoading || contentError) {
    return {
      isLoading: contentLoading,
      contentError,
      refetchContent,
    };
  }

  return {
    isLoading: false,
    data: finalData,
    numberOfData: numberOfData?.rows?.aggregate?.count,
    loadMore,
    content: contentData?.rows[0],
  };
};

export default useLogs;
