import { memo } from 'react';

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ChevronRightIcon, GripVerticalIcon, Trash2Icon } from 'lucide-react';

import IconButton from '@/components/atoms/IconButton/IconButton';

type WrapperProps = {
  children: React.ReactNode;
  title: string;
  subTitle?: string;
  expanded: boolean;
  onChange: () => void;
  handleDelete: () => void;
  variant?: 'white' | 'grey';
  disabled?: boolean;
  isNested?: boolean;
  height?: 'small' | 'medium';
  hideReorderIcon?: boolean;
};

const getHeight = (size: WrapperProps['height']) => {
  switch (size) {
    case 'small':
      return '36px';
    case 'medium':
      return '44px';
    default:
      return '44px';
  }
};

const Accordion = styled(
  (props: AccordionProps & { isNested: WrapperProps['isNested'] }) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
  ),
  { shouldForwardProp: (prop) => prop !== 'isNested' }
)(({ isNested }) => ({
  width: isNested ? '100%' : '312px',
  border: '1px solid var(--color-stroke)',
  boxShadow: 'none',

  '&:hover': {
    border: `1px solid var(--color-stroke)`,
    boxShadow:
      '-1px -2px 8px rgba(214, 214, 214, 0.25), 2px 4px 8px rgba(214, 214, 214, 0.25)',
  },

  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const shouldForwardProp = (prop) =>
  !['variant', 'expanded', 'height'].includes(prop);

const AccordionSummary = styled(
  (
    props: AccordionSummaryProps & {
      variant: WrapperProps['variant'];
      expanded: WrapperProps['expanded'];
      height: WrapperProps['height'];
    }
  ) => (
    <MuiAccordionSummary
      expandIcon={<ChevronRightIcon size={16} />}
      {...props}
    />
  ),
  { shouldForwardProp }
)(({ variant, expanded, height }) => ({
  backgroundColor:
    variant === 'grey' ? 'var(--color-bg-muted-50)' : 'var(--color-background)',
  flexDirection: 'row-reverse',
  height: getHeight(height),
  borderRadius: 'var(--space-4)',

  '& .MuiAccordionSummary-expandIconWrapper': {
    padding: 'var(--space-4)',
    margin: '0',
  },

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    margin: '0',
    padding: 'var(--space-4)',
  },

  '& .dragIcon': {
    position: 'absolute',
    left: '2px',
    transform: 'translateY(-1px)',
    visibility: 'hidden',
  },

  '&:hover': {
    '& .dragIcon': {
      visibility: expanded ? 'hidden' : 'visible',
    },
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiButtonBase-root': {
      visibility: expanded ? 'visible' : 'hidden',
    },

    '& .MuiTypography-root': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    '& .subTitle': {
      position: 'relative',
      marginRight: 'auto',
      marginLeft: 'var(--space-24)',

      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 'calc(var(--space-8) * -1)',
        width: '3px',
        height: '3px',
        borderRadius: '50%',
        transform: 'translateY(-50%)',
      },
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails, {
  shouldForwardProp,
})<{
  variant: WrapperProps['variant'];
}>(({ variant }) => ({
  backgroundColor:
    variant === 'grey' ? 'var(--color-bg-muted-50)' : 'var(--color-background)',
  padding: 'var(--space-16)',
  paddingTop: '0',
  borderRadius: 'var(--space-4)',
}));

const ToolkitAccordionWrapper = ({
  children,
  title,
  subTitle,
  expanded,
  onChange,
  handleDelete,
  variant = 'white',
  disabled = false,
  isNested = false,
  height = 'medium',
  hideReorderIcon = false,
}: WrapperProps) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      isNested={isNested}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls={`${title}-content`}
        id={`${title}-header`}
        variant={variant}
        expanded={expanded}
        height={height}
      >
        {!hideReorderIcon && (
          <span className="dragIcon">
            <GripVerticalIcon size={12} color="var(--color-foreground-muted)" />
          </span>
        )}
        <Typography
          variant="body-semi-bold"
          color="var(--color-foreground-muted)"
        >
          {title}
        </Typography>
        {subTitle && (
          <Typography
            className="subTitle"
            variant="label-regular"
            color="var(--color-foreground-muted)"
          >
            {subTitle}
          </Typography>
        )}
        <IconButton
          ariaLabel="delete"
          onClick={handleDelete}
          disabled={disabled}
        >
          <Trash2Icon size={16} />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails variant={variant}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default memo(ToolkitAccordionWrapper);
