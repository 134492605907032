import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTitle } from 'react-use';

import useFeatureFlag from '@/hooks/useFeatureFlag';
import { usePermissions } from '@/hooks/usePermissions';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import BillingAddressFieldset from '../components/fieldsets/BillingAddress/BillingAddress';
import CompanyNameFieldset from '../components/fieldsets/CompanyName/CompanyName';
import { Coupon } from '../components/fieldsets/Coupon/Coupon';
import InvoiceLanguageFieldset from '../components/fieldsets/InvoiceLanguage/InvoiceLanguage';
import InvoiceRecipientFieldset from '../components/fieldsets/InvoiceRecipient/InvoiceRecipient';
import PaymentMethodsFieldset from '../components/fieldsets/PaymentMethods/PaymentMethods';
import PlanFieldset from '../components/fieldsets/Plan/PlanFieldset';
import TaxIdFieldset from '../components/fieldsets/TaxId/TaxId';
import UsageLimitsFieldset from '../components/fieldsets/UsageLimits/UsageLimits';

export default function Billing() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  useTitle(t('pages.billing', { 0: slug }));
  const { listStatus } = usePermissions();
  useEffect(() => {
    pageView(PageName.BILLING);
  }, []);

  const features = useFeatureFlag();
  const canReadBilling = useSelector((state: RootState) =>
    getPermissions(state, 'billing', actions.READ)
  );

  if (listStatus === 'success' && !canReadBilling) {
    navigate(`/${slug}/account/information`);
  }

  if (listStatus === 'pending') {
    return null;
  }

  return (
    <>
      <PlanFieldset />
      <PaymentMethodsFieldset />
      <Coupon />
      <TaxIdFieldset />
      <BillingAddressFieldset />
      <CompanyNameFieldset />
      <InvoiceRecipientFieldset />
      <InvoiceLanguageFieldset />
      {features?.usage_limits && <UsageLimitsFieldset />}
    </>
  );
}
