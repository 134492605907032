import MuiChip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/system';

type CustomChipProps = Omit<ChipProps, 'variant'> & {
  customVariant?: 'neutral' | 'highlighted' | 'subdued';
};

const CustomChipVariants = {
  neutral: 'var(--color-bg-muted)',
  highlighted: 'var(--color-bg-muted)',
  subdued: 'var(--color-bg-muted-50)',
} as const;

const CustomChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'customVariant',
})<CustomChipProps>(({ customVariant = 'highlighted' }) => ({
  backgroundColor: CustomChipVariants[customVariant],

  '&.Mui-focusVisible': {
    backgroundColor: CustomChipVariants[customVariant],
  },
}));

const Chip = (props: CustomChipProps): JSX.Element => {
  return <CustomChip {...props} />;
};
export default Chip;
