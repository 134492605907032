import { useCallback, useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import ToolkitWrapper from '@/components/organisms/Toolkit/ToolkitWrapper';
import useDialogs from '@/hooks/useDialogs';
import { SetVariablesAction } from '@/models/action';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { setVariable } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import {
  addDollarSign,
  removeDollarSign,
  capitalizeFirstLetter,
} from '@/util/util';
import { setVariableSchema } from '@/util/validator';

import SetVariablesInput from './SetVariablesInput/SetVariablesInput';

export type SetVariablesActionForm = {
  variable: {
    type: string;
    value: string;
    label: string;
  };
  value: string;
  selectedVariable?: string;
};

const RESERVED_VARIABLES = ['tags', 'user', 'global'];

const ToolkitSetVariables = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);
  const { actionId, variable, value } = useSelector((state: RootState) => {
    const selectedAction = selectSelectedAction(state) as SetVariablesAction;
    // For now, we only support one variable per action
    // In the future, we can send multiple variables, because the backend supports it
    const firstVariable = selectedAction?.variables
      ? selectedAction.variables[0]
      : { value: '', variable: '' };
    return {
      actionId: selectedAction?.action_id,
      variable: firstVariable?.variable,
      value: firstVariable?.value,
    };
  }, shallowEqual);
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  // Custom hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getContextVariables } = useDialogs(brainId);
  const contextVariables = getContextVariables();

  // Local variables
  const autoCompleteVariables = contextVariables
    .map((variable) => ({
      type: t('dialog.set_variables.auto_complete_group_by'),
      value: removeDollarSign(variable.value),
      label: variable.label,
    }))
    // Filter out the tags variable
    .filter((variable) => variable.value !== 'tags');

  const defaultVariable = useMemo(() => {
    const defVariable = autoCompleteVariables.find(
      ({ value }) => value === variable
    );

    // This case is for when the user types a variable that is not in the context variables
    if (!defVariable && variable) {
      return {
        type: t('dialog.set_variables.auto_complete_group_by'),
        value: variable,
        label: addDollarSign(variable),
      };
    }

    return defVariable ?? null;
  }, [autoCompleteVariables, t, variable]);

  // RHF
  const {
    trigger,
    setValue,
    getValues,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<SetVariablesActionForm>({
    mode: 'onChange',
    defaultValues: {
      variable: defaultVariable,
      value: value ?? '',
      selectedVariable: '',
    },
    resolver: yupResolver(
      setVariableSchema(RESERVED_VARIABLES)
    ) as Resolver<SetVariablesActionForm>,
  });

  const variableErrorMessage = capitalizeFirstLetter(
    errors.variable?.message || errors?.variable?.value?.message
  );
  const valueErrorMessage = capitalizeFirstLetter(errors.value?.message);

  // Saves the new variable and its value to the redux store
  const saveNewVariable = useCallback(
    (data) => {
      dispatch(
        setVariable({
          actionId,
          variables: [data],
        })
      );
    },
    [actionId, dispatch]
  );

  const saveAllVariablesInRedux = useCallback(() => {
    const variable = removeDollarSign(getValues('variable.value'));
    const value = getValues('value');

    saveNewVariable({
      variable,
      value,
    });
  }, [getValues, saveNewVariable]);

  /**  Triggers:
   * 1. When the user types input
   * 2. When the user selects an option from the dropdown menu
   */
  const handleAutoCompleteChange = useCallback(
    async (_, option) => {
      const variable = removeDollarSign(option?.value);

      setValue('variable.value', variable);

      // We need to trigger the validation because the schema changes
      // when the variable is the Live instructions
      await trigger();

      saveAllVariablesInRedux();
    },
    [saveAllVariablesInRedux, setValue, trigger]
  );

  const updateErrors = useCallback(
    (key: string, value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.set_variables'),
            body: capitalizeFirstLetter(value),
            type: 'set_variables',
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t]
  );

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  useEffect(() => {
    if (isDirty && dirtyFields.variable) {
      updateErrors('variable', variableErrorMessage);
    }
  }, [variableErrorMessage, updateErrors, isDirty, dirtyFields.variable]);

  useEffect(() => {
    if (isDirty && dirtyFields.value) {
      updateErrors('value', valueErrorMessage);
    }
  }, [valueErrorMessage, updateErrors, isDirty, dirtyFields.value]);

  return (
    <ToolkitWrapper type="set_variables">
      <Autocomplete
        freeSolo
        options={autoCompleteVariables}
        enableNewEntry
        control={control}
        name="variable"
        label={t('dialog.set_variables.variable_name.label')}
        groupByProp="type"
        groupByLabelProp={false}
        placeholder={t('dialog.set_variables.variable_name.placeholder')}
        onChange={handleAutoCompleteChange}
        getOptionLabel={(option) => option?.label ?? option}
        hasError={!!errors.variable}
        errorMessage={variableErrorMessage}
        autoHighlight
        size="xs"
      />

      <SetVariablesInput
        setValue={setValue}
        control={control}
        error={errors.value}
        onSave={saveAllVariablesInRedux}
      />
    </ToolkitWrapper>
  );
};

export default ToolkitSetVariables;
