import { yupResolver } from '@hookform/resolvers/yup';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { InfoIcon } from 'lucide-react';
import moment from 'moment';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import Button from '@/components/atoms/Button/Button/Button';
import Input from '@/components/atoms/Input/Input';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { languageAndFlagByCountryCode } from '@/util/languageCodes';
import { capitalizeFirstLetter } from '@/util/util';
import { brainNameSchema } from '@/util/validator';

import { getAIAgentType } from '../../helper';
import { useDirtyFormBlocker } from '../../hooks/useDirtyFormBlocker';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { FlagEmoji } from '../TileAIAgent/FlagEmoji';

import styles from './Overview.module.scss';

type Form = {
  name: string;
};
const FORM_ID = 'ai-agent-overview';

export const Overview = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);

  // Custom hooks
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);

  // Local variables
  const {
    language,
    last_trained,
    last_used_at: last_used,
    updated: last_modified,
    created,
    created_by: userId,
    brain_type,
    name,
  } = brain || {};
  const lastTrainedTimeStamp = last_trained
    ? moment(last_trained).fromNow()
    : '-';
  const lastModifiedTimeStamp = last_modified
    ? moment(last_modified).fromNow()
    : '-';
  const badge = brain_type ? (
    <Typography
      component="p"
      color="var(--color-foreground-muted)"
      variant="label-regular"
      className={styles.type}
    >
      {getAIAgentType(brain_type)}
    </Typography>
  ) : null;

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // RHF
  const {
    register,
    formState: { isValid, errors, isDirty, isLoading },
    handleSubmit,
  } = useForm<Form>({
    mode: 'onChange',
    values: {
      name: name ?? '',
    },
    resolver: yupResolver(brainNameSchema) as Resolver<Form>,
  });

  // Handlers
  const handleSaveForm = ({ name }: Form) => {
    updateBrain({
      brain_id: brainId,
      name: name.trim(),
    });
  };
  useDirtyFormBlocker();
  useTrackFormState({ isDirty, formId: FORM_ID });

  return (
    <div className={styles.overview}>
      <FormCard>
        <FormCard.Header
          title={t('ai_agents.overview.about_your_ai_agent')}
          icon={<InfoIcon color="var(--color-foreground-muted)" size={18} />}
          badge={badge}
        />
        <FormCard.Content>
          <Input
            size="medium"
            label={t('common.name')}
            placeholder={t('signup.name_placeholder')}
            {...register('name')}
            error={!!errors.name}
            errorMessage={capitalizeFirstLetter(errors.name?.message)}
            disabled={!canWrite}
          />

          <div className={styles.infoWrapper}>
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--color-foreground-muted)"
              >
                {t('field.language')}
              </Typography>
              <Typography
                display="flex"
                alignItems="center"
                gap="var(--space-4)"
              >
                {language && (
                  <FlagEmoji
                    flag={languageAndFlagByCountryCode[language]?.flag}
                  />
                )}

                {languageAndFlagByCountryCode[language]?.name}
              </Typography>
            </div>
            <Divider orientation="vertical" />
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--color-foreground-muted)"
              >
                {t('common.last_used')}
              </Typography>
              <Typography>
                {last_used ? moment(last_used).format('DD-MM-YYYY') : '-'}
              </Typography>
            </div>
            <Divider orientation="vertical" />
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--color-foreground-muted)"
              >
                {t('brains.last_trained')}
              </Typography>
              <Typography>{lastTrainedTimeStamp}</Typography>
            </div>
            <Divider orientation="vertical" />
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--color-foreground-muted)"
              >
                {t('common.last_modified')}
              </Typography>
              <Typography>{lastModifiedTimeStamp}</Typography>
            </div>
          </div>
        </FormCard.Content>
        <FormCard.Footer>
          <Typography
            variant="label-caps-large"
            display="flex"
            gap="var(--space-4)"
          >
            {t('ai_agents.overview.created_on')}
            <Typography variant="label-regular">
              {moment(created).format('DD-MM-YYYY')}
            </Typography>
            {userId && (
              <>
                {t('common.by')}
                <Avatar userId={userId} size="small" hideStatus />
              </>
            )}
          </Typography>

          <Button
            onClick={handleSubmit(handleSaveForm)}
            disabled={!(isValid && isDirty)}
            isLoading={isLoading}
            type="submit"
          >
            {t('common.save')}
          </Button>
        </FormCard.Footer>
      </FormCard>
    </div>
  );
};
