import { t } from 'i18next';
import { FileTextIcon, GlobeIcon } from 'lucide-react';

import { AcceptedType } from '@/components/atoms/FileUpload/utils';
import Intercom from '@/components/atoms/Icons/Intercom';
import Zendesk from '@/components/atoms/Icons/Zendesk';
import {
  DatasourceStatus,
  DatasourceType,
  DocumentStatus,
} from '@/models/collections';
import { LENGTH_M, LENGTH_S } from '@/util/validator';

export const getIcon = (
  type: DatasourceType,
  color = 'var(--color-foreground-muted)',
  size = 24
) => {
  switch (type) {
    case DatasourceType.WEBSITE:
      return <GlobeIcon size={size} color={color} />;
    case DatasourceType.FILES:
      return <FileTextIcon size={size} color={color} />;
    case DatasourceType.ZENDESK_KB:
      return <Zendesk size={size - 4} color={color} />;
    case DatasourceType.INTERCOM_KB:
      return <Intercom size={size - 4} color={color} />;
    default:
      return null;
  }
};

export const ACCEPTED_DATASOURCE_FORMATS = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/msword': ['.doc'],
  'application/pdf': ['.pdf'],
  'text/plain': ['.txt'],
  'text/markdown': ['.md'],
  'text/html': ['.html'],
};

export const PLAIN_DATASOURCE_FORMATS = Object.values(
  ACCEPTED_DATASOURCE_FORMATS
)
  .flatMap((k) => k)
  .join(',');

export const disallowSymbolsRegex = /^[^/\\]*$/;

export const rulesTXT = {
  body: { required: true, minLength: LENGTH_S },
};

export const generateValidationRules = (disallowedStrings: string[]) => {
  return {
    required: true,
    maxLength: LENGTH_M,
    validate: (value: string) => {
      if (disallowedStrings.includes(value)) {
        return t('validation.duplicate_name');
      } else if (!disallowSymbolsRegex.test(value)) {
        return t('validation.only_letters_numbers');
      }
      return true;
    },
  };
};

export const getFilesType = (type: DatasourceType) => {
  if (
    type === DatasourceType.ZENDESK_KB ||
    type === DatasourceType.INTERCOM_KB
  ) {
    return t('collections.articles');
  }
  if (type === DatasourceType.WEBSITE) {
    return t('collections.webpages');
  }
  return t('collections.documents.documents');
};

export function getNewFileName(name: string, names: string[]) {
  // Split the name into base name and extension
  const baseName = name.replace(/\.\w+$/, '');
  const extension = name.match(/\.\w+$/)[0];

  // Adjust the regex to match the base name exactly and capture numbers in parentheses
  const regex = new RegExp(
    `^${baseName.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}( \\((\\d+)\\))?${extension.replace('.', '\\.')}$`
  );

  // Filter names to find duplicates or similar patterns
  const filteredNames = names.filter((n) => n.match(regex));

  if (!filteredNames.length) {
    // No duplicates found, return the original name
    return name;
  }

  // Extract numbers from the duplicates, sort them, and find the next available number
  const numbers = filteredNames
    .map((n) => {
      const match = n.match(regex);
      return match && match[2] ? parseInt(match[2], 10) : 0;
    })
    .sort((a, b) => a - b);

  let nextNumber = 1;
  for (let i = 0; i < numbers.length; i++) {
    // If the current number is in sequence, increment nextNumber to find the next available slot
    if (numbers[i] === nextNumber) {
      nextNumber++;
    }
  }

  // Append the next available number to the name
  return `${baseName} (${nextNumber})${extension}`;
}

export const createNewFile = (originalFile, newFileName) => {
  return new File([originalFile], newFileName, { type: originalFile.type });
};

export const handleDuplicateFiles = (
  acceptedFiles: AcceptedType[],
  restrictedNames = []
) => {
  const renamedFiles = [];
  for (const file of acceptedFiles) {
    const newFileName = getNewFileName(file.path, restrictedNames);
    const newFile = createNewFile(file, newFileName);
    renamedFiles.push(newFile);
  }
  return renamedFiles;
};

export const removeTxtExtension = (arr: string[]) => {
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].replace('.txt', '');
  }
  return arr;
};

export const getStatusColor = (status: DatasourceStatus | DocumentStatus) => {
  switch (status) {
    case DatasourceStatus.AVAILABLE:
      return 'success';
    case DocumentStatus.INDEXING:
    case DatasourceStatus.PENDING:
      return 'info';
    case DatasourceStatus.PROCESSING:
      return 'warning';
    case DatasourceStatus.FAILED:
    case DatasourceStatus.INVALID:
      return 'critical';
  }
};

export const getFragmentsPercentage = (
  fragmentCount: number,
  accountMax: number
) => {
  if (isNaN(fragmentCount) || isNaN(accountMax)) {
    return 0;
  }
  const percentage = Math.round((fragmentCount / accountMax) * 100);
  if (percentage > 100) {
    return 100;
  }
  return percentage;
};
