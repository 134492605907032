import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CirclePlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Header from '@/components/organisms/Header/Header';
import useCollections from '@/hooks/useCollections';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { getPermissions } from '@/redux/permissions/selectors';

import { getDisabledMessage } from './util';

const CollectionsHeader = ({ onClick }) => {
  const { maxCollectionsReached, totalFragmentsReached } = useCollections();

  const { billingState } = useBilling();
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );
  const { t } = useTranslation();

  const canCreateCollections =
    !maxCollectionsReached && canWrite && !totalFragmentsReached;

  return (
    <Header>
      <Header.Body>
        <Header.Title
          title={
            <Typography variant="h2-semi-bold" color="var(--color-foreground)">
              {t('common.collections')}
            </Typography>
          }
        />
        <Header.Actions>
          <Tooltip
            arrow
            title={
              !canCreateCollections
                ? getDisabledMessage(
                    maxCollectionsReached,
                    billingState?.plan_name,
                    totalFragmentsReached,
                    t
                  )
                : ''
            }
          >
            <span>
              <Button onClick={onClick} disabled={!canCreateCollections}>
                <CirclePlusIcon
                  color={
                    canCreateCollections
                      ? 'var(--color-background)'
                      : 'var(--color-foreground-muted)'
                  }
                  size={16}
                />
                {t('collections.create_collection')}
              </Button>
            </span>
          </Tooltip>
        </Header.Actions>
      </Header.Body>
    </Header>
  );
};

export default CollectionsHeader;
