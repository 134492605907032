import emojiMartData, { EmojiMartData } from '@emoji-mart/data';
import { ParagraphPlugin } from '@udecode/plate/react';
import { EmojiPlugin } from '@udecode/plate-emoji/react';
import {
  ListItemPlugin,
  ListPlugin,
  TodoListPlugin,
} from '@udecode/plate-list/react';
import { MarkdownPlugin } from '@udecode/plate-markdown';
import { SlashPlugin } from '@udecode/plate-slash-command/react';
import { TrailingBlockPlugin } from '@udecode/plate-trailing-block';

import { autoformatListPlugin } from './plugins/autoformat-list-plugin';
import { autoformatPlugin } from './plugins/autoformat-plugin';
import { basicNodesPlugins } from './plugins/basic-nodes-plugins';
import { FixedToolbarPlugin } from './plugins/fixed-toolbar-plugins';
import { linkPlugin } from './plugins/link-plugin';

//TODO add autocomplete when we want to add context variables
// const newMentionPlugin = {
//   ...mentionPlugin,
//   options: {
//     ...mentionPlugin.options,
//     trigger: ['@', '$'], // Add both '@' and '$' as triggers
//     insertSpaceAfterMention: false, // Optional based on your needs
//   },
// };

export const plugins = [
  ...basicNodesPlugins,
  linkPlugin,
  // newMentionPlugin,
  ListItemPlugin,
  ListPlugin,
  TodoListPlugin,
  TodoListPlugin,
  MarkdownPlugin,
  SlashPlugin,
  autoformatPlugin,
  EmojiPlugin.configure({ options: { data: emojiMartData as EmojiMartData } }),
  autoformatListPlugin,
  TrailingBlockPlugin.configure({ options: { type: ParagraphPlugin.key } }),
];
export const editorPlugins = [FixedToolbarPlugin];
