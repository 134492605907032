import { BoxIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button/Button/Button';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import useDesks from '@/hooks/useDesks';

export default function CreateDeskTile() {
  const { handleCreateDesk } = useDesks();
  const { t } = useTranslation();

  return (
    <TileEmptyPage
      title={t('environments.no_env_tile.title')}
      notClickable
      body={
        <>
          {t('environments.no_env_tile.text')} <br />{' '}
          <Button onClick={handleCreateDesk} variant="secondary">
            {t('environments.no_env_tile.button')}
          </Button>
        </>
      }
      icon={<BoxIcon color="var(--color-foreground-muted)" size={100} />}
    />
  );
}
