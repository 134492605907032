import React, { useCallback } from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { EllipsisIcon, SparklesIcon } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import IconButton from '@/components/atoms/IconButton/IconButton';
import InAppHelp from '@/components/atoms/InAppHelp/InAppHelp';
import Link from '@/components/atoms/Link/Link';
import { ActionMenu } from '@/components/organisms/Dialogs/ActionMenu/ActionMenu';
import useBrains from '@/hooks/useBrains';
import { useCopyPaste } from '@/hooks/useCopyPaste';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useHotkeys from '@/hooks/useHotkeys';
import useMenu from '@/hooks/useMenu';
import { Node } from '@/models/node';
import { iconByType, languageSupportsEnhance } from '@/redux/dialogs/helper';
import { popModal } from '@/redux/modals/actions';
import { isModalOpen } from '@/redux/modals/selectors';
import { resolveBrainsPath } from '@/util/util';

import styles from './NodeHeader.module.scss';

type Props = {
  type: string;
  nodeId: string;
  isRootNodeUnknown?: boolean;
  node: Node;
  isSelected: boolean;
  showAITooltip: boolean;
  onAITooltipDismiss: () => void;
};

const cardStyles = {
  left: 'var(--space-24)',
};

const arrowStyles = {
  left: 'calc(-1 * var(--space-16)) !important',
};

const docs_url =
  'https://docs.moveo.ai/docs/get_started/ai_agents/dialogs#enhance-with-ai';

const NodeHeader = ({
  type,
  nodeId,
  isRootNodeUnknown = true,
  node,
  isSelected,
  showAITooltip,
  onAITooltipDismiss,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const { handlePaste, handleCopy } = useCopyPaste();
  const modalOpen = useSelector(isModalOpen);
  const navigate = useNavigate();
  const { slug, brainId } = useParams();
  const { deleteNode } = useMenu();
  const { ai_agents, enhance_response } = useFeatureFlag();
  const { brain } = useBrains(brainId);
  const showAIEnhancement =
    ai_agents && enhance_response && languageSupportsEnhance(brain?.language);

  const open = Boolean(anchorEl);
  const name =
    node.type === 'unknown' && !isRootNodeUnknown
      ? t('actions.types.unknown')
      : node.name;
  const isReminderNode = !!node?.isReminderNode;
  const hideActionsMenu = isRootNodeUnknown || isReminderNode;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogNameClick = useCallback(
    (dialog_id: string) => {
      dispatch(popModal());
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brainId}/dialogs/${dialog_id}`,
          ai_agents
        )
      );
    },
    [ai_agents, brainId, dispatch, navigate, slug]
  );

  const handleDelete = () =>
    deleteNode(nodeId, name as string, handleDialogNameClick);

  const hotkeys = {
    'mod+v': handlePaste,
    'mod+c': () => handleCopy(node),
    delete: handleDelete,
  };

  useHotkeys({ hotkeys, active: isSelected && !modalOpen });

  return (
    <div className={styles.header}>
      {iconByType({
        // Override type if it is a node created by reminder action
        id: isReminderNode ? 'reminder' : type,
        size: 24,
        color: 'var(--color-foreground-muted)',
      })}
      <p>
        <Typography
          variant="label-semi-bold"
          color="var(--color-informational)"
        >
          {name}
        </Typography>
      </p>
      {!hideActionsMenu && (
        <div className={styles.header__icons}>
          <ActionMenu
            handleClose={handleClose}
            open={open}
            anchorEl={anchorEl}
            handleDelete={handleDelete}
            handleCopy={handleCopy}
            handlePaste={handlePaste}
            objectToCopy={node}
          />
          <InAppHelp
            placement="top-end"
            theme="ai"
            cardStyles={cardStyles}
            arrowStyles={arrowStyles}
            defaultOpen={showAITooltip}
            onClose={onAITooltipDismiss}
            steps={[
              {
                title: t('ai_agents.enhancement_tooltip_title'),
                icon: (
                  <Box pt={0.5} pr={1}>
                    <SparklesIcon size={16} color="var(--color-background)" />
                  </Box>
                ),

                body: (
                  <Typography variant="body-regular">
                    <Trans
                      i18nKey="ai_agents.enhancement_tooltip"
                      components={{
                        link1: (
                          <Link
                            external
                            onClick={() => window.open(docs_url, '_blank')}
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    />
                  </Typography>
                ),
              },
            ]}
          >
            {showAIEnhancement && (
              <Tooltip
                title={
                  node?.enhance
                    ? t('ai_agents.enhancement_enabled')
                    : t('ai_agents.enhancement_disabled')
                }
                arrow
                placement="bottom"
              >
                <SparklesIcon
                  size={16}
                  color={!node?.enhance ? 'var(--color-ai)' : undefined}
                />
              </Tooltip>
            )}
          </InAppHelp>
          <IconButton
            ariaLabel="menu-btn"
            ariaControls="card-menu"
            ariaHasPopUp
            onClick={handleClick}
          >
            <EllipsisIcon size={20} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default NodeHeader;
