import * as React from 'react';

import * as SwitchPrimitive from '@radix-ui/react-switch';
import cn from 'classnames';

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitive.Root> {
  label?: string;
  htmlFor?: string;
}

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitive.Root>,
  SwitchProps
>(({ className, label, htmlFor, ...props }, ref) => {
  return (
    <div className="flex items-center">
      <label
        className="text-sm leading-5 font-normal foreground pr-2"
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <SwitchPrimitive.Root
        id={htmlFor}
        ref={ref}
        data-slot="switch"
        className={cn(
          'peer inline-flex h-4 w-6 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors',
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white',
          'disabled:cursor-not-allowed disabled:opacity-50',
          'data-[state=checked]:bg-[#0D6EFD] data-[state=unchecked]:bg-gray-200',
          className
        )}
        {...props}
      >
        <SwitchPrimitive.Thumb
          className={cn(
            'pointer-events-none block h-3 w-3 rounded-full bg-white shadow-lg ring-0 transition-transform',
            'data-[state=checked]:translate-x-2 data-[state=unchecked]:translate-x-0'
          )}
        />
      </SwitchPrimitive.Root>
    </div>
  );
});

Switch.displayName = 'Switch';

export { Switch };
