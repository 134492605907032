import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CrosshairIcon, LocateFixedIcon, WebhookIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import AvatarGroups from '@/components/atoms/Avatar/AvatarGroup/AvatarGroup';
import { FlowChartIcon } from '@/components/atoms/Icons/FlowChartIcon';
import { selectAllAgents } from '@/redux/presence/selectors';

import styles from './ModalPublishVersion.module.scss';

export type DiffType = 'added' | 'modified' | 'removed' | 'all';
type Props = {
  component: string;
  type: DiffType;
  changes: string[];
};

const icons = {
  intents: <LocateFixedIcon size={16} color="var(--color-foreground-muted)" />,
  dialogs: <FlowChartIcon />,
  entities: <CrosshairIcon size={16} color="var(--color-foreground-muted)" />,
  webhooks: <WebhookIcon size={16} color="var(--color-foreground-muted)" />,
};

const colorResolver = {
  added: 'var(--color-successful)',
  modified: 'var(--color-foreground)',
  removed: 'var(--color-destructive)',
};

export const Changes = ({ component, type, changes }: Props) => {
  const { t } = useTranslation();
  const members = useSelector(selectAllAgents);
  return component === 'users' ? (
    changes.length > 0 && (
      <li className={styles.slice}>
        <span className={styles.users}>
          <Typography color="var(--color-foreground-muted)">
            {t('common.by')}{' '}
          </Typography>
          <AvatarGroups>
            {changes.map((change) => (
              <Tooltip
                key={change}
                arrow
                title={members.find((x) => x.agent_id === change)?.name}
              >
                <span>
                  <Avatar userId={change} size="small" hideStatus />
                </span>
              </Tooltip>
            ))}
          </AvatarGroups>
        </span>
      </li>
    )
  ) : (
    <li className={styles.slice}>
      <Typography color="var(--color-foreground-muted)">
        {changes.length}{' '}
      </Typography>
      {icons[component]}
      <Typography color="var(--color-foreground-muted)">
        {t(`version.${component}`, { count: changes.length })}
      </Typography>
      <Typography color={colorResolver[type]}>
        {t(`version.${type}`)}
      </Typography>
    </li>
  );
};
