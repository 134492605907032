import { useCallback, useMemo } from 'react';

import { PaymentMethod } from '@stripe/stripe-js';
import { CircleCheckIcon, Trash2Icon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Stripe from 'stripe';

import Table from '@/components/atoms/Table/Table';
import { MODAL_CONFIRMATION } from '@/components/organisms/Modals/ModalConductor';
import { useBilling } from '@/modules/billing/hooks/useBilling';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';

import s from './styles.module.scss';

function isDefault(
  paymentMethod: PaymentMethod,
  customer: Stripe.Customer | undefined
) {
  return customer?.invoice_settings.default_payment_method === paymentMethod.id;
}

const PaymentMethodsTable = () => {
  const { t } = useTranslation();
  const {
    paymentMethods,
    customer,
    updateCustomerAsync,
    detachPaymentMethodAsync,
  } = useBilling();
  const dispatch = useDispatch();

  const handleDelete = useCallback(
    (pmId: string) => {
      const paymentMethod = paymentMethods?.find((pm) => pm.id === pmId);
      if (!paymentMethod) {
        return;
      }
      const makeNextOneDefault = isDefault(paymentMethod, customer);
      dispatch(
        pushModal(MODAL_CONFIRMATION, {
          title: t('billing.payment_remove_card'),
          primaryButtonText: t('billing.payment_card_delete_confirm'),
          subtitle: t('billing.payment_remove_card_subtitle', {
            0: paymentMethod.card?.brand,
            1: paymentMethod.card?.last4,
          }),
          onConfirm: async () => {
            await detachPaymentMethodAsync(paymentMethod.id);
            if (makeNextOneDefault) {
              const newDefault = paymentMethods?.find(
                (p) => p.id !== paymentMethod.id
              );
              if (newDefault) {
                await updateCustomerAsync({
                  invoice_settings: { default_payment_method: newDefault.id },
                });
              } else {
                dispatch(
                  addTemporalToast('success', t('billing.payment_card_deleted'))
                );
              }
            } else {
              dispatch(
                addTemporalToast('success', t('billing.payment_card_deleted'))
              );
            }
            dispatch(popModal());
          },
        })
      );
    },
    [
      customer,
      detachPaymentMethodAsync,
      dispatch,
      paymentMethods,
      updateCustomerAsync,
      t,
    ]
  );

  const handleMakeDefault = useCallback(
    async (pmId: string) => {
      const paymentMethod = paymentMethods?.find((pm) => pm.id === pmId);
      if (!paymentMethod) {
        return;
      }
      dispatch(
        pushModal(MODAL_CONFIRMATION, {
          title: t('billing.payment_card_change_default'),
          primaryButtonText: t('billing.payment_card_change_default_confirm'),
          subtitle: t('billing.payment_card_change_default_subtitle', {
            0: paymentMethod.card?.brand,
            1: paymentMethod.card?.last4,
          }),
          onConfirm: async () => {
            await updateCustomerAsync({
              invoice_settings: { default_payment_method: pmId },
            });
            dispatch(popModal());
          },
        })
      );
    },
    [paymentMethods, dispatch, updateCustomerAsync, t]
  );

  const columns = useMemo(
    () => [
      {
        Header: t(`billing.payment_card_columns.brand`),
        accessor: 'brand',
        Cell: ({ value }) => <span className={s.brandName}>{value}</span>,
      },
      {
        Header: t(`billing.payment_card_columns.default`),
        accessor: 'default',
        Cell: ({ value, row }) =>
          value ? (
            <CircleCheckIcon size={16} color="var(--color-foreground-muted)" />
          ) : (
            <button
              onClick={() => handleMakeDefault(row.original.id)}
              type="button"
              className={s.makeDefault}
            >
              <span />
            </button>
          ),
      },
      {
        Header: t(`billing.payment_card_columns.type`),
        accessor: 'type',
        Cell: ({ value }) => t(`billing.payment_card_${value}`),
      },
      {
        Header: t(`billing.payment_card_columns.exp`),
        accessor: 'exp',
      },
      {
        Header: t(`billing.payment_card_columns.last_4_num`),
        accessor: 'last_4_num',
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value }) => (
          <button
            className={s.deleteCard}
            onClick={() => handleDelete(value)}
            type="button"
          >
            <Trash2Icon size={16} />
          </button>
        ),
      },
    ],
    [handleDelete, handleMakeDefault, t]
  );

  if (!paymentMethods?.length) {
    return null;
  }

  const methods = paymentMethods
    .filter((pm) => pm.card)
    .map((pm) => ({
      brand: pm.card?.brand,
      default: isDefault(pm, customer),
      type: pm.card.funding ?? '',
      last_4_num: pm.card?.last4,
      exp: `${pm.card?.exp_month}/${pm.card?.exp_year}`,
      id: pm.id,
    }));

  return (
    <Table
      data={methods}
      columns={columns}
      noGutters
      fullWidth
      emptyMessage={t('billing.payment_card_empty')}
    />
  );
};

export default PaymentMethodsTable;
