import { WhatsappTemplate } from '@/modules/broadcast/models';
import { getVariablesFromComponent } from '@/modules/broadcast/utils';

/**
 * Extracts variable names from a WhatsApp template component
 */
export const extractVariableNames = (
  template: WhatsappTemplate,
  name: 'body' | 'header' | 'footer'
): string[] => {
  if (template.parameter_format !== 'NAMED' || !template.components[0]) {
    return [];
  }

  // Find the component for the current section
  const component = template.components.find(
    (comp) => comp.type.toLowerCase() === name
  );

  if (!component) {
    return [];
  }

  // For named parameters, first check if we have named parameter examples
  const namedParamsKey = `${name}_text_named_params`;
  const namedParams = component.example?.[namedParamsKey];

  if (namedParams && Array.isArray(namedParams)) {
    // If we have named parameter examples, use their names
    const extractedNames = namedParams.map((param) => param.param_name);
    if (extractedNames.length > 0) {
      return extractedNames;
    }
  }

  // Otherwise, extract variable names from the component text
  // This is a fallback for templates that don't have named parameter examples
  const extractedNames = getVariablesFromComponent(component).filter((name) =>
    isNaN(Number(name))
  ); // Filter out positional parameters

  return extractedNames.length > 0 ? extractedNames : [];
};
