import { useLayoutEffect } from 'react';

const MODAL_MIN_MARGIN = 20;

export const useModalBounds = (ref: React.RefObject<HTMLDivElement | null>) => {
  useLayoutEffect(() => {
    const modal = ref?.current;
    if (!modal) {
      return;
    }

    const updateHeight = () => {
      if (!modal) return;

      const currentMinHeight = parseFloat(getComputedStyle(modal).minHeight);
      const currentTop = parseFloat(getComputedStyle(modal).top);
      const rect = modal.getBoundingClientRect();
      const modalHeight = rect.height; // Component height
      const spaceBetween = window.innerHeight - rect.bottom; // Distance between component bottom and window bottom
      const newHeight = modalHeight + spaceBetween - MODAL_MIN_MARGIN; // New height calculated based on space available

      // If new height is less than the min height, adjust the top position
      if (newHeight <= currentMinHeight) {
        const newTop = currentTop - (currentMinHeight - newHeight);
        modal.style.top = newTop + 'px';
      }
      modal.style.maxHeight = `${newHeight}px`;
    };

    // Run immediately
    updateHeight();

    // Listen for window resize
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [ref]);
};
