import { useCallback, useEffect, useMemo, useRef } from 'react';

import cn from 'classnames';
import { BoxIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import { MODAL_VIEWS } from '@/components/organisms/Modals/ModalConductor';
import { useRtmViews } from '@/hooks/rtm/useRtmViews';
import useDesks from '@/hooks/useDesks';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useUser from '@/hooks/useUser';
import { useViews } from '@/hooks/useViews';
import { pushModal } from '@/redux/modals/actions';
import { selectAccountSlug, selectDeskId } from '@/redux/session/selectors';

import { NavList } from './NavList';
import { useNav } from '../../hooks/useNav';
import { usePrefetchConversations } from '../../hooks/usePrefetchConversations';
import { setView } from '../../redux/actions';
import { selectSideBarOpen, selectView } from '../../redux/selectors';

import styles from './Nav.module.scss';

export const Nav = () => {
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();
  const deskId = useSelector(selectDeskId);
  const dispatch = useDispatch();
  const { user } = useUser();
  const {
    conversationsMenu,
    filteredTeammatesMenu,
    filteredDepartmentsMenu,
    teammatesMenu,
    departmentsMenu,
  } = useNav();
  const { views, isViewsLoading, dropdownOpen } = useViews();
  const navigate = useNavigate();
  const sideBarOpen = useSelector(selectSideBarOpen);
  const { serviceDesks } = useDesks();
  const nodeRef = useRef(null);
  const { title } = useSelector(selectView);
  const { chat_views } = useFeatureFlag();
  usePrefetchConversations();
  useRtmViews();

  const handleOnMoreViewsClick = useCallback(() => {
    dispatch(pushModal(MODAL_VIEWS));
  }, [dispatch]);

  useEffect(() => {
    if (user && title === 'me') {
      dispatch(setView({ selectedAgent: user?.user_id }));
    }
  }, [dispatch, title, user]);

  const options = serviceDesks?.map((desk) => ({
    label: desk.name,
    value: desk.desk_id,
    icon: <BoxIcon size={16} />,
  }));

  const handleDropDownOptionChange = useCallback(
    (item: { value: string }) => {
      navigate(`/${slug}/chats/${item.value}/conversations`);
    },
    [navigate, slug]
  );

  const selectedDesk = useMemo(
    () => serviceDesks?.find((desk) => desk.desk_id === deskId)?.name,
    [deskId, serviceDesks]
  );

  const hasOneEnvironment = options?.length <= 1;

  // This needs to be re-rendered when dropdownOpen changes in order to open the list when the user creates a view
  const ViewsItem = useCallback(() => {
    return (
      <NavList
        title={t('conversation.views.views')}
        pinnedItems={views}
        hasPermission
        open={dropdownOpen}
        onMoreClick={handleOnMoreViewsClick}
        isLoading={isViewsLoading}
      />
    );
  }, [t, views, dropdownOpen, handleOnMoreViewsClick, isViewsLoading]);

  return (
    <div
      className={cn(styles.leftMenu, { [styles.closed]: !sideBarOpen })}
      ref={nodeRef}
    >
      <div className={styles.dropdown}>
        <DropdownList
          options={options}
          size="xlarge"
          optionClick={handleDropDownOptionChange}
          selected={selectedDesk}
          fullWidth
          optionsFullWidth
          disabled={hasOneEnvironment}
        >
          {deskId ? (
            <div
              className={cn(styles.deskLabel, {
                [styles.isDisabled]: hasOneEnvironment,
              })}
            >
              <BoxIcon size={16} />
              <span>{selectedDesk}</span>
            </div>
          ) : (
            <>{t('environments.no_env_yet')}</>
          )}
        </DropdownList>
      </div>
      <ul className={styles.sections}>
        <NavList
          title={t('conversation.conversations')}
          pinnedItems={conversationsMenu}
          hasPermission
          keepOpen
          open
        />
        <NavList
          title={t('conversation.teammates')}
          pinnedItems={filteredTeammatesMenu}
          items={teammatesMenu}
          hasPermission
          open={filteredTeammatesMenu.length > 0}
        />

        <NavList
          title={t('conversation.departments')}
          pinnedItems={filteredDepartmentsMenu}
          items={departmentsMenu}
          hasPermission
          open={filteredDepartmentsMenu.length > 0}
        />
        {chat_views && <ViewsItem />}
      </ul>
    </div>
  );
};
