import 'keen-slider/keen-slider.min.css';

import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import usePrevious from '@/hooks/usePrevious';
import { CarouselCard } from '@/models/action';

import Card from './card/Card';

import s from './carousel.module.scss';

type Props = {
  cards: CarouselCard[];
  onPostback?: (text: string) => void;
  slidesPerView?: number;
  isChat?: boolean;
  isReplay?: boolean;
};

const CarouselOld = ({
  cards,
  onPostback,
  slidesPerView = 1.25,
  isChat = false,
  isReplay,
}: Props) => {
  const prevCards = usePrevious(cards);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: slidesPerView,
      spacing: 16,
    },
    slideChanged(slider) {
      setCurrentSlide(slider?.track?.details?.rel);
    },
    updated: (x) => {
      instanceRef.current.update(x.options, 0);
    },
  });

  useEffect(() => {
    if (prevCards !== cards) {
      instanceRef.current.emit('updated');
    }
  }, [cards, instanceRef, prevCards]);

  return (
    <div
      className={cn(s.container, {
        [s.isChat]: isChat,
        [s.isReplay]: isReplay,
      })}
    >
      <div className={s.sliderContainer}>
        <div ref={sliderRef} className={cn('keen-slider', s.slider)}>
          {cards.map((card) => (
            <Card
              isChat={isChat}
              card={card}
              key={`${card.title}-${card.media.url}`}
              className="keen-slider__slide"
              onPostback={onPostback}
            />
          ))}
        </div>
        {currentSlide > 0 && cards.length > 1 && (
          <button
            className={cn(s.controlButton, s.leftControlButton)}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              instanceRef.current?.prev();
            }}
          >
            <ChevronLeftIcon size={16} />
          </button>
        )}
        {((!isChat && currentSlide < cards.length - 1) ||
          (isChat && currentSlide < cards.length - 2)) && (
          <button
            className={cn(s.controlButton, s.rightControlButton)}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              instanceRef.current?.next();
            }}
          >
            <ChevronRightIcon size={16} />
          </button>
        )}
      </div>
      {!isChat && (
        <div className={s.legend}>
          {slidesPerView === 2 ? currentSlide + 2 : currentSlide + 1}/
          {cards.length}
        </div>
      )}
    </div>
  );
};
export default CarouselOld;
