import { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { ArrowLeftIcon } from 'lucide-react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';

import { Banner } from '@/components/atoms/Banner/Banner';
import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import Input from '@/components/atoms/Input/Input';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import { hasChanges } from '@/components/pages/Versions/TimelineBox';
import { useAllRules } from '@/hooks/useAllRules';
import useBrains from '@/hooks/useBrains';
import { useUpdateRules } from '@/hooks/useUpdateRules';
import useVersions, { API as versionsApi } from '@/hooks/useVersions';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { isKeyEnter } from '@/util/util';
import { errorMessage, versionRules } from '@/util/validator';

import { RuleLink } from './RuleLink';
import { VersionChanges } from './VersionChanges';
import Modal from '../Modal';

import styles from './ModalPublishVersion.module.scss';

const ModalPublishVersion = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brainId = useSelector(selectBrainId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [versionsDiff, setVersionsDiff] = useState([]);
  const [loadingChanges, setLoadingChanges] = useState(false);
  const { rulesWithDeskName } = useAllRules({ brainId });
  const {
    isCreatePending,
    createVersion,
    maxVersionsReached,
    versions,
    deleteVersion,
    willReachVersionsLimit,
  } = useVersions(brainId);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'rules', actions.WRITE)
  );
  const lastVersion = versions?.sort((a, b) => b.version - a.version)[0];
  const { updateRule } = useUpdateRules();
  const { brain } = useBrains(brainId);
  const nextVersion = brain?.versions;
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    register,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({
    defaultValues: {
      version: { label: t('versions.select_version'), value: null },
      description: '',
      parent: false,
      rules: [],
    },
  });

  const { fields, replace } = useFieldArray({
    control,
    name: 'rules',
  });

  useDeepCompareEffect(() => {
    if (rulesWithDeskName) {
      const newRules = rulesWithDeskName.map((rule) => ({
        ...rule,
        checked: rule.status === 'active',
      }));
      reset(
        {
          ...getValues(),
          rules: newRules,
        },
        {
          keepDirty: false,
        }
      );
    }
  }, [rulesWithDeskName, reset, getValues]);

  useEffect(() => {
    const from = lastVersion?.version;
    const to = 0;
    if (
      brainId &&
      brain?.versions &&
      versionsDiff.length === 0 &&
      from !== to
    ) {
      setLoadingChanges(true);
      versionsApi
        .getDiff(brainId, from, to)
        .then((data) => {
          setVersionsDiff(data);
          setLoadingChanges(false);
        })
        .catch((error) => {
          console.error('Error getting versions diff', error);
          setLoadingChanges(false);
        });
    }
  }, [
    brainId,
    brain?.versions,
    nextVersion,
    brain?.version,
    lastVersion?.version,
    versionsDiff.length,
  ]);

  const childrenValues = watch('rules');
  const someChecked = childrenValues.some((item) => item.checked);
  const allChecked = childrenValues.every((item) => item.checked);

  const handleParentChange = () => {
    replace(fields.map((item) => ({ ...item, checked: !allChecked })));
    setValue('parent', !allChecked);
  };

  const onSubmit = useCallback(() => {
    setIsSubmitting(true);
    const { description, rules, version } = getValues();

    const createV = (descr: string) =>
      createVersion(descr, {
        onSuccess: (resp) => {
          if (canWrite) {
            const rulesToPublish = rules.filter((rule) => rule.checked);
            rulesToPublish.forEach((rule) => {
              updateRule({
                deskId: rule.desk_id,
                rule: {
                  ...rule,
                  actions: rule.actions.map((action) =>
                    action.type === 'assign_brain'
                      ? { ...action, brain_version: resp.version }
                      : action
                  ),
                },
              });
            });
          }
          dispatch(popModal());
          setIsSubmitting(false);
        },
      });

    if (maxVersionsReached) {
      // check if any rules are connected to the version
      const connectedRules = rulesWithDeskName?.filter((rule) =>
        rule.actions.some(
          (action) => action.brain_version === parseInt(version.value)
        )
      );

      if (connectedRules.length > 0) {
        const modalProps = {
          title: t('versions.connected_rules_title'),
          children: t('versions.connected_rules_subtitle'),
        };
        dispatch(pushModal('MODAL_WARN', modalProps));
        setIsSubmitting(false);
      } else {
        deleteVersion(version.value, {
          onSuccess: () => createV(description),
        });
      }
      setIsSubmitting(false);
      return;
    }

    createV(description);
  }, [
    canWrite,
    createVersion,
    deleteVersion,
    dispatch,
    getValues,
    maxVersionsReached,
    rulesWithDeskName,
    t,
    updateRule,
  ]);

  const handleEnterKey = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (isKeyEnter(e)) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <Modal
      title={
        canWrite
          ? t('version.publish_new_version')
          : t('version.create_version')
      }
      onPrimarySubmit={handleSubmit(onSubmit)}
      primaryButtonText={t('common.done')}
      size="medium"
      primaryButtonDisable={!isValid}
      isSubmitting={isCreatePending || isSubmitting}
    >
      {(maxVersionsReached || willReachVersionsLimit) && (
        <div className={styles.dropdown}>
          <Banner variant="warning" relativePosition>
            <Typography
              color="var(--color-foreground-muted)"
              variant="body-semi-bold"
            >
              {maxVersionsReached
                ? t('versions.max_versions_reached_title')
                : t('versions.about_to_reach_max_versions_title')}
            </Typography>
            <br />
            {maxVersionsReached && (
              <Typography>
                {t('versions.max_versions_reached_subtitle')}
              </Typography>
            )}
          </Banner>
        </div>
      )}

      <form autoComplete="off">
        {maxVersionsReached && (
          <div className={styles.dropdown}>
            <Typography
              variant="label-caps-large"
              color="var(--color-foreground-muted)"
              className={styles.title}
            >
              {t('versions.version_to_replace')}
            </Typography>
            <Controller
              name="version"
              control={control}
              rules={{
                validate: {
                  hasLabel: (version) =>
                    (version && version.value !== null) ||
                    t('versions.select_version'),
                },
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <DropdownList
                    options={versions
                      .map((version) => {
                        if (version.version === 0) return null;
                        return {
                          value: `${version.version}`,
                          label: `${t('common.version')} ${version.version}${version.description ? ' - ' + version.description : ''}`,
                        };
                      })

                      .filter((option) => option !== null)}
                    size="xlarge"
                    height="medium"
                    optionClick={(item) => onChange(item)}
                    selected={value.value}
                    fullWidth
                    sortBy=""
                  >
                    {value.label}
                  </DropdownList>
                );
              }}
            />
          </div>
        )}

        <Input
          label={t('common.description')}
          name="description"
          register={register('description', versionRules.description)}
          errorMessage={errorMessage({
            field: errors.description,
            maxLength: versionRules.description.maxLength,
          })}
          placeholder={t('common.description_placeholder')}
          size="medium"
          onKeyDown={handleEnterKey}
        />

        {hasChanges(versionsDiff) && (
          <>
            <Typography
              variant="label-caps-large"
              color="var(--color-foreground-muted)"
              className={styles.title}
            >
              {t('common.changes')}
            </Typography>

            <VersionChanges
              versionsDiff={versionsDiff}
              loadingChanges={loadingChanges}
            />
          </>
        )}

        {rulesWithDeskName?.length > 0 && canWrite && (
          <>
            <Typography
              variant="label-caps-large"
              color="var(--color-foreground-muted)"
              className={styles.title}
            >
              {t('common.assign')}
            </Typography>

            <div>
              <FormControlLabel
                label={
                  <>
                    <Typography
                      variant="label-caps-large"
                      color="var(--color-foreground-muted)"
                    >
                      {t('common.rule')}
                    </Typography>
                    <Typography
                      variant="label-caps-large"
                      color="var(--color-foreground-muted)"
                    >
                      {t('common.change')}
                    </Typography>
                  </>
                }
                control={
                  <Checkbox
                    checked={allChecked}
                    indeterminate={someChecked && !allChecked}
                    onChange={handleParentChange}
                    disabled={rulesWithDeskName?.length === 0}
                    size="small"
                  />
                }
                sx={{
                  '&.MuiFormControlLabel-root': {
                    height: 'var(--space-40)',
                    width: '100%',
                    backgroundColor: 'var(--color-bg-muted-50)',
                    marginLeft: 0,
                  },
                  '& .MuiFormControlLabel-label': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginRight: '22px',
                  },
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxHeight: 200,
                  overflow: 'auto',
                }}
              >
                {!rulesWithDeskName?.length &&
                  [...Array(3)].map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Skeleton key={index} height={40} />
                  ))}

                {fields.length > 0 &&
                  fields.map((item, index) => {
                    return (
                      <Controller
                        key={item.id}
                        name={`rules.${index}.checked`}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            label={
                              <Box className={styles.wrapper}>
                                <span className={styles.linkWrapper}>
                                  <RuleLink rule={item} />

                                  {item.status === 'inactive' && (
                                    <StatusBadge
                                      withIcon={false}
                                      variant={'neutral'}
                                      label={t('rules.inactive')}
                                    />
                                  )}
                                </span>

                                <span className={styles.arrow}>
                                  <Typography color="var(--color-foreground-muted)">
                                    V{item.brain_version}{' '}
                                  </Typography>
                                  <ArrowLeftIcon
                                    size={16}
                                    color="var(--color-foreground)"
                                  />
                                  <Typography color="var(--color-foreground)">
                                    V{nextVersion}
                                  </Typography>
                                </span>
                              </Box>
                            }
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                                size="small"
                              />
                            }
                            sx={{
                              '&.MuiFormControlLabel-root': {
                                height: 'var(--space-40)',
                                borderBottom: '1px solid var(--color-stroke)',
                                marginLeft: 0,
                              },
                              '& .MuiFormControlLabel-label': {
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 'var(--space-20)',
                              },
                            }}
                          />
                        )}
                      />
                    );
                  })}
              </Box>
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};
export default ModalPublishVersion;
