import cn from 'classnames';
import { ArrowUpIcon } from 'lucide-react';

import styles from './TileRuleHeader.module.scss';

export type Props =
  | { text: string; highPriority: true; lowPriority?: never }
  | { text: string; highPriority?: never; lowPriority: true }
  | { text: string; highPriority?: never; lowPriority?: never };

const TileHeader = ({ highPriority, lowPriority, text }: Props) => {
  if (highPriority || lowPriority) {
    return (
      <div className={styles.wrapper}>
        {highPriority && (
          <div>
            <span>{text}</span>{' '}
            <span>
              <ArrowUpIcon size={12} color="var(--color-foreground-muted)" />
            </span>
          </div>
        )}
        {lowPriority && (
          <div>
            <span>{text}</span>{' '}
            <span className={cn({ [styles.arrowdown]: lowPriority })}>
              <ArrowUpIcon size={12} color="var(--color-foreground-muted)" />
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default TileHeader;
