import { useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import { integrationIconByType } from '@/components/atoms/Icons/integrationIconByType';
import { IntegrationType } from '@/models/integration';
import { selectAccountSlug } from '@/redux/session/selectors';

import { AnalyticsBox } from './AnalyticsBox/AnalyticsBox';
import { SidebarBox } from './SidebarBox/SidebarBox';
import SidebarFields from './SidebarFields/SidebarFields';
import { useSubscribers } from '../../hooks/useSubscribers';
import {
  selectBroadcast,
  selectErrorCounter,
  selectIsDraft,
  selectSubscribers,
} from '../../redux/selectors';
import { getContextVariableOptions } from '../../utils';

import styles from './BroadcastPageSidebar.module.scss';

export const BroadcastSidebar = () => {
  const { t } = useTranslation();
  const broadcast = useSelector(selectBroadcast);
  const isDraft = useSelector(selectIsDraft);
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);
  const draftSubscribers = useSelector(selectSubscribers);
  const { subscribers, validSubscribersCount } = useSubscribers(
    broadcast?.broadcast_id
  );
  const errorCounter = useSelector(selectErrorCounter);

  const flatSubscribers = useMemo(
    () => [...(subscribers?.pages ?? [])].flatMap((page) => page.subscribers),
    [subscribers?.pages]
  );
  const audienceSubscribers = useMemo(
    () => [...draftSubscribers, ...flatSubscribers],
    [draftSubscribers, flatSubscribers]
  );

  const {
    subscribers_count = 0,
    read_count = 0,
    replied_count = 0,
  } = broadcast || {};

  const handleReviewAudienceClick = () => {
    if (isDraft) {
      navigate(`/${slug}/broadcasts/draft/audience`);
    } else {
      navigate(`/${slug}/broadcasts/${broadcast.broadcast_id}/audience`);
    }
  };
  const handleLogsClick = () => {
    navigate(`/${slug}/broadcasts/${broadcast.broadcast_id}/logs`);
  };
  const analyticsData = [
    {
      key: 'open_rate',
      label: t('broadcasts.open_rate'),
      percentage:
        subscribers_count === 0
          ? 0
          : Math.ceil((read_count / subscribers_count) * 100),
      value: `${read_count}/${subscribers_count}`,
    },

    {
      key: 'reply_rate',
      label: t('broadcasts.reply_rate'),
      percentage:
        read_count === 0 ? 0 : Math.ceil((replied_count / read_count) * 100),
      value: `${replied_count}/${read_count}`,
    },
  ];

  const channel: IntegrationType = broadcast.channel;

  const Icon = useMemo(
    () => integrationIconByType[channel] || (() => null),
    [channel]
  );

  const contextVariablesCount =
    getContextVariableOptions(audienceSubscribers)?.length ?? 0;

  return (
    <>
      <SidebarBox>
        <SidebarFields
          validSubscribersCount={validSubscribersCount}
          errorsCount={errorCounter}
          contextVariablesCount={contextVariablesCount}
        />
        <div className={styles.channelContainer}>
          <Typography
            variant="label-caps-large"
            color="var(--color-foreground-muted)"
          >
            {t('broadcasts.channel')}
          </Typography>
          <Icon size={24} />
          <Typography variant="subheading-regular">
            {t([`channels.${channel}`, 'pages.not_found'])}
          </Typography>
        </div>

        {audienceSubscribers.length > 0 && (
          <Button
            variant="secondary"
            size="small"
            onClick={handleReviewAudienceClick}
            className={styles.audienceButton}
          >
            {t('broadcasts.review_audience')}
          </Button>
        )}
      </SidebarBox>

      <SidebarBox>
        <div className={styles.analyticsHeader}>
          {analyticsData.map((item) => (
            <AnalyticsBox
              {...item}
              key={item.key}
              isDisabled={!broadcast?.sent_count}
            />
          ))}
        </div>
        {!broadcast?.sent_count ? (
          <Typography color="var(--color-foreground-muted)">
            {t('broadcasts.analytics_after')}
          </Typography>
        ) : (
          <Button
            variant="secondary"
            size="small"
            onClick={handleLogsClick}
            className={styles.audienceButton}
          >
            {t('broadcasts.see_logs')}
          </Button>
        )}
      </SidebarBox>
    </>
  );
};

export default BroadcastSidebar;
