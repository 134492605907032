import { useCallback } from 'react';

import cn from 'classnames';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { useReminderOptions } from '@/modules/aiAgents/components/Setup/useReminderOptions';
import {
  DEFAULT_TIMER_MINUTES,
  TYPES,
  isFinalAction,
} from '@/redux/dialogs/helper';
import { selectDraftDialogNodes } from '@/redux/dialogs/selectors';
import {
  addAction,
  addNode,
  moveAction,
  updateNode,
} from '@/redux/nodes/actions';

import styles from './Placeholder.module.scss';

interface PlatceHolderProps {
  nodeId: string;
  requisiteIndex?: number;
  conditionIndex?: number;
  beforeActionId?: string;
  show?: boolean;
  label?: string;
  accept?: string;
  isLast?: boolean;
}

function Placeholder({
  accept = TYPES.ACTION,
  nodeId,
  show = false,
  label = '',
  beforeActionId = null,
  requisiteIndex = null,
  conditionIndex = null,
  isLast = false,
}: PlatceHolderProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getTimerLabel } = useReminderOptions();
  const draftDialogNodes = useSelector(selectDraftDialogNodes);

  const dropActionInNode = useCallback(
    (item) => {
      if (item.move) {
        return dispatch(
          moveAction({
            source: item,
            target: {
              nodeId,
              beforeActionId,
              requisiteIndex,
              conditionIndex,
            },
          })
        );
      }

      const newAction = {
        ...item.props,
        type: item.subType,
        action_id: item.id,
      };

      // Create a Reminder node
      if (item.subType === 'reminder') {
        const randomId = uuidv4();
        newAction.trigger_node_id = randomId;

        const reminderNode = {
          name: `${t('common.after')} ${getTimerLabel(DEFAULT_TIMER_MINUTES)}`,
          type: 'event',
          node_id: randomId,
          parent_id: nodeId,
          requisites: [],
          actions: [],
          // special_prop to mark it as inactive action
          isReminderNode: true,
        };

        dispatch(addNode({ node: reminderNode }));

        // Take all nodes that have as parent node the node of the Reminder action
        // Edit these nodes in order to have parent node the new Reminder node
        // We do to prevent adding the Reminder node next to the existing ones
        const updateParentIdNodes = draftDialogNodes.filter(
          (node) => node.parent_id === nodeId
        );

        if (updateParentIdNodes.length > 0) {
          updateParentIdNodes.forEach((node) => {
            dispatch(
              updateNode({
                nodeId: node.node_id,
                node: {
                  parent_id: randomId,
                },
              })
            );
          });
        }
      }

      dispatch(
        addAction({
          nodeId,
          beforeActionId,
          requisiteIndex,
          conditionIndex,
          newAction,
        })
      );
    },
    [
      dispatch,
      nodeId,
      beforeActionId,
      requisiteIndex,
      conditionIndex,
      t,
      getTimerLabel,
      draftDialogNodes,
    ]
  );

  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: dropActionInNode,
    canDrop: (item) => {
      if (item.subType) {
        return !isFinalAction(item.subType) || isLast;
      }
      return !isFinalAction(item.type);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div
      ref={drop}
      className={cn({
        [styles.placeholder]: true,
        [styles['placeholder--over']]: isOver,
        [styles['placeholder--can-drop']]: canDrop,
        [styles['placeholder--show']]: show,
      })}
    >
      {label && <p>{label}</p>}
    </div>
  );
}

export default Placeholder;
