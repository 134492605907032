import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image as PdfImage,
} from '@react-pdf/renderer';
import moment from 'moment';

import { LogSessionContentRow } from '@/modules/analytics/models';

const timeKeys = ['expired_time', 'end_time', 'start_time'];

Font.register({
  // This font is saved in assets
  family: 'NotoSans',
  fonts: [
    {
      src: '/assets/fonts/NotoSans-Regular.ttf',
    },
  ],
});

const rowStyles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tablePage: {
    fontSize: 12,
    justifyContent: 'center',
    paddingTop: '30px',
  },
  section: {
    paddingLeft: 60,
    paddingRight: 60,
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
    marginBottom: '4px',
    paddingBottom: '4px',
    fontFamily: 'NotoSans',
  },
  description: {
    width: '30%',
  },
  value: {
    maxWidth: '70%',
  },
  logo: {
    width: '120px',
    marginBottom: '16px',
  },
  image: {
    objectFit: 'contain',
  },
});

interface Props {
  content: LogSessionContentRow;
  snapshot: string;
}

export const PdfExport = ({ content, snapshot: oldSnapshot }: Props) => {
  // FIlters out rows
  const showRows = (key: string) => {
    if (key === '__typename') {
      return false;
    }
    if (key === 'tags' && content?.tags?.length > 0) {
      return true;
    }
    return content[key] !== null && typeof content[key] !== 'object';
  };

  const showContent = (key: string) => {
    if (key === 'tags') {
      return JSON.stringify(content[key]);
    }
    if (typeof content[key] === 'boolean') {
      return String(content[key]);
    }
    if (timeKeys.includes(key)) {
      return moment.utc(content[key]).local().format('YYYY-MM-DD HH:mm:ss');
    }
    return content[key];
  };

  return (
    <Document>
      <Page style={rowStyles.page}>
        <View>
          <PdfImage src="/assets/logo.png" style={rowStyles.logo} />
          <View style={rowStyles.section}>
            <PdfImage src={oldSnapshot} style={rowStyles.image} />
          </View>
        </View>
      </Page>
      <Page size="A4" style={rowStyles.tablePage}>
        <View style={rowStyles.section}>
          {Object.keys(content)
            .filter(showRows)
            .sort()
            .map((key) => {
              return (
                <View style={rowStyles.row} key={key}>
                  <Text style={rowStyles.description}>
                    {key.split('_').join(' ')}
                  </Text>
                  <Text style={rowStyles.value}>{showContent(key)}</Text>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};
