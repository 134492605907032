import { useEffect, useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Routes, Route, useParams, Navigate } from 'react-router';

import Conversations from '@/components/pages/Conversations/Conversations';
import { ChatContext } from '@/modules/humanChat/components/Conversation/context';
import Conversation from '@/modules/humanChat/components/Conversation/Conversation';
import { SubNavConversationNew } from '@/modules/humanChat/components/SubNavConversation';
import { resetView } from '@/modules/humanChat/redux/actions';
import { setDeskId } from '@/redux/session/actions';

import NotFound from '../NotFound/NotFound';

import styles from './ConversationsRouter.module.scss';

const ConversationsRouter: React.FC = () => {
  const dispatch = useDispatch();
  const { deskId } = useParams();
  const [chat, setChat] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (deskId) {
      dispatch(setDeskId(deskId));
    }
  }, [dispatch, deskId]);

  useEffect(() => {
    return () => {
      dispatch(resetView());
    };
  }, [dispatch]);

  const contextValue = useMemo(() => ({ chat, setChat }), [chat]);

  return (
    <ChatContext.Provider value={contextValue}>
      <div className={styles.wrapper}>
        <SubNavConversationNew />
        <Routes>
          <Route path="/" element={<Navigate to="conversations" replace />} />
          <Route path="/conversations" element={<Conversations />} />
          <Route path="/conversations/:sessionId" element={<Conversation />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </ChatContext.Provider>
  );
};

export default ConversationsRouter;
