import { useCallback, useEffect, useRef } from 'react';

import { ExternalLinkIcon, UploadIcon } from 'lucide-react';
import moment from 'moment';
import QRCodeStyling from 'qr-code-styling';

import Link from '../Link/Link';

import styles from './QrCodeGenerator.module.scss';

interface QrCodeGeneratorProps {
  url: string;
  image: string;
  width?: number;
  height?: number;
  margin?: number;
  overlayType?: 'download' | 'link';
}

const QrCodeGenerator = ({
  url,
  image,
  width = 300,
  height = 150,
  margin = 40,
  overlayType = 'download',
}: QrCodeGeneratorProps) => {
  const qrCodeRef = useRef(null);
  const constructorRef = useRef(
    new QRCodeStyling({
      width,
      height,
      margin,
      imageOptions: {
        margin: 2,
        imageSize: 0.5,
      },
    })
  );

  useEffect(() => {
    constructorRef.current.append(qrCodeRef.current);
  }, []);

  useEffect(() => {
    if (url) {
      constructorRef.current.update({
        data: url,
        image,
      });
    }
  }, [url, image]);

  const handleClick = useCallback(
    () =>
      constructorRef.current.download({
        name: `qr-${moment().format('YYYY-MM-DD')}`,
        extension: 'png',
      }),
    []
  );

  const qrSize = Math.min(width, height) - 2 * margin;

  const qrCodeStyles = {
    transform: `translate(${(-1 * width) / 2 + qrSize / 2}px, ${
      (-1 * height) / 2 + qrSize / 2
    }px)`,
  };

  const containerStyles = {
    width: qrSize,
    height: qrSize,
  };

  const renderOverlay = () => {
    switch (overlayType) {
      case 'download':
        return (
          <div
            className={styles.overlay}
            onClick={handleClick}
            onKeyUp={handleClick}
            role="button"
            tabIndex={0}
          >
            <UploadIcon size={32} color="var(--color-foreground-muted)" />
          </div>
        );
      case 'link':
        return (
          <Link className={styles.overlay} href={url} external tabIndex={0}>
            <ExternalLinkIcon size={32} color="var(--color-foreground-muted)" />
          </Link>
        );
    }
  };

  return (
    <div style={containerStyles} className={styles.container}>
      <div style={qrCodeStyles} className={styles.qrcode} ref={qrCodeRef} />
      {renderOverlay()}
    </div>
  );
};

export default QrCodeGenerator;
