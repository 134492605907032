import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { CirclePlusIcon } from 'lucide-react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Node } from 'slate';

import Button from '@/components/atoms/Button/Button/Button';
import {
  markdownToSlatePromise,
  richTextToMarkdown,
} from '@/components/organisms/RichTextEditor/utils';
import RichTextEditorTabs from '@/components/organisms/RichTextEditorTabs/RichTextEditorTabs';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { generateOption, randId } from '@/redux/dialogs/helper';
import {
  selectActionData,
  selectNodeIdByActionId,
} from '@/redux/dialogs/selectors';
import { updateAction, updateOptions } from '@/redux/nodes/actions';
import { MAX_TEXT_ACTION_OPTIONS } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';
import { LENGTH_XXL } from '@/util/validator';

import ToolkitButton from './ToolkitButton';
import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionText.module.scss';

type FormType = {
  actionTexts: {
    textValue: Node[];
    tabName: string;
    maxCharacters: number;
  }[];
} & FieldValues;

const ToolkiActionText = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actionId, texts, options } = useSelector(
    selectActionData,
    shallowEqual
  );
  const errorRefOne = useRef(false);
  const errorRefTwo = useRef(false);
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const [optionList, setOptionList] = useState(options);
  const newTabProps = useMemo(
    () => ({
      textValue: [
        {
          type: 'paragraph',
          children: [{ text: t('actions.generate_text', { 0: randId() }) }],
        } as Node,
      ],
      tabName: t('dialog.actions.alt_text'),
      maxCharacters: LENGTH_XXL,
    }),
    [t]
  );
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      actionTexts: [
        {
          textValue: [],
          maxCharacters: LENGTH_XXL,
          tabName: '',
        },
      ],
    },
  });

  const firstTabErrorMessage = errors.actionTexts?.[0]?.textValue?.message;
  const secondTabErrorMessage = errors.actionTexts?.[1]?.textValue?.message;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'actionTexts',
  });

  useEffect(() => {
    const convertTexts = async () => {
      try {
        const convertedTexts = await Promise.all(
          texts.map(async (text, index) => {
            const slateNodes = await markdownToSlatePromise(text);
            return {
              textValue: slateNodes,
              tabName:
                index === 0 ? t('chatBox.reply') : t('dialog.actions.alt_text'),
              maxCharacters: LENGTH_XXL,
            };
          })
        );
        setValue('actionTexts', convertedTexts);
      } catch (error) {
        console.error('Error converting texts to slate nodes:', error);
      }
    };

    convertTexts();
    // this useEffect should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const onAddOptionClick = useCallback(() => {
    setOptionList((currentList) => {
      const newList = [...currentList];
      newList.push(generateOption());
      dispatch(updateOptions({ actionId, options: newList }));
      return newList;
    });
  }, [dispatch, actionId]);

  const onOptionChange = useCallback(
    (option, index) => {
      setOptionList((currentList) => {
        const newList = [...currentList];
        const oldItem = newList[index];
        newList[index] = { ...oldItem, ...option };
        setTimeout(
          () => dispatch(updateOptions({ actionId, options: newList })),
          0
        );
        return newList;
      });
    },
    [actionId, dispatch]
  );

  const onOptionDelete = useCallback(
    (index) => {
      setOptionList((currentList) => {
        const newList = [...currentList];
        newList.splice(index, 1);
        const options = newList.length > 0 ? newList : undefined;

        dispatch(updateOptions({ actionId, options }));
        return newList;
      });
    },
    [dispatch, actionId]
  );

  const handleDragItem = useCallback((dragIndex, hoverIndex) => {
    setOptionList((currentList) => {
      const draggedItem = currentList[dragIndex];
      const newList = [...currentList];
      newList.splice(dragIndex, 1);
      newList.splice(hoverIndex, 0, draggedItem);
      return newList;
    });
  }, []);

  const handleOnDrop = useCallback(() => {
    dispatch(updateOptions({ actionId, options: optionList }));
  }, [dispatch, actionId, optionList]);

  const getIndex = useCallback(
    (item) => () => {
      return optionList.findIndex(
        (option) => option.option_id === item.option_id
      );
    },
    [optionList]
  );

  const dispatchDialogError = useCallback(
    (key: string, errorMessage: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.text'),
            body: capitalizeFirstLetter(errorMessage),
            type: 'text',
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t]
  );

  useEffect(() => {
    const checkErrors = async () => {
      // Check first tab
      const isValidTabOne = await trigger('actionTexts.0.textValue');
      if (!isValidTabOne && firstTabErrorMessage && !errorRefOne.current) {
        dispatchDialogError('tab-1', firstTabErrorMessage);
        errorRefOne.current = true;
      } else if (isValidTabOne && errorRefOne.current) {
        dispatchDialogError('tab-1', '');
        errorRefOne.current = false;
      }

      // Check second tab
      const isValidTabTwo = await trigger('actionTexts.1.textValue');
      if (!isValidTabTwo && secondTabErrorMessage && !errorRefTwo.current) {
        dispatchDialogError('tab-2', secondTabErrorMessage);
        errorRefTwo.current = true;
      } else if (isValidTabTwo && errorRefTwo.current) {
        dispatchDialogError('tab-2', '');
        errorRefTwo.current = false;
      }
    };

    checkErrors();
  }, [
    trigger,
    firstTabErrorMessage,
    secondTabErrorMessage,
    dispatchDialogError,
  ]);

  const handleTabOnChange = useCallback(async () => {
    const watchedTexts = watch('actionTexts');
    const convertedTexts = watchedTexts.map((text) =>
      richTextToMarkdown(text.textValue)
    );

    if (!isEqual(convertedTexts, texts)) {
      dispatch(
        updateAction({
          actionId,
          action: {
            texts: convertedTexts,
          },
        })
      );
    }
  }, [actionId, dispatch, texts, watch]);

  const debouncedHandleTabOnChange = useMemo(
    () => debounce(handleTabOnChange, 200),
    [handleTabOnChange]
  );

  return (
    <ToolkitWrapper type="text">
      <RichTextEditorTabs<FormType>
        tabConfig={fields}
        control={control}
        append={append}
        remove={remove}
        handleTabBlur={debouncedHandleTabOnChange}
        newTabProps={newTabProps}
        errors={errors}
        controllerName="actionTexts"
        inputName="textValue"
        trigger={trigger}
        handleTabOnChange={debouncedHandleTabOnChange}
      />
      <div className={styles.buttonContainer}>
        <Button
          size="small"
          variant="tertiary"
          onClick={onAddOptionClick}
          className={styles.button}
          disabled={optionList.length === MAX_TEXT_ACTION_OPTIONS}
        >
          <CirclePlusIcon size={16} color="var(--color-foreground-muted)" />
          {t('common.add_quick_option')}
        </Button>
      </div>
      <div className={styles.options}>
        {optionList?.map((o, i) => {
          return (
            <ToolkitButton
              actionId={actionId}
              option={o}
              onChange={onOptionChange}
              onDelete={onOptionDelete}
              index={i}
              key={o.option_id}
              handleDragItem={handleDragItem}
              getIndex={getIndex(o)}
              onDrop={handleOnDrop}
            />
          );
        })}
      </div>
    </ToolkitWrapper>
  );
};

export default ToolkiActionText;
