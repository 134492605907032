import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RotateCwIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';

import styles from './ContextSectionWrapper.module.scss';

type Props = {
  title: string;
  onClick: () => void;
  children?: React.ReactNode;
  hideIcon?: boolean;
};

export const ContextSectionWrapper = ({
  title,
  onClick,
  hideIcon = false,
  children,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.wrapper__header}>
        <Typography
          variant="label-caps-large"
          color="var(--color-foreground-muted)"
        >
          {title}
        </Typography>
        {!hideIcon && (
          <IconButton
            ariaLabel={t('try_it.reset')}
            size="small"
            onClick={onClick}
          >
            <RotateCwIcon size={16} />
          </IconButton>
        )}
      </Box>

      {children && <Box mt="var(--space-16)">{children}</Box>}
    </Box>
  );
};
