// Custom icon

import { Icon } from '@/models/icon';

const FileDollarIcon = ({ size = 16, color = 'currentColor' }: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14 22h4a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v3m10-5v4a2 2 0 0 0 2 2h4M8 8.833v13.334m3.333-11.334h-5a2.333 2.333 0 1 0 0 4.667h3.334a2.334 2.334 0 0 1 0 4.667H4"
      />
    </svg>
  );
};
export default FileDollarIcon;
