import { ItalicIcon } from 'lucide-react';

import { StyleButton } from './StyleButton';

export const ItalicButton = () => {
  return (
    <StyleButton
      type="italic"
      Icon={() => <ItalicIcon size={18} color="var(--color-foreground)" />}
    />
  );
};
