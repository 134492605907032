import { useCallback, useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { sendMessage } from '@/api/client';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import { Brain } from '@/models/brain';
import { RootState } from '@/models/state';
import {
  BrainMessage,
  Message,
  MessageSent,
  NodeType,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';
import {
  updateContext,
  updateSessionId,
  updateSelectedMessage,
  setAddTryItMessage,
  setSessionNodes,
  setIsLoadingResponse,
  resetSelectedMessage,
} from '@/modules/TryIt/redux/actions';
import {
  selectContext,
  selectIsTryItReplay,
  selectTryItSessionId,
} from '@/modules/TryIt/redux/selectors';
import { randId } from '@/redux/dialogs/helper';

import { calculateNodesAfterTryResponse } from '../utils/helper';

const useTryIt = (
  messages: LogMessage[],
  brain: Brain,
  tags?: string[],
  webHost?: string
) => {
  const { accountUserChecklist, markAsComplete } = useHomeCheckList();

  const tryItSessionId = useSelector(selectTryItSessionId);
  const isReplay = useSelector(selectIsTryItReplay);
  const storeContext = useSelector(selectContext);

  const bodyRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const widgetRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const [activeNode, setActiveNode] = useState<number>();
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const activeNodes = useSelector(
    (state: RootState) => state.tryIt.sessionNodes
  );

  useEffect(() => {
    if (inputRef.current && activeNodes.length === 0 && !isReplay) {
      inputRef.current.focus();
    }
  }, [activeNodes.length, isReplay]);

  useEffect(() => {
    // Format messages when isReplay
    if (isReplay && messages) {
      return () => {
        dispatch(resetSelectedMessage());
        dispatch(updateContext([{}]));
      };
    }
  }, [dispatch, isReplay, messages]);

  useEffect(() => {
    if (!tryItSessionId && !isReplay) {
      dispatch(updateSessionId(uuidv4()));
    }
  }, [dispatch, isReplay, tryItSessionId]);

  // Use a ref to track if this effect has run before (only for replay mode)
  const hasRunEffect = useRef(false);

  useEffect(() => {
    // Check if we should apply the single-run constraint (only in replay mode)
    const shouldCheckIfRun = isReplay && hasRunEffect.current;

    // Should focus on the last message if its the first time ot if new message comes from TEST
    if (!isEmpty(activeNodes) && !shouldCheckIfRun) {
      const debugLogs = activeNodes[activeNodes.length - 1]?.debugLogs;
      const context = tags
        ? { ...activeNodes[activeNodes.length - 1]?.context, tags }
        : activeNodes[activeNodes.length - 1]?.context;

      setActiveNode(activeNodes.length - 1);
      if (bodyRef.current) {
        bodyRef.current.scrollTop = bodyRef.current.scrollHeight;
      }

      dispatch(
        updateSelectedMessage({
          selectedMessage: {
            debugLogs,
            context,
            index: activeNodes.length - 1,
          },
        })
      );

      // If in replay mode, mark that we've run this effect
      if (isReplay) {
        hasRunEffect.current = true;
      }
    }
  }, [dispatch, isReplay, activeNodes, tags]);

  const addMessage = useCallback(
    (message: Message) => {
      const nodeKey = randId();
      const newNode: Partial<NodeType> = {
        intents: undefined,
        key: nodeKey,
        messages: [message],
        isComplete: false,
        name: null,
        id: null,
        context: undefined,
        debugLogs: undefined,
        collection: undefined,
        nodes_stack: undefined,
        reaction: undefined,
      };

      dispatch(setAddTryItMessage(newNode));
      return { nodeKey, newNode };
    },
    [dispatch]
  );

  // Handles user input
  const handleInput = useCallback(
    async (inputText: string) => {
      // If the 'brain' object is not initialized, exit the function early.
      if (!brain) {
        return;
      }

      // Checks if a specific user preference is not set, and if so, marks it as complete.
      if (!accountUserChecklist[AccountUserPrefsEnum.TEST_DIALOG]) {
        markAsComplete(AccountUserPrefsEnum.TEST_DIALOG);
      }

      // Disables user input to prevent multiple inputs during processing.
      setIsInputDisabled(true);

      // Generates a unique identifier for the new message.
      const messageSentId = randId();

      // Creates a new message object with the user's input.
      const newMessage: UserMessage = {
        text: inputText,
        id: messageSentId,
        type: 'user',
        intents: undefined,
        entities: undefined,
      };

      dispatch(setIsLoadingResponse(true));
      // Adds the new message to the messages list and retrieves its node key.
      const { nodeKey, newNode } = addMessage(newMessage);

      // Prepares a message object to be sent to the backend.
      const messageSent: MessageSent = {
        session_id: tryItSessionId,
        input: { text: newMessage.text },
        channel: 'test',
        context: storeContext[storeContext.length - 1] ?? {},
      };

      try {
        // Sends the message to the backend and waits for a response.
        const { data, headers } = await sendMessage(
          brain.brain_id,
          messageSent,
          undefined,
          webHost
        );
        dispatch(setIsLoadingResponse(false));

        const request_id = headers['x-request-id'];
        // Extracts data from the backend's response.
        const { nodes_stack } = data.output.debug;

        // Updates the context with the new data from the response.
        dispatch(updateContext([...storeContext, data.context]));

        // Prepares the response message from the backend.
        const responseMessage: BrainMessage | null = data.output.responses
          .length
          ? { id: randId(), type: 'brain', responses: data.output.responses }
          : null;

        const isDisambiguation =
          responseMessage?.responses?.[0]?.type === 'disambiguation';

        const newNodes = calculateNodesAfterTryResponse({
          nodes: [...activeNodes, newNode],
          nodes_stack,
          isDisambiguation,
          data,
          nodeKey,
          request_id,
          messageSentId,
          responseMessage,
        });

        dispatch(setSessionNodes(newNodes));
      } catch (error) {
        console.error(error);
        dispatch(setIsLoadingResponse(false));
        dispatch(updateContext([...storeContext, {}]));
        const newNodes = [...activeNodes, newNode].map((node) => {
          if (node.key !== nodeKey) {
            return node;
          }
          return {
            ...node,
            messages: [
              // Updates the message to show there was an error.
              ...(node.messages || []).map((msg: BrainMessage) =>
                msg.id === messageSentId ? { ...msg, intents: null } : msg
              ),
              // Adds an error message.
              { id: randId(), type: 'error', text: error.message },
            ],
          };
          //TODO maybe check the TS here
        }) as NodeType[];
        dispatch(setSessionNodes(newNodes));
      } finally {
        // Re-enables user input after processing is complete.
        setIsInputDisabled(false);
      }
    },
    [
      accountUserChecklist,
      activeNodes,
      addMessage,
      brain,
      dispatch,
      markAsComplete,
      storeContext,
      tryItSessionId,
      webHost,
    ]
  );

  const handleNodeClick = useCallback(
    (index: number) => {
      if (index !== activeNode) {
        setActiveNode(index);
        dispatch(
          updateSelectedMessage({
            selectedMessage: {
              debugLogs: activeNodes[index].debugLogs,
              context: tags
                ? { ...activeNodes[index]?.context, tags }
                : activeNodes[index]?.context,
              index,
            },
          })
        );
      }
    },
    [activeNode, dispatch, activeNodes, tags]
  );

  return {
    nodes: activeNodes,
    isReplay,
    activeNode,
    isInputDisabled,
    handleInput,
    handleNodeClick,
    bodyRef,
    inputRef,
    widgetRef,
  };
};

export default useTryIt;
