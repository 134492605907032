import { PlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { MODAL_CUSTOM_GUIDELINE_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';

export const AddGuidelineButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  return (
    <Button
      size="small"
      variant="secondary"
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(pushModal(MODAL_CUSTOM_GUIDELINE_CREATE));
      }}
      disabled={!canWrite}
    >
      <PlusIcon size={16} />
      {t('ai_agents.knowledge.add_guideline')}
    </Button>
  );
};
