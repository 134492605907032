import Button from '@mui/material/Button';
import cn from 'classnames';
import { BellIcon } from 'lucide-react';

import styles from './Changelog.module.scss';

import './beamer.scss';

export const Changelog = () => {
  return (
    <Button className={cn(styles.container, 'beamer')} disableRipple>
      <BellIcon color="var(--color-background)" size={21} />
    </Button>
  );
};
