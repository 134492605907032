import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';

import { ToneButton } from './ToneButton';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { ToneIcon } from '../../icons/ToneIcon';
import { ToneOfVoice } from '../../models';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

import styles from './Setup.module.scss';

type Form = {
  tone_of_voice: number;
};

const TONE_OF_VOICES: ToneOfVoice[] = [
  'creative',
  'neutral',
  'matter_of_fact',
  'professional',
];

const DEBT_COLLECTION_TONE_OF_VOICES: ToneOfVoice[] = [
  'empathetic',
  'neutral',
  'firm',
  'urgent',
];

const FORM_ID = 'ai-agent-tone-of-voice';

export const ToneOfVoiceForm = ({
  number: number,
  isDebtCollection = false,
}) => {
  const brainId = useSelector(selectBrainId);
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    control,
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      tone_of_voice: brain?.guidelines?.tone_of_voice ?? 0,
    },
  });

  const onSubmit = ({ tone_of_voice }: Form) => {
    updateBrain({
      guidelines: {
        ...brain.guidelines,
        tone_of_voice,
      },
      brain_id: brainId,
    });
  };

  useTrackFormState({ isDirty, formId: FORM_ID });

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <FormCard.Header
        title={t('ai_agents.tone_of_voice.title')}
        subtitle={t('ai_agents.tone_of_voice.subtitle')}
        icon={
          <NumberIcon
            color="var(--color-foreground-muted)"
            size="large"
            number={number}
          />
        }
      />
      <FormCard.Content>
        <Controller
          name="tone_of_voice"
          control={control}
          render={({ field }) => {
            return (
              <Box display="flex" gap="var(--space-8)">
                {(isDebtCollection
                  ? DEBT_COLLECTION_TONE_OF_VOICES
                  : TONE_OF_VOICES
                ).map((tone, index) => {
                  const helperTextKey = `ai_agents.tone_of_voice.${tone}${isDebtCollection && tone === 'neutral' ? '_dc' : ''}_helper_text`;

                  return (
                    <ToneButton
                      key={tone}
                      className={cn({
                        [styles.selected]: field.value === index - 1,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        field.onChange(index - 1);
                      }}
                      disabled={!canWrite}
                    >
                      <ToneIcon type={tone} />
                      <Typography
                        variant="body-semi-bold"
                        color="var(--color-foreground)"
                      >
                        {t(`ai_agents.tone_of_voice.${tone}`)}
                      </Typography>
                      <Typography
                        color="var(--color-foreground-muted)"
                        variant="label-regular"
                      >
                        {t(helperTextKey)}
                      </Typography>
                    </ToneButton>
                  );
                })}
              </Box>
            );
          }}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
          disabled={!isDirty}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
