import { PropsWithChildren } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { CircleCheckIcon } from 'lucide-react';

import styles from './StepFieldset.module.scss';
interface Props {
  title: string;
  step: number;
  status: 'completed' | 'active' | 'disabled';
}

const StepFieldset = ({
  title,
  step,
  status = 'disabled',
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {status === 'completed' ? (
          <CircleCheckIcon size={18} color="var(--color-successful)" />
        ) : (
          <Typography className={cn(styles.step, styles[status])}>
            {step}
          </Typography>
        )}
        <Typography variant="h3-semi-bold">{title}</Typography>
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  );
};

export default StepFieldset;
