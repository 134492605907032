import { forwardRef } from 'react';

import MaterialLink, {
  LinkProps as MaterialLinkProps,
} from '@mui/material/Link';
import { ExternalLinkIcon } from 'lucide-react';

import './Link.scss';

export interface LinkProps extends MaterialLinkProps {
  icon?: boolean;
  external?: boolean;
  children?: React.ReactNode;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ external = false, icon = false, children, ...rest }, ref) => {
    const props: MaterialLinkProps = { ...rest };
    if (external) {
      props.target = '_blank';
      props.rel = 'noopener noreferrer';
    }

    return (
      <MaterialLink
        sx={{
          color: 'var(--color-accent)',
        }}
        className="link"
        {...props}
        ref={ref}
      >
        {children}
        {icon && (
          <i className="link__icon">
            <ExternalLinkIcon size={16} color="var(--color-foreground-muted)" />
          </i>
        )}
      </MaterialLink>
    );
  }
);

Link.displayName = 'Link';

export default Link;
