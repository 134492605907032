import Skeleton from '@mui/material/Skeleton';
import { DefaultRawDatum, ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';

import styles from './Pie.module.scss';

interface Props {
  data?: (DefaultRawDatum & { label?: string | number })[];
  Tooltip?: React.ReactElement;
  innerRadius?: number;
  hasCenterMetric?: boolean;
  padAngle?: number;
  multiColor?: boolean;
  arcLabelsRadiusOffset?: number;
  enableArcLinkLabels?: boolean;
  enableArcLabels?: boolean;
  arcLinkLabelsDiagonalLength?: number;
  arcLinkLabelsStraightLength?: number;
  hasMargin?: boolean;
  arcLabelsSkipAngle?: number;
  arcLinkLabelsSkipAngle?: number;
  colors?: string[];
}

const pieColors = [
  'var(--analytics-01)',
  'var(--analytics-02)',
  'var(--analytics-03)',
  'var(--analytics-04)',
  'var(--analytics-05)',
  'var(--analytics-06)',
  'var(--analytics-07)',
];

const CenteredMetric = ({
  dataWithArc,
  innerRadius,
}: {
  dataWithArc: { value: number; label: string }[];
  innerRadius: number;
}) => {
  const { t } = useTranslation();
  const centerPercentage = dataWithArc[1].value;
  const centerTitle = dataWithArc[1]?.label;

  return (
    <foreignObject width="100%" height="100%">
      <div className={styles.wrapper}>
        <div
          className={styles.inner_wrapper}
          style={{ width: innerRadius * 2, height: innerRadius * 2 }}
        >
          <p className={styles.middle_percent}>
            {t('common.percentage', { 0: Math.round(centerPercentage) })}
          </p>
          {centerTitle && <p className={styles.middle_text}>{centerTitle}</p>}
        </div>
      </div>
    </foreignObject>
  );
};

const PieChart = ({
  data,
  hasCenterMetric = true,
  padAngle = 0,
  multiColor = false,
  arcLabelsRadiusOffset = 0.5,
  arcLinkLabelsDiagonalLength = 16,
  arcLinkLabelsStraightLength = 24,
  enableArcLinkLabels = false,
  enableArcLabels = false,
  Tooltip,
  innerRadius = 0.624,
  hasMargin = false,
  arcLabelsSkipAngle = 0,
  arcLinkLabelsSkipAngle = 0,
  colors = ['var(--analytics-07)', 'var(--color-accent)'],
}: Props) => {
  const { t } = useTranslation();
  if (!data) {
    return (
      <div className={styles.chart}>
        <Skeleton variant="circular" width={150} height={150} />
      </div>
    );
  }

  return (
    <div className={styles.chart}>
      <ResponsivePie
        data={data}
        innerRadius={innerRadius}
        tooltip={() => Tooltip ?? null}
        margin={hasMargin ? { top: 30, right: 20, bottom: 20, left: 30 } : {}}
        colors={multiColor ? pieColors : colors}
        enableArcLinkLabels={enableArcLinkLabels}
        enableArcLabels={enableArcLabels}
        padAngle={padAngle}
        arcLabelsRadiusOffset={arcLabelsRadiusOffset}
        arcLabelsTextColor="var(--color-text-primary-foreground)"
        arcLinkLabelsDiagonalLength={arcLinkLabelsDiagonalLength}
        arcLinkLabelsStraightLength={arcLinkLabelsStraightLength}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={arcLabelsSkipAngle}
        arcLinkLabelsSkipAngle={arcLinkLabelsSkipAngle}
        arcLabel={(d: { value: number }) =>
          t('common.percentage', { 0: Math.round(d.value) })
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Types in ResponsivePie are wrong
        layers={
          hasCenterMetric
            ? [CenteredMetric, 'radialLabels', 'arcs']
            : ['arcs', 'arcLabels', 'arcLinkLabels', 'legends']
        }
      />
    </div>
  );
};

export default PieChart;
