import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { CircleDollarSignIcon } from 'lucide-react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';

import styles from '../DetailsSection.module.scss';

type ContextChangesProps = {
  context_changes: Record<string, number>;
  isSubsteps?: boolean;
};

export const ContextChanges = ({
  context_changes,
  isSubsteps = false,
}: ContextChangesProps) => {
  const fontVariant = isSubsteps ? 'label-regular' : 'body-regular';
  const { t } = useTranslation();

  if (!context_changes) return null;

  const contextChange = {
    '0': t('activityLogs.updated'),
    '1': t('try_it.details.added'),
    '-1': t('activityLogs.removed'),
  };

  return (
    <span
      className={cn(styles.contextChanges, {
        [styles['contextChanges--substeps']]: isSubsteps,
      })}
    >
      {isSubsteps && (
        <>
          <CircleDollarSignIcon
            size={14}
            color="var(--color-foreground-muted)"
          />
          <Typography
            variant="label-regular"
            color="var(--color-foreground-muted)"
          >
            {t('common.context')}
          </Typography>
        </>
      )}

      {Object.keys(context_changes).map((key, index) => {
        return (
          <Fragment key={key}>
            <Typography
              variant={fontVariant}
              color="var(--color-foreground-muted)"
              // eslint-disable-next-line react/no-array-index-key
              key={`${key}: ${context_changes[key]}-${index}`}
            >
              <Typography
                className={styles.contextVariable}
                variant={fontVariant}
                color="var(--color-foreground-muted)"
              >
                ${key}
              </Typography>{' '}
              {contextChange[context_changes[key].toString()]}
            </Typography>

            {index < Object.keys(context_changes).length - 1 && ', '}
          </Fragment>
        );
      })}
    </span>
  );
};
