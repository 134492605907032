import Typography from '@mui/material/Typography';
import { CirclePlusIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Loading from '@/components/atoms/Loading/Loading';
import Header from '@/components/organisms/Header/Header';
import { MODAL_MEMBER_INVITE } from '@/components/organisms/Modals/ModalConductor';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useHomeCheckList, {
  AccountUserPrefsEnum,
} from '@/hooks/useHomeCheckList';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { pushModal } from '@/redux/modals/actions';
import {
  getPermissions,
  selectPermissions,
} from '@/redux/permissions/selectors';
import { selectIframeView, selectUser } from '@/redux/user/selectors';

import HomeFieldset from './fieldsets/HomeFieldset/HomeFieldset';
import HomeOverview from './fieldsets/HomeOverview/HomeOverview';
import SidebarFieldset from './fieldsets/SidebarFieldset/SidebarFieldset';
import HomeCompleted from './HomeCompleted';
import useHomeInit from './useHomeInit';

import styles from './Home.module.scss';

const Home = () => {
  useHomeInit();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const permissions = useSelector(selectPermissions);
  const firstName = user?.name?.split(' ')[0];
  const { t } = useTranslation();
  const title = `${t('common.welcome')} ${firstName}`;
  const isIframeView = useSelector(selectIframeView);
  const handleClick = () => {
    dispatch(pushModal(MODAL_MEMBER_INVITE));
  };

  const { accountUserChecklist } = useHomeCheckList();

  const isCompleted = accountUserChecklist[AccountUserPrefsEnum.COMPLETED];

  const canAssign = useSelector((state: RootState) =>
    getPermissions(state, 'roles', actions.ASSIGN)
  );

  const canReadBraiins = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.READ)
  );

  if (!permissions) {
    return <Loading />;
  }
  const inviteAction = canAssign ? (
    <Button onClick={handleClick} variant="tertiary">
      <CirclePlusIcon size={16} color="var(--color-accent)" />
      {t('members.invite')}
    </Button>
  ) : undefined;

  return (
    <Layout>
      <Header>
        <Header.Body>
          <Header.Title
            title={
              <Typography
                variant="h2-semi-bold"
                mr="var(--space-24)"
                color="var(--color-foreground)"
              >
                {title}
              </Typography>
            }
            withDesk
          />

          {!isIframeView && <Header.Actions>{inviteAction}</Header.Actions>}
        </Header.Body>
      </Header>
      <PageContentWrapper fullHeight>
        {canReadBraiins && (
          <div className={styles.overviewContainer}>
            <HomeOverview />
          </div>
        )}
        {!isCompleted && !isIframeView && (
          <div className={styles.container}>
            <HomeFieldset />
            <SidebarFieldset />
          </div>
        )}
        {(isCompleted || isIframeView) && <HomeCompleted />}
      </PageContentWrapper>
    </Layout>
  );
};

export default Home;
