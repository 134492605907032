import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { AlertTriangleIcon, CircleAlertIcon } from 'lucide-react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import styles from '@/components/organisms/Dialogs/Action/Action/Action.module.scss';
import ActionHeader from '@/components/organisms/Dialogs/ActionHeader/ActionHeader';
import Box from '@/components/organisms/Dialogs/Box/Box';
import Placeholder from '@/components/organisms/Dialogs/Node/RequisitePlaceholder';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import useMenu from '@/hooks/useMenu';
import { Requisite as RequisiteType } from '@/models/node';
import { RootState } from '@/models/state';
import {
  selectHasError,
  selectHasWarning,
} from '@/redux/dialogAlerts/selectors';
import { TYPES, generateRequisite } from '@/redux/dialogs/helper';
import { popModal, pushModal } from '@/redux/modals/actions';
import {
  addNewRequisite,
  moveRequisite,
  removeRequisite,
  selectRequisite,
} from '@/redux/nodes/actions';
import {
  selectIsIdInFlow,
  selectIsNodeConditionRequisiteSelected,
} from '@/redux/nodes/selectors';
import { isKeyEnter, preventClickThrough } from '@/util/util';

interface Props {
  requisite: RequisiteType;
  index: number;
  nodeId: string;
  requisiteIndex?: number;
}

const type = TYPES.QUESTION;

const Requisite = ({ requisite, index, nodeId }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { deleteRequisite } = useMenu();
  const hasDialogError = useSelector(selectHasError(requisite.requisite_id));
  const hasDialogWarning = useSelector(
    selectHasWarning(requisite.requisite_id)
  );

  const [_, dragRef] = useDrag({
    item: {
      nodeId,
      requisiteIndex: index,
      move: true,
      subType: TYPES.QUESTION,
    },
    type: TYPES.QUESTION,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const { selectedRequisiteIndex, selectedNodeId } = useSelector(
    (state: RootState) => ({
      selectedRequisiteIndex: state.nodes.selectedRequisiteIndex,
      selectedNodeId: state.nodes.selectedNodeId,
    }),
    shallowEqual
  );

  const isActionInFlow = useSelector(selectIsIdInFlow(requisite.requisite_id));
  const isAnythingSelected = useSelector(
    selectIsNodeConditionRequisiteSelected
  );

  const dropRequisiteInNode = useCallback(
    (item) => {
      if (item.move) {
        return dispatch(
          moveRequisite({
            source: item,
            target: {
              nodeId,
              index,
            },
          })
        );
      }

      return dispatch(
        addNewRequisite({
          nodeId,
          requisite: generateRequisite(),
          requisiteIndex: index,
        })
      );
    },
    [dispatch, index, nodeId]
  );

  const onClick = useCallback(
    (e) => {
      preventClickThrough(e);
      dispatch(
        selectRequisite({ nodeId, index, requisiteId: requisite.requisite_id })
      );
    },
    [dispatch, index, nodeId, requisite.requisite_id]
  );

  const onKeyUp = useCallback(
    (e) => {
      preventClickThrough(e);
      if (isKeyEnter(e)) {
        dispatch(
          selectRequisite({
            nodeId,
            index,
            requisiteId: requisite.requisite_id,
          })
        );
      } else if (e.key === 'Delete') {
        const deleteProps = {
          subtitle: (
            <Trans
              i18nKey="dialog.delete_requisite"
              values={[requisite?.save_as ?? 'question']}
            />
          ),
          confirm: false,
          onDelete: () => {
            dispatch(removeRequisite({ nodeId, index }));
            dispatch(popModal());
          },
          secondaryButtonText: t('common.cancel'),
        };
        dispatch(pushModal(MODAL_DELETE, deleteProps));
      }
    },
    [dispatch, nodeId, index, requisite.requisite_id, requisite?.save_as, t]
  );

  const [{ canDrop }] = useDrop({
    accept: TYPES.QUESTION,
    drop: dropRequisiteInNode,
    canDrop: () => {
      return true;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });

  const isSelected =
    nodeId === selectedNodeId && index === selectedRequisiteIndex;
  return (
    <>
      <section
        id={requisite.requisite_id}
        className={cn(styles.action, {
          [styles['action--placeholder']]: canDrop,
        })}
      >
        <div className={cn(styles.icon)}>
          {hasDialogError ? (
            <AlertTriangleIcon size={16} color="var(--color-destructive)" />
          ) : hasDialogWarning ? (
            <CircleAlertIcon color="var(--color-warning)" size={14} />
          ) : null}
        </div>

        <Box
          ref={dragRef}
          className={cn({
            [styles.selected]: isSelected,
            [styles.selectedWarning]: hasDialogWarning && isSelected,
            [styles.selectedError]: isSelected && hasDialogError,
            [styles.opacity]: !isActionInFlow && !isAnythingSelected,
          })}
          role="button"
          tabIndex={0}
          onKeyUp={onKeyUp}
          onClick={onClick}
        >
          <ActionHeader
            type={type}
            onDelete={deleteRequisite}
            objectToCopy={requisite}
            isSelected={isSelected}
            hasDialogError={hasDialogError}
          />
          <div className={styles.action__content}>
            <Typography
              color="var(--color-foreground-muted)"
              variant="label-regular"
            >
              {t('dialog.question.save_as', { 0: requisite?.save_as })}
            </Typography>
          </div>
        </Box>
      </section>
      <Placeholder
        nodeId={nodeId}
        requisiteIndex={index + 1}
        requisiteId={requisite.requisite_id}
      />
    </>
  );
};

export default Requisite;
