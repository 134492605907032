/* eslint-disable @typescript-eslint/no-explicit-any */

import { Trash2Icon } from 'lucide-react';
import { Control, Path, UseFormRegister, UseFormReset } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Autocomplete from '@/components/atoms/AutoComplete/AutoComplete';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import {
  integrationRulesWebWidget,
  MINIMUM_LANGUAGE_LENGTH,
  errorMessage,
} from '@/util/validator';

import styles from './VisitorFormCard.module.scss';

type Props<FormType> = {
  index: number;
  onDelete: () => void;
  onBlur?: () => void;
  register: UseFormRegister<FormType>;
  errors: any;
  watch?: any;
  reset?: UseFormReset<FormType>;
  control?: Control<FormType>;
  languageOptions?: { label: string; value: string }[];
};

export const fieldOptions = [
  { value: 'email', label_key: 'field.email' },
  { value: 'display_name', label_key: 'field.name' },
  { value: 'phone', label_key: 'field.phone' },
  { value: 'address', label_key: 'field.address' },
  { value: 'language', label_key: 'field.language' },
];

const VisitorFormCard = <FormType,>({
  index,
  errors,
  onBlur,
  onDelete,
  register,
  watch,
  control,
  languageOptions,
}: Props<FormType>) => {
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  const requiredOptions = [
    { value: true, label: t('common.yes') },
    { value: false, label: t('common.no') },
  ];

  const isCustom = watch(`fields.${index}.field`) === 'custom';
  const isLanguage = watch(`fields.${index}.field`) === 'language';
  const isFields = watch('fields');
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.levelOne}>
          <div>
            <Select
              label={t('integrations.visitor.select_field')}
              register={register(`fields.${index}.field` as Path<FormType>, {
                required: isFields ? true : undefined,
              })}
              name="field"
              options={fieldOptions}
              sortBy="label_key"
              size="medium"
              defaultValue={'custom'}
              onChange={() => {}}
              disabled={!canWrite}
            />
          </div>
          <div>
            <Select
              label={t('common.required')}
              register={register(`fields.${index}.required` as Path<FormType>)}
              name="required"
              options={requiredOptions}
              size="medium"
              disabled={!canWrite}
            />
          </div>
        </div>
        {isCustom && (
          <div className={styles.levelTwo}>
            <Input
              error={!!errors.fields?.[index]?.field}
              label={t('integrations.visitor.field_name')}
              name={`fields.${index}.custom` as const}
              placeholder={t('integrations.visitor.custom_name')}
              onBlur={onBlur}
              register={register(`fields.${index}.custom` as Path<FormType>)}
              size="medium"
              readOnly={!canWrite}
            />
          </div>
        )}
        {isLanguage && languageOptions && (
          <div className={styles.levelTwo}>
            <Autocomplete
              getOptionSelected={(option, value) => {
                return option.value === value.value;
              }}
              control={control}
              name={`fields.${index}.languages`}
              options={languageOptions}
              placeholder={t('integrations.visitor.languages')}
              multiple
              disabled={!canWrite}
              disableAddNew
              error={!!errors.fields?.[index]?.languages}
              errorMessage={errorMessage({
                field: errors.fields?.[index]?.languages,
                minLength: MINIMUM_LANGUAGE_LENGTH,
              })}
              rules={
                integrationRulesWebWidget.visitor_information.fields.language
              }
            />
          </div>
        )}
        <span className={styles.delete}>
          <IconButton
            onClick={onDelete}
            ariaLabel={t('integrations.web_visitor.remove_field')}
          >
            <Trash2Icon size={16} />
          </IconButton>
        </span>
      </div>
    </>
  );
};

export default VisitorFormCard;
