import { Fragment, memo } from 'react';

import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  User,
  Message as MessageType,
  ConversationSource,
} from '@/models/chat';

import Message from '../Message';
import MessageStatus from '../MessageStatus/MessageStatus';

import styles from '../MessageStatus/MessageStatus.module.scss';

type Props = {
  message: MessageType;
  showTime: boolean;
  showAvatar: boolean;
  user: User;
  visitorStatus: 'online' | 'offline' | 'away' | null;
  lastVisitorActivity: string | null;
  source: ConversationSource;
};

export const MessageGroup = memo(
  ({
    message,
    showTime,
    showAvatar,
    user,
    visitorStatus,
    lastVisitorActivity,
    source,
  }: Props) => {
    const { failed_at, author_type } = message;
    const { t } = useTranslation();

    return (
      <Fragment key={message.message_id}>
        <Message
          message={message}
          showAvatar={showAvatar}
          user={user}
          group={showTime}
          visitorStatus={visitorStatus}
          lastVisitorActivity={lastVisitorActivity}
          source={source}
        />
        {failed_at ? (
          <div
            className={cn(styles.messageStatus, {
              [styles.visitor]: author_type === 'visitor',
            })}
          >
            <Typography variant="label-regular" color="error">
              {t('conversation.message_failed')}
            </Typography>
          </div>
        ) : (
          showTime && (
            <MessageStatus
              created={message.created}
              authorType={author_type}
              shouldShow={showTime}
            />
          )
        )}
      </Fragment>
    );
  }
);

MessageGroup.displayName = 'MessageGroup';
