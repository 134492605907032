import { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import uniqBy from 'lodash/uniqBy';
import {
  BookmarkIcon,
  CircleHelpIcon,
  CrosshairIcon,
  SearchIcon,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { Collection } from '@/models/collections';
import {
  AmendPayload,
  CollectionMessage,
  Entity,
  Fragment,
  NodeType,
  UserMessage,
} from '@/models/tryIt';
import { LogMessage, LogSessionContentRow } from '@/modules/analytics/models';
import { useDetailsSection } from '@/modules/TryIt/hooks/useDetailsSection';
import {
  selectSelectedMessageIndex,
  selectSessionNodes,
  selectTryItSessionId,
} from '@/modules/TryIt/redux/selectors';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { resolveBrainsPath } from '@/util/util';

import { DocumentLine } from './CollectionDetailsSection/DocumentLine';
import { StandaloneWrapper } from './StandaloneWrapper';
import { PossibleMatchedIntents } from '../../Messages/PossibleMatchedIntents/PossibleMatchedIntents';
import { StatusIcon } from '../../StatusIcon/StatusIcon';

import styles from './DetailsSection.module.scss';

type UnknownDetailsSectionProps = {
  content: LogSessionContentRow;
};

export type UnknownDetailsSectionContentProps = {
  nodes: NodeType[] | Partial<NodeType>[];
  index: number;
  entities: Entity[];
  collection: Collection;
  uniqueDocuments: Fragment[];
  slug: string;
  messages?: LogMessage[];
  session_id?: string;
  activeBrainId: string;
};

export const UnknownDetailsSectionContent = ({
  nodes,
  index,
  entities,
  collection,
  uniqueDocuments,
  slug,
  messages,
  session_id,
  activeBrainId,
}: UnknownDetailsSectionContentProps) => {
  const { t } = useTranslation();
  const tryItSessionId = useSelector(selectTryItSessionId);

  const dispatch = useDispatch();

  const intent =
    nodes[index]?.intents?.[0] ||
    (messages?.[index]?.message as UserMessage)?.intents?.[0];
  const intentName = intent?.intent;

  const userMessage = (nodes[index]?.messages[0] as CollectionMessage)?.text;

  const planner_intents = nodes[index]?.planner_intents;
  const isPlannerIntentOfftopic =
    planner_intents?.includes('offtopics') && planner_intents?.length === 1;

  const plannerIntent = !isPlannerIntentOfftopic && planner_intents?.[0];

  const possibleMatchedIntents =
    nodes[index]?.intents ||
    (messages?.[index]?.message as UserMessage)?.intents;

  const closeModal = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const showAddTrainingPhrase = intent?.confidence !== 1;

  const { brain } = useBrains(activeBrainId);
  const { ai_agents } = useFeatureFlag();
  const amendPayload: AmendPayload = {
    request_id:
      nodes[index].request_id || nodes[index].nodes_stack[0]?.request_id,
    task: 'standalone',
    session_id: session_id || tryItSessionId,
    language: brain?.language,
  };

  const isAmended = !!nodes[index]?.corrections?.find(
    (c) => c.task === 'standalone'
  );

  const isIntentAmended = !!nodes[index]?.corrections?.find(
    (c) =>
      c.task === 'planner' &&
      possibleMatchedIntents?.some((i) => i.intent === c.correction)
  );

  return (
    <Stack data-testid="newDebugLogs">
      <StandaloneWrapper
        userMessage={userMessage}
        amendPayload={amendPayload}
        isAmended={isAmended}
      />

      {entities.length > 0 && (
        <div className={styles.step}>
          <div className={styles.title}>
            <CrosshairIcon size={16} color="var(--color-foreground-muted)" />
            <Typography
              variant="body-regular"
              color="var(--color-foreground-muted)"
              component="div"
            >
              {t('try_it.entity', {
                count: entities?.length,
              })}{' '}
              {entities?.map((entity, index) => (
                <>
                  <Typography key={entity.value} variant="body-regular">
                    <NavLink
                      to={resolveBrainsPath(
                        `/${slug}/brains/${activeBrainId}/entities/${entity.entity}`,
                        ai_agents
                      )}
                      className={styles.link}
                      onClick={closeModal}
                    >
                      {entity.entity}
                    </NavLink>
                  </Typography>
                  {index < entities.length - 1 && ', '}
                </>
              ))}{' '}
              {t('try_it.matched')}
            </Typography>
          </div>
          <div className={styles.details}></div>
        </div>
      )}

      <div className={styles.step}>
        <PossibleMatchedIntents
          key={amendPayload.request_id}
          brainId={activeBrainId}
          isPlannerIntentOfftopic={isPlannerIntentOfftopic}
          intentName={plannerIntent ?? intentName}
          amendPayload={amendPayload}
          isAmended={isIntentAmended}
          possibleMatchedIntents={possibleMatchedIntents}
          showAddTrainingPhrase={showAddTrainingPhrase}
          userMessage={userMessage}
        />
      </div>

      {collection && (
        <>
          <div className={styles.step}>
            <div className={styles.title}>
              <BookmarkIcon color="var(--color-foreground-muted)" size={16} />

              <Typography
                variant="body-regular"
                color="var(--color-foreground-muted)"
                component="div"
              >
                {t('collections.collection')}{' '}
                <NavLink
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`/${slug}/collections/${collection?.collection_id}`}
                  className={styles.link}
                >
                  {collection?.name}
                </NavLink>{' '}
                {t('try_it.triggered')}
              </Typography>
            </div>
            <div className={styles.details}></div>
          </div>
          <div className={styles.step}>
            <div className={styles.title}>
              <SearchIcon size={16} color="var(--color-foreground-muted)" />

              <Typography
                variant="body-regular"
                color="var(--color-foreground-muted)"
                component="div"
              >
                {t('try_it.document_retrieved', {
                  0: uniqueDocuments.length,
                  count: uniqueDocuments.length,
                })}
              </Typography>
            </div>
            <div className={styles.details}>
              {uniqueDocuments.length > 0 && (
                <ol className={styles.retrievedDocuments}>
                  {uniqueDocuments.map((document) => (
                    <DocumentLine
                      key={document.document_id}
                      collectionId={
                        nodes[index]?.collection?.collection_id ??
                        messages?.[index]?.collection_id
                      }
                      datasourceId={document.datasource_id}
                      documentId={document.document_id}
                    />
                  ))}
                </ol>
              )}
            </div>
          </div>
          <div className={styles.step}>
            <div className={styles.title}>
              <StatusIcon status="warning">
                <SearchIcon size={16} color="var(--color-foreground-muted)" />
              </StatusIcon>

              <Typography
                variant="body-regular"
                color="var(--color-foreground-muted)"
                component="div"
              >
                {t('try_it.answer_not_found_in_collection')}
              </Typography>
            </div>
            <div className={styles.details}></div>
          </div>
        </>
      )}

      <div className={styles.step}>
        <div className={styles.title}>
          <CircleHelpIcon size={16} color="var(--color-foreground-muted)" />

          <Typography
            variant="body-regular"
            color="var(--color-foreground-muted)"
            component="div"
          >
            {t('try_it.unknown_response_sent')}
          </Typography>
        </div>
        <div className={styles.details}></div>
      </div>
    </Stack>
  );
};

export const UnknownDetailsSection = ({
  content,
}: UnknownDetailsSectionProps) => {
  const brainId = useSelector(selectBrainId);

  const { session_id, brain_id: logBrainId } = content || {};

  const activeBrainId = brainId || logBrainId;
  const index = useSelector(selectSelectedMessageIndex);
  const nodes = useSelector(selectSessionNodes);

  const messages = content?.messages.filter(
    (msg) =>
      msg.event === 'message:brain_send' ||
      msg.event === 'message:broadcast_send'
  );

  const { slug } = useAccount();
  const { collection, uniqueDocuments } = useDetailsSection(
    nodes[index]?.collection,
    messages?.[index]
  );

  const entities = uniqBy(
    (nodes[index]?.messages[0] as UserMessage)?.entities,
    'entity'
  ) as unknown as Entity[];

  return (
    <UnknownDetailsSectionContent
      {...{
        nodes,
        index,
        entities,
        collection,
        uniqueDocuments,
        slug,
        messages,
        session_id,
        activeBrainId,
      }}
    />
  );
};
