export type Size = 'xxs' | 'xs' | 'small' | 'medium' | 'large' | 'xlarge';

const tags = {
  primary: {
    '&.MuiChip-deletable': {
      backgroundColor: 'var(--color-accent)',
      color: 'var(--color-text-primary-foreground)',
      '&:hover': {
        backgroundColor: 'var(--color-surface-button-primary-hover)',
        color: 'var(--color-text-primary-foreground)',
        '& .MuiChip-deleteIcon': {
          borderRadius: '4px',
          color: 'var(--color-foreground-muted)',
          '&:hover': {
            color: 'var(--color-highlight-background)',
          },
        },
      },
    },
  },
  default: {
    '&.MuiChip-deletable': {
      borderRadius: 'var(--space-20)',
      backgroundColor: 'var(--color-bg-muted)',
    },
  },
  secondary: {
    '&.MuiChip-deletable': {
      backgroundColor: 'var(--color-bg-muted)',
      '&:hover': {
        backgroundColor: 'var(--color-bg-muted)',
      },
      '& .MuiChip-deleteIcon': {
        color: 'var(--color-informational)',
        borderRadius: '4px',
        '&:hover': {
          color: 'var(--color-accent)',
        },
      },
    },
  },
};

export const getSize = (size: Size) => {
  switch (size) {
    case 'xxs':
      return 30;
    case 'xs':
      return 32;
    case 'small':
      return 36;
    case 'medium':
      return 40;
    case 'xlarge':
      return 44;
    default:
      return 40;
  }
};

export const getStyles = (
  size: Size,
  variant: 'primary' | 'secondary' | 'default',
  multiple: boolean
) => ({
  '& .MuiAutocomplete-inputRoot': {
    minHeight: `${getSize(size)}px`,
    height: 'auto',
  },

  inputRoot: {
    '&.MuiOutlinedInput-root.MuiAutocomplete-hasClearIcon': {
      paddingRight: multiple ? '65px' : '40px',
    },
  },
  input: {
    padding: '6px 8px',
    height: '100%',
    flex: '1 1',
  },
  tag: tags[variant],
});
