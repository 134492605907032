import Typography from '@mui/material/Typography';
import { UseMutateFunction } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Autocomplete from '@/components/atoms/AutoComplete/AutoComplete';
import Button from '@/components/atoms/Button/Button/Button';
import Input from '@/components/atoms/Input/Input';
import { MODAL_API_KEY_CREATED } from '@/components/organisms/Modals/ModalConductor';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { pushModal } from '@/redux/modals/actions';
import { noUrlPattern } from '@/util/validator';

import styles from './GenerateKeyForm.module.css';
import useCredentials from '../../hooks/useCredentials';
import { Credential } from '../../types';

type Form = {
  name: Credential['name'];
  type: Credential['type'];
};

type GenerateKeyFormContentProps = {
  createCredential: UseMutateFunction<
    Credential,
    Error,
    Partial<Credential>,
    unknown
  >;
};

export const GenerateKeyFormContent = ({
  createCredential,
}: GenerateKeyFormContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const CREDENTIAL_TYPES = [
    {
      value: 'analytics',
      label: t('developer_tools.api_form.type.analytics'),
      description: t('developer_tools.api_form.type.analytics_description'),
    },
    {
      value: 'manage',
      label: t('developer_tools.api_form.type.manage'),
      description: t('developer_tools.api_form.type.manage_description'),
    },
    {
      value: 'use',
      label: t('developer_tools.api_form.type.use'),
      description: t('developer_tools.api_form.type.use_description'),
    },
  ];

  // RHF
  const {
    register,
    formState: { errors, isSubmitting, dirtyFields },
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
  } = useForm<Form>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      type: null,
    },
  });
  const isDisabled = Object.keys(dirtyFields).length < 2;

  const onSubmit = (data: Form) => {
    createCredential(data, {
      onSuccess: (resp) => {
        const modalProps = {
          apiKey: resp?.key,
          name: resp?.name,
          type: t(`developer_tools.api_form.type.${resp?.type}`),
        };

        dispatch(pushModal(MODAL_API_KEY_CREATED, { ...modalProps }));
        reset();
        dispatch(
          addTemporalToast(
            'success',
            t('developer_tools.credentials.created', { 0: resp?.type })
          )
        );
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });
  };

  return (
    <section
      aria-labelledby="generate-api-key-title"
      className={styles.wrapper}
    >
      <header>
        <Typography
          component="h2"
          variant="subheading-bold"
          color="var(--color-foreground-muted)"
        >
          {t('developer_tools.api_form.title')}
        </Typography>
        <Typography
          component="p"
          variant="label-regular"
          color="var(--color-foreground-muted)"
        >
          {t('developer_tools.api_form.title')}
        </Typography>
      </header>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <fieldset>
          <Input
            label={t('common.name')}
            name="name"
            register={register('name', {
              required: t('validation.required'),
              maxLength: {
                value: 32,
                message: t('validation.maxLength', {
                  0: 32,
                }),
              },
              validate: {
                noUrl: (value) => {
                  return (
                    !noUrlPattern.test(value) || t('validation.url_not_allowed')
                  );
                },
              },
            })}
            errorMessage={errors.name?.message}
            size="large"
          />

          <Autocomplete
            control={control}
            name="type"
            label={t('common.type')}
            options={CREDENTIAL_TYPES}
            placeholder={t('developer_tools.api_form.select_type')}
            labelLarge
            disableAddNew
            size="small"
            variant="secondary"
            error={!!errors?.type?.message}
            errorMessage={errors.type?.message}
            rules={{
              required: t('validation.required'),
            }}
            onChange={async (option) => {
              setValue('type', option?.value);

              await trigger('type');
            }}
            getOptionSelected={(option, value) => {
              return option?.value === value;
            }}
            filterSelectedOptions={false}
            getOptionLabel={(option) => {
              if (!option) {
                return '';
              }

              return t(`developer_tools.api_form.type.${option}`);
            }}
          />
        </fieldset>

        <Button
          variant="primary"
          disabled={isDisabled}
          isLoading={isSubmitting}
          size="large"
          type="submit"
        >
          {t('common.generate')}
        </Button>
      </form>
    </section>
  );
};

export const GenerateKeyForm = () => {
  const { createCredential } = useCredentials();

  return <GenerateKeyFormContent createCredential={createCredential} />;
};
