import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router';

import Header from '@/components/organisms/Header/Header';
import { accountSettings } from '@/components/organisms/Navbar/SettingsNavigation/constants';
import { SettingsNavigation } from '@/components/organisms/Navbar/SettingsNavigation/SettingsNavigation';
import AccountDanger from '@/components/pages/AccountDanger/AccountDanger';
import { AccountSettings } from '@/components/pages/AccountSettings/AccountSettings';
import { ActivityLogs } from '@/components/pages/ActivityLogs/ActivityLogs';
import { DataPrivacy } from '@/components/pages/DataPrivacy/DataPrivacy';
import NotFound from '@/components/pages/NotFound/NotFound';
import Layout from '@/components/templates/Layout/Layout';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import Billing from '@/modules/billing/pages/Billing';
import Invoices from '@/modules/billing/pages/Invoices/Invoices';
import { getPermissions } from '@/redux/permissions/selectors';

import { HumanAgents } from '../HumanAgents/HumanAgents';

const AccountSettingsRouter: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const title_key = accountSettings.find((s) =>
    location.pathname.includes(s.link)
  )?.title_key;

  const canUpdateAccount = useSelector((state: RootState) =>
    getPermissions(state, 'accounts', actions.UPDATE)
  );

  const isReadOnly = title_key == 'common.information' && !canUpdateAccount;

  return (
    <Layout withSidebar>
      <SettingsNavigation />
      <Header>
        <Header.Body>
          <Header.Title
            title={
              <Typography
                variant="h2-semi-bold"
                color="var(--color-foreground)"
              >
                {t(title_key)}
              </Typography>
            }
          />
        </Header.Body>
      </Header>
      <PageContentWrapper
        fullHeight={!isReadOnly}
        newPlain2={isReadOnly}
        readOnly={isReadOnly}
      >
        <Routes>
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="/information" element={<AccountSettings />} />
          <Route path="/danger" element={<AccountDanger />} />
          <Route path="/activity-logs" element={<ActivityLogs />} />
          <Route path="/human-agents" element={<HumanAgents />} />
          <Route path="/data-privacy" element={<DataPrivacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </PageContentWrapper>
    </Layout>
  );
};

export default AccountSettingsRouter;
