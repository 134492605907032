import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { pluginsEndpoints as endpoints } from '../../../api/endpoints';
import { callDelete, callGet, callPost, callPut } from '../../../api/fetcher';
import { selectAccountId } from '../../../redux/session/selectors';
import { addTemporalToast } from '../../notifications/redux/actions';
import { Plugin, Plugins } from '../types';

/**
 * API object containing methods for interacting with the plugins API
 */
export const API = Object.freeze({
  /**
   * Fetches all plugins
   * @returns Promise resolving to Plugins object
   */
  listPlugins: async (): Promise<Plugins> => callGet(endpoints.plugins),

  /**
   * Creates a new plugin
   * @param newPlugin - Partial Plugin object with required fields
   * @returns Promise resolving to the created Plugin
   */
  createPlugin: async (newPlugin: Partial<Plugin>): Promise<Plugin> =>
    callPost(endpoints.plugins, newPlugin),

  /**
   * Fetches a specific plugin by ID
   * @param pluginId - ID of the plugin to fetch
   * @returns Promise resolving to Plugin object
   */
  getPlugin: async (pluginId: string): Promise<Plugin> =>
    callGet(endpoints.plugin(pluginId)),

  /**
   * Updates an existing plugin
   * @param pluginId - ID of the plugin to update
   * @param updatedPlugin - Partial Plugin object with fields to update
   * @returns Promise resolving to the updated Plugin
   */
  updatePlugin: async (
    pluginId: string,
    updatedPlugin: Partial<Plugin>
  ): Promise<Plugin> => callPut(endpoints.plugin(pluginId), updatedPlugin),

  /**
   * Deletes a plugin
   * @param pluginId - ID of the plugin to delete
   * @returns Promise resolving to the deleted Plugin
   */
  deletePlugin: async (pluginId: string): Promise<Plugin> =>
    callDelete(endpoints.plugin(pluginId)),
});

/**
 * Updates the query cache when a plugin is created
 * @param queryClient - React Query client
 * @param plugin - The created plugin
 */
export const onPluginCreated = (
  queryClient: QueryClient,
  plugin: Plugin
): void => {
  queryClient.setQueryData<Plugin>(
    [endpoints.plugin(plugin.plugin_id)],
    (prev: Plugin) => ({ ...prev, ...plugin })
  );

  const queryKey = [endpoints.plugins, plugin.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Plugins>(queryKey, (prev) => ({
      plugins: [
        ...(prev?.plugins || []).filter(
          (p) => p.plugin_id !== plugin.plugin_id
        ),
        plugin,
      ],
    }));
  }
};

/**
 * Updates the query cache when a plugin is deleted
 * @param queryClient - React Query client
 * @param account_id - Account ID
 * @param plugin_id - ID of the deleted plugin
 */
export const onPluginDelete = (
  queryClient: QueryClient,
  account_id: string,
  plugin_id: string
): void => {
  const queryKey = [endpoints.plugins, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Plugins>(queryKey, (prev: Plugins) => ({
      plugins: (prev?.plugins || []).filter((p) => p.plugin_id !== plugin_id),
    }));
  }
  queryClient.removeQueries({
    queryKey: [endpoints.plugin(plugin_id)],
  });
};

/**
 * Updates the query cache when a plugin is updated
 * @param queryClient - React Query client
 * @param plugin - The updated plugin
 */
export const onPluginUpdated = (
  queryClient: QueryClient,
  plugin: Plugin
): void => {
  const queryKey = [endpoints.plugin(plugin.plugin_id)];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Plugin>(queryKey, (prev) => ({
      ...prev,
      ...plugin,
    }));
  }

  // update all
  const pluginsQueryKey = [endpoints.plugins, plugin.account_id];
  if (queryClient.getQueryData(pluginsQueryKey)) {
    queryClient.setQueryData<Plugins>(pluginsQueryKey, (prev) => ({
      ...prev,
      plugins: prev.plugins.map((p) =>
        p.plugin_id === plugin.plugin_id ? plugin : p
      ),
    }));
  }
};

/**
 * Hook for managing plugins
 * @param pluginId - Optional plugin ID for fetching a specific plugin
 * @returns Object containing plugins data and mutation functions
 */
const usePlugins = (pluginId?: string) => {
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // For now, we'll skip the permission check since we don't have the correct module name
  // This should be updated once the correct module name is known
  const canReadPlugins = true;

  // Query for fetching all plugins
  const { data: plugins, status: listStatus } = useQuery<Plugins, Error>({
    queryKey: [endpoints.plugins, accountId],
    queryFn: () => API.listPlugins(),
    enabled: !!accountId && canReadPlugins,
  });

  // Mutation for creating a new plugin
  const { mutate: createPlugin, status: createStatus } = useMutation<
    Plugin,
    Error,
    Partial<Plugin>
  >({
    mutationFn: (newPlugin: Partial<Plugin>) => API.createPlugin(newPlugin),
    onSuccess: (resp) => {
      onPluginCreated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t('plugins.created', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addTemporalToast('error', error.message));
    },
  });

  // Query for fetching a specific plugin
  const { data: plugin, status: getStatus } = useQuery<Plugin, Error>({
    queryKey: [endpoints.plugin(pluginId)],
    queryFn: () => API.getPlugin(pluginId),
    enabled: !!pluginId,
  });

  // Mutation for updating a plugin
  const { mutate: updatePlugin, status: updateStatus } = useMutation<
    Plugin,
    Error,
    { id: string; data: Partial<Plugin> }
  >({
    mutationFn: ({ id, data }) => API.updatePlugin(id, data),
    onSuccess: (resp) => {
      onPluginUpdated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t('plugins.updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addTemporalToast('error', error.message));
    },
  });

  // Mutation for deleting a plugin
  const { mutate: deletePlugin, status: deleteStatus } = useMutation<
    Plugin,
    Error,
    string
  >({
    mutationFn: (pluginId) => API.deletePlugin(pluginId),
    onSuccess: (resp) => {
      onPluginDelete(queryClient, accountId, resp.plugin_id);
      dispatch(addTemporalToast('success', t('plugins.deleted')));
    },
  });

  return {
    plugins: plugins?.plugins || [],
    listStatus,
    createPlugin,
    createStatus,
    plugin,
    getStatus,
    updatePlugin,
    updateStatus,
    deletePlugin,
    deleteStatus,
  };
};

export default usePlugins;
