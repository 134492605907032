export const listStyles = {
  width: '300px',
  maxHeight: '368px',
  height: 'auto',
  overflow: 'auto',
  border: '1px solid var(--color-stroke)',
  boxShadow: 'var(--shadow-primary)',
  borderRadius: 'var(--space-4)',
  color: 'var(--color-foreground-muted)',
  position: 'relative',
  '& ul': { padding: 0 },
  bgcolor: 'background.paper',
  padding: 'var(--space-4)',
};

export const listSubheaderStyles = {
  backgroundColor: 'var(--color-background)',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const listItemButtonStyles = {
  height: '32px',
  '&.Mui-selected': {
    backgroundColor: 'var(--color-bg-muted)',
  },
  '&.Mui-selected:hover': {
    backgroundColor: 'var(--color-bg-muted)',
  },
  display: 'flex',
  alignItems: 'center',
};

export const typographyStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: 1.2,

  '& .locker': {
    transform: 'translateY(2px)',
    display: 'inline-flex',
  },
};
