import { useCallback, useEffect, useMemo } from 'react';

import { Plate } from '@udecode/plate/react';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import { EditorProvider } from '@/components/ui/editor/editor-context';
import { useCreateEditor } from '@/components/ui/editor/use-create-editor';
import {
  Editor as PlateEditor,
  EditorContainer,
} from '@/components/ui/plate-ui/editor';
import { removeTrailingBRs } from '@/modules/aiAgents/helper';

import { handleKeyDown, insertMentions } from './util';

interface Props {
  defaultValue?: string;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  value?: string;
  reset?: boolean;
  expanded?: boolean;
  disabled?: boolean;
  size?: 'default' | 'small';
}

const emptyValue = [
  {
    children: [
      {
        text: '',
      },
    ],
    type: 'p',
  },
];

export default function BasicEditorStylingDemo({
  defaultValue,
  onChange,
  autoFocus,
  placeholder,
  value = '',
  reset,
  disabled = false,
  size = 'default',
}: Props) {
  const editor = useCreateEditor({ defaultValue, readOnly: disabled });
  const { t } = useTranslation();

  const handleChange = useCallback(() => {
    insertMentions(editor);
    if (editor && editor?.api) {
      const content = editor?.api.markdown.serialize();
      const newContent = removeTrailingBRs(content);
      onChange(newContent);
    }
  }, [editor, onChange]);

  useEffect(() => {
    if (defaultValue) {
      handleChange();
    }
  }, [defaultValue, handleChange]);

  useEffect(() => {
    if (reset) {
      editor.children = emptyValue;
    }
  }, [editor, reset]);

  const debouncedHandleChange = useMemo(
    () => debounce(handleChange, 100),
    [handleChange]
  );

  const renderLeaf = (props) => {
    const { leaf, attributes, children } = props;
    if (leaf.isMention) {
      return (
        <span {...attributes} className="text-primary">
          {children}
        </span>
      );
    }

    return <span {...attributes}>{children}</span>;
  };

  return (
    <EditorProvider value={{ size }}>
      <Plate
        editor={editor}
        onChange={debouncedHandleChange}
        renderLeaf={renderLeaf}
      >
        <EditorContainer>
          <PlateEditor
            onKeyDown={(e) => handleKeyDown(e, editor)}
            placeholder={placeholder ?? t('dialog.requisite.placeholder_type')}
            autoFocus={autoFocus}
            value={value}
            disabled={disabled}
          />
        </EditorContainer>
      </Plate>
    </EditorProvider>
  );
}
