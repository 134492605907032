import { QueryClient } from '@tanstack/react-query';
import i18n, { t } from 'i18next';
import moment from 'moment';
import { SetRequired } from 'type-fest';

import {
  DEFAULT_HOURS,
  extractIntervalsFromHours,
} from '@/components/pages/BusinessHours/utils';
import { API as brainsApi, onBrainCreated } from '@/hooks/useBrains';
import { API as businessHoursApi } from '@/hooks/useBusinessHours';
import { API as deskApi, onDeskCreated } from '@/hooks/useDesks';
import {
  API as integrationsApi,
  onIntegrationCreated,
} from '@/hooks/useIntegrations';
import { API as userPreferencesApi } from '@/hooks/usePreferences';
import { API as templatesApi } from '@/hooks/useTemplates';
import { Desk } from '@/models/desk';
import {
  Integration,
  IntegrationId,
  IntegrationType,
} from '@/models/integration';
import { onRuleCreated, API as rulesApi } from '@/modules/rules/hooks/useRules';
import { ActionType, Rule } from '@/modules/rules/model';
import { ZENDESK_DOCS_URL } from '@/util/constants';

type OnboardingVideos = {
  [key in string]: {
    upload_video?: string;
    connect_video?: string;
  };
};

type OnboardingByType = {
  [key in string]: {
    support_upload: boolean;
    upload_docs?: string;
    thumbnail_url?: string;
  };
} & OnboardingVideos;

export const ONBOARDING_BY_TYPE = {
  zendesk: {
    support_upload: true,
    upload_docs: `${ZENDESK_DOCS_URL}/hc/en-us/articles/4408893866778-Reviewing-and-exporting-past-chats-in-History`,
    upload_video: 'https://www.youtube.com/watch?v=Hw0yNgle60I',
    connect_video: 'https://www.youtube.com/watch?v=-iIOsdd5V7Y',
    thumbnail_url:
      'https://media.dev.moveo.ai/media/accounts/89468aff-a843-4d3c-9428-90578b94eeb6/093917a4-ae11-4cbf-947f-fc5249bf82d7.jpg',
  },
  intercom: {
    support_upload: false,
  },
} as OnboardingByType;

/**
 * Creates a desk and default business hours if there isn't one.
 */
export const setupDeskIfRequired = async ({
  newAccount = false,
}: {
  newAccount?: boolean;
}): Promise<Desk> => {
  const name = i18n.t('onboarding.environment_name');
  const { desks } = await deskApi.listDesks();
  let desk = desks.find((d) => d.name === name);
  // 1. Create a desk if it doesn't exist
  if (!desk) {
    desk = await deskApi.createDesk({
      name,
      is_service_desk: true,
      description: i18n.t(
        newAccount ? 'account.created' : 'onboarding.environment_description',
        {
          0: new Date(),
        }
      ),
    });
  }

  // 2. Create business hours if they do not exist
  const { business_hours } = await businessHoursApi.listBusinessHours(
    desk.desk_id
  );

  if (business_hours?.length === 0) {
    // Create default business hours
    await businessHoursApi.addBusinessHour(desk.desk_id, {
      name: i18n.t('business_hours.default'),
      timezone: moment.tz.guess(),
      is_default: true,
      intervals: extractIntervalsFromHours([DEFAULT_HOURS]),
      holidays: [],
    });
  }

  return desk;
};

/**
 * Creates integrations if there aren't there. It uses the `desk_id` and `type`
 * provided as parameter
 */
export const setupIntegrationsIfRequired = async (
  params: SetRequired<Partial<Integration>, 'desk_id'> & {
    types?: IntegrationType[];
  }
): Promise<Integration[]> => {
  const { desk_id, types, ...restParams } = params;
  const uniqueTypes = [...new Set(types)];
  const { integrations } = await integrationsApi.listIntegrations(desk_id);
  const integrationPromises = uniqueTypes.map(async (t) => {
    let integration = integrations.find((i) => i.type === t);
    if (!integration) {
      integration = await integrationsApi.createIntegration({
        ...restParams,
        desk_id,
        type: t,
        name: t,
        active: t === 'web',
      });
    }
    return integration;
  });

  return await Promise.all(integrationPromises);
};

/**
 * Creates an integration if there isn't one there. It uses the `desk_id` and `type`
 * provided as parameter
 */
export const setupIntegrationIfRequired = async (
  params: SetRequired<Partial<Integration>, 'desk_id' | 'type'>
): Promise<Integration> => {
  const { integrations } = await integrationsApi.listIntegrations(
    params.desk_id
  );

  let integration = integrations.find((i) => i.type === params.type);
  if (!integration) {
    integration = await integrationsApi.createIntegration({
      ...params,
      name: params.type,
    });
  }
  return integration;
};

export const updateIntegration = async <T extends Integration>(
  params: IntegrationId<T>
): Promise<T> => {
  let integration = await integrationsApi.getIntegration<T>(
    params.desk_id,
    params.integration_id
  );

  integration = await integrationsApi.updateIntegration<T>({
    ...integration,
    config: {
      ...integration.config,
      ...params.config,
    },
  });

  return integration;
};

/**
 * Generates the next name for a given item in a list of items with a common base name.
 */
export const generateNextName = (
  items: { name: string }[] = [],
  baseName: string
): string => {
  const regex = new RegExp(`${baseName} (\\d+)$`, 'i');

  let maxNumber = -1;
  let baseNameExists = false;
  for (const item of items) {
    if (item.name.toLowerCase() === baseName.toLowerCase()) {
      baseNameExists = true;
    }
    const match = item.name.match(regex);
    if (match) {
      const currentNumber = parseInt(match[1], 10);
      maxNumber = Math.max(maxNumber, currentNumber);
    }
  }
  if (maxNumber === -1 && !baseNameExists) {
    return baseName;
  }

  if (maxNumber === -1 && baseNameExists) {
    return `${baseName} 1`; // if only baseName exists
  }

  return `${baseName} ${maxNumber + 1}`;
};

/**
 * Creates a default rule to assign conversations to a brain if there is not one
 */
export const setupRuleIfRequired = async (
  deskId: string,
  brainId: string
): Promise<Rule> => {
  const brain_parent_id = brainId;
  const brain_version = 0;

  const { rules } = await rulesApi.listRules(deskId);

  // If there is a rule that assigns to this brain, make sure it's the first rule
  const existingRule = rules.find((rule) =>
    rule.actions.some(
      (action) =>
        action.type === ActionType.ASSIGN_BRAIN &&
        action.brain_parent_id === brain_parent_id
    )
  );
  // If the existing rule is already the first rule, nothing more to do
  if (existingRule && rules[0].rule_id === existingRule.rule_id) {
    return;
  }

  // If the existing rule is not the first rule, move it to the top
  if (existingRule) {
    await rulesApi.reorderRule(deskId, existingRule.rule_id, 1);
    return;
  }

  // otherwise, create a new rule
  const newRule = await rulesApi.addRule(deskId, {
    name: generateNextName(rules, i18n.t('onboarding.rule_name')),
    status: 'active',
    triggers: [{ type: 'first_message' }],
    condition: { conditions: [], operator: 'or' },
    actions: [
      { brain_parent_id, type: ActionType.ASSIGN_BRAIN, brain_version },
    ],
    metadata: {
      source: 'onboarding',
    },
  });

  // and move it to the top
  return await rulesApi.reorderRule(deskId, newRule.rule_id, 1);
};

// this is used only when creating a new account
export const initDeskBrainIntegration = async ({
  queryClient,
  language,
  accountId,
}: {
  queryClient?: QueryClient;
  language?: string;
  accountId?: string;
} = {}) => {
  try {
    const desk = await setupDeskIfRequired({ newAccount: true });

    if (queryClient) {
      onDeskCreated(queryClient, { ...desk, account_id: accountId });
    }

    const integr = await setupIntegrationsIfRequired({
      desk_id: desk.desk_id,
      types: ['web'],
    });

    if (queryClient) {
      for (const integration of integr) {
        onIntegrationCreated(queryClient, integration);
      }
    }

    const userPreferences = await userPreferencesApi.getUserPreferences();
    const lang = userPreferences?.preferences?.language as string;
    const brain = await brainsApi.getTemplate(language || lang || 'en');

    const createdBrain = await templatesApi.createBrain({
      ...brain,
      brain_id: 'playground',
      name: t('onboarding.first_agent'),
    });

    if (queryClient) {
      onBrainCreated(queryClient, createdBrain);
    }

    const rule = await setupRuleIfRequired(desk.desk_id, createdBrain.brain_id);

    if (queryClient) {
      onRuleCreated(queryClient, rule, desk.desk_id);
    }
  } catch (error) {
    console.error('Error initializing brain with template:', error);
    throw error;
  }
};

// helper function to create language options
export const createLangOptions = (sourceArray, languageByCountryCode, t) => {
  return sourceArray.map((i) => ({
    value: i.toLowerCase(),
    label: t(`languages.${i.toLowerCase()}`, {
      defaultValue: languageByCountryCode[i],
    }),
  }));
};
